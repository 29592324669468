import { DSSYSTEM_APIS } from "libs/apis/d-support-system.api";

const useDashboard = () => {
  const getOrgDataForAdminDashboard = async (setData: Function) => {
    const response = await DSSYSTEM_APIS.getOrgDataForAdminDashboard();
    console.log(response);
    if (response?.status) {
      setData(response?.response);
    }
  };

  return { getOrgDataForAdminDashboard };
};

export default useDashboard;
