import { FC, Fragment, useState, useEffect } from "react";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AdmissionLockedMeritListingMain,
  AdmissionLockedMeritListingSection,
  AdmissionLockedMeritListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAdmissions from "../useHooks";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import AllocateSection from "./components/extend-date";
import Pagination from "components/particles/table/pagination";

interface LockedMeritListingProps { }

const AdmissionLockedMeritListing: FC<LockedMeritListingProps> = ({ }) => {
  const breadcrumbLinks = [
    { title: "Admission /", path: "" },
    {
      title: "Locked Merit List",
      path: siteRoutes.admissionLockedMeritListing,
    },
  ];
  const {
    getLockedMeritList,
    getStudentFilterData,
    createRegStudent,
    downloadFeeChallan,
    downloadAdmissionAppByAdmin
  } = useAdmissions();

  const [search, setSearch] = useState<string>("");
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const { hasAccess } = useStore();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [meritLists, setMeritLists] = useState<any[]>([]);
  const [studentId, setStudentId] = useState<number>();
  const [data, setData] = useState<any>({});
  const [isTableOpened, setIsTableOpened] = useState(false);
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(null);
  const [extendDate, setExtendDate] = useState<boolean>(false);
  const { handleSearchChange, handleTableSearch } = useUtils();
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "",
    "Application No.",
    "Name",
    "CNIC",
    "Merit",
    "Program",
    "Fee Status",
    "Hafiz e Quran",
    "Quota",
    "Merit List",
    "Offer Letter",
    "Offer Date",
    "Expiry Date",
    "Action",
  ];

  const toggleRowExpand = (index: number) => {
    const updatedLockedList = [...meritLists];
    updatedLockedList[index].isExpanded = !updatedLockedList[index].isExpanded;
    setMeritLists(updatedLockedList);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };
  useEffect(() => {
    getAllLockedMeritLists(pagination.page, search, pagination.per_page, filters);
    getStudentFilterData(setData);
  }, []);

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const openExtendDate = (studentId: number) => {
    setStudentId(studentId);
    setExtendDate(true);
  };
  const downlaodFeeChallan = (item: string) => {
    downloadFeeChallan(item);
  }
  const goToRegStudent = (id: any) => {
    createRegStudent(id, setData);
  };
  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllLockedMeritLists(page + 1, search, per_page, filters);
  };
  const [filters, setFilters] = useState<any>({
    session_id: "",
    category: "",
    program_id: "",
    fee_status: "",
    quota_id: "",
    ml: "",
    program_title: "",
    offer_date: "",
    cnic: ""
  });

  const getAllLockedMeritLists = (page: number = 1, search: string, per_page: number, filters: any) => {
    const filtersToSend: any = {};
    for (let key in filters) {
      if (filters[key]) {
        filtersToSend[key] = filters[key];
      }
    }
    const queryParams = {
      per_page,
      page,
      search,
      ...filtersToSend
    };
    getLockedMeritList(setMeritLists, queryParams, setPagination);
  };

  const downloadApplication = (app_no: string) => {
    downloadAdmissionAppByAdmin(app_no);
  }

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = '';
    }

    setFilters({ ...filters });

    getAllLockedMeritLists(1, search, pagination.per_page, filters);
  }

  return (
    <AdmissionLockedMeritListingMain>
      <AdmissionLockedMeritListingTop>
        <div className="left">
          <span className="page-heading">Locked Merit List</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AdmissionLockedMeritListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Academic Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_id"
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Session</option>
                      {data?.academic_sessions?.map(
                        (item: any, index: number) => {
                          return (
                            <option value={item?.id}>{item?.session_title}</option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Category</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="category" onChange={handleFilterChange}>
                      <option value="">Select Category</option>
                      {data?.certificate_levels?.map(
                        (item: any, index: number) => {
                          return <option value={item?.id}>{item?.title}</option>;
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="program_id" onChange={handleFilterChange}>
                      <option value="">Select Program</option>
                      {data?.programs_view?.map((item: any, index: number) => {
                        return <option key={index} value={item?.id}>{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Fee Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="fee_status" onChange={handleFilterChange}>
                      <option value="-1">All Status</option>
                      <option value="1">Submitted</option>
                      <option value="0">In-Process</option>
                      <option value="">Not Submitted</option>
                      <option value="2">Invalid</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Quota </label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="quota_id" onChange={handleFilterChange} >
                      <option value="">Select Quota </option>
                      {data?.quotas?.map((item: any, index: number) => {
                        return <option value={item?.id} key={index}>{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Merit List</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="ml" onChange={handleFilterChange} >
                      <option value="">Select Merit List</option>
                      {meritLists?.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.ml}>
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label>Offer Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="date" name="offer_date" id="" onChange={handleFilterChange} />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" name="cnic" id="" onChange={handleFilterChange} />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={resetFilters}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={() => getAllLockedMeritLists(1, search, pagination.per_page, filters)}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AdmissionLockedMeritListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.admissionStudentDownloadPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}
            {hasAccess(sitePermissions.admissionStudentDownloadExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => handleSearchChange(e, setSearch, pagination.per_page, getAllLockedMeritLists)}
              onKeyUp={e => handleTableSearch(e, pagination.per_page, getAllLockedMeritLists)}
            />
          </div>
        </div>

        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      key={index}
                      className={
                        index > 6 ? `extra-head extra-head-${index - 6}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {meritLists?.map((item: any, index: number) => (
                <Fragment key={item.id}>
                  <tr onClick={() => toggleRowExpand(index)} className={`expandable ${item.isExpanded && "opened"}`}>
                    <td>
                      <div
                        className="rounded-expand-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleRowExpand(index)
                        }}
                      >
                        <span>{item.isExpanded ? "-" : "+"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.app_no}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.name}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.cnic}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.merit}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.program}</div>
                    </td>
                    <td>
                      {item?.fee_status === 0 && <span className="">No</span>}
                      {item?.fee_status === 1 && <span>Yes</span>}
                    </td>
                    <td className="extra-col extra-col-1">
                      {" "}
                      {item?.is_hafiz === 0 && <span className="">No</span>}
                      {item?.is_hafiz === 1 && <span>Yes</span>}
                    </td>
                    <td className="extra-col extra-col-2">{item?.quota}</td>
                    <td className="extra-col extra-col-3">{item?.ml}</td>
                    <td className="extra-col extra-col-4">
                      {item?.offer_letter}
                    </td>
                    <td className="extra-col extra-col-5">
                      {item?.offer_date}
                    </td>
                    <td className="extra-col extra-col-6">{item?.exp_date}</td>

                    <td className="extra-col extra-col-7">
                      <div className="action-menu">
                        <div
                          className="menu-icon cp"
                          onClick={() => toggleDropdown(index)}
                        >
                          <TabPrimaryActionMenu className="icon" />
                        </div>
                        {/* modal */}
                        {openedDropdownIndex === index && ( // Show the dropdown only if the index matches
                          <div className="table-menu-dropdown">
                            <div
                              className="particular-menu cp"
                              onClick={() => goToRegStudent(item.id)}
                            >
                              <span className="title">Registerd Student</span>
                            </div>
                            <div
                              className="particular-menu cp"
                              onClick={() => downloadAdmissionAppByAdmin(item.app_no)}
                            >
                              <span className="title">Download Application</span>
                            </div>
                            <div
                              className="particular-menu cp"
                              onClick={() => openExtendDate(item?.id)}
                            >
                              <span className="title">Extend Date</span>
                            </div>
                            {/* Add other menu items here */}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>

                  {item.isExpanded && (
                    <tr className="expanded-row">
                      <td></td>
                      <td colSpan={5}>
                        <div className="expanded-content">
                          {/* Expanded Row Details */}
                          <div className="particular-info particular-info-1">
                            <span className="title">Hafiz e Quran</span>
                            <span className="info">
                              {" "}
                              {item?.is_hafiz === 0 && (
                                <span className="">No</span>
                              )}
                              {item?.is_hafiz === 1 && <span>Yes</span>}
                            </span>
                          </div>
                          <div className="particular-info particular-info-2">
                            <span className="title">Quota</span>
                            <span className="info">{item?.quota}</span>
                          </div>

                          <div className="particular-info particular-info-3">
                            <span className="title">Merit List</span>
                            <div className="info">
                              <span className="status">{item?.ml}</span>
                            </div>
                          </div>

                          <div className="particular-info particular-info-4">
                            <span className="title">Offer Letter</span>
                            <div className="info">
                              <span className="status">
                                {item?.offer_letter}
                              </span>
                            </div>
                          </div>

                          <div className="particular-info particular-info-5">
                            <span className="title"> Offer Date</span>
                            <div className="info">
                              <span className="status">{item?.offer_date}</span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-6">
                            <span className="title">Expiry Date</span>
                            <div className="info">
                              <span className="status">{item?.exp_date}</span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-7">
                            <span className="title">Action</span>
                            <div className="info">
                              <div className="table-action-icons">
                                <div
                                  className="action-button"
                                  onClick={() => goToRegStudent(item.id)}
                                >
                                  <button className="black-btn">
                                    Register Student
                                  </button>
                                </div>

                                <div
                                  className="action-button"
                                  onClick={() => openExtendDate(item?.id)}
                                >
                                  <button className="primary-btn">
                                    Extend Date
                                  </button>
                                </div>
                                <div
                                  className="action-button"
                                  onClick={() => downlaodFeeChallan(item?.offer_letter)}
                                >
                                  <button className="green-btn">
                                    Download Fee Challan
                                  </button>
                                </div>

                                <div
                                  className="action-button"
                                  onClick={() => downloadApplication(item?.app_no)}
                                >
                                  <button className="primary-btn">
                                    Download Application
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <DataNotFound show={!isLoading && !meritLists.length} />
            <Pagination
              onPageChange={onPageChange}
              {...pagination}
            />
            {extendDate && (
              <AllocateSection setOpen={setExtendDate} student_id={studentId} />
            )}
          </Fragment>
        </div>
      </AdmissionLockedMeritListingSection>
    </AdmissionLockedMeritListingMain>
  );
};

export default AdmissionLockedMeritListing;
