import { FC, useEffect, useState } from "react";
import { AddAllocateTeacherMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { InstallmentDTO } from "utils/helpers/models/finance/installment.dto";
import useFinance from "containers/private/finance/useHooks";

interface Props {
  setOpen: Function;
  master_id: number;
}

const CreateInstallment: FC<Props> = ({
  setOpen,
  master_id,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<InstallmentDTO>();
  const { getInstallements, createMasterBookInstallment } = useFinance();
  const [formData, setFormData] = useState<InstallmentDTO>(
    new InstallmentDTO({ master_id })
  );
  const [installments, setInstallments] = useState<any[]>([]);

  const onSubmit = () => {
    createMasterBookInstallment(formData, setOpen);
  };

  useEffect(() => {
    setValue('master_id',master_id);
    getInstallements(setInstallments, master_id);
  }, [])

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFormData({...formData, [name]: value});
    setValue(name,value);
    trigger(name);
  }

  return (
    <AddAllocateTeacherMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Create Installment</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label>Installment</label>
                <div className="field-wrap">
                  <div className="field">
                    <select {...register('installment_id')} value={formData.installment_id} onChange={handleChange}>
                      <option value="">Select one</option>
                      {installments.map((installment,index) => {
                        return <option value={installment.id} key={index}>{installment.title}</option>
                      })}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.installment_id} />
                </div>
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>

                <button className="lg-rounded-btn" type="submit">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddAllocateTeacherMain>
  );
};

export default CreateInstallment;
