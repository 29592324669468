import { FC, Fragment, useEffect, useState } from "react";
import { AggregateSection, Container, ContentWrapper, ListingSection, VMBPMain } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useFinance from "containers/private/finance/useHooks";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

interface Props {
  trans_id: number;
  setOpen: Function;
}
const ViewMasterBookParticulars: FC<Props> = ({trans_id,setOpen}) => {
    const columns: string[] = [
        'Particular ID',
        'Particular',
        'Amount',
    ];
    const [data, setData] = useState<any[]>([]);
    const {isLoading} = useSelector((state: any) => state.sharedReducer);
    const [details, setDetails] = useState<{title: string, total_amount: number}>({
        title: 'Transaction Detail (Application:, Challan:)',
        total_amount: 0,
    })
    const {getMasterBookParticulars} = useFinance();

    useEffect(() => {
        getMasterBookParticulars(setData, setDetails, trans_id);
    }, []);
  return (
    <VMBPMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Particulars</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
        <AggregateSection>
            <div className="title">{details.title}</div>
            <div className="total-amount">Total Amount: {details.total_amount}</div>
        </AggregateSection>
        <ListingSection>
            <div className="data-table">
                <table className="bottom-bordered-cells">
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((particular,index) => {
                            return(
                                <tr key={index}>
                                    <td>{particular?.id}</td>
                                    <td>{particular?.particular}</td>
                                    <td>{particular?.amount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Fragment>
                <DataNotFound show={!isLoading && !data.length}/>
            </Fragment>
        </ListingSection>
        </ContentWrapper>
      </Container>
    </VMBPMain>
  );
};

export default ViewMasterBookParticulars;
