import { FC, useEffect, useRef, useState } from "react";
import { DropdownMain } from "./style";
import { useSelector } from "react-redux";

interface MultiselectDropdownProps {
  options: any[];
  onSelect: (item: any) => void;
  value: number[];
  property?: string;
  searchable?: boolean;
  disabled?:boolean;
}

const MultiselectDropdown: FC<MultiselectDropdownProps> = ({
  options,
  onSelect,
  value,
  property = 'title',
  searchable,
  disabled = false
}) => {
  const [items, setItems] = useState<any[]>([options]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [searchedItems, setSearchedItems] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
    const searchRef = useRef(null);

  const handleSelect = (item: any) => {
    if(!disabled){
      if(!item?.disabled){
        item.selected = !item.selected;
        const index = items.findIndex((i: any) => i.id == item.id);
        items[index] = item;
        setItems([...items]);
        onSelect(item);
      }
    }
  };

  useEffect(() => {
    if (value) {
      const customOptions: any[] = [...options];
      value.map((item: any) => {
        const findIndex = customOptions.findIndex((opt: any) => opt.id == item);
        if (findIndex >= 0) {
          customOptions[findIndex]["selected"] = true;
        }

        setItems([...customOptions]);
      });
    }
  }, []);

  useEffect(() => {
    setItems([...options]);
  }, [options]);

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearch(value);
    const helper = items.filter((board) =>
      board[property].toLowerCase().includes(search.toLowerCase())
    );
    setSearchedItems([...helper]);
  };
  
  const getOptions = (): any[] => {
    return search === '' ? items : searchedItems;
  }

  useEffect(() => {
    if(searchable && searchRef && items.length){
      (searchRef as any).current.focus();
    }
  }, [items]);

  return (
    <DropdownMain>
      <ul className="p-custom-scrollbar-8">
      {(searchable && items.length) ? 
        <div className="search-section">
          <input type="search" placeholder="Search" onChange={handleSearchChange} ref={searchRef}/>
        </div> : ''
        }
        {getOptions().map((item: any, index: number) => {
          return (
            <li key={index} onClick={() => handleSelect(item)} className={`${(item?.disabled || disabled) ? 'disabled' : ''}`}>
              <div className="checkbox">
                <input type="checkbox" checked={value.includes(item.id)} readOnly disabled={disabled}/>
              </div>
              <div className="item-text">
                <span className="text">
                  {item?.[property]}
                </span>
              </div>
            </li>
          );
        })}
        {!items.length && !isLoading && (
          <span className="no-options-found">No options found</span>
        )}
      </ul>
    </DropdownMain>
  );
};

export default MultiselectDropdown;
