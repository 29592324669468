import { FC, Fragment, useEffect, useState } from "react";
import {
  QoutasProgramListMain,
  QoutasProgramListSection,
  QoutasProgramListTop,
} from "./style";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import Pagination from "components/particles/table/pagination";
import CreateQoutaListProgram from "../create";
import { useLocation } from "react-router-dom";
import useAdmissions from "containers/private/admissions/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import { FormProvider, useForm } from "react-hook-form";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

interface QuotaItem {
  id: number;
  admission_session: {
    program: {
      title: string;
    };
  };
  quota_seats: number | null;
  acc_voucher_id: string | null;
}

const QoutasProgramList: FC = () => {
  const {
    state: { quota },
  } = useLocation();
    const breadcrumbLinks = [
      { title: "Admission /", path: siteRoutes.admissionCampaignListing },
      { title: "Quota Management / ", path: siteRoutes.quotasAdmissionListing },
      { title: "Link programs", path: window.location.pathname, state: {quota} },
    ];
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<any[]>([]);

  const columns: string[] = [
    "Program",
    "Quota Seats",
    "Voucher Type",
    "Voucher Template",
    "Action",
  ];

  const { getQuotaProgramLinks, deleteQuotaProgramLink } = useAdmissions();
  const { handleSearchChange, handleTableSearch } = useUtils();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [headerId, setHeaderId] = useState<number>(0);
  const [quotaeditdata, setQuotaEditData] = useState<QuotaItem | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleOpenEditModal = (item: QuotaItem) => {
    const headerId: number = item.id;
    setOpenEditModal(true);
    setHeaderId(headerId);
    setQuotaEditData(item);
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllQuotaProgramLinks(page + 1, search);
  };
  const getAllQuotaProgramLinks = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      quota_id: quota?.id,
    };

    getQuotaProgramLinks(setData, queryParams, setPagination);
  };

  useEffect(() => {
    if (!openEditModal) getAllQuotaProgramLinks(pagination.page, search);
  }, [openEditModal]);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        quota_id: quota?.id,
      };

      deleteQuotaProgramLink(id, setData, queryParams, setPagination);
    }
  };
  
  return (
    <QoutasProgramListMain>
      <QoutasProgramListTop>
        <div className="left">
          <span className="page-heading">{quota?.title}</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        {/* <div className="right">
          <div className="create-org-btn">
            <button className="lg-rounded-btn" onClick={handleOpenModal}>
              + Add New
            </button>
          </div>
        </div> */}
      </QoutasProgramListTop>
      <QoutasProgramListSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getAllQuotaProgramLinks)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllQuotaProgramLinks)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div className="mw-150">
                      {item?.admission_session?.program?.title}
                    </div>
                  </td>
                  <td>
                    <div className="mw-150">
                      {item?.quota_seats ? item.quota_seats : "-"}
                    </div>
                  </td>
                  <td>{item?.acc_voucher_id ? item.voucher_template?.voucher_type?.title : "-"}</td>
                  <td>{item?.acc_voucher_id ? item.voucher_template?.title : "-"}</td>
                  <td>
                    <div className="table-action-icons">
                      {/* <div
                        className="action-icon "
                        onClick={() => handleOpenEditModal(item)}
                      >
                        <EditTableSvg />
                      </div> */}
                      <div
                        className="action-icon"
                        onClick={() => handleDelete(item?.id)}
                      >
                        <DeleteTableSvg />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </QoutasProgramListSection>
      {openModal && (
        <CreateQoutaListProgram setOpen={setOpenModal} headerId={headerId} />
      )}
    </QoutasProgramListMain>
  );
};

export default QoutasProgramList;
