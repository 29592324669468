import styled from "styled-components";

export const PlannerDashboard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const PlannerLisitngSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  table td {
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: auto;
    }
    .mw-100 {
      min-width: 100px;
    }

    .expanded-content {
      display: flex;
      flex-direction: column;
      gap: 0.7em;

      .particular-info {
        display: flex;
        gap: 1em;
        .title {
          min-width: 170px;
          color: var(--black-text);
          font-size: 15px;
          font-weight: 400;
        }

        .info {
          font-size: 15px;
          font-weight: 400;
          color: var(--lightgray-medium);

          .table-action-icons {
            @media screen and (max-width: 900px) {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  .action-menu {
    position: relative;

    .menu-icon {
      cursor: pointer;

      .icon {
        rect {
          fill: var(--primary);
        }
      }
    }

    .menu-dropdown {
      position: absolute;
      z-index: 999 !important;
      top: 30px;
      right: 3px;
      max-width: 500px;

      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      width: max-content;
      padding: 1.5em;
      background: var(--white-color);
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 0.2em;

      @media (min-width: 768px) {
        flex-direction: row;
      }
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .action-button-icon {
        display: flex;
        align-items: center;
        gap: 0.3em;
      }
      .action-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.2em 0.5em;
        background-color: var(--blue-color);
        color: var(--white-color);
        border-radius: 0.2em;
        border: none;
        gap: 0.5em;
        text-align: center;

        &:hover {
          background-color: var(--blue-light-color);
          cursor: pointer;
        }
      }
    }
  }
`;

export const PlannerLisitngTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }
`;

export const PlannerSection = styled.div<{ $isDropdownOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  background-color: var(--white-color);
  border-radius: 25px;

  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
    border-bottom: 1px solid var(--lightgray-extralight);
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--lightgray-extralight);
    margin-top: 1em;
  }

  .dropdown-arrow {
    display: flex;
    align-items: center;
    rotate: ${({ $isDropdownOpen }) => ($isDropdownOpen ? "180deg" : "")};
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }

  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    width: 32%;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-card-text {
    width: 100%;
    color: var(--lightgray-medium);
  }

  .card-heading {
    display: flex;
    width: inherit;
    justify-content: space-between;
  }
  .card-text {
    display: flex;
    margin-top: 2em;
  }
  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--black-text);
  }
  .total-amount {
    color: var(--black-text);
    font-weight: 800;
  }
`;
