import useStore from "hooks/useStore";
import { ADMIN_APIS } from "libs/apis/admin.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { LoginFormDTO } from "utils/helpers/models/auth/login";
import { ChangePasswordDTO } from "utils/helpers/models/e-portal/change-passwords.dto";

const useAdmin = () => {
  // const { postRequest } = useHttp();
  const {
    setToken,
    setUser,
    setPermissions,
    setOrganization,
    setRole,
    setLastLoggedInUserRole,
    getRelativePathAfterLogin,
  } = useStore();

  const navigate = useNavigate();

  const adminLogin = async (body: LoginFormDTO) => {
    const response = await ADMIN_APIS.adminLogin(body);
    if (response?.status) {
      setOrganization(response?.organization);
      setRole(response?.data?.roles[0]);
      delete response?.data?.role_id;
      delete response?.data?.roles;
      setUser(response?.data);
      setToken(response?.token);
      setLastLoggedInUserRole(ROLES.ADMIN);
      setPermissions(response?.permissions[0]?.permissions);
      const pathToNavigate = getRelativePathAfterLogin(siteRoutes.adminDashboard);
      navigate(pathToNavigate, { replace: true });
      successToaster(response?.message);
    }
  };

  const changeAdminPassword = async (body: ChangePasswordDTO, setOpen: (open: boolean) => void ) => {
    const response = await ADMIN_APIS.changeAdminPassword(body);
    const {status = false, message = ''} = response || {};
    if(status){
      setOpen(false);
      successToaster(message);
    }

  }

  return {
    adminLogin,
    changeAdminPassword
  };
};

export default useAdmin;
