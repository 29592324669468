import { FC, Fragment, useEffect, useState } from "react";
import {
  StudentPortalDocumentsListingSection,
  StudentPortalDocumentsListingMain,
  StudentPortalDocumentsListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  DownloadPrimaryTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
  UploadGreenTableSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import UploadDocument from "./components/upload-document";

interface StudentPortalDocumentsListingProps {}

const StudentPortalDocumentsListing: FC<
  StudentPortalDocumentsListingProps
> = ({}) => {
  const breadcrumbLinks = [
    { title: "Student Dashboard /", path: "" },
    { title: "My Documents", path: siteRoutes.studentPortalDocumentsListing },
  ];
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const columns: string[] = [
    "Document Title",
    "Status",
    "Rejection Reason",
    "Action",
  ];

  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch } = useUtils();

  const goToCreateBank = () => {
    navigate(siteRoutes.createBank);
  };

  useEffect(() => {
    getAllBankInfos(pagination.page, search);
  }, []);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      // deleteBankInfo(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllBankInfos(page + 1, search);
  };
  const getAllBankInfos = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    // getBankInfos(setData, queryParams, setPagination);
  };

  const goToEditBankInfo = (id: number) => {
    navigate(`${siteRoutes.createBank}?id=${id}`);
  };
  const [data, setData] = useState([
    {
      courseCode: "ARIN-4567",
      course: "Advanced Theory of Computation",
      teacher: "Dr. Muhammad Faheem Mushtaq",
      isDropdownOpen: false,
    },
    {
      courseCode: "CS101",
      course: "Introduction to Computer Science",
      teacher: "Dr. John Doe",
      isDropdownOpen: false,
    },
    // Add more objects here
  ]);

  const toggleRowExpand = (index: number) => {
    const updatedData = data.map((item, idx) => {
      if (idx === index) {
        return { ...item, isDropdownOpen: !item.isDropdownOpen };
      }
      return { ...item, isDropdownOpen: false }; // Close others
    });
    setData(updatedData);
  };
  const [uploadDocument, setUploadDocument] = useState<boolean>(false);
  const openUploadDocument = () => {
    setUploadDocument(true);
  };
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  ); // State to track the open dropdown by index
  const [isTableOpened, setIsTableOpened] = useState(false);

  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  return (
    <StudentPortalDocumentsListingMain>
      <StudentPortalDocumentsListingTop>
        <div className="left">
          <span className="page-heading">My Documents</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </StudentPortalDocumentsListingTop>

      <StudentPortalDocumentsListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getAllBankInfos)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllBankInfos)}
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((bank, index) => {
                return (
                  <tr key={index}>
                    <td>Matriculation Certification / Equivalent</td>
                    <td>
                      <span className="status-tile green">Uploaded</span>
                    </td>
                    <td>Here</td>
                    <td>
                      <div className="action-menu">
                        <div
                          className="menu-icon cp"
                          onClick={() => toggleDropdown(index)}
                        >
                          <TabPrimaryActionMenu className="icon" />
                        </div>
                        {openedDropdownIndex === index && (
                          <div className="table-menu-dropdown">
                            <div className="particular-menu cp">
                              <div className="action-icons">
                                <DownloadPrimaryTableSvg />
                              </div>
                              <span className="title">Download File</span>
                            </div>
                            <div
                              className="particular-menu cp"
                              onClick={openUploadDocument}
                            >
                              <div className="action-icons">
                                <UploadGreenTableSvg />
                              </div>
                              <span className="title">Upload File</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {/* <DataNotFound show={!isLoading && !data.length} /> */}
          <Pagination {...pagination} onPageChange={onPageChange} />
          {uploadDocument && <UploadDocument setOpen={setUploadDocument} />}
        </Fragment>
      </StudentPortalDocumentsListingSection>
    </StudentPortalDocumentsListingMain>
  );
};

export default StudentPortalDocumentsListing;
