import { FC, useEffect, useState } from "react";
import {
    EquivalentCoursesMain,
    EquivalentCorsesContainer,
    EquivaLentCoursesWrapper,
} from "./style";

import { CloseMediumSvg } from "assets/images/common/svgs";
import { EquivalentCoursesDTO } from "utils/helpers/models/academics/equivalent-courses.dto";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { useForm } from "react-hook-form";
import useAcademics from "../../useHooks";
import MultiselectField from "components/particles/forms/multiselect-field";

interface EquivaLentCoursesProps {
    setOpen: (open: boolean) => void;
    headerId: any;
    getEQuvalentCoursesList: Function;
}

const EquavalentCoursesCreate: FC<EquivaLentCoursesProps> = ({
    setOpen,
    headerId,
    getEQuvalentCoursesList,
}) => {
    const {
        getTemplateCoursesForAddRequisiteCourses,
        AddEquivalentCoursesToTemplateCourses,
    } = useAcademics();

    const [formData, setFormData] = useState<EquivalentCoursesDTO>(() => new EquivalentCoursesDTO());
    const [courses, setCourses] = useState<any[]>([]);
    const [showButton, setShowButton] = useState<boolean>(true);
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<EquivalentCoursesDTO>({
        defaultValues: formData,
        mode: "onChange",
    });

    const handleCloseModal = () => {
        setOpen(false);
    };

    const getTemplateCourses = () => {
        getTemplateCoursesForAddRequisiteCourses(setCourses);
    };

    const onSelect = (value: number[], name: keyof EquivalentCoursesDTO) => {
        (formData as any)[name] = value;
        setValue(name as keyof EquivalentCoursesDTO, formData[name]);
        trigger([name]);
        setFormData({ ...formData });
    }

    useEffect(() => {
        getTemplateCourses();
    }, []);
    const onSubmit = () => {
        if (formData?.equivalent_course_id?.length > 0) {
            const body = {
                course_id: headerId,
                equivalent_course_id: formData?.equivalent_course_id,
            };
            AddEquivalentCoursesToTemplateCourses(body, setOpen, getEQuvalentCoursesList);
        } else {
            warningToaster(warningMessages.academicsTemplateCourseNotseletedWarning);
        }
    };

    return (
        <EquivalentCoursesMain>
            <EquivalentCorsesContainer>
                <EquivaLentCoursesWrapper className="p-custom-scrollbar-8" showSubmitButton={showButton}>
                    <div className="header">
                        <div className="empty"></div>
                        <div className="heading">
                            <span>Add Equivalent Courses</span>
                        </div>
                        <div className="close-icon cp" onClick={handleCloseModal}>
                            <CloseMediumSvg className="icon" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <MultiselectField
                            options={courses}
                            onSelect={(item) => onSelect(item, "equivalent_course_id")}
                            value={formData.equivalent_course_id}
                            searchable
                            property="tc_title"
                            label="Select Equivalent Courses"
                            helperText="Select Course(s)"
                            onClose={() => setShowButton(true)}
                            onOpen={() => setShowButton(false)}
                        />

                        <div className="action-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                                    type="button"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                                <button className="lg-rounded-btn" type="submit">
                                    Save & Close
                                </button>
                            </div>
                        </div>
                    </form>
                </EquivaLentCoursesWrapper>
            </EquivalentCorsesContainer>
        </EquivalentCoursesMain>
    );
};

export default EquavalentCoursesCreate;
