export class StudentAnomaliesDTO {
  campus_not_assigned: number = 0;
  cnic_not_provided: number = 0;
  department_not_assigned: number = 0;
  pos_not_assigned: number = 0;
  program_not_assigned: number = 0;

  constructor(data: Partial<StudentAnomaliesDTO> = {}){
    Object.assign(this, data);
  }
}
