import { FC, Fragment, useEffect, useState } from "react";

import {
    AddStudentInBulkMain,
    AddStudentInBulkContainer,
    AddStudentInBulkWrraper,
} from "./style";

import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useTestingServices from "../../useHooks";
import { TestScheduleDTO } from "utils/helpers/models/testing-service/test-schedule.dto";
import { AddStudentsForTestDTO } from "utils/helpers/models/testing-service/add-students-for-test.dto";
import MultiselectField from "components/particles/forms/multiselect-field";

interface CoRequisiteProps {
    setOpen: (open: boolean) => void;
}


const AddStudentInBulk: FC<CoRequisiteProps> = ({
    setOpen,
}) => {

    const {
        handleSubmit,
        register,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<AddStudentsForTestDTO>();

    const {
        getTestSchedules,
        getTestingCenters,
        getAddmisionApplicants,
        AddStudentInBulkForTest,
    } = useTestingServices();

    const [addmissionApplicants, setAddmisionAplicants] = useState<AddStudentsForTestDTO[]>([]);
    const [schedule, setSchedule] = useState<TestScheduleDTO[]>([]);
    const [testCenter, setTestCenter] = useState<TestScheduleDTO[]>([]);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);

    const [formData, setFormData] = useState<AddStudentsForTestDTO>(() => new AddStudentsForTestDTO());

    const handleCloseModal = () => {
        setOpen(false);
    };



    const onSelect = (value: number[], name: keyof AddStudentsForTestDTO) => {
        (formData as any)[name] = value;
        setValue(name as keyof AddStudentsForTestDTO, formData[name]);
        trigger([name]);
        setFormData({ ...formData });
    }

    const handleChange = (event: any) => {
        const { value, name } = event.target;
        setValue(name, value);
        trigger([name]);
    };

    useEffect(() => {
        getTestSchedules(setSchedule);
        getTestingCenters(setTestCenter);
        getAddmisionApplicants(setAddmisionAplicants);
        register('user_id', { required: true });
    }, [])

    const onSubmit = (data: AddStudentsForTestDTO) => {
        // console.log(data);
        AddStudentInBulkForTest(data);
        handleCloseModal();
    };

    return (
        <AddStudentInBulkMain>
            <AddStudentInBulkContainer>
                <AddStudentInBulkWrraper className="p-custom-scrollbar-8" $isUserDropdownOpen={isUserDropdownOpen}>
                    <div className="header">
                        <div className="empty"></div>
                        <div className="heading">
                            <span>Add Student In Bulk</span>
                        </div>
                        <div className="close-icon cp" onClick={handleCloseModal}>
                            <CloseMediumSvg className="icon" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="common-fields">
                            <div className="input-field">
                                <label>Current Scedule</label>
                                <div className="field-wrap">
                                    <div className="field">
                                        <select
                                            {...register("test_schedule_id", { required: true })}
                                            value={formData.test_schedule_id}
                                            onChange={handleChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {
                                                schedule.map((item: any, index: number) => {
                                                    return (
                                                        <option value={item?.id}>{item?.description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <FormErrorMessage error={errors.test_schedule_id} />
                                </div>
                            </div>
                        </div>

                        <div className="common-fields">
                            <div className="input-field">
                                <label>Test Center</label>
                                <div className="field-wrap">
                                    <div className="field">
                                        <select
                                            {...register("test_center_id", { required: true })}
                                            value={formData.test_center_id}
                                            onChange={handleChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {
                                                testCenter.map((item: any, index: number) => {
                                                    return (
                                                        <option value={item?.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <FormErrorMessage error={errors.test_center_id} />
                                </div>
                            </div>
                        </div>

                        <MultiselectField
                            options={addmissionApplicants.map(i => ({ ...i, id: i.user_id }))}
                            onSelect={(item) => onSelect(item, "user_id")}
                            value={formData.user_id}
                            searchable
                            label="Select Users"
                            helperText="-- Select --"
                            error={errors?.user_id}
                            onClose={() => setIsUserDropdownOpen(false)}
                            onOpen={() => setIsUserDropdownOpen(true)}

                        />

                        <div className="action-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                                    type="button"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                                <button className="lg-rounded-btn" type="submit">
                                    Save & Close
                                </button>
                            </div>
                        </div>
                    </form>
                </AddStudentInBulkWrraper>
            </AddStudentInBulkContainer >
        </AddStudentInBulkMain >
    );
};

export default AddStudentInBulk;
