import { FC, useEffect, useState } from "react";
import {
  AcadDashboardMain,
  AcadDashboardSection,
  AcadDashboardTop,
  FilterHeader,
  Filters,
  FilterSection,
  EnrolledSection,
  CourseSection,
  AnomiliesSection,
  AcademicDataSection,
  EnrolledCourses,
  SemesterData,
  NotEnrolledSection,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { DownArrowLightgrayMediumSvg } from "assets/images/common/svgs";
import useDSSystem from "../useHook";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useOrganization from "containers/private/organization/useHooks";
import useAcademics from "containers/private/academics/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { EnrolledStudentsDTO } from "utils/helpers/models/ds-system/enrolled-students.dto";
import { NotEnrolledStudentsDTO } from "utils/helpers/models/ds-system/not-enrolled-students.dto";
import { CourseAnomaliesDTO } from "utils/helpers/models/ds-system/course-anomalies.dto";
import { StudentAnomaliesDTO } from "utils/helpers/models/ds-system/student-anomalies.dto";
import { AcademicsDataDTO } from "utils/helpers/models/ds-system/academics-data.dto";
import { EnrolledCourseTeachersDTO } from "utils/helpers/models/ds-system/enrolled-courses-teachers.dto";
import { AddAdmissionSessionDTO } from "utils/helpers/models/admissions/add-admission-session.dto";
import useAdmissions from "containers/private/admissions/useHooks";

interface Props {}

const AcademicsDashboard: FC<Props> = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Decision Support System /", path: "" },

    { title: "Academics Dashboard", path: siteRoutes.academicsDashboard },
  ];
  const [isFilterOpen, setIsFilterFilter] = useState<boolean>(true);
  const [enStudentOpen, setEnStudentOpen] = useState<boolean>(true);
  const [notStudentOpen, setNotStudentOpen] = useState<boolean>(true);
  const [isCAOpen, setIsCAOpen] = useState<boolean>(true);
  const [isSAOpen, setIsSAOpen] = useState<boolean>(true);
  const [isAcadDataOpen, setIsAcadDataOpen] = useState<boolean>(true);
  const [isCoursesOpen, setIsCoursesOpen] = useState<boolean>(true);
  const [isSRDOpen, setIsSRDOpen] = useState<boolean>(true);
  const [enrolledStudents, setEnrolledStudents] = useState<EnrolledStudentsDTO>(new EnrolledStudentsDTO());
  const [notEnrolledStudents, setNotEnrolledStudents] = useState<NotEnrolledStudentsDTO>(new NotEnrolledStudentsDTO());
  const [courseAnomalies, setCourseAnomalies] = useState<CourseAnomaliesDTO>(new CourseAnomaliesDTO());
  const [studentAnomalies, setStudentAnomalies] = useState<StudentAnomaliesDTO>(new StudentAnomaliesDTO());
  const [academicsData, setAcademicsData] = useState<AcademicsDataDTO>(new AcademicsDataDTO());
  const [enrolledCoursesTeachers, setEnrolledCourseTeachers] = useState<EnrolledCourseTeachersDTO>(new EnrolledCourseTeachersDTO());
  const [filters, setFilters] = useState<any>({
    academic_session_id: '',
    campus_id : '',
    department_id: ''
  });
  const [sessions, setSessions] = useState<AddAdmissionSessionDTO[]>([]);
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);

  const {
    getCourseAnomalies,
    getStudentAnomalies,
    getAcademicsData,
    getEnrolledCoursesAndTeachers,
    getEnrolledStudents,
    getNotEnrolledStudents
  } = useDSSystem();
  const { getSpecificOrgStructures } = useOrganization();
  const { getAdmissionSessions } = useAdmissions();

  const toggleFilter = () => {
    setIsFilterFilter((prevState) => !prevState);
  };
  const toggleEnStudentOpen = () => {
    setEnStudentOpen((prevState) => !prevState);
  };
  const toggleNotStudentOpen = () => {
    setNotStudentOpen((prevState) => !prevState);
  };
  const toggleCAOpen = () => {
    setIsCAOpen((prevState) => !prevState);
  };
  const toggleSAOpen = () => {
    setIsSAOpen((prevState) => !prevState);
  };
  const toggleAcadDataOpen = () => {
    setIsAcadDataOpen((prevState) => !prevState);
  };
  const toggleIsCoursesOpen = () => {
    setIsCoursesOpen((prevState) => !prevState);
  };
  const toggleIsSRDOpen = () => {
    setIsSRDOpen((prevState) => !prevState);
  };

  const getAllDashboardData = (filters: any) => {
    const filtersToSend = {...filters};
    for(let key in filters){
      if(!filters[key]){
        delete filtersToSend[key];
      }
    }

    getCourseAnomalies(setCourseAnomalies, filtersToSend);
    getStudentAnomalies(setStudentAnomalies, filtersToSend);
    getEnrolledCoursesAndTeachers(setEnrolledCourseTeachers, filtersToSend);
    getEnrolledStudents(setEnrolledStudents, filtersToSend);
    getNotEnrolledStudents(setNotEnrolledStudents, filtersToSend);
    getAcademicsData(setAcademicsData, filtersToSend);

  }

  const handleFilterChange = (event: any) => {
    const { target: { name, value } } = event;
    setFilters({...filters, [name] : value});
  }

  useEffect(() => {
    getAllDashboardData(filters);
    getAdmissionSessions(setSessions);
    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
  }, []);

  const resetFilters = () => {
    for(let key in filters){
      filters[key] = '';
    }
    getAllDashboardData(filters);
  };

  useEffect(() => {
    if (sessions.length) {
      const latestSession = sessions.find((s) => s.is_active);
      const { id: academic_session_id = null } = latestSession || {};
      if (academic_session_id) {
        setFilters({ ...filters, academic_session_id });
      }
    }
  }, [sessions.length]);

  return (
    <AcadDashboardMain>
      <AcadDashboardTop>
        <div className="left">
          <span className="page-heading">Academics Dashboards</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AcadDashboardTop>
      <AcadDashboardSection>
        <FilterSection className="content-radius-shadow">
          <FilterHeader $isFilterOpen={isFilterOpen}>
            <span className="filter-heading">Filter</span>
            <span className="dropdown-arrow cp" onClick={toggleFilter}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </FilterHeader>
          {isFilterOpen && (
            <Filters>
              <div className="filter-fields">
                <div className="input-field">
                  <label>Select Academic Session</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="academic_session_id"
                        value={filters.academic_session_id}
                        onChange={handleFilterChange}
                      >
                        {sessions.map((session, index) => {
                          return (
                            <option value={session.id} key={index}>
                              {session?.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Select Campus</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select name="campus_id" value={filters.campus_id} onChange={handleFilterChange}>
                        <option value="">All</option>
                        {campuses.map((campus,index) => {
                          return <option value={campus.id} key={index}>{campus.title}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Select Department</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select name="department_id" value={filters.department_id} onChange={handleFilterChange}>
                        <option value="">All</option>
                        {departments.map((dept,index) => {
                          return <option value={dept.id} key={index}>{dept.title}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-buttons">
                <div className="buttons">
                  <button className="lg-rounded-btn gray" onClick={resetFilters}>Reset</button>
                  <button className="lg-rounded-btn" onClick={() => getAllDashboardData(filters)}>Apply Filters</button>
                </div>
              </div>
            </Filters>
          )}
        </FilterSection>
        <EnrolledSection className="content-radius-shadow"  $EnStudentOpen={enStudentOpen}>
          <div className="link-heading">Enrolled Students (Spring 2025)
          <span className="dropdown-arrow cp" onClick={toggleEnStudentOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {enStudentOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Total Enrolled Students",
                color: "rgba(149, 165, 166,0.8)",
                value: enrolledStudents.total_enrolled_students,
              },
              {
                title: "Male Students",
                value: enrolledStudents.total_enrolled_male_students,
                color: "rgba(52, 152, 219,0.8)",
              },
              {
                title: "Female Students",
                value: enrolledStudents.total_enrolled_female_students,
                color: "rgba(142, 68, 173,0.6)",
              },
              {
                title: "BS Students",
                value: 0,
                color: "rgba(231, 76, 60,0.8)",

                navigateTo: siteRoutes.studentRegListing,
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </EnrolledSection>
        <NotEnrolledSection className="content-radius-shadow" $notStudentOpen={notStudentOpen}>
          <div className="link-heading">
            Not Enrolled Students (Spring 2025)
            <span className="dropdown-arrow cp" onClick={toggleNotStudentOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {notStudentOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Total Enrolled Students",
                value: notEnrolledStudents.total_not_enrolled_students,
                color: "rgba(149, 165, 166,0.8)",
              },
              {
                title: "Male Students",
                value: notEnrolledStudents.total_enrolled_male_students,
                color: "rgba(52, 152, 219,0.8)",
              },
              {
                title: "Female Students",
                value: notEnrolledStudents.total_enrolled_female_students,
                color: "rgba(142, 68, 173,0.6)",
              },
              {
                title: "BS Students",
                value: 0,
                color: "rgba(231, 76, 60,0.8)",

                navigateTo: siteRoutes.studentRegListing,
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </NotEnrolledSection>
        <CourseSection className="content-radius-shadow"  $isCAOpen={isCAOpen}>
          <div className="link-heading">Course Anomilies
          <span className="dropdown-arrow cp" onClick={toggleCAOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isCAOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Course Enrollments Not Assigned",
                value: courseAnomalies.course_enrollemts_not_assigned,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Enrollments not belong to Plan Of Study",
                value: courseAnomalies.enrollments_not_belongsto_pos,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Duplicate Template Courses",
                value: courseAnomalies.duplicate_template_courses,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Course Teacher Not Assigned",
                value: courseAnomalies.course_teachers_not_assigned,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Course Sections Not Assigned",
                value: courseAnomalies.course_sections_not_assigned,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Template Courses which have multiple child courses",
                value: 0,
                color: "rgba(231, 76, 60,0.8)",
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </CourseSection>
        <AnomiliesSection className="content-radius-shadow"  $isSAOpen={isSAOpen}>
          <div className="link-heading">Student Anomilies
          <span className="dropdown-arrow cp" onClick={toggleSAOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isSAOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Campus Not Assigned",
                value: studentAnomalies.campus_not_assigned,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Department Not Assigned",
                value: studentAnomalies.department_not_assigned,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Plan Of Study Not Assigned",
                value: studentAnomalies.pos_not_assigned,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "Program Not Assigned",
                value: studentAnomalies.program_not_assigned,
                color: "rgba(231, 76, 60,0.8)",
              },
              {
                title: "CNIC Not Provided",
                value: studentAnomalies.cnic_not_provided,
                color: "rgba(231, 76, 60,0.8)",
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </AnomiliesSection>
        <AcademicDataSection className="content-radius-shadow" $isAcadDataOpen={isAcadDataOpen}>
          <div className="link-heading">Academic Data
          <span className="dropdown-arrow cp" onClick={toggleAcadDataOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isAcadDataOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Total Facilities",
                value: academicsData.total_faculties,
                color: "rgba(230, 126, 34,0.8)",
              },
              {
                title: "Total Department",
                value: academicsData.total_departments,
                color: "rgba(41, 128, 185,1.0)",
              },
              {
                title: "Total Programs",
                value: academicsData.total_programs,
                color: "rgba(142, 68, 173,1.0)",
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </AcademicDataSection>
        <EnrolledCourses className="content-radius-shadow" $isCoursesOpen={isCoursesOpen}>
          <div className="link-heading">Enrolled Courses & Teachers
          <span className="dropdown-arrow cp" onClick={toggleIsCoursesOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          { isCoursesOpen && (
          <div className="link-card-container">
            <div className="link-card2" style={{ backgroundColor: "purple" }}>
              <div className="link-card-text">
                <div className="link-title">Total Courses: {enrolledCoursesTeachers?.tatal_courses?.courses_count}</div>
                <div className="total-amount">Credit hours: {enrolledCoursesTeachers?.tatal_courses?.credit_hours_count}</div>
              </div>
            </div>
            {[
              {
                title: "Regular Workload Courses",
                value: enrolledCoursesTeachers.regular_workload_courses.courses_count,
                color: "rgba(230, 126, 34,0.8)",
              },
              {
                title: "Regular Part Time Courses",
                value: enrolledCoursesTeachers?.regular_part_time_courses?.courses_count,
                color: "rgba(41, 128, 185,1.0)",
              },
              {
                title: "Non Teaching Courses",
                value: enrolledCoursesTeachers.non_teaching_courses.courses_count,
                color: "rgba(142, 68, 173,1.0)",
              },
              {
                title: "Visiting Workload Courses",
                value: enrolledCoursesTeachers.visiting_workload_courses.courses_count,
                color: "rgba(230, 126, 34,0.8)",
              },
              {
                title: "Total Enrolled Teachers",
                value: enrolledCoursesTeachers.total_enrolled_teachers,
                color: "rgba(41, 128, 185,1.0)",
              },
              {
                title: "Regular Teachers ENROLLED",
                value: enrolledCoursesTeachers.regular_teachers_enrolled,
                color: "rgba(142, 68, 173,1.0)",
              },
              {
                title: "Regular Teachers NOT ENROLLED",
                value: enrolledCoursesTeachers.regular_teachers_not_enrolled,
                color: "rgba(230, 126, 34,0.8)",
              },
              // {
              //   title: "Regular Teachers Enrolled in part time Courses",
              //   value: enrolledCoursesTeachers.regular,
              //   color: "rgba(41, 128, 185,1.0)",
              // },
              {
                title: "Visiting Teachers Enrolled",
                value: enrolledCoursesTeachers.visiting_teachers_enrolled,
                color: "rgba(230, 126, 34,0.8)",
              },
              {
                title: "Regular Teachers 0 Workload Enrolled in Part Time Course(s)",
                value: enrolledCoursesTeachers.regular_teachers_zero_workload_enrolled_in_part_time,
                color: "rgba(142, 68, 173,1.0)",
              },
              {
                title: "Course having less or equal to 5 students enrollments",
                value: enrolledCoursesTeachers.courses_having_Enrollments_less_then_five,
                color: "rgba(41, 128, 185,1.0)",
              },
              {
                title:
                  "Courses having greater than 5 and less then or equal 10 students enrollments",
                  value: enrolledCoursesTeachers.courses_having_Enrollments_gt_5_lt_10,
                  color: "rgba(142, 68, 173,1.0)",
              },
              {
                title: "Courses having greater than 10 and less then or equal to 20 students enrollments",
                value: enrolledCoursesTeachers.courses_having_Enrollments_gt_10_lt_20,
                color: "rgba(230, 126, 34,0.8)",
              },
              {
                title: "Courses having greater than 20 and less then or equal to 30 students enrollments",
                value: enrolledCoursesTeachers.courses_having_Enrollments_gt_20_lt_30,
                color: "rgba(41, 128, 185,1.0)",
              },
              {
                title:
                  "Courses having greater than 30 and less then or equal to 40 students enrollments",
                  value: enrolledCoursesTeachers.courses_having_Enrollments_gt_30_lt_40,
                  color: "rgba(142, 68, 173,1.0)",
              },
              {
                title: "Courses having greater then 40 students enrollments",
                value: enrolledCoursesTeachers.courses_having_Enrollments_gt_40,
                color: "rgba(230, 126, 34,0.8)",
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </EnrolledCourses>

        <SemesterData className="content-radius-shadow" $isSRDOpen={isSRDOpen}>
          <div className="link-heading">Semester Result Data
          <span className="dropdown-arrow cp" onClick={toggleIsSRDOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isSRDOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Total Gradebook Submitted",
                value: "-", // Primitive value
                color: "rgba(46, 204, 113,1.0)",

              
              },
              {
                title: "Gradebooks Submitted but Not Verified",
                value: "-", // Extract total_count
                color: "rgba(41, 128, 185,1.0)",

             
              },
              {
                title: "Gradebook Submitted and Verified ",
                value: "-", // Extract total_count
                color: "rgba(243, 156, 18,1.0)",

              
              },
              {
                title: "Gradebook Recipt Acknowledge",
                value: "-", // Extract total_count
                color: "rgba(46, 204, 113,1.0)",

              
              },
              {
                title: "Gradebook Not Submited Semester System",
                value: "-", // Extract total_count
                color: "rgba(231, 76, 60,0.8)",

                navigateTo: `${siteRoutes.systemUsersListing}?user_action_status=applied_on_admission`,
              },
              {
                title: "Gradebook Not Submited Annual System",
                value: "-", // Extract total_count
                color: "rgba(231, 76, 60,0.8)",

                navigateTo: `${siteRoutes.systemUsersListing}?user_action_status=signedup_users`,
              },
              {
                title: "0 Marks records in verified Gradebook",
                value: "-", // Extract total_count
                color: "rgba(231, 76, 60,0.8)",

                navigateTo: `${siteRoutes.systemUsersListing}?user_action_status=applied_on_admission`,
              },
              {
                title: "0 Marks records in submitted Gradebook",
                value: "-", // Extract total_count
                color: "rgba(231, 76, 60,0.8)",

                navigateTo: `${siteRoutes.systemUsersListing}?user_action_status=signedup_users`,
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </SemesterData>
      </AcadDashboardSection>
    </AcadDashboardMain>
  );
};

export default AcademicsDashboard;
