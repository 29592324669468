
import { FC, Fragment, useEffect, useState } from "react";

import {
  EqvalentCoursesMain,
  EqvalentCoursesListingSection,
  EqvalentCoursesTop,
} from "./style";

import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import useAlert from "hooks/useAlert";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

import Breadcrumb from "components/particles/breadcrumb";
import useStore from "hooks/useStore";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import EquavalentCoursesCreate from "../create";
import useUtils from "hooks/useUtils";
import useAcademics from "../../useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import { useNavigate } from "react-router-dom";

interface EquavalentCoursesProps { }

const columns: string[] = [
  "Title",
  "Short Title",
  "Descryption",
  "Code",
  "Lab Credit Hours",
  "Is Virtual",
  "Is Research Course",
  "Is Credit Hours",
  "Is Lab",
  "Is Elective",
  "Action",
];

const TemplateCourseEquivalentCourseList: FC<EquavalentCoursesProps> = ({ }) => {

  const breadcrumbLinks = [
    { title: "Academics / ", path: "" },
    { title: "Template Course /", path: siteRoutes.academicTemplateCoursesListing },
    { title: "Equivalent Course Listing", path: siteRoutes.academicTemplateEqualentCourseList },
  ];

  const navigate = useNavigate();
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { confirmationPopup } = useAlert();
  const { hasAccess } = useStore();

  const {
    deleteEqvalentCourseItemById,
    getEquvalentCourses,
  } = useAcademics();

  const [data, setData] = useState<any>([])
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const OpenModalFOrAddEquivalentCourse = () => {
    setOpenModal(true);
  }

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    const queryParams = {
      per_page: pagination.per_page,
      page: pagination.page,
      search
    };
    if (result.isConfirmed) {
      deleteEqvalentCourseItemById(id, params?.id, setData, queryParams, setPagination);
    };
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getEQuvalentCoursesList(page, search, per_page);
  };

  const getEQuvalentCoursesList = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getEquvalentCourses(params?.id, setData, queryParams, setPagination);
  }
  useEffect(() => {
    if (params?.id) {
      getEQuvalentCoursesList(pagination.page, search, pagination.per_page);
    } else {
      navigate(-1);
    }
  }, [params?.id]);

  return (
    <EqvalentCoursesMain>
      <EqvalentCoursesTop>
        <div className="left">
          <span className="page-heading">Equivalent Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.academicTemplateEqualentCourseCreate) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={OpenModalFOrAddEquivalentCourse}>
                + Add Equivalent Courses
              </button>
            </div>
          )}
        </div>
      </EqvalentCoursesTop>
      <EqvalentCoursesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadBuildingListPdf) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadBuildingListExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data && data.length ? (
                data.map((item: any, index: number) => (
                  <tr key={item?.id | index}>
                    <td>{item?.tc_title ?? "-"}</td>
                    <td>{item?.tc_short_title ?? "-"}</td>
                    <td>{item?.tc_description ?? "-"}</td>
                    <td>{item?.tc_code ?? "-"}</td>
                    <td>
                      {item?.tc_lab_credit_hours ? <span className="status-tile green">Yes</span> : <span className="status-tile red">No</span>}
                    </td>
                    <td>
                      {item?.tc_is_virtual ? <span className="status-tile green">Yes</span> : <span className="status-tile red">No</span>}
                    </td>
                    <td>
                      {item?.tc_is_research_course ? <span className="status-tile green">Yes</span> : <span className="status-tile red">No</span>}
                    </td>
                    <td>
                      {item?.tc_is_non_credit ? <span className="status-tile green">Yes</span> : <span className="status-tile red">No</span>}
                    </td>
                    <td>
                      {item?.tc_is_lab ? <span className="status-tile green">Yes</span> : <span className="status-tile red">No</span>}
                    </td>
                    <td>
                      {item?.tc_is_elective ? <span className="status-tile green">Yes</span> : <span className="status-tile red">No</span>}
                    </td>

                    <td>
                      <div className="table-action-icons">
                        {hasAccess(sitePermissions.academicTemplateEqualentCourseDelete) && (
                          <div
                            className="action-icon"
                            onClick={() => handleDelete(item?.equivalent_id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}>
                    <DataNotFound show={!isLoading && !data.length} />
                  </td>
                </tr>
              )}

            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
          {openModal && (
            <EquavalentCoursesCreate setOpen={setOpenModal} headerId={params?.id} getEQuvalentCoursesList={getEQuvalentCoursesList} />
          )}
        </Fragment>
      </EqvalentCoursesListingSection>
    </EqvalentCoursesMain >
  );
};

export default TemplateCourseEquivalentCourseList;
