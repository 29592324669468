import { FC, Fragment, useEffect, useState } from "react";
import {
  CallTableSvg,
  DarkEyeSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  TableBlackRightArrowSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import {
  UsersListingMain,
  UsersListingSection,
  UsersListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useSystemAdministration from "../../useHooks";
import useUtils from "hooks/useUtils";
import useOrganization from "containers/private/organization/useHooks";
import useAlert from "hooks/useAlert";
import { UserManagementDTO } from "utils/helpers/models/system-administration/user-management.dto";
import KalaAvatar from "../../../../../assets/images/common/others/avatar-9.jpg";
import Breadcrumb from "components/particles/breadcrumb";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import SingleDetailDialog from "components/particles/dialogs/single-detail-dialog";
import useAdmissions from "containers/private/admissions/useHooks";
import UpdateUserComments from "./components/update-comments";
import { CityDTO } from "utils/helpers/models/organization/city.dto";

const UsersListing: FC = () => {
  const breadcrumbLinks = [
    { title: "System Administration  /", path: "" },
    { title: "Users", path: siteRoutes.systemUsersListing },
  ];
  const navigate = useNavigate();
  const { getUsers, deleteUserById } = useSystemAdministration();
  const { loginAsUser } = useAdmissions();
  const { getCitiesAdmin } = useOrganization();
  const [search, setSearch] = useState<string>("");
  const [openCommentsDialog, setOpenCommentsDialog] = useState<boolean>(false);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [data, setData] = useState<UserManagementDTO[]>([]);
  const [cities, setCities] = useState<CityDTO[]>([]);
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const { user_action_status = "", user_ids } = getQueryParams() || {};
  const [filters, setFilters] = useState({
    city: "",
    cnic: "",
    start_date: "",
    end_date: "",
    status: "",
    user_action_status,
    user_ids: user_ids || "",
  });
  const [singleDetail, setSingleDetail] = useState({ value: "", label: "" });
  const [showSingleDetailDialog, setShowSingleDetailDialog] =
    useState<boolean>(false);
  const { hasAccess } = useStore();
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const [userCommentsToUpdate, setUserCommentsToUpdate] = useState({
    call_status: "",
    userId: 0,
    remarks: "",
  });

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const userActionStatusOptions = [
    { title: "All Users", value: "" },
    { title: "Signed Up Users", value: "signedup_users" },
    { title: "Applied on Admission", value: "applied_on_admission" },
    { title: "Applied on Job", value: "applied_on_job" },
    { title: "Applied on Short Course", value: "applied_on_short_course" },
  ];

  const columns: string[] = [
    "",
    "image",
    "Name",
    "CNIC",
    "Gender",
    "City",
    "Registered on",
    "Status",
    "Address",
    "Passport No.",
    "Country",
    "Action",
  ];
  const { confirmationPopup } = useAlert();
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllUsers(page + 1, search, per_page, filters);
  };

  const getAllUsers = (
    page: number,
    search: string,
    per_page: number,
    filters: any = {}
  ) => {
    const filtersToSend: any = {};
    for (let key in filters) {
      if (filters[key]) {
        filtersToSend[key] = filters[key];
      }
    }
    const queryParams: any = {
      per_page,
      page,
      search,
      ...filtersToSend,
    };
    getUsers(setData, queryParams, setPagination);
  };

  const handleResetFilters = () => {
    const needToReset: boolean = Object.values(filters).some(
      (value) => value !== ""
    );
    if (needToReset) {
      const resetFilters = { ...filters };
      for (const key in resetFilters) {
        if (Object.prototype.hasOwnProperty.call(resetFilters, key)) {
          resetFilters[key] = "";
        }
      }
      setFilters(resetFilters); // Update the filters state
      getAllUsers(pagination.page, search, pagination.per_page, filters);
    }
  };

  const handleShowSingleDetailDialog = (label: string, value: string) => {
    setSingleDetail({ label, value });
    setShowSingleDetailDialog(true);
  };

  const toggleRowExpand = (index: number) => {
    (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
    setData([...data]);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const goToCreateUSer = () => {
    navigate(siteRoutes.createSystemUsers);
  };

  const handleChangeFilter = (event: any) => {
    const { value, name } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const goToUpdateUser = (userId: number) => {
    navigate(`${siteRoutes.createSystemUsers}?id=${userId}`);
  };

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deleteUserById(id, setData, pagination, setPagination);
    }
  };

  const goToViewCnicImage = (path: string | null) => {
    if (path) window.open(path, "_blank");
  };

  const handleLoginAsUser = async (user_id: number) => {
    const response = await confirmationPopup(
      "Are you sure you want to login to this student account?"
    );
    if (response.isConfirmed) {
      loginAsUser({ user_id });
    }
  };

  const handleOpenUpdateCommentsDialog = (user: any) => {
    const {
      users_meta: [{ remarks = "", call_status = "" }],
      id: userId = 0,
    } = user || { users_meta: [{}] };
    setUserCommentsToUpdate({ remarks, call_status, userId });
    setOpenCommentsDialog(true);
  };
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  ); // State to track the open dropdown by index
  const [isTableOpened, setIsTableOpened] = useState(false);

  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  useEffect(() => {
    getCitiesAdmin(setCities);
  }, []);

  useEffect(() => {
    if (!openCommentsDialog) {
      getAllUsers(pagination.page, search, pagination.per_page, filters);
    }
  }, [openCommentsDialog]);

  return (
    <UsersListingMain>
      <UsersListingTop>
        <div className="left">
          <span className="page-heading">Users</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-org-btn">
            <button className="lg-rounded-btn" onClick={goToCreateUSer}>
              + Add New
            </button>
          </div>
        </div>
      </UsersListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="cnic"
                      value={filters.cnic}
                      onChange={handleChangeFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>City</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.city}
                      onChange={handleChangeFilter}
                      name="city"
                    >
                      <option value="">Select City</option>
                      {cities?.map((city, index) => {
                        return (
                          <option value={city.id} key={index}>
                            {city.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>From Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="start_date"
                      onChange={handleChangeFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>To Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleChangeFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="no">Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.status}
                      onChange={handleChangeFilter}
                      name="status"
                    >
                      <option value="">-- Select --</option>
                      <option value={1}>Active</option>
                      <option value={0}>In Active</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="user_action_status">User Action Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="user_action_status"
                      value={filters.user_action_status}
                      onChange={handleChangeFilter}
                    >
                      {userActionStatusOptions.map((option, index) => {
                        return (
                          <option value={option.value} key={index}>
                            {option.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={handleResetFilters}
                >
                  Reset
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={() =>
                    getAllUsers(1, search, pagination.per_page, filters)
                  }
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <UsersListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadStudentRegPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadStudentRegExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllUsers
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllUsers)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 7 ? `extra-head extra-head-${index - 7}` : ""
                      }
                      key={index}
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{item.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>
                        <img
                          src={
                            item?.profile_image && item.profile_image !== ""
                              ? item.profile_image
                              : KalaAvatar
                          }
                          alt="Profile"
                          style={{
                            height: "35px",
                            width: "35px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      </td>

                      <td>
                        <div>{item?.name}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.cnic}</div>
                      </td>
                      <td>
                        <div className="mw-100">{item?.gender}</div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {item?.city_relation?.title}
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {
                            new Date(item?.created_at)
                              .toISOString()
                              .split("T")[0]
                          }
                        </div>
                      </td>
                      <td>
                        <div className="">
                          {item?.status ? (
                            <span className="status-tile">Active</span>
                          ) : (
                            <span className="status-tile red">Inactive</span>
                          )}
                        </div>
                      </td>
                      <td className="extra-col extra-col-1">{item?.address}</td>
                      <td className="extra-col extra-col-2">
                        {item?.passport_no && item?.passport_no !== "null"
                          ? item?.passport_no
                          : "-"}
                      </td>

                      <td className="extra-col extra-col-3">
                        {item?.country_relation?.name ?? "-"}
                      </td>

                      <td className="extra-col extra-col-5">
                        {/* <div className="table-action-icons">
                          {hasAccess(sitePermissions.studentRegEdit) && (
                            <div
                              className="action-icon"
                              onClick={() => goToUpdateUser(item?.id)}
                            >
                              <EditTableSvg />
                            </div>
                          )}

                          <div
                            className="action-icon"
                            onClick={() =>
                              handleShowSingleDetailDialog(
                                "Phone",
                                item?.phone_no
                              )
                            }
                          >
                            <CallTableSvg />
                          </div>

                          <div
                            className="action-icon"
                            onClick={() =>
                              handleShowSingleDetailDialog("Email", item?.email)
                            }
                          >
                            <MessageTableSvg />
                          </div>
                          {hasAccess(sitePermissions.deleteUserByAdmin) && (
                            <div
                              className="action-icon"
                              onClick={() => handleDelete(item.id)}
                            >
                              <DeleteTableSvg />
                            </div>
                          )}

                          <div className="table-action-button">
                            <button>Manage comments</button>
                          </div>
                        </div> */}
                        <div className="action-menu">
                          <div
                            className="menu-icon cp"
                            onClick={() => toggleDropdown(index)}
                          >
                            <TabPrimaryActionMenu className="icon" />
                          </div>
                          {/* modal */}
                          {openedDropdownIndex === index && (
                            <div className="table-menu-dropdown">
                              <div
                                className="particular-menu cp"
                                onClick={() => goToUpdateUser(item?.id)}
                              >
                                <span className="title">Edit</span>
                              </div>
                              <div
                                className="particular-menu cp"
                                onClick={() =>
                                  handleShowSingleDetailDialog(
                                    "Phone",
                                    item?.phone_no
                                  )
                                }
                              >
                                <span className="title">Call</span>
                              </div>

                              <div
                                className="particular-menu cp"
                                onClick={() =>
                                  handleShowSingleDetailDialog(
                                    "Email",
                                    item?.email
                                  )
                                }
                              >
                                <span className="title">Message</span>
                              </div>
                              {hasAccess(sitePermissions.deleteUserByAdmin) && (
                                <div
                                  className="particular-menu cp"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <span className="title"> Delete</span>
                                </div>
                              )}
                              <div
                                className="particular-menu cp"
                                onClick={() =>
                                  handleOpenUpdateCommentsDialog(item)
                                }
                              >
                                <span className="title"> Manage comments</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    {item.isExpanded && (
                      <tr className="expanded-row">
                        <td></td>
                        <td colSpan={7}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info-1">
                              <span className="title">Address</span>
                              <span className="info">{item?.address}</span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">Passport No.</span>
                              <span className="info">{item?.passport_no}</span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Country</span>
                              <div className="info">
                                <span className="status">
                                  {item?.country_relation?.name}
                                </span>
                              </div>
                            </div>
                            {item?.cnic_image ? (
                              <div className="particular-info particular-info-4">
                                <span className="title">CNIC Image</span>
                                <div className="info">
                                  <span className="status">
                                    <div
                                      className="action-icon cp"
                                      onClick={() =>
                                        goToViewCnicImage(item?.cnic_image)
                                      }
                                    >
                                      <DarkEyeSvg />
                                    </div>
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            <div className="particular-info particular-info-5">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-icons">
                                  {hasAccess(
                                    sitePermissions.studentRegEdit
                                  ) && (
                                    <div
                                      className="action-icon"
                                      onClick={() => goToUpdateUser(item?.id)}
                                    >
                                      <EditTableSvg />
                                    </div>
                                  )}

                                  <div
                                    className="action-icon"
                                    title="Login as"
                                    onClick={() => handleLoginAsUser(item?.id)}
                                  >
                                    <TableBlackRightArrowSvg />
                                  </div>

                                  <div
                                    className="action-icon"
                                    onClick={() =>
                                      handleShowSingleDetailDialog(
                                        "Phone",
                                        item?.phone_no
                                      )
                                    }
                                  >
                                    <CallTableSvg />
                                  </div>

                                  <div
                                    className="action-icon"
                                    onClick={() =>
                                      handleShowSingleDetailDialog(
                                        "Email",
                                        item?.email
                                      )
                                    }
                                  >
                                    <MessageTableSvg />
                                  </div>
                                  {hasAccess(
                                    sitePermissions.deleteUserByAdmin
                                  ) && (
                                    <div
                                      className="action-icon"
                                      onClick={() => handleDelete(item.id)}
                                    >
                                      <DeleteTableSvg />
                                    </div>
                                  )}
                                  <div className="table-action-button">
                                    <button
                                      onClick={() =>
                                        handleOpenUpdateCommentsDialog(item)
                                      }
                                    >
                                      Manage comments
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
          {openCommentsDialog ? (
            <UpdateUserComments
              {...userCommentsToUpdate}
              setOpen={setOpenCommentsDialog}
            />
          ) : (
            ""
          )}
          {showSingleDetailDialog ? (
            <SingleDetailDialog
              {...singleDetail}
              setOpen={setShowSingleDetailDialog}
            />
          ) : (
            ""
          )}
        </Fragment>
      </UsersListingSection>
    </UsersListingMain>
  );
};

export default UsersListing;
