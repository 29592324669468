import { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  DropdownWrapper,
  EditEPortalProfileMain,
  EditEPortalProfileTopSection,
  EditProfileContentSection,
  FormSection,
  FormStepper,
  Step1,
  Step2,
  Step3,
  Step4,
  TableWrapper,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AdditionalInfoSvg,
  ExamEntrySvg,
  GuardiansSvg,
  PersonalInfoSvg,
} from "assets/images/e-portal/svgs";
import dummyAvatar from "assets/images/common/others/dummy-avatar.png";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import Stepper from "components/particles/forms/stepper";
import {
  DeleteTableSvg,
  DownloadPrimaryTableSvg,
  EditGreenTableSvg,
  SmallUploadSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import InputMask from "react-input-mask";
import AddEntryTest from "./components/add-entry-test";
import AddQualification from "./components/add-qualification";
import useEportal from "../../useHooks";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import {
  confirmationPopup,
  warningToaster,
} from "utils/helpers/common/alert-service";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { EditStudentProfile } from "utils/helpers/models/e-portal/edit-student-profile.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useComponentVisible from "hooks/click-outside";
import useStore from "hooks/useStore";
import useMultiClickOutside from "hooks/multi-click-outside";
import SingleSelectField from "components/particles/forms/single-select-field";

interface EditEPortalProfileProps { }

const EditEPortalProfile: FC<EditEPortalProfileProps> = ({ }) => {
  const breadcrumbLinks = [
    {
      title: "Admission Eportal /",
      path: siteRoutes.ePortalDashboard,
    },
    {
      title: "Edit Profile",
      path: siteRoutes.ePortalEditProfile,
    },
  ];

  const relations = [
    { title: "Father" },
    { title: "Mother" },
    { title: "Brother" },
    { title: "Sister" },
    { title: "Uncle" },
    { title: "Grandfather" },
    { title: "Husband" },
    { title: "Other" },
  ];
  const [currentStep, setCurrentStep] = useState<number>(1);
  const academicTableRef = useRef<any>(null);
  const { doesFileSizeExceed } = useStore();
  const [formData, setFormData] = useState<EditStudentProfile>(
    new EditStudentProfile()
  );

  const [openAcademicModal, setOpenAcademicModal] = useState<boolean>(false);
  const [openEntryTestModal, setOpenEntryTestModal] = useState<boolean>(false);
  const [entryTests, setEntryTests] = useState<any[]>([]);
  const [isErrored, setIsErrored] = useState<boolean>(false);
  const [qualifications, setQualifications] = useState<any[]>([]);
  const [invalidStep, setInvalidStep] = useState<number | undefined>();
  const [oldFormData, setOldFormData] = useState<EditStudentProfile>(
    new EditStudentProfile()
  );
  const navigate = useNavigate();
  const {
    getProfile,
    getCitiesByUser,
    updateUserPersonalInfo,
    updateUserGuradianInfo,
    updateUserAdditionalInfo,
    getEntryTestsByUser,
    getQualificationsByUser,
    deleteQualificationByUser,
    downloadQualificationDocumentByUser,
    downloadEntryTestDocument,
    deleteEntryTestByUser,
  } = useEportal();
  const {
    setValue,
    getValues,
    register,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm<EditStudentProfile>();
  const parentRef = useRef(null);
  const [cities, setCities] = useState<any[]>([]);
  const {
    currentIndex: qualificationCurrentIndex,
    refs: qualificationRefs,
    setOpen: setOpenQualificationIndex,
  } = useMultiClickOutside();
  const {
    currentIndex: entryTestCurrentIndex,
    refs: entryTestRefs,
    setOpen: setOpenEntryTestIndex,
  } = useMultiClickOutside();
  const academicColumns: string[] = [
    "Certificate/Degree",
    "Roll No./Reg No.",
    "Board University",
    "Passing Year",
    "Total Marks/GPA",
    "Obtained Marks",
    "Result",
    "Action",
  ];
  const entryTestsColumns: string[] = [
    "Test",
    "Date",
    "Roll No.",
    "Total Marks",
    "Obtained Marks",
    "Result Status",
    "Action",
  ];
  const editProfileSteps = [
    {
      title: "Personal Information",
      icon: PersonalInfoSvg,
      active: true,
      completed: false,
    },
    {
      title: "Guardian’s Information",
      icon: GuardiansSvg,
      active: false,
      completed: false,
    },
    {
      title: "Additional information",
      icon: AdditionalInfoSvg,
      active: false,
      completed: false,
    },
    {
      title: "Exam and Entry",
      icon: ExamEntrySvg,
      active: false,
      completed: false,
    },
  ];

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const goNext = async (step: number, e?: any) => {
    setCurrentStep(step + 1);
    goToPageTop();
  };

  const goToPageTop = () => {
    (parentRef as any)?.current?.scrollIntoView();
  };

  const goBack = (e: any) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  const goToEditQualification = (qualification: any) => {
    const index = qualifications.findIndex((q) => q.id === qualification.id);
    navigate(`${siteRoutes.ePortalEditProfile}`, { state: { qualification } });
    setOpenAcademicModal(true);
    setOpenQualificationIndex(index);
  };

  const goToEditEntryTest = (entryTest: any) => {
    const index = entryTests.findIndex((t) => t.id === entryTest.id);
    navigate(`${siteRoutes.ePortalEditProfile}`, { state: { entryTest } });
    setOpenEntryTestModal(true);
    setOpenEntryTestIndex(index);
  };

  const handleDeleteQualification = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      deleteQualificationByUser(id, setQualifications);
    }
  };

  const handleDeleteEntryTest = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      deleteEntryTestByUser(id, setEntryTests);
    }
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target;
    if (name === "religion") {
      setFormData({ ...formData, [name]: value, is_hafiz: "" });
      setValue("is_hafiz", "");
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setValue(name, value);
    await trigger([name]);
  };

  const handleFileUpload = (event: any) => {
    const { name, files } = event.target;
    const file = files[0];
    if (doesFileSizeExceed(file)) {
      warningToaster("File size must be smaller than 1 MB");
    } else {
      setFormData({ ...formData, [name]: URL.createObjectURL(file) });
      setValue(name, file);
    }
  };

  const onSubmit = (data: any, step: number) => {
    if (step === 1) {
      const fields: any = [
        "first_name",
        "last_name",
        "gender",
        "date_of_birth",
        "email",
        "phone_no",
        // "domicile",
        "p_city",
        "father_name",
        "m_city",
        "m_address",
        "p_address",
        "is_self_dependent",
        "profile_image",
        "cnic_image",
        "cnic_back_image",
        // "domicile_certificate",
      ];

      if (!formData.profile_image) {
        warningToaster("Profile image is required");
      } else if (!formData.cnic_image) {
        warningToaster("Cnic front image is required");
      } else if (!formData.cnic_back_image) {
        warningToaster("Cnic back image is required");
      } else {
        const doesChange = didFormDataChange(fields);
        if (doesChange || isErrored) {
          const form_data = new FormData();
          if (typeof data.profile_image === 'string') {
            delete data.profile_image;
          }
          if (typeof data.cnic_image === 'string') {
            delete data.cnic_image;
          }

          if (typeof data.cnic_back_image === 'string') {
            delete data.cnic_back_image;
          }
          for (let key in data) {
            if (fields.includes(key)) {
              if (key === "is_self_dependent") {
                form_data.append(key, formData.is_self_dependent);
              } else {
                form_data.append(key, data[key]);
              }
            }
          }
          updateUserPersonalInfo(form_data, goNext, step, setIsErrored);
          setOldFormData({ ...formData });
        } else {
          goNext(step);
        }
      }
    } else if (step === 2) {
      const fields = [
        "guardian_name",
        "guardian_email",
        "guardian_phone",
        "guardian_dependent",
        "guardian_relation",
        "guardian_monthly_income",
        "guardian_cnic",
        "guardian_occupation",
      ];

      const doesChange = didFormDataChange(fields);

      if (doesChange || isErrored) {
        const form_data = new FormData();
        for (let key in data) {
          if (fields.includes(key)) {
            form_data.append(key, data[key]);
          }
        }
        updateUserGuradianInfo(form_data, goNext, step, setIsErrored);
        setOldFormData({ ...formData });
      } else {
        goNext(step);
      }
    } else {
      const fields = [
        "blood_group",
        "how_did_know",
        "disability",
        "religion",
        "is_hafiz",
        "it_deficiency",
        "hostel_check",
        "kin_name",
        "relation_with_kin",
        "kin_cnic",
        "kin_phone",
        "other_disability",
        "kin_email",
      ];

      const doesChange = didFormDataChange(fields);

      if (doesChange || isErrored) {
        const form_data = new FormData();
        for (let key in data) {
          if (fields.includes(key)) {
            form_data.append(key, data[key]);
          }
        }

        updateUserAdditionalInfo(form_data, goNext, step, setIsErrored);
        setOldFormData({ ...formData });
      } else {
        goNext(step);
      }
    }
  };
  const onSelectCurrentAddress = (value: number) => {
    handleChange({ target: { name: "m_city", value } });
  };
  const onSelectPermanentAddress = (value: number) => {
    handleChange({ target: { name: "p_city", value } });
  };

  const handleSaveAsAboveCheckbox = async (event: any) => {
    const { checked } = event.target;
    if (checked) {
      const m_city = getValues("m_city");
      const m_address = getValues("m_address");
      setValue("p_city", m_city);
      setValue("p_address", m_address);
      setFormData({ ...formData, p_address: m_address, p_city: m_city });
    } else {
      setValue("p_city", "");
      setValue("p_address", "");
      setFormData({ ...formData, p_address: "", p_city: "" });
    }
    await trigger(["p_city", "p_address"]);
  };

  const handleIsDependentCheckbox = (event: any) => {
    const { checked } = event.target;
    const value = checked ? 1 : 0;
    setValue("is_self_dependent", value);
    setFormData({ ...formData, is_self_dependent: value });
  };

  useEffect(() => {
    getCitiesByUser(setCities);
    getProfile(setFormData, setOldFormData, setValue, formData);
    // register("domicile", { required: true });
    register("m_city", { required: true });
    register("p_city", { required: true });
  }, []);

  useEffect(() => {
    getQualificationsByUser(setQualifications);
    goToPageTop();
  }, [openAcademicModal]);

  useEffect(() => {
    getEntryTestsByUser(setEntryTests);
  }, [openEntryTestModal]);

  const didFormDataChange = (fields: string[]) => {
    let doesChange: boolean = false;
    const oldData: any = { ...oldFormData };
    const newData: any = { ...formData };

    for (let key in newData) {
      if (fields.includes(key)) {
        if (oldData[key] !== newData[key]) {
          doesChange = true;
          break;
        }
      }
    }

    return doesChange;
  };
  const goToApply = () => {
    if (qualifications.length) {
      navigate(siteRoutes.createEportalAdmissionList);
    } else {
      navigate(siteRoutes.ePortalDashboard);
    }
  };

  return (
    <EditEPortalProfileMain ref={parentRef}>
      <EditEPortalProfileTopSection>
        <span className="page-heading">Edit Profile</span>
        <Breadcrumb links={breadcrumbLinks} />
      </EditEPortalProfileTopSection>
      <EditProfileContentSection>
        <FormStepper>
          <Stepper
            steps={editProfileSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            invalidStep={invalidStep}
          />
        </FormStepper>
        <FormSection>
          <form>
            {currentStep === 1 ? (
              <Step1>
                <div className="common-fields">
                  <div className="input-field">
                    <label>First Name</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          value={formData.first_name}
                          {...register("first_name", { required: false })}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.first_name} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Last Name</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          value={formData.last_name}
                          {...register("last_name", { required: false })}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.last_name} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Father Name</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          value={formData.father_name}
                          {...register("father_name", { required: false })}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <FormErrorMessage error={errors.website} /> */}
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Gender</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("gender", { required: true })}
                          value={formData.gender}
                          onChange={handleChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <FormErrorMessage error={errors.gender} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Email</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="email"
                          {...register("email", { required: true })}
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.email} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Mobile Number</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="tel"
                          placeholder="Enter Mobile Number"
                          {...register("phone_no", { required: true })}
                          value={formData.phone_no}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.phone_no} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Date Of Birth</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="date"
                          {...register("date_of_birth", { required: true })}
                          value={formData.date_of_birth}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.date_of_birth} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Current Mailing Address( Address 1, 2)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Enter Mailing Address"
                          {...register("m_address", { required: true })}
                          value={formData.m_address}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.m_address} />
                    </div>
                  </div>

                  <SingleSelectField
                    options={cities}
                    value={formData.m_city}
                    onSelect={onSelectCurrentAddress}
                    helperText="Select City/Tehsil"
                    label="City"
                    error={errors.m_city}
                    searchable={true}
                  />
                  <div className="checkbox-field same-as-above">
                    <input
                      type="checkbox"
                      id="same-as-above"
                      onChange={handleSaveAsAboveCheckbox}
                      checked={
                        formData.p_city === formData.m_city &&
                        formData.m_address === formData.p_address
                      }
                    />
                    <label htmlFor="same-as-above">Same As Above</label>
                  </div>
                  <div className="input-field">
                    <label>Permanent Mailing Address( Address 1, 2)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Enter Mailing Address"
                          {...register("p_address", { required: true })}
                          value={formData.p_address}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.p_address} />
                    </div>
                  </div>
                  <SingleSelectField
                    options={cities}
                    value={formData.p_city}
                    onSelect={onSelectPermanentAddress}
                    helperText="Select City/Tehsil"
                    label="City"
                    searchable={true}
                    error={errors.p_city}
                  />
                </div>

                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    id="is_self_dependent"
                    {...register("is_self_dependent", { required: false })}
                    checked={formData.is_self_dependent == 1}
                    onChange={handleIsDependentCheckbox}
                  />
                  <label htmlFor="is_self_dependent">I am self dependent</label>
                </div>

                <div className="image-fields">
                  <div className="organiztion-admin-top-images">
                    <div className="image-field light">
                      <label className="image">
                        {formData?.profile_image ? (
                          <img src={(formData as any).profile_image} />
                        ) : (
                          <div>
                            <p className="placeholder-heading">
                              Upload
                            </p>
                            Dimensions must be of 300*300
                          </div>
                        )}
                        <input
                          type="file"
                          className="d-none"
                          {...register('profile_image', { required: false })}
                          onChange={handleFileUpload}
                        />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="org-logo">Profile Picture</label>
                    </div>
                    {/* <FormErrorMessage error={errors?.profile_image} /> */}
                  </div>
                  <div className="organiztion-top-images">
                    <div className="image-field light">
                      <label className="side-bar-image">
                        {formData?.cnic_image ? (
                          <img src={(formData as any).cnic_image} />
                        ) : (
                          <div>
                            <p className="placeholder-heading">Upload</p>
                            Dimensions must be of 300*300
                          </div>
                        )}
                        <input
                          type="file"
                          className="d-none"
                          {...register('cnic_image', { required: false })}
                          onChange={handleFileUpload}
                        />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="sidebar_dark_logo">
                        CNIC / B.Form Front Side
                      </label>
                    </div>
                    {/* <FormErrorMessage error={errors?.cnic_image} /> */}
                  </div>
                  <div className="organiztion-top-images">
                    <div className="image-field light">
                      <label className="side-bar-image">
                        {formData?.cnic_back_image ? (
                          <img src={(formData as any).cnic_back_image} />
                        ) : (
                          <div>
                            <p className="placeholder-heading">Upload </p>
                            Dimensions must be of 300*300
                          </div>
                        )}
                        <input
                          type="file"
                          className="d-none"
                          {...register('cnic_back_image', { required: false })}
                          onChange={handleFileUpload}
                        />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="org-logo"> CNIC / B.Form Back Side</label>
                    </div>
                    {/* <FormErrorMessage error={errors?.cnic_back_image} /> */}
                  </div>
                </div>

                <div className="submit-buttons">
                  <div className="buttons">
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 1))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step1>
            ) : currentStep === 2 ? (
              <Step2>
                <div className="common-fields">
                  <div className="input-field">
                    <label>Name of Guardian</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          {...register("guardian_name", { required: true })}
                          value={formData.guardian_name}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.guardian_name} />
                    </div>
                  </div>
                  {/* <div className="input-field">
                    <label>Status</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("guardian_status", { required: true })}
                          value={formData.guardian_status}
                          onChange={handleChange}
                        >
                          <option value="alive">Alive</option>
                          <option value="not_alive">Deceased</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="input-field">
                    <label>Relationship with Guardian</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("guardian_relation", { required: true })}
                          value={formData.guardian_relation}
                          onChange={handleChange}
                        >
                          {relations.map((relation, index) => {
                            return (
                              <option value={relation.title} key={index}>
                                {relation.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <FormErrorMessage error={errors.guardian_relation} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Guardian’s Monthly Income</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="number"
                          {...register("guardian_monthly_income", {
                            required: true,
                          })}
                          value={formData.guardian_monthly_income}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage
                        error={errors.guardian_monthly_income}
                      />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Guardian’s Occupation</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          {...register("guardian_occupation", {
                            required: true,
                          })}
                          value={formData.guardian_occupation}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.guardian_occupation} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Guardian’s CNIC(Optional)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <InputMask
                          mask="99999-9999999-9"
                          {...register("guardian_cnic", { required: false })}
                          value={formData.guardian_cnic ?? ""}
                          onChange={handleChange}
                        >
                          {(inputProps) => (
                            <input type="text" {...inputProps} />
                          )}
                        </InputMask>
                      </div>
                      <FormErrorMessage error={errors.guardian_cnic} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Guardian’s Mobile Number</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="tel"
                          {...register("guardian_phone", { required: true })}
                          value={formData.guardian_phone}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.guardian_phone} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Guardian’s Email Address(Optional)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="email"
                          {...register("guardian_email", { required: false })}
                          value={formData.guardian_email}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.email} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Dependent Family Members on Guardian’s Income</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="number"
                          {...register("guardian_dependent", {
                            required: true,
                          })}
                          value={formData.guardian_dependent}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.guardian_dependent} />
                    </div>
                  </div>
                </div>
                <div className="submit-buttons">
                  <div className="buttons">
                    <button className="lg-rounded-btn gray" onClick={goBack}>
                      Back
                    </button>
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 2))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step2>
            ) : currentStep === 3 ? (
              <Step3>
                <div className="common-fields">
                  <div className="input-field">
                    <label>Blood Group</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("blood_group", { required: false })}
                          value={formData.blood_group}
                          onChange={handleChange}
                        >
                          <option value="">Select Blood Group</option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="AB+">AB+</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                        </select>
                      </div>
                      <FormErrorMessage error={errors.blood_group} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Disability</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("disability", { required: true })}
                          value={formData.disability}
                          onChange={(e) => {
                            handleChange(e);
                            setFormData({
                              ...formData,
                              disability: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <FormErrorMessage error={errors.disability} />
                    </div>
                  </div>
                  {formData?.disability?.toLowerCase() === "yes" && (
                    <div className="input-field">
                      <label>Describe Your Disability</label>
                      <div className="field-wrap">
                        <div className="field">
                          <input
                            type="text"
                            placeholder="Describe Your Disability"
                            {...register("other_disability", {
                              required: formData.disability,
                            })}
                            value={formData.other_disability}
                            onChange={handleChange}
                          />
                        </div>
                        <FormErrorMessage error={errors.other_disability} />
                      </div>
                    </div>
                  )}

                  <div className="input-field">
                    <label>Religion</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("religion", { required: true })}
                          value={formData.religion}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Select one
                          </option>
                          <option value="Islam">Islam</option>
                          <option value="Hinduism">Hinduism</option>
                          <option value="Judism">Judism</option>
                          <option value="Christian">Christian</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <FormErrorMessage error={errors.religion} />
                    </div>
                  </div>
                  {formData.religion === "Islam" ? (
                    <div className="input-field">
                      <label>Hafiz-e-Quran</label>
                      <div className="field-wrap">
                        <div className="field">
                          <select
                            {...register("is_hafiz", { required: true })}
                            value={formData.is_hafiz}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Select one
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0} selected>
                              No
                            </option>
                          </select>
                        </div>
                        <FormErrorMessage error={errors.is_hafiz} />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="skills-questions">
                  <div className="radio-field">
                    <label>
                      Do you feel deficiency in IT and interpersonal skills ?
                    </label>
                    <div className="field-wrap">
                      <div className="field">
                        <label htmlFor="yes">Yes</label>
                        <input
                          type="radio"
                          id="yes"
                          value={1}
                          {...register("it_deficiency", { required: true })}
                          onChange={handleChange}
                          checked={formData.it_deficiency == 1}
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="no">No</label>
                        <input
                          type="radio"
                          id="no"
                          value={0}
                          {...register("it_deficiency", { required: true })}
                          onChange={handleChange}
                          checked={formData.it_deficiency == 0}
                        />
                      </div>
                    </div>
                    <FormErrorMessage error={errors.it_deficiency} />
                  </div>
                  <div className="radio-field">
                    <label>Do you want hostel facility?</label>
                    <div className="field-wrap">
                      <div className="field">
                        <label htmlFor="yes-hostel">Yes</label>
                        <input
                          type="radio"
                          id="yes-hostel"
                          value={1}
                          {...register("hostel_check", { required: true })}
                          onChange={handleChange}
                          checked={formData.hostel_check == 1}
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="no-hostel">No</label>
                        <input
                          type="radio"
                          value={0}
                          checked={formData?.hostel_check == 0}
                          id="no-hostel"
                          {...register("hostel_check", { required: true })}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <FormErrorMessage error={errors.hostel_check} />
                  </div>
                </div>

                <div className="next-of-kins-section">
                  <div className="heading">
                    <span>Next of Kin Information</span>
                  </div>

                  <div className="common-fields">
                    <div className="input-field">
                      <label>Next of Kin</label>
                      <div className="field-wrap">
                        <div className="field">
                          <input
                            type="text"
                            {...register("kin_name", { required: true })}
                            value={formData.kin_name}
                            onChange={handleChange}
                          />
                        </div>
                        <FormErrorMessage error={errors.kin_name} />
                      </div>
                    </div>
                    <div className="input-field">
                      <label>Relationship with next of kin</label>
                      <div className="field-wrap">
                        <div className="field">
                          <select
                            {...register("relation_with_kin", {
                              required: true,
                            })}
                            value={formData.relation_with_kin}
                            onChange={handleChange}
                          >
                            {relations.map((relation, index) => {
                              return (
                                <option value={relation.title} key={index}>
                                  {relation.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <FormErrorMessage error={errors.relation_with_kin} />
                      </div>
                    </div>
                    <div className="input-field">
                      <label>Next of Kin Mobile Number</label>
                      <div className="field-wrap">
                        <div className="field">
                          <input
                            type="tel"
                            {...register("kin_phone", { required: true })}
                            value={formData.kin_phone}
                            onChange={handleChange}
                          />
                        </div>
                        <FormErrorMessage error={errors.kin_phone} />
                      </div>
                    </div>
                    <div className="input-field">
                      <label>Next of kin CNIC(Optional)</label>
                      <div className="field-wrap">
                        <div className="field">
                          <InputMask
                            mask="99999-9999999-9"
                            {...register("kin_cnic", { required: false })}
                            value={formData.kin_cnic ?? ""}
                            onChange={handleChange}
                          >
                            {(inputProps) => (
                              <input type="cnic" {...inputProps} />
                            )}
                          </InputMask>
                        </div>
                        <FormErrorMessage error={errors.kin_cnic} />
                      </div>
                    </div>
                    <div className="input-field">
                      <label>Next of Kin Email Address(Optional)</label>
                      <div className="field-wrap">
                        <div className="field">
                          <input
                            type="email"
                            {...register("kin_email", { required: false })}
                            value={formData.kin_email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-buttons">
                  <div className="buttons">
                    <button className="lg-rounded-btn gray" onClick={goBack}>
                      Back
                    </button>
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 3))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step3>
            ) : (
              <Step4>
                <TableWrapper isTableOverflowing={false}>
                  <div className="header">
                    <div className="heading">
                      <span>Academic Qualification(s)</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenAcademicModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div
                    className={`data-table ${qualificationCurrentIndex !== null ? "menu-opened" : ""
                      }`}
                  >
                    <table
                      className="bottom-bordered-cells"
                      ref={academicTableRef}
                    >
                      <thead>
                        <tr>
                          {academicColumns.map(
                            (item: string, index: number) => {
                              return <th key={index}>{item}</th>;
                            }
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {qualifications.map(
                          (qualification: any, index: number) => {
                            return (
                              <tr>
                                <td>
                                  {qualification?.certificate_type?.title}
                                </td>
                                <td>{qualification?.roll_num}</td>
                                <td>
                                  <div className="mw-150">
                                    {qualification?.board?.title}
                                  </div>
                                </td>
                                <td>{qualification?.passing_year}</td>
                                <td>{qualification?.total_marks}</td>
                                <td>{qualification?.obt_marks}</td>
                                <td>{qualification?.result_type?.title}</td>
                                <td>
                                  <div
                                    className="action-menu"
                                    ref={(el) =>
                                      qualificationRefs.current.push(el!)
                                    }
                                  >
                                    <div
                                      className="menu-icon cp"
                                      onClick={() =>
                                        setOpenQualificationIndex(index)
                                      }
                                    >
                                      <TabPrimaryActionMenu className="icon" />
                                    </div>
                                    {qualificationCurrentIndex === index && (
                                      <div className="table-menu-dropdown">
                                        <div
                                          className="particular-menu cp"
                                          onClick={() =>
                                            goToEditQualification(qualification)
                                          }
                                        >
                                          <div className="action-icon">
                                            <EditGreenTableSvg className="icon" />
                                          </div>
                                          <span className="title">Edit</span>
                                        </div>
                                        <div
                                          className="particular-menu cp"
                                          onClick={() =>
                                            handleDeleteQualification(
                                              qualification?.id
                                            )
                                          }
                                        >
                                          <div className="action-icon">
                                            <DeleteTableSvg className="icon" />
                                          </div>
                                          <span className="title">Delete</span>
                                        </div>
                                        {qualification?.result_document && (
                                          <div
                                            className="particular-menu cp"
                                            onClick={() =>
                                              downloadQualificationDocumentByUser(
                                                qualification?.id
                                              )
                                            }
                                          >
                                            <div className="action-icon">
                                              <DownloadPrimaryTableSvg className="icon" />
                                            </div>
                                            <span className="title">
                                              Download Document
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !qualifications.length} />
                  </Fragment>
                </TableWrapper>
                <TableWrapper
                  isTableOverflowing={
                    academicTableRef?.current?.scrollHeight >
                    academicTableRef?.current?.offsetHeight
                  }
                >
                  <div className="header">
                    <div className="heading">
                      <span>Entry test(s)</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenEntryTestModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div
                    className={`data-table ${entryTestCurrentIndex !== null ? "menu-opened" : ""
                      }`}
                  >
                    <table
                      className="bottom-bordered-cells"
                      ref={academicTableRef}
                    >
                      <thead>
                        <tr>
                          {entryTestsColumns.map(
                            (item: string, index: number) => {
                              return <th key={index}>{item}</th>;
                            }
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {entryTests.map((item: any, index: number) => {
                          return (
                            <tr>
                              <td>{item?.test?.title}</td>
                              <td>{item?.test_date}</td>
                              <td>{item?.roll_number}</td>
                              <td>{item?.total_marks}</td>
                              <td>{item?.obtained_marks}</td>
                              <td>
                                {item?.result_awaiting
                                  ? "Pending"
                                  : "Announced"}
                              </td>
                              <td>
                                <DropdownWrapper>
                                  <div
                                    className="action-menu"
                                    ref={(el) =>
                                      entryTestRefs.current.push(el!)
                                    }
                                  >
                                    <div
                                      className="menu-icon"
                                      onClick={() =>
                                        setOpenEntryTestIndex(index)
                                      }
                                    >
                                      <TabPrimaryActionMenu className="icon" />
                                    </div>

                                    {entryTestCurrentIndex === index && (
                                      <div className="table-menu-dropdown">
                                        <div
                                          className="particular-menu cp"
                                          onClick={() =>
                                            goToEditEntryTest(item)
                                          }
                                        >
                                          <div className="action-icon">
                                            <EditGreenTableSvg className="icon" />
                                          </div>
                                          <span className="title">Edit</span>
                                        </div>
                                        <div
                                          className="particular-menu cp"
                                          onClick={() =>
                                            handleDeleteEntryTest(item?.id)
                                          }
                                        >
                                          <div className="action-icon">
                                            <DeleteTableSvg className="icon" />
                                          </div>
                                          <span className="title">Delete</span>
                                        </div>
                                        {item?.result_document && (
                                          <div
                                            className="particular-menu cp"
                                            onClick={() =>
                                              downloadEntryTestDocument(
                                                item?.id
                                              )
                                            }
                                          >
                                            <div className="action-icon">
                                              <DownloadPrimaryTableSvg className="icon" />
                                            </div>
                                            <span className="title">
                                              Download Document
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </DropdownWrapper>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !entryTests.length} />
                  </Fragment>
                </TableWrapper>

                <div className="submit-buttons">
                  <button onClick={goBack} className="lg-rounded-btn gray">
                    Back
                  </button>
                  <button
                    onClick={goToApply}
                    className="lg-rounded-btn"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Step4>
            )}
          </form>
        </FormSection>
      </EditProfileContentSection>
      {openEntryTestModal && <AddEntryTest setOpen={setOpenEntryTestModal} />}
      {openAcademicModal && <AddQualification setOpen={setOpenAcademicModal} />}
    </EditEPortalProfileMain>
  );
};

export default EditEPortalProfile;
