import { FC, useState } from "react";
import {
    QuotaAddListProgramMain,
    QuotaAddListProgramContainer,
    QuotaAddListProgramWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { AddGroupDTO } from "utils/helpers/models/academics/add-group.dto";
import useAdmissions from "containers/private/admissions/useHooks";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
// import useAcademics from "../../useHooks";

interface Group {
    or_group: any;
    combined_group: any;
}

interface addGroupProps {

    setOpen: (open: boolean) => void;
    groupData: Group[],
    record: string,
    recordJoin: any,
    setData: any,
    headerId: any,
    templateId: number,
}

const AddGroupModal: FC<addGroupProps> = ({ setOpen, groupData, record, recordJoin, setData, headerId, templateId }) => {
    const [formData, setFormData] = useState<AddGroupDTO>(() => new AddGroupDTO());

    const {
        mergeTemplateBodies,
    } = useAdmissions();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<AddGroupDTO>({
        defaultValues: formData,
        mode: "onChange",
    });

    const handleCloseModal = () => {
        setOpen(false);
    };

    const onSubmit = () => {
        const queryParams = { per_page: "All", page: "1" };
        if (record == "or_group") {
            mergeTemplateBodies(
                {
                    record_type: record,
                    record_ids: templateId,
                    group_number: getValues('or_group'),
                },
                headerId,
                setData,
                queryParams,
                setOpen
            );
        } else {
            const queryParams = { per_page: "All", page: "1" };
            mergeTemplateBodies(
                {
                    record_type: record,
                    record_ids: templateId,
                    group_number: getValues('combined_group'),
                },
                headerId,
                setData,
                queryParams,
                setOpen

            );
        }
    };

    return (
        <QuotaAddListProgramMain>
            <QuotaAddListProgramContainer>
                <QuotaAddListProgramWrapper className="p-custom-scrollbar-8">
                    <div className="header">
                        <div className="empty"></div>
                        <div className="heading">
                            <span>Add {record == "or_group" ? "OR" : "Combined"} Group</span>
                        </div>
                        <div className="close-icon cp" onClick={handleCloseModal}>
                            <CloseMediumSvg className="icon" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {record == "or_group" ? (
                            <div className="common-fields">
                                <div className="input-field">
                                    <label>Or Groups</label>
                                    <div className="field-wrap">
                                        <div className="field">
                                            <select
                                                {...register("or_group", { required: true })}
                                            // onChange={handleChange}
                                            >
                                                <option value="" disabled>Select Group</option>
                                                <optgroup label="Generate new group ID">
                                                    <option value="new">New</option>
                                                </optgroup>
                                                <optgroup label="Select Existing Group ID">
                                                    {groupData && groupData?.map((item: any, index: number) => (
                                                        <option className={item == null ? "d-none" : ""} key={index} value={item}>{item == null ? "this value not added" : item}</option>
                                                    ))}
                                                </optgroup>

                                            </select>
                                        </div>
                                        <FormErrorMessage error={errors.or_group} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="common-fields">
                                <div className="input-field">
                                    <label>Combined Groups</label>
                                    <div className="field-wrap">
                                        <div className="field">
                                            <select
                                                {...register("combined_group", { required: true })}
                                            // value={formData.type}
                                            // onChange={handleChange}
                                            >
                                                <option value="" disabled>Select Group</option>
                                                <optgroup label="Generate new group ID">
                                                    <option value="new">New</option>
                                                </optgroup>
                                                <optgroup label="Select Existing Group ID">
                                                    {groupData && groupData?.map((item: any, index: number) => (
                                                        <option className={item == null ? "d-none" : ""} key={index} value={item}>{item}</option>
                                                    ))}
                                                </optgroup>

                                            </select>
                                        </div>
                                        <FormErrorMessage error={errors.combined_group} />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="action-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                                    type="button"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                                <button className="lg-rounded-btn" type="submit">
                                    Save & Close
                                </button>
                            </div>
                        </div>
                    </form>
                </QuotaAddListProgramWrapper>
            </QuotaAddListProgramContainer>
        </QuotaAddListProgramMain >
    );
};

export default AddGroupModal;
