import useStore from "hooks/useStore";
import { SYSTEM_ADMINISTRATION_API } from "libs/apis/system-administration";
import { useNavigate } from "react-router-dom";
import { errorToaster, successToaster } from "utils/helpers/common/alert-service";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { OrganizationSettingDTO } from "utils/helpers/models/organization/org-setting.dto";
import { UpdateUserCommentsDTO } from "utils/helpers/models/system-administration/update-user-comments.dto";
import { UserManagementDTO } from "utils/helpers/models/system-administration/user-management.dto";

const useSystemAdministration = () => {
  const navigate = useNavigate();
  const { setToken, setUser, setPermissions, setPriorLoginAsRoute, setRole } = useStore();

  const getOrganizationSetting = async (
    setFormData: Function,
    setValue: Function
  ) => {
    const response = await SYSTEM_ADMINISTRATION_API.getCurrentOrgSetting();
    // console.log(response?.response);
    const data = response?.response;

    if (response?.status) {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          setValue(key as keyof OrganizationSettingDTO, data[key]);
        }
      }
      setFormData(data);
    }
  };

  const UpdateOrganizationSetting = async (body: OrganizationSettingDTO) => {
    const response = await SYSTEM_ADMINISTRATION_API.updateCurrentOrgSetting(
      body
    );
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
    }
  };

  const createUser = async (body: any, addMore: boolean, reset: Function) => {
    const response = await SYSTEM_ADMINISTRATION_API.createUser(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      if (addMore) {
        reset();
      } else {
        navigate(-1);
      }
    }
  };
  const updateUser = async (
    id: number,
    body: any,
    shouldNavigate: boolean = true
  ) => {
    const response = await SYSTEM_ADMINISTRATION_API.updateUser(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      if (shouldNavigate) navigate(-1);
    }
  };

  const getUsers = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await SYSTEM_ADMINISTRATION_API.getUsers(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data.map((i) => new UserManagementDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new UserManagementDTO(i))
            : []
        );
      }
    }
  };

  const getUserById = async (
    id: number,
    formData: any,
    setFormData: Function,
    setValue?: Function
  ) => {
    const response = await SYSTEM_ADMINISTRATION_API.getUserById(id);
    const { response: data } = response || {};
    if (Object.keys(data ?? {}).length) {
      delete data?.users_meta;
      for (let key in formData) {
        formData[key] = data[key];
        if (setValue) {
          setValue(key, formData[key]);
        }
      }
      setFormData({ ...formData });
    }
  };

  const updateUserComments = async (body: UpdateUserCommentsDTO, setOpen: Function) => {
    const response = await SYSTEM_ADMINISTRATION_API.updateUserComments(body, body.userId);
    const { status = false, message = ''} = response || {};
    if(status){
      successToaster(message);
      setOpen(false);
    }
  }

  const deleteUserById = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await SYSTEM_ADMINISTRATION_API.deleteUserById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getUsers(setData, queryParams, setPagination);
    }
  };

  const getCitiesByAdmin = async (setData: Function) => {
    const response = await SYSTEM_ADMINISTRATION_API.getCitiesByAdmin();
    // console.log(response);
    if (response) {
      setData(response?.data);
    }
    return response;
  };
  const getRoles = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await SYSTEM_ADMINISTRATION_API.getRoles(queryParams);

    if (response?.status) {
      const data = response?.response;
      if (data) {
        if (setPagination) {
          const {
            total: totalRecords,
            current_page: page,
            per_page,
          } = response?.response;
          setPagination({ per_page, totalRecords, page });
          setData(data);
        } else {
          setData(response?.response);
        }
      }
    }
  };

  return {
    createUser,
    updateUser,
    getUserById,
    getUsers,
    deleteUserById,
    getRoles,
    getCitiesByAdmin,
    getOrganizationSetting,
    UpdateOrganizationSetting,
    updateUserComments,
  };
};

export default useSystemAdministration;
