export class CourseAnomaliesDTO {
  course_enrollemts_not_assigned: number = 0;
  course_sections_not_assigned: number = 0;
  course_teachers_not_assigned: number = 0;
  duplicate_template_courses: number = 0;
  enrollments_not_belongsto_pos: number = 0;

  constructor(data: Partial<CourseAnomaliesDTO> = {}) {
    Object.assign(this, data);
  }
}
