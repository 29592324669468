import { ChangeEvent, FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AddBuildingDTO } from "utils/helpers/models/organization/add-building.dto";
import { SmallUploadSvg } from "assets/images/common/svgs";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useOrganization from "../../useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import Breadcrumb from "components/particles/breadcrumb";
import { CreateBuildingStructureMain, CreateBuildingStructureTopSection, Form } from "./style";

const CreateBuildingStructure: FC = () => {
    const breadcumbLinks = [
        { title: "Infrastructure & Asset Management /", path: siteRoutes.buildingStructureListing },
        { title: "Add Building Structure", path: siteRoutes.createBuilding },
    ];

    const { getQueryParams } = useUtils();
    const params = getQueryParams();
    const {
        createBuilding,
        updateBuildingById,
        getStucutureForBuildingManagement,
        getStucutureChildForBuildingManagement,
        getDepartmentForBuildingManagement,
        getBuildingById,
    } = useOrganization();

    const [getFaculty, setGetFaculty] = useState<any[]>([]);
    const [getDeparments, setGetDepartments] = useState<any[]>([]);
    const [structureType, setStructureType] = useState<any[]>([]);
    const [formData, setFormData] = useState<AddBuildingDTO>(new AddBuildingDTO());

    const {
        handleSubmit,
        register,
        setValue,
        trigger,
        getValues,
        formState: { errors },
    } = useForm<AddBuildingDTO>({
        defaultValues: formData,
    });

    const handleUploadImg = (event: ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files![0];
        if (file) {
            setValue("img_url", file);
            setFormData({ ...formData, img_url: URL.createObjectURL(file) });
            trigger(["img_url"]);
        }
    };

    const handleUploadImgMap = (event: ChangeEvent<HTMLInputElement>) => {
        // const handleUploadImgMap = (event: any) => {
        const file: any = event.target.files![0];
        const url = URL.createObjectURL(file);

        if (file) {
            setValue("map_img_url", file);
            setFormData({ ...formData, map_img_url: URL.createObjectURL(file) });
            trigger(["map_img_url"]);
        }
    };
    const handleChange = (event: any) => {
        const { name, value } = event.target;

        if (name == "campus") {
            setGetFaculty([]);
            getStucutureChildForBuildingManagement(value, setGetFaculty);
        } else if (name == "faculty") {
            setGetDepartments([]);
            getDepartmentForBuildingManagement(value, setGetDepartments);
        } else if (name == "department") {
            setFormData({
                ...formData,
                department: formData?.department,
            });
        } else {
            setValue(name, value);
            formData[name as keyof AddBuildingDTO] = value as never;
            setFormData({ ...formData });
            trigger(name);
        }
    };

    useEffect(() => {
        if (params?.id) {
            getBuildingById(params?.id, (fetchedData: any) => {
                setFormData(fetchedData);  // Update form data in state
                Object.keys(fetchedData).forEach(key => {
                    setValue(key as keyof AddBuildingDTO, fetchedData[key]);
                });
            });
        }
        getStucutureForBuildingManagement(setStructureType);
    }, [params?.id, setValue]);

    const resetForm = () => {
        for (let key in getValues()) {
            setValue(key as keyof AddBuildingDTO, "");
        }
        setFormData({ ...new AddBuildingDTO() });
    };

    const onSubmit = (
        data: AddBuildingDTO,
        addMore: boolean = false,
    ) => {

        const form_data = new FormData();
        for (let key in data) {
            form_data.append(key, data[key as keyof AddBuildingDTO]);
        }
        if (params?.id) {
            updateBuildingById(params.id, form_data, resetForm);
        } else {
            createBuilding(form_data, resetForm, addMore);
        }
    };

    return (
        <CreateBuildingStructureMain>
            <CreateBuildingStructureTopSection>
                <span className="page-heading">{params?.id ? 'Update' : 'Add'} Buildings</span>
             <Breadcrumb links={breadcumbLinks} />
            </CreateBuildingStructureTopSection>

            {/* <Form className="content-radius-shadow"> */}
            <div className="common-fields">
                {/* Building Image Upload */}
                <div className="upload-field">
                    <label>Building Image</label>
                    <div className="field-wrapper">
                        <label className="file-name-section" htmlFor="buildingImage">
                            <div className="inner-content">
                                <div className="upload-text">
                                    <div className="upload-icon">
                                        <SmallUploadSvg className="icon" />
                                    </div>
                                    <span className="text">Upload Building Image</span>
                                </div>
                                <div className="upload-restrictions">
                                    Select jpg/png image with a maximum size of 900 KB
                                </div>
                            </div>
                        </label>
                        <input
                            type="file"
                            {...register("img_url", { required: false })}
                            className="d-none"
                            id="buildingImage"
                            onChange={handleUploadImg}
                        />

                        <div className="uploaded-image">
                            <img src={formData?.img_url ?? squareAvatar} alt="Building" />
                        </div>

                    </div>
                    <FormErrorMessage error={errors.img_url} />
                </div>

                {/* Building Map Upload */}
                <div className="upload-field">
                    <label>Building Map</label>
                    <div className="field-wrapper">
                        <label className="file-name-section" htmlFor="buildingMap">
                            <div className="inner-content">
                                <div className="upload-text">
                                    <div className="upload-icon">
                                        <SmallUploadSvg className="icon" />
                                    </div>
                                    <span className="text">Upload Building Map</span>
                                </div>
                                <div className="upload-restrictions">
                                    Select jpg/png image with a maximum size of 900 KB
                                </div>
                            </div>
                        </label>
                        <input
                            type="file"
                            {...register("map_img_url", { required: false })}
                            className="d-none"
                            id="buildingMap"
                            onChange={handleUploadImgMap}
                        />
                        {formData?.map_img_url ? (
                            <div className="uploaded-image">
                                <img src={formData?.map_img_url} alt="Building Map" />
                            </div>
                        ) : (
                            <div className="uploaded-image">
                                <img src={squareAvatar} alt="Default" />
                            </div>
                        )}
                    </div>
                    <FormErrorMessage error={errors.map_img_url} />
                </div>
            </div>

            {/* Building Details Fields */}
            <div className="common-fields">

                <div className="input-field">
                    <label>Select {structureType[0]?.campus_type?.title}</label>
                    <div className="field-wrap">
                        <div className="field">
                            <select
                                {...register('campus', { required: false })}
                                onChange={handleChange}
                            >
                                <option value="">Select {structureType[0]?.campus_type?.type}</option>
                                {structureType && structureType?.map((item, i) => (
                                    <option key={i} value={item?.id}>{item?.title}</option>
                                ))}
                            </select>
                        </div>
                        <FormErrorMessage error={errors.campus} />
                    </div>
                </div>

                {/* dynamic fields */}
                {getFaculty.length >= 1 ? (
                    <div className="input-field">
                        <label>Select {getFaculty[0]?.type?.title}</label>
                        <div className="field-wrap">
                            <div className="field">
                                <select
                                    {...register('faculty', { required: false })}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select {getFaculty[0]?.type?.type}</option>
                                    {getFaculty && getFaculty?.map((item, i) => (
                                        <option key={i} value={item?.id}>{item?.title}</option>
                                    ))}
                                </select>
                            </div>
                            <FormErrorMessage error={errors.faculty} />
                        </div>
                    </div>
                ) : (<></>)}
                {/* dynamic fields */}
                {(formData?.department || getDeparments.length >= 1) && (
                    <div className="input-field">
                        <label>Select {getDeparments[0]?.type?.title}</label>
                        <div className="field-wrap">
                            <div className="field">
                                <select
                                    {...register('department', { required: false })}
                                    onChange={handleChange}
                                //     value={getDeparments?.find(
                                //         (option) => option.id == formData?.department
                                //     )}
                                >
                                    <option value="" disabled>Select {getDeparments[0]?.type?.type}</option>
                                    {getDeparments?.map((item, i) => (
                                        <option key={i} value={item?.id}>{item?.title}</option>
                                    ))}
                                </select>
                            </div>
                            <FormErrorMessage error={errors.department} />
                        </div>
                    </div>
                )}

                <div className="input-field">
                    <label>Building Title</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="text"
                                {...register('title', { required: true })}
                                value={formData?.title}
                                onChange={handleChange}
                                placeholder="Building title"
                            />
                        </div>
                        <FormErrorMessage error={errors.title} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Building Length</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="number"
                                {...register('building_length', { required: true })}
                                value={formData?.building_length}
                                onChange={handleChange}
                                placeholder="Building Length"
                            />
                        </div>
                        <FormErrorMessage error={errors.building_length} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Building Width</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="number"
                                {...register('building_width', { required: true })}
                                value={formData?.building_width}
                                onChange={handleChange}
                                placeholder="Building Width"
                            />
                        </div>
                        <FormErrorMessage error={errors.building_width} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Short Name</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="text"
                                {...register('short_name', { required: true })}
                                value={formData?.short_name}
                                onChange={handleChange}
                                placeholder="Short Name"
                            />
                        </div>
                        <FormErrorMessage error={errors.short_name} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Location type Id</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="number"
                                {...register('location_type_id', { required: false })}
                                value={formData?.location_type_id}
                                onChange={handleChange}
                                placeholder="Location type Id"
                            />
                        </div>
                        <FormErrorMessage error={errors.location_type_id} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Location Id</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="number"
                                {...register('location_id', { required: true })}
                                value={formData?.location_id}
                                onChange={handleChange}
                                placeholder="Location Id"
                            />
                        </div>
                        <FormErrorMessage error={errors.location_id} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Building Type Id</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="number"
                                {...register('building_type_id', { required: true })}
                                value={formData?.building_type_id}
                                onChange={handleChange}
                                placeholder="Building Type Id"
                            />
                        </div>
                        <FormErrorMessage error={errors.building_type_id} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Longitude</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="text"
                                {...register('longitude', { required: true })}
                                value={formData?.longitude}
                                onChange={handleChange}
                                placeholder="Longitude"
                            />
                        </div>
                        <FormErrorMessage error={errors.longitude} />
                    </div>
                </div>
                <div className="input-field">
                    <label>Latitude</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input
                                type="text"
                                {...register('latitude', { required: true })}
                                value={formData?.latitude}
                                onChange={handleChange}
                                placeholder="Latitude"
                            />
                        </div>
                        <FormErrorMessage error={errors.latitude} />
                    </div>
                </div>
            </div>

            {/* Submit Buttons */}
            <div className="submit-buttons">
                <button type="button" onClick={resetForm} className="lg-rounded-btn gray">Reset</button>
                {!params?.id && (
                    <button
                        className="lg-rounded-btn spring"
                        onClick={handleSubmit((formData: AddBuildingDTO) =>
                            onSubmit(formData, true)
                        )}
                    >
                        Save & Add more
                    </button>
                )}
                <button
                    className="lg-rounded-btn"
                    onClick={handleSubmit((formData: AddBuildingDTO) =>
                        onSubmit(formData)
                    )}
                >
                    {params?.id ? "Update" : "Save"} & Exit
                </button>
            </div>
            {/* </Form> */}
        </CreateBuildingStructureMain>
    );
};

export default CreateBuildingStructure;
