import styled from "styled-components";

export const HeaderMain = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: var(--white-color);
    position: relative;
`;

export const MenuIcon = styled.div`
    width: 35px;
    height: 20px;
    cursor: pointer;

    .icon{
        width: 100%;
        height: 100%;
    }
`;

export const HeaderMenuBar = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 5px 4px 14px 0px #0000001A;
    padding: 0 2.5em 0 2em;

    @media screen and (max-width: 630px){
        padding: 0 1em;
    }
`;

export const MenuBarRight = styled.div`
    display: flex;
    gap: 4em;
    align-items: center;
`;

export const MenuBarLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 3rem;
    
    @media  (max-width: 425px) {
        justify-content: space-between;
        width: 75%;
        gap: 0;
    }
    @media  (max-width: 375px) {
        justify-content: space-between;
        width: 85%;
        
    }
  

`;

export const SearchField = styled.div`
    width: 223px;
    height: 48px;
    display: flex;
    gap: 0.2em;
    align-items: center;
    background: var(--header-utility-icons-bg);
    border-radius: 200px;

    @media screen and (max-width: 710px){
        display: none;
    }

    .search-icon{
        width: 100%;
        /* height: 100%; */
        padding-left: 1em;
        padding-top: 0.3em;

        .icon{
        width: 20px;
        height: 19px;
        }
    }

    .field{
        width: 100%;
        padding-right: 1em;
        /* height: 100%; */
        color: var(--metalic-gray);
        font-size: 15px;
        font-weight: 500;

        input{
            &::placeholder{
                color: inherit;
            }
        }
    }
`;

export const UtilityIcons = styled.div`
    display: flex;
    gap: 1em;
    @media(max-width: 425px) {
        display: none;
    }

    .utility-icon{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: var(--header-utility-icons-bg);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 630px){
            width: 40px;
            height: 40px;

            .icon{
                width: 20px;
                height: 20px;
            }
        }
        .icon{
            width: 22px;
            height: 22px;
        }
    }
`;

export const ProfileSection = styled.div`

`;

export const ProfileImage = styled.div`
    width: 58px;
    height: 58px;

    .image{
        img{
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
        }
    }

    .avatar{
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background: var(--header-utility-icons-bg);
        color: var(--black-text);
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
export const ModalMain = styled.div`
    border: 1px solid var(--field-border);
    border-radius: 8px;
    width: 308px;
    background-color: var(--white-color);
    position: absolute;
    z-index: 100;
    right: 1rem;
    top: 5rem;
    padding: 1em 1.5em;


    .top-img{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;

    }
    .user-name{
    font-weight: 500;
    color: var(--black-text);
    }
    .modal-listing-section{
        ul{
            list-style: none;
            margin-top: 1.5rem;
            li{
                display: flex;
                gap: 10px;
                justify-content: start;
                margin-top: 10px;
                cursor: pointer;
            }
        }
        span{
            color: var(--modal-grey);
        }
    }
    .modal-icon{
    path{
        fill:var(--lightgray-medium);
    }
}
.theme-zoom-main{
    @media (min-width: 425px) {
        display: none;
    }
}
.theme-btn-main{
    margin-top: 1em;
}
.theme-label{
    color: var(--modal-grey);
}
.theme-btn{
    margin-top: 5px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary); 
}

input:checked + .slider:before {
  transform: translateX(26px);
}


`


export const SiteLogo = styled.div`
  padding: 0 1em 0 1em;
  display: flex;
  @media (min-width: 425px) {
    display: none;
  }
  
  .org-logo{
    max-width: 220px;

    img{
      width: 100%;
      height: 100%;
    }
  }
`
export const ZoomSettings = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 0;

    .particular-setting{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
      


        .heading{
        
          
            color: var(--lightgray-medium);
        }

        .btns{
            width: 100%;
            display: flex;
          
            gap: 1em;
        }
    }

    .bottom-border{
        width: 100%;
        border-bottom: 1px solid var(--lightgray-extralight);
    }
`;

export const ZoomBtn = styled.div<{$zoomOverflowed: boolean}>`
        width: 45px;
        height: 30px;
        border: 1px solid var(--lightgray-extralight);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: var(--lightgray-medium);
        opacity: ${({$zoomOverflowed}) => $zoomOverflowed ? '.5' : '1'};

        .operator{
            font-size: var(--font-24);
            font-weight: 500;
        }
`;
    