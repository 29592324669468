import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicFinancialVouchersSection,
  AcademicFinancialVouchersMain,
  AcademicFinancialVouchersTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import {
  DownloadTableActionSvg,
  UploadGreenTableSvg,
  ViewDocumentTableSvg,
} from "assets/images/common/svgs";

const AcademicFinancialVouchers: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard  / ", path: "" },
    { title: "Financial Voucher ", path: siteRoutes.academicFinancialVouchers },
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = [
    "Title",
    "Challan #",
    "Amount",
    "Due Date",
    "Status",
    "Remarks",
    "Action",
  ];
  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
  };

  return (
    <AcademicFinancialVouchersMain>
      <AcademicFinancialVouchersTop>
        <div className="left">
          <span className="page-heading">Financial Voucher</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </AcademicFinancialVouchersTop>

      <AcademicFinancialVouchersSection className="content-radius-shadow">
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>Fall 2023 Admission Process Fee</td>

                    <td>
                      <div className="mw-150">2398764320</div>
                    </td>

                    <td>
                      <div className="status">1500</div>
                    </td>
                    <td>21-12-2024</td>
                    <td>
                      <span className="status-tile red">Pending</span>
                    </td>
                    <td></td>
                    <td>
                      <div className="table-action-icons">
                        <div className="action-icons">
                          <DownloadTableActionSvg />
                        </div>
                        <div className="action-icons">
                          <UploadGreenTableSvg />
                        </div>
                        <div className="action-icons">
                          <ViewDocumentTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </AcademicFinancialVouchersSection>
    </AcademicFinancialVouchersMain>
  );
};

export default AcademicFinancialVouchers;
