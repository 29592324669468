import { FC, Fragment, useState, useEffect } from "react";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AdmissionApplicantsListingMain,
  AdmissionApplicantsListingSection,
  AdmissionApplicantsListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAdmissions from "../useHooks";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import Pagination from "components/particles/table/pagination";

interface Props { }

const AdmissionApplicantsListing: FC<
Props
> = ({ }) => {
  const breadcrumbLinks = [
    { title: "Admission /", path: "" },
    { title: "Student Listing", path: siteRoutes.admissionStudentListing },
  ];
  const {
    getStudentApplicants,
    getCampusList,
    getStudentProgram,
    getStudentFilterData,
  } = useAdmissions();
  const { getQueryParams,handleSearchChange, handleTableSearch } = useUtils();
  const params = getQueryParams();

  const [search, setSearch] = useState("");
  const [studentData, setStudentData] = useState<any[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const { hasAccess } = useStore();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "",
    "CNIC",
    "Name",
    "Father Name",
    "Father CNIC",
    "Application No.",
    "Fee Status",
    "Registration Date",
    "Merit",
    "Program",
    "Domicile ",
    "Campus",
    "Session Year",
    "Session Type",
    "Academic Session",
    "Category",
    "Admission Eligibility",
    "Gender",
    "Religion",
    "Hostel facility",
    "Hafiz-e-Quran",
  ];

  const toggleRowExpand = (index: number) => {
    const updatedStudentData = [...studentData];
    updatedStudentData[index].isExpanded =
      !updatedStudentData[index].isExpanded;
    setStudentData(updatedStudentData);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const [filters, setFilters] = useState<any>({
    program: "",
    session_year: "",
    session_type: "",
    is_eligible: "",
    program_code: "",
    domicile: "",
    gender: "",
    interview: "",
    category: "",
    cat_slug: "",
    academic_session: "",
    quota: "",
    fee_status: "",
    religion: "",
    is_hafiz: "",
    campus: "",
    admission_status: "",
    app_no: "",
    name: "",
    city_id: "",
    hostel_facility: "",
    cnic: "",
  });

  const [campus, setCampus] = useState<any[]>([]);
  const [data, setData] = useState<any>({});
  const [programs, setPrograms] = useState<any>({});

  const getAllStudentApplicants = (page: number = 1) => {
    const filterOptions = { ...filters };

    for (let key in filterOptions) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }

    const queryParams = {
      per_page: pagination.per_page,
      page,
      ...filterOptions,
    };

    getStudentApplicants((data: any) => {

      const studentsWithExpandState = data?.map((student: any) => ({
        ...student,
        isExpanded: false,
      }));
      setStudentData(studentsWithExpandState);
    }, queryParams);
  };

  const getAllprograms = () => {
    const queryParams = {
      category: "",
      academic_session: "",
    };

    getStudentProgram(setPrograms, queryParams);
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllStudentApplicants(page + 1);
  };
  const [selectedReligion, setSelectedReligion] = useState("Islam");
  const [isHafiz, setIsHafiz] = useState("");

  const handleReligionChange = (event: any) => {
    setSelectedReligion(event.target.value);
  };

  const handleHafizChange = (event: any) => {
    setIsHafiz(event.target.value);
  };

  const shouldShowHafizField =
    selectedReligion === "Islam" ||
    (selectedReligion === "All" && isHafiz === "");

  useEffect(() => {
    getAllStudentApplicants();
    getCampusList(setCampus);
    getAllprograms();
    getStudentFilterData(setData);
  }, []);

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleApplyFilters = () => {
    getAllStudentApplicants();
  };

  return (
    <AdmissionApplicantsListingMain>
      <AdmissionApplicantsListingTop>
        <div className="left">
          <span className="page-heading">Applicant Listing</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.admissionStudentDownloadListing) && (
            <div className="download-list-button">
              <button className="lg-rounded-btn">Download List</button>
            </div>
          )}
        </div>
      </AdmissionApplicantsListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="campus" onChange={handleFilterChange}>
                      <option value="">All Campus</option>
                      {campus?.map((item: any, index: number) => {
                        return <option>{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Session Year</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="session_year" onChange={handleFilterChange}>
                      <option value="">All Types</option>
                      {data?.academic_sessions?.map(
                        (item: any, index: number) => {
                          return (
                            <option value="" key={index}>
                              {item?.session_year}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Session type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="session_type" onChange={handleFilterChange}>
                      <option value="">All Types</option>
                      {data?.academic_sessions?.map(
                        (item: any, index: number) => {
                          return <option value="">{item?.session_type}</option>;
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Academic Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="academic_session"
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Session</option>
                      {data?.academic_sessions?.map(
                        (item: any, index: number) => {
                          return (
                            <option value="">{item?.session_title}</option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Category</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="category" onChange={handleFilterChange}>
                      <option value="">Select Category</option>
                      {data?.certificate_levels?.map(
                        (item: any, index: number) => {
                          return <option value="">{item?.title}</option>;
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="program" onChange={handleFilterChange}>
                      <option value="">All Programs</option>
                      {/* {programs?.programs?.map((item: any, index: number) => { */}
                      {programs?.map((item: any, index: number) => {
                        return <option>{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Processing Fee Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="fee_status" onChange={handleFilterChange}>
                      <option value="-1">All Status</option>
                      <option value="1">Submitted</option>
                      <option value="0">In-Process</option>
                      <option value="">Not Submitted</option>
                      <option value="2">Invalid</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Admission Eligibility</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="is_eligible"
                      id="is_eligible"
                      onChange={handleFilterChange}
                    >
                      <option value="-1">All</option>
                      <option value="1">Eligible</option>
                      <option value="0">Not Eligible</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>APP NO</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="app_no"
                      id=""
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="name"
                      id=""
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Domicile City</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="domicile" onChange={handleFilterChange}>
                      <option value="">All Districts</option>
                      {data?.domicile?.map((item: any, index: number) => {
                        return <option value="">{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>City Tehsil</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="city_id" onChange={handleFilterChange}>
                      <option value="">All City</option>
                      {data?.cities?.map((item: any, index: number) => {
                        return <option value="">{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Gender</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="gender" onChange={handleFilterChange}>
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Quota</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="quota" onChange={handleFilterChange}>
                      <option value="">All</option>
                      {data?.quotas?.map((item: any, index: number) => {
                        return <option value="">{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Religion</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={selectedReligion}
                      name="religion"
                      onChange={handleReligionChange}
                    >
                      <option value="">All</option>
                      <option value="Islam">Islam</option>
                      <option value="Hinduism">Hinduism</option>
                      <option value="Judism">Judism</option>
                      <option value="Christian">Christian</option>
                    </select>
                  </div>
                </div>
              </div>
              {shouldShowHafizField && (
                <div className="input-field">
                  <label>Hafiz-e-Quran</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="is_hafiz"
                        value={isHafiz}
                        onChange={handleHafizChange}
                      >
                        <option value="">All</option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="input-field">
                <label>Hostel facility</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="hostel_facility"
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      onChange={handleFilterChange}
                      name="cnic"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                // onClick={handleResetFilters}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={handleApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AdmissionApplicantsListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.admissionStudentDownloadPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}
            {hasAccess(sitePermissions.admissionStudentDownloadExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onKeyUp={e => handleTableSearch(e,pagination.per_page, getAllStudentApplicants, filters)}
              onChange={(e) => handleSearchChange(e,setSearch, pagination.per_page,getAllStudentApplicants, filters)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      key={index}
                      className={
                        index > 6 ? `extra-head extra-head-${index - 6}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {studentData?.map((item: any, index: number) => (
                <Fragment key={index}>
                  <tr className={`expandable ${item.isExpanded && "opened"}`}>
                    <td>
                      <div
                        className="rounded-expand-button"
                        onClick={() => toggleRowExpand(index)}
                      >
                        <span>{item.isExpanded ? "-" : "+"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.cnic}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.name}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.father_name}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.father_cnic || "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.app_no || "-"}</div>
                    </td>
                    <td>
                      {item?.fee_status === 0 && (
                        <span className="status-tile yellow">Unpaid</span>
                      )}
                      {item?.fee_status === 1 && (
                        <span className="status-tile green">Paid</span>
                      )}
                    </td>
                    <td className="extra-col extra-col-1">{item?.app_date}</td>
                    <td className="extra-col extra-col-2">{item?.merit}</td>
                    <td className="extra-col extra-col-3">{item?.program}</td>
                    <td className="extra-col extra-col-4">{item?.domicile}</td>
                    <td className="extra-col extra-col-5">
                      {" "}
                      {item?.campus_title}
                    </td>
                    <td className="extra-col extra-col-6">
                      {" "}
                      {item?.session_year}
                    </td>
                    <td className="extra-col extra-col-7">
                      {item?.session_type}
                    </td>
                    <td className="extra-col extra-col-8">
                      {" "}
                      {item?.session_title}
                    </td>
                    <td className="extra-col extra-col-9">{item?.category}</td>
                    <td className="extra-col extra-col-10">
                      {" "}
                      {item?.is_eligible === 1
                        ? "Yes"
                        : item?.is_eligible === 0
                          ? "No"
                          : "--"}
                    </td>
                    <td className="extra-col extra-col-11">{item?.gender}</td>
                    <td className="extra-col extra-col-12">
                      {" "}
                      {item?.religion}
                    </td>
                    <td className="extra-col extra-col-13">
                      {" "}
                      {item?.hostel_check === 1
                        ? "Yes"
                        : item?.hostel_check === 0
                          ? "No"
                          : "--"}
                    </td>
                    <td className="extra-col extra-col-14">
                      {item?.is_hafiz === 1
                        ? "Yes"
                        : item?.is_hafiz === 0
                          ? "No"
                          : "--"}
                    </td>
                  </tr>

                  {item.isExpanded && (
                    <tr className="expanded-row">
                      <td></td>
                      <td colSpan={5}>
                        <div className="expanded-content">
                          {/* Expanded Row Details */}
                          <div className="particular-info particular-info-1">
                            <span className="title">Registration Date</span>
                            <span className="info">{item?.app_date}</span>
                          </div>
                          <div className="particular-info particular-info-2">
                            <span className="title">Merit</span>
                            <span className="info">{item?.merit}</span>
                          </div>
                          {/* <div className="particular-info">
                            <span className="title">Challan No. </span>
                            <div className="info">
                              <span className="status">
                                {item?.fee_challan_number
                                  ? item.fee_challan_number
                                  : "-"}
                              </span>
                            </div>
                          </div> */}
                          <div className="particular-info particular-info-3">
                            <span className="title">Program</span>
                            <div className="info">
                              <span className="status">{item?.program}</span>
                            </div>
                          </div>
                          {/* commit by backend */}
                          {/* <div className="particular-info">
                            <span className="title">Quota</span>
                            <div className="info">
                              <span className="status">
                                {item?.quota ? item.quota : "-"}
                              </span>
                            </div>
                          </div> */}

                          {/* <div className="particular-info">
                            <span className="title">City</span>
                            <div className="info">
                              <span className="status">{item?.p_city}</span>
                            </div>
                          </div> */}
                          <div className="particular-info particular-info-4">
                            <span className="title">Domicile</span>
                            <div className="info">
                              <span className="status">{item?.domicile}</span>
                            </div>
                          </div>

                          <div className="particular-info particular-info-5">
                            <span className="title"> Campus</span>
                            <div className="info">
                              <span className="status">
                                {item?.campus_title}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-6">
                            <span className="title">Session Year</span>
                            <div className="info">
                              <span className="status">
                                {item?.session_year}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-7">
                            <span className="title">Session Type</span>
                            <div className="info">
                              <span className="status">
                                {item?.session_type}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-8">
                            <span className="title">Academic Session</span>
                            <div className="info">
                              <span className="status">
                                {item?.session_title}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-9">
                            <span className="title">Category</span>
                            <div className="info">
                              <span className="status">{item?.category}</span>
                            </div>
                          </div>
                          {/* commit by backend */}
                          {/* <div className="particular-info">
                            <span className="title">Admission Status</span>
                            <div className="info">
                              <span className="status">
                                {item?.document_status
                                  ? item.document_status
                                  : "-"}
                              </span>
                            </div>
                          </div> */}
                          <div className="particular-info particular-info-10">
                            <span className="title">Admission Eligibility</span>
                            <div className="info">
                              <span className="status">
                                {/* {item?.is_eligible} */}
                                {item?.is_eligible === 1
                                  ? "Yes"
                                  : item?.is_eligible === 0
                                    ? "No"
                                    : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-11">
                            <span className="title">Gender</span>
                            <div className="info">
                              <span className="status">{item?.gender}</span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-12">
                            <span className="title">Religion</span>
                            <div className="info">
                              <span className="status">{item?.religion}</span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-13">
                            <span className="title">Hostel Facility</span>
                            <div className="info">
                              <span className="status">
                                {/* {item?.hostel_check} */}
                                {item?.hostel_check === 1
                                  ? "Yes"
                                  : item?.hostel_check === 0
                                    ? "No"
                                    : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-14">
                            <span className="title">Hafiz-e-Quran</span>
                            <div className="info">
                              {/* <span className="status">{item?.is_hafiz}</span> */}
                              <span className="status">
                                {item?.is_hafiz === 1
                                  ? "Yes"
                                  : item?.is_hafiz === 0
                                    ? "No"
                                    : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <Pagination onPageChange={onPageChange} {...pagination} />
            <DataNotFound show={!isLoading && !Object.keys(data)?.length} />
          </Fragment>
        </div>
      </AdmissionApplicantsListingSection>
    </AdmissionApplicantsListingMain>
  );
};

export default AdmissionApplicantsListing;
