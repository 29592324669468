import OrganizationSettingCreate from "containers/private/system-administration/organization-setting/create";
import RollManagementCreate from "containers/private/organization/role-management/create";
import RoleManagementListing from "containers/private/organization/role-management/listing";
import SystemLogs from "containers/private/system-administration/logs";
import CreateSystemMenus from "containers/private/system-administration/menus/create";
import SystemMenusListing from "containers/private/system-administration/menus/listing";
import UnregisteredMenusListing from "containers/private/system-administration/unregistered-menu/listing";
import UserAuditListing from "containers/private/system-administration/user-audit";
import CreateSystemUser from "containers/private/system-administration/users/create";
import UsersListing from "containers/private/system-administration/users/listing";
import CreateWorkflow from "containers/private/system-administration/workflow/create";
import WorkflowListing from "containers/private/system-administration/workflow/listing";
import useUtils from "hooks/useUtils";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const AdministrationRoutes: FC<any> = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.systemLogsListing)}
        Component={SystemLogs}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.systemUsersListing, true)}
        Component={UsersListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createSystemUsers, true)}
        Component={CreateSystemUser}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.systemMenusListing, true)}
        Component={SystemMenusListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createSystemMenus, true)}
        Component={CreateSystemMenus}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.unregisteredMenusListing)}
        Component={UnregisteredMenusListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.userAuditListing)}
        Component={UserAuditListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.workflowListing, true)}
        Component={WorkflowListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createWorkflow, true)}
        Component={CreateWorkflow}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.rollManagementCreate, true)}
        Component={RollManagementCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.roleManagementListing, true)}
        Component={RoleManagementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.organizationSettingCreate , true)}
        Component={OrganizationSettingCreate}
      />
    </Routes>
  );
};

export default AdministrationRoutes;
