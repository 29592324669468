import { FC, Fragment, useEffect, useState } from "react";
import {
  DownArrowLightgrayMediumSvg,
  DownloadApplicationSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  PlannerSection,
  PlannerDashboard,
  PlannerLisitngSection,
  PlannerLisitngTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "containers/private/academics/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useMultiClickOutside from "hooks/multi-click-outside";
import { DownArrowSvg } from "assets/images/e-portal/svgs";

interface TimeTablePlannerDashboardProps {}

const TimeTablePlannerDashboard: FC<TimeTablePlannerDashboardProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Time Table / ", path: siteRoutes.timeTablePlannerListing },

    {
      title: "Time Table Planner Dashboard",
      path: siteRoutes.timeTablePlannerDashboard,
    },
  ];
  const columns: string[] = [
    "Planner Title",
    "Academic Session",
    "Campus",
    "Building",
    "Faculties",
    "Dep",
    "Programs",
    "Sections",
    "Action",
  ];
  const navigate = useNavigate();
  const { createQuery } = useUtils();
  const { getPlanOfStudies, deletePlanOfStudy } = useAcademics();
  const { currentIndex, refs, setOpen } = useMultiClickOutside();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const [data, setData] = useState<PlanOfStudyDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch } = useUtils();
  const [search, setSearch] = useState<string>("");

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };
      deletePlanOfStudy(id, setData, queryParams, setPagination);
    }
  };
  const toggleApplicant = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    // getAllPlanOfStudies(page + 1, search, per_page);
  };
  // const getAllPlanOfStudies = (page: number, search: string, per_page: number) => {
  //     const queryParams = {
  //         per_page,
  //         page,
  //         search,
  //     };
  //     getPlanOfStudies(setData, queryParams, setPagination);
  // };

  const goToManageDefaultCreditHours = () => {
    navigate(siteRoutes.defaultCreditHoursListing);
  };

  useEffect(() => {
    // getAllPlanOfStudies(pagination.page, search, pagination.per_page);
  }, []);
  return (
    <PlannerDashboard>
      <PlannerLisitngTop>
        <div className="left">
          <span className="page-heading">Timetable Planner Dashboard</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </PlannerLisitngTop>

      <PlannerLisitngSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              // handleSearchChange(e, setSearch, pagination.per_page, getAllPlanOfStudies)
              // onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllPlanOfStudies)}
            />
          </div>
        </div>
        <div
          className={`data-table ${currentIndex !== null ? "menu-opened" : ""}`}
        >
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[0, 1, 2].map((plan, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>
                    <td>
                      <div className="mw-150">planner ----- dummy data</div>
                    </td>

                    <td>
                      <div
                        className="action-menu"
                        ref={(el) => refs.current.push(el!)}
                      >
                        <div
                          className="menu-icon cp"
                          onClick={() => setOpen(index)}
                        >
                          <TabPrimaryActionMenu className="icon" />
                        </div>

                        {currentIndex === index && (
                          <div className="menu-dropdown">
                            <div className="action-button">
                              Preferred Room to Course List
                            </div>
                            <div className="action-button">
                              Room To Section List
                            </div>
                            <div className="action-button">
                              Break Time Management
                            </div>
                            <div className="action-button">
                              Constrains Management
                            </div>
                            <div
                              className="action-button"
                              onClick={goToManageDefaultCreditHours}
                            >
                              Default Credit Hours Management
                            </div>
                            <div className="action-button">Rooms</div>
                            <div className="action-button">View Sections</div>
                            <div className="action-button">Assign Section</div>
                            <div className="action-button-icon">
                              <span>
                                <DownloadApplicationSvg />
                              </span>
                              Generate FET File
                            </div>
                            <div className="action-button-icon">
                              <span>
                                <DownloadApplicationSvg />
                              </span>
                              Execute FET File
                            </div>
                            <div className="action-button-icon">
                              <span>
                                <DownArrowSvg />
                              </span>
                              Download Course File (CSV)
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </PlannerLisitngSection>

      <PlannerSection $isDropdownOpen={isDropdownOpen}>
        <div className="link-main">
          <div className="link-heading">Dashboard</div>
          <span className="dropdown-arrow cp" onClick={toggleApplicant}>
            <DownArrowLightgrayMediumSvg />
          </span>
        </div>

        {isDropdownOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Planner Courses",
                value: "123123",
                color: "#128BAB26",
                text: "Total Courses : ",
                availble_numbers: "12",
              },
              {
                title: "Planner Credit Hours",
                value: "123123",
                color: "#ffbb3826",
                text: "Total Credit Hour's : ",
                availble_numbers: "12",
              },
              {
                title: "Planner Rooms",
                value: "123123",
                color: "#128BAB26",
                text: "Total Available Rooms : ",
                availble_numbers: "12",
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="card-heading">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                  <div className="card-text">
                    <div>{card.text ?? "-"}</div>
                    <div>{card.availble_numbers ?? "-"}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </PlannerSection>
    </PlannerDashboard>
  );
};

export default TimeTablePlannerDashboard;
