import { FC, Fragment, useEffect, useState } from "react";
import {
    AcademicsCurrentEnrollmentSection,
    AcademicsCurrentEnrollmentMain,
    AcademicsCurrentEnrollmentTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import Pagination from "components/particles/table/pagination";
import { siteRoutes } from "utils/helpers/enums/routes.enum";




const AcademicsCurrentEnrollment: FC = ({ }) => {
    const breadcrumbLinks: BreadcrumbLink[] = [
        { title: "Student Dashboard / ", path: ''},
        { title: "Current Enrollments ", path: siteRoutes.academicsCurrentEnrollment },
     
    ];


    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });
 
    const columns: string[] = [
        "Course Code",
        "Course",
        "Faculty",
        "Credit Hours(Theory -Lab)",
       
    ];
    const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
        const { selected: page, per_page } = pageInfo;
        setPagination({ ...pagination, page: page + 1, per_page });
      
    };

    
   
 
    

   

    return (
        <AcademicsCurrentEnrollmentMain>
            <AcademicsCurrentEnrollmentTop>
                <div className="left">
                    <span className="page-heading">Current Enrollments</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">
                   
                </div>
            </AcademicsCurrentEnrollmentTop>

            <AcademicsCurrentEnrollmentSection className="content-radius-shadow">
           
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {[1,1,1].map((course, index) => {
                                return (
                                    <tr key={index}>
                                      
                                        <td>ARIN-4567</td>
                                      
                                        <td>
                                            <div className="mw-150">
                                            Advanced Theory of Computation
                                            </div>
                                        </td>
                                 
                                        <td>
                                            <div className="status">
                                            Dr. Wareesha Sharif
                                            </div>
                                        </td>
                                        <td>
                                        6(6-0) 
                                        </td>
                                        
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                 
                    <Pagination {...pagination} onPageChange={onPageChange} />
                 
                </Fragment>
            </AcademicsCurrentEnrollmentSection>
        </AcademicsCurrentEnrollmentMain>
    );
};

export default AcademicsCurrentEnrollment;
