import { DSSYSTEM_APIS } from "libs/apis/d-support-system.api";
import { AcademicsDataDTO } from "utils/helpers/models/ds-system/academics-data.dto";
import { CourseAnomaliesDTO } from "utils/helpers/models/ds-system/course-anomalies.dto";
import { EnrolledCourseTeachersDTO } from "utils/helpers/models/ds-system/enrolled-courses-teachers.dto";
import { EnrolledStudentsDTO } from "utils/helpers/models/ds-system/enrolled-students.dto";
import { NotEnrolledStudentsDTO } from "utils/helpers/models/ds-system/not-enrolled-students.dto";
import { StudentAnomaliesDTO } from "utils/helpers/models/ds-system/student-anomalies.dto";

const useDSSystem = () => {
  const getAdmissionDashboard = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getAdmissionDashboard(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };
  const getFilterDataForAdmDashboard = async (setData: Function) => {
    const response = await DSSYSTEM_APIS.getFilterDataForAdmDashboard();
    if (response?.status) {
      setData(response?.response);
    }
  };

  const getCourseAnomalies = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getCourseAnomalies(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new CourseAnomaliesDTO(data ?? {}));
    }
  };
  const getEnrolledStudents = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getEnrolledStudents(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new EnrolledStudentsDTO(data ?? {}));
    }
  };
  const getNotEnrolledStudents = async (
    setData: Function,
    queryParams: any
  ) => {
    const response = await DSSYSTEM_APIS.getNotEnrolledStudents(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new NotEnrolledStudentsDTO(data ?? {}));
    }
  };
  const getStudentAnomalies = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getStudentAnomalies(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new StudentAnomaliesDTO(data ?? {}));
    }
  };
  const getEnrolledCoursesAndTeachers = async (
    setData: Function,
    queryParams: any
  ) => {
    const response = await DSSYSTEM_APIS.getEnrolledCoursesAndTeachers(
      queryParams
    );
    const { status, response: data } = response || {};
    if (status) {
      setData(new EnrolledCourseTeachersDTO(data ?? {}));
    }
  };
  const getAcademicsData = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getAcademicsData(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new AcademicsDataDTO(data ?? {}));
    }
  };

  return {
    getAdmissionDashboard,
    getAcademicsData,
    getFilterDataForAdmDashboard,
    getEnrolledCoursesAndTeachers,
    getStudentAnomalies,
    getNotEnrolledStudents,
    getEnrolledStudents,
    getCourseAnomalies,
  };
};

export default useDSSystem;
