import useUtils from "hooks/useUtils";
import { ACADEMICS_APIS } from "libs/apis/academics.api";
import { useNavigate } from "react-router-dom";
import {
  errorToaster,
  successToaster,
} from "utils/helpers/common/alert-service";
import { errorMessages } from "utils/helpers/enums/messages.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { AcademicSectionDTO } from "utils/helpers/models/academics/academic-section.dto";
import {
  AcademicSessionDTO,
  AddCourseSectionDTO,
  AddCourseTeacherDTO,
} from "utils/helpers/models/academics/academic-session.dto";
import { AcademicStatusDTO } from "utils/helpers/models/academics/academic-status.dto";
import { CoReqDTO } from "utils/helpers/models/academics/co-req-dto";
import { CoursePoolBodyDTO } from "utils/helpers/models/academics/course-pool-body.dto";
import { CoursePoolHeaderDTO } from "utils/helpers/models/academics/course-pool-header.dto";
import { CourseTypeDTO } from "utils/helpers/models/academics/course-type.dto";
import { EnrollCourseTeacherDTO } from "utils/helpers/models/academics/enroll-course-teacher.dto";
import { EquivalentCoursesDTO } from "utils/helpers/models/academics/equivalent-courses.dto";
import { ExamInchargeDTO } from "utils/helpers/models/academics/exam-incharge.dto";
import { FeePlanDTO } from "utils/helpers/models/academics/fee-plan.dto";
import { GradeTemplateDTO } from "utils/helpers/models/academics/grade-template.dto";
import { LetterGradeDTO } from "utils/helpers/models/academics/letter-grade.dto";
import { PlanOfStudyCourseDTO } from "utils/helpers/models/academics/plan-of-study-course.dto";
import { PlanOfStudyStudentDTO } from "utils/helpers/models/academics/plan-of-study-student";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { PreReqDTO } from "utils/helpers/models/academics/pre-req-dto";
import { ProSpececilizationDTO } from "utils/helpers/models/academics/program-specialization.dto";
import { SessionCourseClassDTO } from "utils/helpers/models/academics/session-coures-class.dto";
import { SessionEnrollmentDTO } from "utils/helpers/models/academics/session-enrollment.dto";
import { StudentSpecializationDTO } from "utils/helpers/models/academics/student-specialization.dto";
import { StudentStatusDTO } from "utils/helpers/models/academics/student-status.dto";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import { VirtualCourseDTO } from "utils/helpers/models/academics/virtual-course.dto";
import { TimeTablePlanerDTO } from "utils/helpers/models/time-table/time-table-planer.dto";

const useAcademics = () => {
  const navigate = useNavigate();
  const { getQueryParams } = useUtils();

  const createLetterGrade = async (body: LetterGradeDTO) => {
    const response = await ACADEMICS_APIS.createLetterGrade(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateLetterGrade = async (id: number, body: any) => {
    const response = await ACADEMICS_APIS.updateLetterGrade(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getLetterGrades = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getLetterGrades(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getLetterGradeById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getLetterGradeById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteLetterGrade = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteLetterGradeById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLetterGrades(setData, queryParams, setPagination);
    }
  };
  const createPlanOfStudyCourse = async (
    body: PlanOfStudyCourseDTO,
    setOpen: Function
  ) => {
    const response = await ACADEMICS_APIS.createPlanOfStudyCourse(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };
  const updatePlanOfStudyCourse = async (
    id: number,
    body: PlanOfStudyCourseDTO
  ) => {
    const response = await ACADEMICS_APIS.updatePlanOfStudyCourse(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
    }
  };

  const getPlanOfStudyCourses = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getPlanOfStudyCourses(queryParams);
    const { status } = response || {};
    if (status) {
      const data: PlanOfStudyCourseDTO[] = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          data.sort((a, b) => a.semester_seq_number - b.semester_seq_number)
        );
      } else {
        setData(response?.response);
      }
    }
  };
  const getAcademicStudents = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicStudents(queryParams);
    const { status } = response || {};
    if (status) {
      let data: any[] = response?.response?.data;
      // if (queryParams?.isActive) {
      //   data = data.filter((student) => !student?.pos_id);
      // }
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };
  const getStudentsForAddToPOS = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await ACADEMICS_APIS.getStudentsForAddToPOS(queryParams);
    console.log(response);
    const { status } = response || {};
    if (status) {
    }
  };

  const getPlanOfStudyCourseById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getPlanOfStudyCourseById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const studentsBulkEnrollmentByFile = async (body: FormData, setOpenDialog: Function) => {
    const response = await ACADEMICS_APIS.studentsBulkEnrollmentByFile(body);
    const { status = false, message = ''} = response || {};
    if(status){
      successToaster(message);
      setOpenDialog(false);
    }
  }

  const deletePlanOfStudyCourse = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function,
    planOfStudy: PlanOfStudyDTO,
    setPlanOfStudy: Function
  ) => {
    const response = await ACADEMICS_APIS.deletePlanOfStudyCourse(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getPlanOfStudyCourses(setData, queryParams, setPagination);
      getPlanOfStudyById(queryParams.pos_id, planOfStudy, setPlanOfStudy);
    }
  };
  const createPoolCourseHeader = async (body: CoursePoolHeaderDTO) => {
    const response = await ACADEMICS_APIS.createPoolCourseHeader(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updatePoolCourseHeader = async (
    id: number,
    body: CoursePoolHeaderDTO
  ) => {
    const response = await ACADEMICS_APIS.updatePoolCourseHeader(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getPoolCourseHeaders = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getPoolCourseHeaders(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getPoolCourseHeaderById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getPoolCourseHeaderById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deletePoolCourseHeader = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deletePoolCourseHeader(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getPoolCourseHeaders(setData, queryParams, setPagination);
    }
  };
  const createPoolCourseBody = async (
    body: CoursePoolBodyDTO,
    setOpen: Function
  ) => {
    const response = await ACADEMICS_APIS.createPoolCourseBody(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };
  const updatePoolCourseBody = async (id: number, body: CoursePoolBodyDTO) => {
    const response = await ACADEMICS_APIS.updatePoolCourseBody(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getPoolCourseBodies = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getPoolCourseBodies(queryParams);
    if (response?.length) {
      setData(response);
    }
  };

  const getPoolCourseBodyById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getPoolCourseBodyById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deletePoolCourseBody = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deletePoolCourseBody(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getPoolCourseHeaders(setData, queryParams, setPagination);
    }
  };
  const createGradeTemplate = async (body: GradeTemplateDTO) => {
    const response = await ACADEMICS_APIS.createGradeTemplate(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateGradeTemplate = async (id: number, body: GradeTemplateDTO) => {
    const response = await ACADEMICS_APIS.updateGradeTemplate(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getGradeTemplates = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getGradeTemplates(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getGradeTemplateById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getGradeTemplateById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteGradeTemplate = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteGradeTemplate(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getGradeTemplates(setData, queryParams, setPagination);
    }
  };
  const createAcademicStatus = async (body: AcademicStatusDTO) => {
    const response = await ACADEMICS_APIS.createAcademicStatus(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateAcademicStatus = async (id: number, body: AcademicStatusDTO) => {
    const response = await ACADEMICS_APIS.updateAcademicStatus(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getAcademicStatuses = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicStatuses(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };
  const getRooms = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getRooms(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getAcademicStatusById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicStatusById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteAcademicStatus = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteAcademicStatus(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getAcademicStatuses(setData, queryParams, setPagination);
    }
  };
  const createTemplateCourse = async (body: TemplateCourseDTO) => {
    const response = await ACADEMICS_APIS.createTemplateCourse(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateTemplateCourse = async (id: number, body: TemplateCourseDTO) => {
    const response = await ACADEMICS_APIS.updateTemplateCourse(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.academicTemplateCoursesListing);
    }
  };

  const getTemplateCourses = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getTemplateCourses(queryParams);
    const { status } = response || {};
    const data = response?.response?.data;
    if (status) {
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data.map((i: TemplateCourseDTO) => new TemplateCourseDTO(i)));
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new TemplateCourseDTO(i))
            : []
        );
      }
    }
  };
  const deleteCoRequsiteListItemById = async (
    id: number,
    course_id,
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteCoRequsiteListItemById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      GetCoRequsiteCourses(course_id, setData, queryParams, setPagination);
    }
  };
  const deletePreRequsiteListItemById = async (
    id: number,
    course_id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deletePreRequsiteListItemById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      GetPreRequsiteCourses(course_id, setData, queryParams, setPagination);
    }
  };

  const GetCoRequsiteCourses = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: "All", page: "1" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.GetCoRequsiteCourses(id, queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };
  const getEquvalentCourses = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: "All", page: "1" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getEquvalentCourses(id, queryParams);
    const { status } = response || {};
    const data = response?.response;
    console.log(data);
    if (status) {
      if (data) {
        // const {
        //   total: totalRecords,
        //   current_page: page,
        //   per_page,
        // } = response?.response;
        // if (setPagination) {
        // setPagination({ per_page, totalRecords, page });
        // }
        setData(data);
      }
    }
  };

  const deleteEqvalentCourseItemById = async (
    id: number,
    course_id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteEqvaleentCourseItemByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getEquvalentCourses(course_id, setData, queryParams, setPagination);
    }
  };

  const GetPreRequsiteCourses = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getPreReqList(id);
    const data = response?.response?.data;
    const { status } = response || {};
    if (status) {
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data.map((i: PreReqDTO) => new PreReqDTO(i)));
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new PreReqDTO(i))
            : []
        );
      }
    }
  };

  const getTemplateCoursesForAddRequisiteCourses = async (
    setCourses: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await ACADEMICS_APIS.getTemplateCourses(queryParams);

    const data = response?.response;
    if (data) {
      setCourses(data);
    }
  };

  const addCoreqCourseToTemplateCourse = async (
    body: CoReqDTO,
    setOpen: Function,
    GetCoRequsiteCoursesList: Function
  ) => {
    const response = await ACADEMICS_APIS.addCoReqCourseToTemplateCourse(body);
    const { status } = response;
    if (status) {
      setOpen(false);
      successToaster(response?.message);
      GetCoRequsiteCoursesList();
    }
  };
  const addPrereqCourseToTemplateCourse = async (
    body: PreReqDTO,
    setOpen: Function,
    GetPreReqCoursesList: Function
  ) => {
    const response = await ACADEMICS_APIS.addPreReqCourseToTemplateCourse(body);
    const { status } = response;
    if (status) {
      setOpen(false);
      GetPreReqCoursesList();
    }
  };
  const AddEquivalentCoursesToTemplateCourses = async (
    body: EquivalentCoursesDTO,
    setOpen: Function,
    getEQuvalentCoursesList: Function
  ) => {
    const response = await ACADEMICS_APIS.addEquivalentCoursesToTemplateCourse(
      body
    );
    const { status } = response;
    if (status) {
      setOpen(false);
      getEQuvalentCoursesList();
    }
  };

  const getTemplateCourseById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getTemplateCourseById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteTemplateCourse = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteTemplateCourse(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getTemplateCourses(setData, queryParams, setPagination);
    }
  };
  const createStudentStatus = async (body: StudentStatusDTO) => {
    const response = await ACADEMICS_APIS.createStudentStatus(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateStudentStatus = async (id: number, body: StudentStatusDTO) => {
    const response = await ACADEMICS_APIS.updateStudentStatus(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getStudentStatuses = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getStudentStatuses(queryParams);

    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data.map((i: AcademicSectionDTO) => new AcademicSectionDTO(i)));
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new AcademicSessionDTO(i))
            : []
        );
      }
    }
  };

  const getStudentStatusById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getStudentStatusById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }
    setFormData({ ...formData });
  };

  const deleteStudentStatus = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteStudentStatus(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getStudentStatuses(setData, queryParams, setPagination);
    }
  };
  const createAcademicSection = async (body: AcademicSectionDTO) => {
    const response = await ACADEMICS_APIS.createAcademicSection(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateAcademicSection = async (
    id: number,
    body: AcademicSectionDTO
  ) => {
    const response = await ACADEMICS_APIS.updateAcademicSection(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getAcademicSections = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicSections(queryParams);
    const { status } = response || {};
    if (status) {
      const { data } = response?.response;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data.map((i: AcademicSectionDTO) => new AcademicSectionDTO(i)));
      } else {
        setData(
          response?.response?.map(
            (i: AcademicSectionDTO) => new AcademicSectionDTO(i)
          )
        );
      }
    }
  };

  const getAcademicSectionById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicSectionById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteAcademicSection = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteAcademicSection(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getAcademicSections(setData, queryParams, setPagination);
    }
  };
  const createStudentSpecialization = async (
    body: StudentSpecializationDTO
  ) => {
    const response = await ACADEMICS_APIS.createStudentSpecialization(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateStudentSpecialization = async (
    id: number,
    body: StudentSpecializationDTO
  ) => {
    const response = await ACADEMICS_APIS.updateStudentSpecialization(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.studentSpecializationsListing);
    }
  };

  const getStudentSpecializations = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getStudentSpecializations(
      queryParams
    );
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getStudentSpecializationById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getStudentSpecializationById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }
    setFormData({ ...formData });
  };

  const deleteStudentSpecialization = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteStudentSpecialization(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getAcademicSections(setData, queryParams, setPagination);
    }
  };
  const createAcademicSession = async (body: AcademicSessionDTO) => {
    const response = await ACADEMICS_APIS.createAcademicSession(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateAcademicSession = async (
    id: number,
    body: AcademicSessionDTO
  ) => {
    const response = await ACADEMICS_APIS.updateAcademicSession(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getAcademicSessions = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicSessions(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new AcademicSessionDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new AcademicSessionDTO(i))
            : []
        );
      }
    }
  };

  const getAcademicSessionById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicSessionById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteAcademicSession = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteAcademicSession(id);
    const { status, message, errors } = response || {};
    if (status) {
      successToaster(message);
      getAcademicSessions(setData, queryParams, setPagination);
    } else {
      errorToaster(errors);
    }
  };
  const deleteSessionAllocatedCourses = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteSessionAllocatedCourses(id);
    const { status, message, errors } = response || {};
    if (status) {
      successToaster(message);
      getSessionAllocatedCourses(setData, queryParams, setPagination);
    } else {
      errorToaster(errors);
    }
  };
  const createCourseType = async (body: CourseTypeDTO) => {
    const response = await ACADEMICS_APIS.createCourseType(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateCourseType = async (id: number, body: CourseTypeDTO) => {
    const response = await ACADEMICS_APIS.updateCourseType(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getCourseTypes = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getCourseTypes(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getCourseTypeById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getCourseTypeById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteCourseType = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteCourseType(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getAcademicSessions(setData, queryParams, setPagination);
    }
  };
  const createPlanOfStudyStudent = async (body: PlanOfStudyStudentDTO) => {
    const response = await ACADEMICS_APIS.createPlanOfStudyStudent(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.planOfStudyStudentListing + window.location.search);
    }
  };

  const getPlanOfStudyStudents = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getPlanOfStudyStudents(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getPlanOfStudyStudentById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getPlanOfStudyStudentById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deletePlanOfStudyStudent = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deletePlanOfStudyStudent(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getPlanOfStudyStudents(setData, queryParams, setPagination);
    }
  };
  const createTeachersTitle = async (body: CourseTypeDTO) => {
    const response = await ACADEMICS_APIS.createTeachersTitle(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const enrollCourseTeacher = async (body: EnrollCourseTeacherDTO) => {
    const response = await ACADEMICS_APIS.enrollCourseTeacher(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateTeachersTitle = async (id: number, body: CourseTypeDTO) => {
    const response = await ACADEMICS_APIS.updateTeachersTitle(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getTeachersTitles = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getTeachersTitles(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getTeachersTitleById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getTeachersTitleById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteTeachersTitle = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteTeachersTitle(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getAcademicSessions(setData, queryParams, setPagination);
    }
  };
  const createPlanOfStudy = async (body: PlanOfStudyDTO) => {
    const response = await ACADEMICS_APIS.createPlanOfStudy(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updatePlanOfStudy = async (id: number, body: PlanOfStudyDTO) => {
    const response = await ACADEMICS_APIS.updatePlanOfStudy(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getPlanOfStudies = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getPlanOfStudies(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };
  const getPlanOfStudyById = async (
    id: number,
    formData: any,
    setFormData: Function,
    setValue?: Function
  ) => {
    const response = await ACADEMICS_APIS.getPlanOfStudyById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      if (setValue) setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deletePlanOfStudy = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deletePlanOfStudy(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getPlanOfStudies(setData, queryParams, setPagination);
    }
  };
  const createExamIncharge = async (body: ExamInchargeDTO) => {
    const response = await ACADEMICS_APIS.createExamIncharge(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateExamIncharge = async (id: number, body: ExamInchargeDTO) => {
    const response = await ACADEMICS_APIS.updateExamIncharge(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getExamIncharges = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getExamIncharges(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };
  const getExamInchargeById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getExamInchargeById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteExamIncharge = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteExamIncharge(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getPlanOfStudies(setData, queryParams, setPagination);
    }
  };
  const getSessionAllocatedCourses = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getSessionAllocatedCourses(
      queryParams
    );
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }

        setData(
          Array.isArray(data) ? data?.map((i) => new AcademicSectionDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new AcademicSectionDTO(i))
            : []
        );
      }
    }
  };
  const getTeachers = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getTeachers(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const createAcademicFeePlan = async (body: FeePlanDTO) => {
    const response = await ACADEMICS_APIS.createAcademicFeePlan(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateAcademicFeePlan = async (id: number, body: FeePlanDTO) => {
    const response = await ACADEMICS_APIS.updateAcademicFeePlan(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.academicFeePlansListing);
    }
  };

  const getAcademicFeePlanById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicFeePlanById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteAcademicFeePlan = async (id: number, setData: Function, queryParams: any, setPagination: Function) => {
    const response = await ACADEMICS_APIS.deleteAcademicFeePlan(id);
    const { status = false, message = '' } = response || {};
    if(status){
      successToaster(message);
      getAcademicFeePlans(setData, queryParams, setPagination);
    }
  }
  const getAcademicFeePlans = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getAcademicFeePlans(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(Array.isArray(data) ? data.map(i => new FeePlanDTO(i)) : []);
      } else {
        const data = response?.response;
        setData(Array.isArray(data) ? data.map(i => new FeePlanDTO(i)) : []);
      }
    }
  };

  const getStudentsSpecificData = async (
    setData: Function,
    optionalParams = {}
  ) => {
    const fixedParams = { per_page: "All" };
    const response = await ACADEMICS_APIS.getStudentsSpecificData({
      ...fixedParams,
      ...optionalParams,
    });
    const { response: data = [] } = response || {};
    setData(
      data.map(({ user = {}, reg_number = "", id }) => ({ ...user, reg_number, id }))
    );
  };

  const addSessionCourse = async (body: any) => {
    const response = await ACADEMICS_APIS.addSessionCourse(body);
    const { response: status, message } = response || {};
    if (status) {
      successToaster(message);
      const { session_id } = getQueryParams();
      navigate(
        `${siteRoutes.academicSessionManagementListing}?session_id=${session_id}`
      );
    }
  };
  const addCourseTeacher = async (
    body: AddCourseTeacherDTO<string>,
    setOpen: Function
  ) => {
    const response = await ACADEMICS_APIS.addCourseTeacher(body);
    const { response: status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };
  const addCourseRoom = async (body: any, setOpen: Function) => {
    const response = await ACADEMICS_APIS.addCourseRoom(body);
    const { response: status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const updateSessionCourseClass = async (
    body: SessionCourseClassDTO,
    courseId: number
  ) => {
    const response = await ACADEMICS_APIS.updateSessionCourseClass(
      body,
      courseId
    );
    const { status = false, message = "" } = response || {};
    if (status) {
      successToaster(message);
    }
  };

  const addCourseSection = async (
    body: AddCourseSectionDTO<string>,
    setOpen: Function
  ) => {
    const response = await ACADEMICS_APIS.addCourseSection(body);
    const { response: status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const uploadGradeBook = async (body: FormData) => {
    const response = await ACADEMICS_APIS.uploadGradeBook(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
    }
  };

  const downloadBlankGradeBook = async () => {
    const response = await ACADEMICS_APIS.downloadBlankGradeBook();
  };
  const getProgramSpecialization = async (
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getProgramSpecialization(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      }
    }
  };
  const createProgramSpecialization = async (body: any) => {
    const response = await ACADEMICS_APIS.createProgramSpecialization(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(-1);
    }
  };
  const updateProgramSpecialization = async (
    id: number,
    body: ProSpececilizationDTO
  ) => {
    const response = await ACADEMICS_APIS.updateProgramSpecialization(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const deleteProgramSpecialization = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteProgramSpecialization(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getProgramSpecialization(setData, queryParams, setPagination);
    }
  };
  const getProgramSpecializationById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getProgramSpecializationById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };
  const getResultTemplateHeader = async (
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getResultTemplateHeader(queryParams);

    if (response?.status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      }
    }
  };
  const deleteResultTemplateHeader = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteResultTemplateHeader(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getResultTemplateHeader(setData, queryParams, setPagination);
    }
  };
  const createResultTemplateHeader = async (body: any) => {
    const response = await ACADEMICS_APIS.createResultTemplateHeader(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(-1);
    }
  };
  const updateResultTemplateHeader = async (id: number, body: any) => {
    const response = await ACADEMICS_APIS.updateResultTemplateHeader(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const enrollStudentInSession = async (
    body: SessionEnrollmentDTO<string>,
    setOpen: Function
  ) => {
    const response = await ACADEMICS_APIS.enrollStudentInSession(body);
    const { status = false, message = "" } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const getResultTemplateHeaderById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getResultTemplateHeaderById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }
  };
  const getResultTemplate = async (
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getResultTemplate(queryParams);
    if (response?.status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      }
    }
  };

  const deleteAcademicSessionSection = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteAcademicSessionSection(id);
    const { status = false, message } = response || {};
    if (status) {
      getSessionAllocatedCourses(setData, queryParams, setPagination);
      successToaster(message);
    }
  };
  const deleteAcademicSessionTeacher = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteAcademicSessionTeacher(id);
    const { status = false, message } = response || {};
    if (status) {
      getSessionAllocatedCourses(setData, queryParams, setPagination);
      successToaster(message);
    }
  };

  const deleteResultTemplate = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteResultTemplate(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getResultTemplateHeader(setData, queryParams, setPagination);
    }
  };
  const createResultTemplate = async (body: any) => {
    const response = await ACADEMICS_APIS.createResultTemplate(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(-1);
    }
  };
  const updateResultTemplate = async (id: number, body: any) => {
    const response = await ACADEMICS_APIS.updateResultTemplate(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const getResultTemplateById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getResultTemplateById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }
  };
  const getFeeTypesForAcadSessions = async (setData: Function) => {
    const response = await ACADEMICS_APIS.getFeeTypesForAcadSessions();

    setData(response?.response?.data);
  };
  const createFeeTypes = async (body: any) => {
    const response = await ACADEMICS_APIS.createFeeTypes(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(-1);
    }
  };
  const getFeeTypes = async (
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getFeeTypes(queryParams);

    if (response?.status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      }
    }
    if (response?.status) {
      setData(response?.response?.data);
    }
  };
  const deleteFeeTypes = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await ACADEMICS_APIS.deleteFeeTypes(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getFeeTypes(setData, queryParams, setPagination);
    }
  };
  const updateFeeTypes = async (id: number, body: any) => {
    const response = await ACADEMICS_APIS.updateFeeTypes(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const getFeeTypesById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ACADEMICS_APIS.getFeeTypesById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }
  };

  const createVirtualCourse = async (body: VirtualCourseDTO<string>, setOpen: Function) => {
    const response = await ACADEMICS_APIS.createVirtualCourse(body);
    const { status = false, message = ''} = response || {};
    if(status){
      successToaster(message);
      setOpen(false);
    }
  }
  const deleteVirtualCourse = async (id: number, setData: Function, setTemplateCourse: Function, queryParams: any, setPagination: Function) => {
    const response = await ACADEMICS_APIS.deleteVirtualCourse(id);
    const { status = false, message = ''} = response || {};
    if(status){
      successToaster(message);
      getVirtualCourses(setData,setTemplateCourse, queryParams, setPagination);
    }
  }

  const getVirtualCourses = async (
    setData: Function,
    setTemplateCourse: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getVirtualCourses(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        const dataToSet = (data ?? [{}])[0]?.virtual_courses;
        setData(Array.isArray(dataToSet) ? dataToSet.map(i => new VirtualCourseDTO(i)) : [] );
        delete (data ?? [{}])![0]?.virtual_courses;
        setTemplateCourse((data ?? [{}])[0]);
      } else {
        setData(response?.response);
      }
    }
  };

  return {
    getResultTemplateById,
    getVirtualCourses,
    createVirtualCourse,
    deleteVirtualCourse,
    updateResultTemplate,
    createResultTemplate,
    deleteResultTemplate,
    getResultTemplate,
    getResultTemplateHeaderById,
    updateResultTemplateHeader,
    createResultTemplateHeader,
    deleteResultTemplateHeader,
    createPlanOfStudyStudent,
    getPlanOfStudyStudents,
    getPlanOfStudyStudentById,
    deletePlanOfStudyStudent,
    studentsBulkEnrollmentByFile,
    getResultTemplateHeader,
    deleteProgramSpecialization,
    updateProgramSpecialization,
    createProgramSpecialization,
    createLetterGrade,
    downloadBlankGradeBook,
    uploadGradeBook,
    addCourseSection,
    addCourseRoom,
    addCourseTeacher,
    addSessionCourse,
    updateLetterGrade,
    getLetterGradeById,
    getLetterGrades,
    deleteLetterGrade,
    createTemplateCourse,
    createAcademicFeePlan,
    updateAcademicFeePlan,
    getAcademicFeePlanById,
    deleteAcademicFeePlan,
    updateTemplateCourse,
    getAcademicFeePlans,
    enrollCourseTeacher,
    getTemplateCourseById,
    getTemplateCourses,
    getTemplateCoursesForAddRequisiteCourses,
    GetCoRequsiteCourses,
    GetPreRequsiteCourses,
    getEquvalentCourses,
    deleteCoRequsiteListItemById,
    deletePreRequsiteListItemById,
    deleteEqvalentCourseItemById,
    addCoreqCourseToTemplateCourse,
    addPrereqCourseToTemplateCourse,
    AddEquivalentCoursesToTemplateCourses,
    deleteTemplateCourse,
    createPoolCourseBody,
    updatePoolCourseBody,
    getPoolCourseBodyById,
    getPoolCourseBodies,
    deletePoolCourseBody,
    createExamIncharge,
    updateExamIncharge,
    getExamInchargeById,
    getStudentsSpecificData,
    getExamIncharges,
    deleteExamIncharge,
    createPlanOfStudyCourse,
    updatePlanOfStudyCourse,
    getAcademicStudents,
    getStudentsForAddToPOS,
    getPlanOfStudyCourseById,
    getPlanOfStudyCourses,
    deletePlanOfStudyCourse,
    createPoolCourseHeader,
    updatePoolCourseHeader,
    getPoolCourseHeaderById,
    getPoolCourseHeaders,
    deletePoolCourseHeader,
    createCourseType,
    updateCourseType,
    getCourseTypeById,
    getCourseTypes,
    deleteCourseType,
    createGradeTemplate,
    enrollStudentInSession,
    updateGradeTemplate,
    getGradeTemplateById,
    getGradeTemplates,
    deleteGradeTemplate,
    createAcademicStatus,
    getSessionAllocatedCourses,
    deleteAcademicSessionSection,
    deleteAcademicSessionTeacher,
    getTeachers,
    updateAcademicStatus,
    getAcademicStatusById,
    getAcademicStatuses,
    deleteAcademicStatus,
    createStudentStatus,
    updateStudentStatus,
    getStudentStatusById,
    getStudentStatuses,
    deleteStudentStatus,
    createAcademicSection,
    updateAcademicSection,
    getAcademicSectionById,
    getAcademicSections,
    deleteAcademicSection,
    createStudentSpecialization,
    updateStudentSpecialization,
    getStudentSpecializationById,
    getStudentSpecializations,
    deleteStudentSpecialization,
    createAcademicSession,
    updateAcademicSession,
    getAcademicSessionById,
    getAcademicSessions,
    deleteAcademicSession,
    deleteSessionAllocatedCourses,
    createPlanOfStudy,
    getRooms,
    updatePlanOfStudy,
    getPlanOfStudyById,
    getPlanOfStudies,
    deletePlanOfStudy,
    createTeachersTitle,
    updateTeachersTitle,
    getTeachersTitleById,
    getTeachersTitles,
    updateSessionCourseClass,
    deleteTeachersTitle,
    getProgramSpecialization,
    getProgramSpecializationById,
    getFeeTypesForAcadSessions,
    createFeeTypes,
    getFeeTypes,
    deleteFeeTypes,
    updateFeeTypes,
    getFeeTypesById,
  };
};

export default useAcademics;
