import { FC, useEffect, useState } from "react";
import { Main, STCreateContent, STCreateTop } from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { useForm } from "react-hook-form";
import { OrganizationSettingDTO } from "utils/helpers/models/organization/org-setting.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useSystemAdministration from "../../useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { EyeHidingSvg, EyeSvg } from "assets/images/common/svgs";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";

interface CreateStructureTypeProps { }

const OrganizationSettingCreate: FC<CreateStructureTypeProps> = () => {

  const breadcrumbLinks = [
    {
      title: "System Administration /",
      path: "",
    },
    {
      title: "Organization Setting",
      path: siteRoutes.organizationSettingCreate,
    },
  ];
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
  } = useForm<OrganizationSettingDTO>({
    defaultValues: new OrganizationSettingDTO(),
  });

  const {
    getOrganizationSetting,
    UpdateOrganizationSetting,
  } = useSystemAdministration();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {hasAccess } = useStore();
  let [formData, setFormData] = useState<OrganizationSettingDTO>(new OrganizationSettingDTO());

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    // setValue(name, value);
    formData[name as keyof OrganizationSettingDTO] = value as never;
    setFormData({ ...formData });
    trigger(name);
  };

  const resetForm = () => {
    for (let key in getValues()) {
      setValue(key as keyof OrganizationSettingDTO, "");
    }
    formData = new OrganizationSettingDTO();
    setFormData({ ...formData });
  };

  const getOrganiztionSettingData = () => {
    getOrganizationSetting(setFormData, setValue);
  }

  useEffect(() => {
    getOrganiztionSettingData();
  }, []);

  const onSubmit = (
    body: OrganizationSettingDTO,
  ) => {
    UpdateOrganizationSetting(body);
  };

  return (
    <Main>
      <STCreateTop>
        <div className="heading">
          <span className="page-heading">
            Organization Setting
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </STCreateTop>
      <STCreateContent className="content-radius-shadow">

        <div className="form-section">
          <form>
            <div className="fields-section">

              <div className="input-field">
                <label>System Email</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="email"
                      {...register("system_email", { required: true })}
                      value={formData?.system_email}
                      onChange={handleChange}
                      placeholder="System Email"
                    />
                  </div>
                  <FormErrorMessage error={errors.system_email} />
                </div>
              </div>

              <div className="input-field">
                <label>Is Maintenance</label>
                <div className="field-wrap">
                  <div className="flex_radio">
                    <div className="radio_feild">
                      <input
                        type="radio"
                        id="is_maintenance_on_yes"
                        {...register("is_maintenance_on", { required: false })}
                        checked={formData?.is_maintenance_on == 1}
                        value={1}
                        onChange={handleChange}
                      />
                      <label htmlFor="is_active_yes">Yes</label>
                    </div>
                    <div className="radio_feild">
                      <input
                        type="radio"
                        //  id="is_maintenance_on_no"
                        {...register("is_maintenance_on", { required: false })}
                        value={0}
                        checked={formData?.is_maintenance_on == 0}
                        onChange={handleChange}
                      />
                      <label htmlFor="is_active_no">No</label>
                    </div>
                  </div>
                  <FormErrorMessage error={errors.is_maintenance_on} />
                </div>
              </div>

              <div className="input-field">
                <label>SMTP Host</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("smtp_host", { required: true })}
                      value={formData?.smtp_host}
                      onChange={handleChange}
                      placeholder="SMTP Hose"
                    />
                  </div>
                  <FormErrorMessage error={errors.smtp_host} />
                </div>
              </div>
              <div className="input-field">
                <label>Password</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      {...register("smtp_password", { required: true, })}
                      placeholder="Password"
                    />
                    <span
                      className="field-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeSvg className="password-icon" />
                      ) : (
                        <EyeHidingSvg className="password-icon" />
                      )}
                    </span>
                  </div>
                  <FormErrorMessage error={errors.smtp_password} />
                </div>
              </div>

              <div className="input-field">
                <label>SMTP Port</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("smtp_port", { required: true })}
                      value={formData?.smtp_port}
                      onChange={handleChange}
                      placeholder="SMTP Password"
                    />
                  </div>
                  <FormErrorMessage error={errors.smtp_port} />
                </div>
              </div>
              <div className="input-field">
                <label>SMTP User</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="email"
                      {...register("smtp_user", { required: true })}
                      value={formData?.smtp_user}
                      onChange={handleChange}
                      placeholder="SMTP User"
                    />
                  </div>
                  <FormErrorMessage error={errors.smtp_user} />
                </div>
              </div>

            </div>
            <div className="fields-section">
              <div className="input-field">
                <label>Maintenance Mode Description</label>
                <div className="field-wrap">
                  <div className="text-area">
                    <textarea
                      {...register("maintenance_mode_text", { required: false })}
                      value={formData?.maintenance_mode_text}
                      onChange={handleChange}
                      placeholder="Enter Maintenance Description"
                      rows={2}
                    />
                  </div>
                  <FormErrorMessage error={errors.maintenance_mode_text} />
                </div>
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button
                  type="button"
                  className="lg-rounded-btn gray"
                  onClick={resetForm}
                >
                  Reset
                </button>
                {
                  hasAccess(sitePermissions.organizationSettingCreate) && (
                    <button
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: OrganizationSettingDTO) => onSubmit(data))}
                    >
                      Update Organization Setting
                    </button>
                  )
                }

              </div>
            </div>
          </form>
        </div>
      </STCreateContent >
    </Main >
  );
};

export default OrganizationSettingCreate;