import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicTemplateCoursesListingMain,
  AcademicTemplateCoursesListingSection,
  AcademicTemplateCoursesListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useAcademics from "../../useHooks";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import DataNotFound from "components/particles/table/data-not-found";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useUtils from "hooks/useUtils";

const AcademicTemplateCoursesListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Template Courses",
      path: siteRoutes.academicTemplateCoursesListing,
    },
  ];
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const columns: string[] = [
    "",
    "Course Code",
    "Course Title",
    // "Theory Credit Course",
    // "Lab Credit Hours",
    "Credit Hour's",
    "Department",
    "Is Virtual",
    "Is Lab",
    "Is elective",
    "Status",
  ];
  const navigate = useNavigate();
  const { getTemplateCourses, deleteTemplateCourse, updateTemplateCourse } = useAcademics();
  const [data, setData] = useState<TemplateCourseDTO[]>([]);
  const { hasAccess} = useStore();
  const { createQuery } = useUtils();
  const [filters, setFilters] = useState({});
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const goToCreate = () => {
    navigate(siteRoutes.createAcademicTemplateCourse);
  };

  const toggleRowExpand = (index: number) => {
    (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
    setData([...data]);
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };


  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search
      };
      deleteTemplateCourse(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllTemplateCourses(page + 1, search, per_page);
  };
  const getAllTemplateCourses = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getTemplateCourses(setData, queryParams, setPagination);
  };

  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.createAcademicTemplateCourse}?id=${id}`);
  };
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  ); // State to track the open dropdown by index
  const [isTableOpened, setIsTableOpened] = useState(false);

  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  const goToCoReqCoursesList = (id: number) => {
    navigate(`${siteRoutes.academicTemplateCoursesCoReqListing}?id=${id}`);
  }
  const GotToPreReqCourseList = (id: number) => {
    navigate(`${siteRoutes.academicTemplateCoursesPreReqListing}?id=${id}`);
  }
  const goToManageEquivalentCourse = (id: number) => {
    navigate(`${siteRoutes.academicTemplateEqualentCourseList}?id=${id}`);
  }

  const handleChangeTemplateCourse = async (course: TemplateCourseDTO, index: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const { id } = course;
      data[index] = course;
      setData([...data]);
      updateTemplateCourse(id, course);
    }
  }
  useEffect(() => {
    getAllTemplateCourses(pagination.page, search, pagination.per_page);
  }, []);

  const showCreditHours = (course: { tc_credit_hours: string; tc_lab_credit_hours: string }) => {
    const { tc_credit_hours, tc_lab_credit_hours } = course;

    if (tc_credit_hours != "0" && tc_lab_credit_hours == "0") {
      return {
        value: tc_credit_hours,
        title: "Theory Credit Hour's"
      };
    } else if (tc_credit_hours == "0" && tc_lab_credit_hours != "0") {
      return {
        value: tc_lab_credit_hours,
        title: "Lab Credit Hour's"
      };
    } else if (tc_credit_hours != "0" && tc_lab_credit_hours != "0") {
      return {
        value: `${tc_credit_hours} - ${tc_lab_credit_hours}`,
        titles: [
          { value: tc_credit_hours, title: "Theory Credit Hour's" },
          { value: tc_lab_credit_hours, title: "Lab Credit Hour's" }
        ]
      };
    } else {
      return {
        value: "NCrH",
        title: "Non Credit Hour Course"
      };
    }
  };

  const goToVirtualCourses = (link_course_id: number) => {
    const query = createQuery({link_course_id});
    navigate(siteRoutes.academicVirtualCoursesListing + query);
  }


  return (
    <AcademicTemplateCoursesListingMain>
      <AcademicTemplateCoursesListingTop>
        <div className="left">
          <span className="page-heading">Template Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="add-new-button">
            <button className="lg-rounded-btn" onClick={goToCreate}>
              + Add Template Course
            </button>
          </div>
        </div>
      </AcademicTemplateCoursesListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {showFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="">Course Title</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Enter Title" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Course Code</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Enter Course Code" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Department Title</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Department Title" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Virtual Course</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Is active</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Apply Filters</button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AcademicTemplateCoursesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      key={index}
                      className={
                        index > 9 ? `extra-head extra-head-${index - 9}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((course, index) => {
                const { tc_is_virtual, tc_is_lab, tc_is_elective, tc_is_active, tc_credit_hours, tc_lab_credit_hours } = course;
                const creditHoursInfo = showCreditHours(course);
                return (
                  <Fragment key={index}>
                    <tr
                      className={`expandable ${(course as any).isExpanded && "opened"
                        }`}
                    >
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{(course as any).isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>{course.tc_code}</td>
                      <td>{course.tc_title}</td>

                      <td className="curser-pointer">
                        {creditHoursInfo.titles ? (
                          <>
                            <span
                              title={creditHoursInfo.titles[0].title}
                              className="curser-pointer"
                            >
                              {creditHoursInfo.titles[0].value}
                            </span>
                            {" + "}
                            <span
                              title={creditHoursInfo.titles[1].title}
                              className="cursor-pointer"
                            >
                              {creditHoursInfo.titles[1].value}
                            </span>
                          </>
                        ) : (

                          <span title={creditHoursInfo.title} className="cursor-pointer">
                            {creditHoursInfo.value}
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="mw-150">
                          {course.department.title}
                        </div>
                      </td>
                      <td>
                        <label className="switch-field">
                          <input type="checkbox" checked={tc_is_virtual == 1} onChange={({ target: { checked } }) => handleChangeTemplateCourse({ ...course, tc_is_virtual: checked ? 1 : 0 }, index)} />
                          <span className="slider"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch-field">
                          <input type="checkbox" checked={tc_is_lab == 1} onChange={({ target: { checked } }) => handleChangeTemplateCourse({ ...course, tc_is_lab: checked ? 1 : 0 }, index)} />
                          <span className="slider"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch-field">
                          <input type="checkbox" checked={tc_is_elective == 1} onChange={({ target: { checked } }) => handleChangeTemplateCourse({ ...course, tc_is_elective: checked ? 1 : 0 }, index)} />
                          <span className="slider"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch-field">
                          <input type="checkbox" checked={tc_is_active == 1} onChange={({ target: { checked } }) => handleChangeTemplateCourse({ ...course, tc_is_active: checked ? 1 : 0 }, index)} />
                          <span className="slider"></span>
                        </label>
                      </td>
                      <td className="extra-col extra-col-1">
                        <div className="action-menu">
                          <div
                            className="menu-icon cp"
                            onClick={() => toggleDropdown(index)}
                          >
                            <TabPrimaryActionMenu className="icon" />
                          </div>
                          {/* modal */}
                          {openedDropdownIndex === index && (
                            <div className="table-menu-dropdown">
                              <div className="particular-menu cp">
                                <span className="title">Edit</span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title">
                                  Manage Co Request Courses
                                </span>
                              </div>

                              <div className="particular-menu cp">
                                <span className="title">
                                  Manage Equilant Courses
                                </span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title">
                                  {" "}
                                  Manage Pre Req Courses{" "}
                                </span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title">
                                  Course Room Allocation
                                </span>
                              </div>
                              {(hasAccess(sitePermissions.academicVirtualCoursesListing) && course.tc_is_virtual) ? <div className="particular-menu cp" onClick={() => goToVirtualCourses(course.id)}>
                                <span className="title">
                                  Manage Template Courses
                                </span>
                              </div> : ''}
                              <div className="particular-menu cp">
                                <span className="title">Delete</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    {
                      (course as any).isExpanded && (
                        <tr>
                          <td colSpan={9}>
                            <div className="expanded-content">
                              <div className="particular-info">
                                <div className="title">Action</div>
                                <div className="info">
                                  <div className="table-action-buttons">
                                    <div className="table-action-button">
                                      <button
                                        onClick={() => goToCoReqCoursesList(course?.id)}
                                        className="green"
                                      >
                                        Manage Co Req Courses
                                      </button>
                                    </div>
                                    <div className="table-action-button">
                                      <button
                                        className="green"
                                        onClick={() => GotToPreReqCourseList(course?.id)}
                                      >
                                        Manage Pre Req Courses
                                      </button>
                                    </div>
                                    <div className="table-action-button">
                                      <button
                                        className="green"
                                        onClick={() => goToManageEquivalentCourse(course?.id)}
                                      >
                                        Manage Equivalent Courses
                                      </button>
                                    </div>

                                    <div className="table-action-button">
                                      <button className="green">
                                        Course Room Allocations
                                      </button>
                                    </div>
                                    {(hasAccess(sitePermissions.academicVirtualCoursesListing) && course.tc_is_virtual) ? <div className="table-action-button">
                                      <button className="black" onClick={() => goToVirtualCourses(course?.id)}>
                                        Manage Virtual Courses
                                      </button>
                                    </div> : ''}
                                    <div
                                      className="action-icon"
                                      onClick={() => goToEdit(course.id)}
                                    >
                                      <EditTableSvg />
                                    </div>
                                    <div
                                      className="action-icon"
                                      onClick={() => handleDelete(course.id)}
                                    >
                                      <DeleteTableSvg />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    }
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>

      </AcademicTemplateCoursesListingSection>
    </AcademicTemplateCoursesListingMain >
  );
};

export default AcademicTemplateCoursesListing;
