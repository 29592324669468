import { CertificateLevelDTO } from "../organization/certificate-levels.dto";
import { OrgStructureDTO } from "../organization/org-structure.dto";
import { AddAdmissionSessionDTO } from "./add-admission-session.dto";

export class AdmissionCampaignDTO {
  id: number;
  title: string = "";
  session_id: string = "";
  session: AddAdmissionSessionDTO;
  code: string = ''
  cat_id: string = "";
  program_id: number[] = [];
  fee_due_date: string = "";
  start_date: string = "";
  end_date: string = "";
  campaign_status: number = 1;
  certificate_level_id: string = "";
  certificate: CertificateLevelDTO;
  max_class_start_date: string = ''
  has_active_status: number;
  category: OrgStructureDTO;
  mission: string = "";
  description: string = "";
  extra_fields: DynamicField[] = [];
  external_fields: DynamicField[] = [];
  internal_fields: DynamicField[] = [];

  constructor(data = {}){
    if(Object.keys(data).length){
      Object.assign(this,data);  
      this.category = this.category ?? new OrgStructureDTO();
      this.certificate = this.certificate ?? new CertificateLevelDTO();
      this.session = this.session ?? new AddAdmissionSessionDTO();
    }
  }
}
export class DynamicField {
  level: any = "";
  type: any = "";
  is_hafiz: number = 1;
  is_test: number = 1;
  level_weightage: number = 1;
  marks: number = 0;
  test_weightage: number = 1;
  options: string[] = [];
}
