import styled from "styled-components";

export const SettingsMain = styled.div`
    border: 1px solid var(--field-border);
    border-radius: 8px;
    width: 308px;
    background-color: var(--white-color);
    position: absolute;
    z-index: 100;
    right: 9rem;
    top: 5rem;
    display: flex;
    justify-content: center;
`;

export const ZoomSettings = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 0;

    .particular-setting{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 0 1em;


        .heading{
            font-size: var(--font-18);
            font-weight: 700;
            color: var(--lightgray-medium);
        }

        .btns{
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 1em;
        }
    }

    .bottom-border{
        width: 100%;
        border-bottom: 1px solid var(--lightgray-extralight);
    }
`;

export const ZoomBtn = styled.div<{$zoomOverflowed: boolean}>`
        width: 45px;
        height: 30px;
        border: 1px solid var(--lightgray-extralight);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: var(--lightgray-medium);
        opacity: ${({$zoomOverflowed}) => $zoomOverflowed ? '.5' : '1'};

        .operator{
            font-size: var(--font-24);
            font-weight: 500;
        }
`;