import { FC, Fragment, useEffect, useState } from "react";
import {
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import { useNavigate } from "react-router-dom";
import {
  ManageMeritListListingMain,
  ManageMeritListListingSection,
  ManageMeritListListingTop,
} from "./style";
import useUtils from "hooks/useUtils";
import BulkLockList from "./components/bulk-lock-list";
import BulkUnlockStep from "./components/bulk-unlock-step";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useAdmissions from "../../useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

const ManageMeritList: FC = ({ }) => {
  const breadcrumbLinks = [
    { title: "Admission /", path: siteRoutes.admissionCampaignListing },
    { title: "Manage Merit List", path: siteRoutes.manageMeritListListing },
  ];
  const [openManageCriteriaModal, setOpenManageCriteriaModal] = useState(false);
  const [openManageSeatsModal, setOpenManageSeatsModal] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [automationId, setAutomationId] = useState<number>(0);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const { handleSearchChange, handleTableSearch } = useUtils();
  const { getMeritListAutomations, deleteMeritAutomation, downloadBulkList } =
    useAdmissions();

  const columns: string[] = [
    "",
    "Title",
    "Offer Date",
    "Expiry Date",
    "Merit List No",
    "Minimum App. to lock",
    "Quotas",
    "Ptrogram Title Filter",
    "Link Slot Id",
    "Action",
  ];

  const toggleRowExpand = (index: number) => {
    const programs = [...data];
    programs[index].isExpanded = !programs[index].isExpanded;
    setData([...programs]);
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllMeritListAutomations(page + 1, search, per_page);
  };

  const getAllMeritListAutomations = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };

    getMeritListAutomations(setData, queryParams, setPagination);
  };
  const downloadList = (id: number) => {
    downloadBulkList(id);
  };

  useEffect(() => {
    getAllMeritListAutomations(pagination.page, search, pagination.per_page);
  }, []);

  const handleOpenManageCriteriaModal = (automationId: number) => {
    setAutomationId(automationId);
    setOpenManageCriteriaModal(true);
  };

  const handleOpenManageSeatsModal = (automationId: number) => {
    setAutomationId(automationId);
    setOpenManageSeatsModal(true);
  };

  const goToAddManageMeritList = () => {
    navigate(siteRoutes.createAdmissionManageMeritList);
  };

  const goToEditManageMeritList = (id: number) => {
    navigate(`${siteRoutes.createAdmissionManageMeritList}?id=${id}`);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const { per_page, page } = pagination;
      const queryParams = {
        per_page,
        page,
        search
      };
      deleteMeritAutomation(id, setData, queryParams, setPagination);
    }
  };

  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(null);
  const [isTableOpened, setIsTableOpened] = useState(false);

  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  return (
    <ManageMeritListListingMain>
      <ManageMeritListListingTop>
        <div className="left">
          <span className="page-heading">Manage Merit List</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-btn">
            <button className="lg-rounded-btn" onClick={goToAddManageMeritList}>
              + Add New
            </button>
          </div>
        </div>
      </ManageMeritListListingTop>
      <ManageMeritListListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getAllMeritListAutomations)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllMeritListAutomations)}
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 5 ? `extra-head extra-head-${index - 5}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{item.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{item.title}</div>
                      </td>
                      <td>{item.offer_date}</td>
                      <td>{item?.expiry_date}</td>
                      <td>{`${item?.ml < 10 ? 0 : ""}${item?.ml}`}</td>
                      <td>{`${item?.minimum_apps_to_lock < 10 ? 0 : ""}${item?.minimum_apps_to_lock
                        }`}</td>
                      <td className="extra-col extra-col-1">
                        {Object.entries(
                          (item?.quota_titles as Record<string, string>) || {}
                        ).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </td>
                      <td className="extra-col extra-col-2">
                        {" "}
                        {item?.program_title_filter ?? "__"}
                      </td>
                      <td className="extra-col extra-col-3">
                        {" "}
                        {item?.link_slots ?? "__"}
                      </td>
                      <td className="extra-col extra-col-4">
                        <div className="action-menu">
                          <div
                            className="menu-icon cp"
                            onClick={() => toggleDropdown(index)}
                          >
                            <TabPrimaryActionMenu className="icon" />
                          </div>
                          {/* modal */}
                          {openedDropdownIndex === index && (
                            <div className="table-menu-dropdown">
                              <div
                                className="particular-menu cp"
                                onClick={() => downloadList(item?.id)}
                              >
                                <span className="title">
                                  Download Locked List
                                </span>
                              </div>
                              <div
                                className="particular-menu cp"
                                onClick={() =>
                                  goToEditManageMeritList(item?.id)
                                }
                              >
                                <span className="title">Edit</span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title">
                                  Download Awaiting List
                                </span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title"> Extend Date</span>
                              </div>
                              <div
                                className="particular-menu cp"
                                onClick={() =>
                                  handleOpenManageCriteriaModal(item?.id)
                                }
                              >
                                <span className="title"> Bulk lock list</span>
                              </div>

                              <div
                                className="particular-menu cp"
                                onClick={() =>
                                  handleOpenManageSeatsModal(item.id)
                                }
                              >
                                <span className="title"> Bulk unlock step</span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title">
                                  {" "}
                                  Bulk lock using Excel
                                </span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title"> Download Summary</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    {item.isExpanded && (
                      <tr className="expanded-row">
                        <td></td>
                        <td colSpan={5}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info-1">
                              <span className="title">Quotas</span>
                              <span className="info">
                                {Object.entries(
                                  (item?.quota_titles as Record<
                                    string,
                                    string
                                  >) || {}
                                ).map(([key, value], index, arr) => (
                                  <span key={key}>
                                    {value}
                                    {index < arr.length - 1 && " , "}
                                  </span>
                                ))}
                              </span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">
                                Program Title Filter
                              </span>
                              <span className="info">
                                {item?.program_title_filter ?? "__"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Link Slot ID</span>
                              <div className="info">
                                <span className="status">
                                  {item?.link_slots ?? "__"}
                                </span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-4">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-icons">
                                  <div
                                    className="action-icon"
                                    onClick={() =>
                                      goToEditManageMeritList(item?.id)
                                    }
                                  >
                                    <EditTableSvg />
                                  </div>
                                  <div
                                    className="action-button"
                                    onClick={() => downloadList(item?.id)}
                                  >
                                    <button className="download-lock-list-btn">
                                      Download Locked List
                                    </button>
                                  </div>
                                  <div className="action-button">
                                    <button>Download Awaiting List </button>
                                  </div>

                                  <div className="action-button">
                                    <button className="extend-date-btn">
                                      Extend Date
                                    </button>
                                  </div>

                                  <div
                                    className="action-button"
                                    onClick={() =>
                                      handleOpenManageCriteriaModal(item?.id)
                                    }
                                  >
                                    <button className="lock-list-btn">
                                      Bulk lock list
                                    </button>
                                  </div>
                                  <div
                                    className="action-button"
                                    onClick={() =>
                                      handleOpenManageSeatsModal(item.id)
                                    }
                                  >
                                    <button className="unlock-step-btn">
                                      Bulk unlock step
                                    </button>
                                  </div>

                                  <div className="action-button">
                                    <button className="bulk-lock-excel-btn">
                                      Bulk lock using Excel
                                    </button>
                                  </div>

                                  <div className="action-button">
                                    <button className="download-summary-btn">
                                      Download Summary
                                    </button>
                                  </div>

                                  {/* <div
                                    className="action-icon"
                                    onClick={() => handleDelete(item?.id)}
                                  >
                                    <DeleteTableSvg />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!data.length && !isLoading} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </ManageMeritListListingSection>
      {openManageCriteriaModal && (
        <BulkLockList
          setOpen={setOpenManageCriteriaModal}
          automationId={automationId}
        />
      )}
      {openManageSeatsModal && (
        <BulkUnlockStep
          setOpen={setOpenManageSeatsModal}
          automationId={automationId}
        />
      )}
    </ManageMeritListListingMain>
  );
};

export default ManageMeritList;
