import { FC, Fragment, useState, useEffect } from "react";

import {
  CallTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  TableBlackRightArrowSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";

import {
  AdmissionStudentsListingMain,
  AdmissionStudentsListingSection,
  AdmissionStudentsListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAdmissions from "../useHooks";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import SingleDetailDialog from "components/particles/dialogs/single-detail-dialog";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import {
  OrgStructureTypes,
  SESSION_TYPES,
} from "utils/helpers/enums/shared.enums";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { CertificateLevelDTO } from "utils/helpers/models/organization/certificate-levels.dto";
import { CreateAdmissionQuotaDTO } from "utils/helpers/models/admissions/create-quota.dto";
import { religions } from "utils/constants/array";
import { CityDTO } from "utils/helpers/models/organization/city.dto";
import { AddAdmissionSessionDTO } from "utils/helpers/models/admissions/add-admission-session.dto";

interface Filters {
  program_id: string;
  campus_id: string;
  session_year: string;
  session_type: string;
  start_date: string;
  end_date: string;
  session_id: string;
  gender: string;
  category: string;
  city: string;
  quota: string;
  religion: string;
  is_hafiz: string;
  cnic: string;
  app_no: string;
}

interface AdmissionStudentListingProps {}
const AdmissionStudentListing: FC<AdmissionStudentListingProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Admission /", path: "" },
    { title: "Student Listing", path: siteRoutes.admissionStudentListing },
  ];
  const columns: string[] = [
    "",
    "CNIC",
    "Name",
    "Father Name",
    "Program",
    "Application No.",
    "Applied on",
    "Fee Status",
    "Merit",
    "Program",
    "Domicile ",
    "Campus",
    "Session Year",
    "Session Type",
    "Academic Session",
    "Category",
    "Admission Eligibility",
    "Gender",
    "Religion",
    "Hostel facility",
    "Hafiz-e-Quran",
    "Action",
  ];

  const { getStudentApplicants, loginAsUser, downloadAdmissionAppByAdmin } =
    useAdmissions();
  const {
    getPrograms,
    getSpecificOrgStructures,
    getCertificateLevelsAdmin,
    getCitiesAdmin,
  } = useOrganization();
  const { getAdmissionQuotas, getAdmissionSessions } = useAdmissions();
  const { getQueryParams, handleTableSearch, handleSearchChange, getYears } =
    useUtils();
  const params = getQueryParams();
  const [data, setData] = useState<any[]>([]);
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [admissionSessions, setAdmissionSessions] = useState<
    AddAdmissionSessionDTO[]
  >([]);
  const [certificateTypes, setCertificateTypes] = useState<
    CertificateLevelDTO[]
  >([]);
  const [cities, setCities] = useState<CityDTO[]>([]);
  const [quotas, setQuotas] = useState<CreateAdmissionQuotaDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<Filters>({
    program_id: "",
    campus_id: "",
    session_year: "",
    session_type: "",
    start_date: "",
    end_date: "",
    session_id: "",
    gender: "",
    category: "",
    city: "",
    quota: "",
    religion: "",
    is_hafiz: "",
    cnic: "",
    app_no: "",
  });
  const [singleDetail, setSingleDetail] = useState({ value: "", label: "" });
  const [showSingleDetailDialog, setShowSingleDetailDialog] =
    useState<boolean>(false);
  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const { hasAccess } = useStore();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const toggleRowExpand = (index: number) => {
    const updatedStudentData = [...data];
    updatedStudentData[index].isExpanded =
      !updatedStudentData[index].isExpanded;
    setData(updatedStudentData);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const getAllStudentApplicants = (
    page: number,
    search: string,
    per_page: number,
    filter: any
  ) => {
    const filterOptions = { ...filter };
    for (let key in filter) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }

    const queryParams = {
      search,
      per_page,
      page,
      ...filterOptions,
    };
    getStudentApplicants(setData, queryParams, setPagination);
  };

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleShowSingleDetailDialog = (label: string, value: string) => {
    setSingleDetail({ label, value });
    setShowSingleDetailDialog(true);
  };

  const handleResetFilters = () => {
    const needToReset: boolean = Object.values(filters).some(
      (value) => value !== ""
    );

    if (needToReset) {
      const resetFilters = { ...filters };
      for (const key in resetFilters) {
        if (Object.prototype.hasOwnProperty.call(resetFilters, key)) {
          resetFilters[key] = "";
        }
      }

      setFilters(resetFilters); // Update the filters state
      getAllStudentApplicants(1, search, pagination.per_page, resetFilters);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllStudentApplicants(page + 1, search, per_page, filters);
  };

  const handleLoginAsUser = async (user_id: number) => {
    const response = await confirmationPopup(
      "Are you sure you want to login to this student account?"
    );
    if (response.isConfirmed) {
      loginAsUser({ user_id });
    }
  };

  const handleApplyFilters = () => {
    getAllStudentApplicants(
      pagination.page,
      search,
      pagination.per_page,
      filters
    );
  };

  const handleDownloadApplication = async (app_no: string) => {
    const result = await confirmationPopup(
      "Are you sure you want to download the application?"
    );
    if (result.isConfirmed) {
      downloadAdmissionAppByAdmin(app_no);
    }
  };

  useEffect(() => {
    getAllStudentApplicants(
      pagination.page,
      search,
      pagination.per_page,
      filters
    );
    getPrograms(setPrograms);
    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
    getAdmissionSessions(setAdmissionSessions);
    getCertificateLevelsAdmin(setCertificateTypes);
    getAdmissionQuotas(setQuotas);
    getCitiesAdmin(setCities);
  }, []);

  return (
    <AdmissionStudentsListingMain>
      <AdmissionStudentsListingTop>
        <div className="left">
          <span className="page-heading">Students Applications</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AdmissionStudentsListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $isFilterOpen={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg />
          </span>
        </FilterHeader>

        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Programs</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="program_id"
                      value={filters.program_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {programs?.map((program, index) => {
                        return (
                          <option value={program.id} key={index}>
                            {program.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="campus_id"
                      value={filters.campus_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {campuses?.map((campus, index) => {
                        return (
                          <option value={campus.id} key={index}>
                            {campus.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Session Year</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_year"
                      value={filters.session_year}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {getYears(2000)?.map((year, index) => {
                        return (
                          <option value={year} key={index}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Session type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_type"
                      value={filters.session_type}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {Object.values(SESSION_TYPES)?.map((type, index) => {
                        return <option key={index}>{type}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label>From Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="start_date"
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>To Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Academic Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_id"
                      value={filters.session_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {admissionSessions?.map((session, index) => {
                        return (
                          <option key={index} value={session.id}>
                            {session.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Gender</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_id"
                      value={filters.session_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {["Male", "Female"].map((gender, index) => {
                        return <option key={index}>{gender}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Certificate type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="category"
                      value={filters.category}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {certificateTypes?.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>City / Tehsil</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="city"
                      value={filters.city}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {cities?.map((city, index) => {
                        return (
                          <option key={index} value={city.id}>
                            {city.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Quota</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="quota"
                      value={filters.quota}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {quotas?.map((quota, index) => {
                        return (
                          <option key={index} value={quota.id}>
                            {quota.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Religion</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="religion"
                      value={filters.religion}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {religions?.map((religion, index) => {
                        return (
                          <option key={index} value={religion.id}>
                            {religion.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Hafiz-e-Quran</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="is_hafiz"
                      value={filters.is_hafiz}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="cnic"
                      value={filters.cnic}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Application No.</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="app_no"
                      value={filters.app_no ?? ""}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={handleResetFilters}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={handleApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AdmissionStudentsListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.admissionStudentDownloadPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}
            {hasAccess(sitePermissions.admissionStudentDownloadExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllStudentApplicants
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllStudentApplicants
                )
              }
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return (
                    <th
                      className={
                        index > 7 ? `extra-head extra-head-${index - 7}` : ""
                      }
                      key={index}
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => (
                <Fragment key={index}>
                  <tr className={`expandable ${item.isExpanded && "opened"}`}>
                    <td>
                      <div
                        className="rounded-expand-button"
                        onClick={() => toggleRowExpand(index)}
                      >
                        <span>{item.isExpanded ? "-" : "+"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.cnic}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.name}</div>
                    </td>
                    <td>
                      <div className="mw-100">
                        {item?.father_name != "undefined" && item?.father_name
                          ? item?.father_name
                          : "-"}
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.program || "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.app_no || "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.app_date || "-"}</div>
                    </td>
                    <td>
                      {item?.fee_status === 0 && (
                        <span className="status-tile yellow">Unpaid</span>
                      )}
                      {item?.fee_status === 1 && (
                        <span className="status-tile green">Paid</span>
                      )}
                    </td>
                    <td className="extra-col extra-col-1">{item?.merit}</td>
                    <td className="extra-col extra-col-2">{item?.program}</td>
                    <td className="extra-col extra-col-3">{item?.domicile}</td>
                    <td className="extra-col extra-col-4">
                      {" "}
                      {item?.campus_title}
                    </td>
                    <td className="extra-col extra-col-5">
                      {" "}
                      {item?.session_year}
                    </td>
                    <td className="extra-col extra-col-6">
                      {item?.session_type}
                    </td>
                    <td className="extra-col extra-col-7">
                      {" "}
                      {item?.session_title}
                    </td>
                    <td className="extra-col extra-col-8">{item?.category}</td>
                    <td className="extra-col extra-col-9">
                      {" "}
                      {item?.is_eligible === 1
                        ? "Yes"
                        : item?.is_eligible === 0
                        ? "No"
                        : "--"}
                    </td>
                    <td className="extra-col extra-col-10">{item?.gender}</td>
                    <td className="extra-col extra-col-11">
                      {" "}
                      {item?.religion}
                    </td>
                    <td className="extra-col extra-col-12">
                      {" "}
                      {item?.hostel_check === 1
                        ? "Yes"
                        : item?.hostel_check === 0
                        ? "No"
                        : "--"}
                    </td>
                    <td className="extra-col extra-col-13">
                      {item?.is_hafiz === 1
                        ? "Yes"
                        : item?.is_hafiz === 0
                        ? "No"
                        : "--"}
                    </td>
                    <td className="extra-col extra-col-14">
                      <div className="table-action-icons">
                        {hasAccess(
                          sitePermissions.loginAsStudentAdmissionApplication
                        ) && (
                          <div
                            className="action-icon"
                            title="Login as"
                            onClick={() => handleLoginAsUser(item?.user_id)}
                          >
                            <TableBlackRightArrowSvg />
                          </div>
                        )}
                        {hasAccess(
                          sitePermissions.editStudentAdmissionApplication
                        ) && (
                          <div className="action-icon" title="Edit application">
                            <EditTableSvg />
                          </div>
                        )}
                        {hasAccess(
                          sitePermissions.callStudentAdmissionApplication
                        ) && (
                          <div
                            className="action-icon"
                            title="View phone number"
                            onClick={() =>
                              handleShowSingleDetailDialog("Phone", item?.phone)
                            }
                          >
                            <CallTableSvg />
                          </div>
                        )}
                        {hasAccess(
                          sitePermissions.messageStudentAdmissionApplication
                        ) && (
                          <div
                            className="action-icon"
                            title="View email"
                            onClick={() =>
                              handleShowSingleDetailDialog("Email", item?.email)
                            }
                          >
                            <MessageTableSvg />
                          </div>
                        )}

                        <div className="table-action-button">
                          <button
                            onClick={() =>
                              handleDownloadApplication(item?.app_no)
                            }
                          >
                            Download Application
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>

                  {item.isExpanded && (
                    <tr className="expanded-row">
                      <td></td>
                      <td colSpan={9}>
                        <div className="expanded-content">
                          <div className="particular-info particular-info-2">
                            <span className="title">Merit</span>
                            <span className="info">{item?.merit}</span>
                          </div>

                          <div className="particular-info particular-info-3">
                            <span className="title">Program</span>
                            <div className="info">
                              <span className="status">{item?.program}</span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-4">
                            <span className="title">Domicile</span>
                            <div className="info">
                              <span className="status">{item?.domicile}</span>
                            </div>
                          </div>

                          <div className="particular-info particular-info-5">
                            <span className="title"> Campus</span>
                            <div className="info">
                              <span className="status">
                                {item?.campus_title}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-6">
                            <span className="title">Session Year</span>
                            <div className="info">
                              <span className="status">
                                {item?.session_year}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-7">
                            <span className="title">Session Type</span>
                            <div className="info">
                              <span className="status">
                                {item?.session_type}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-8">
                            <span className="title">Academic Session</span>
                            <div className="info">
                              <span className="status">
                                {item?.session_title}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-9">
                            <span className="title">Category</span>
                            <div className="info">
                              <span className="status">{item?.category}</span>
                            </div>
                          </div>

                          <div className="particular-info particular-info-10">
                            <span className="title">Admission Eligibility</span>
                            <div className="info">
                              <span className="status">
                                {item?.is_eligible === 1
                                  ? "Yes"
                                  : item?.is_eligible === 0
                                  ? "No"
                                  : "--"}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-11">
                            <span className="title">Gender</span>
                            <div className="info">
                              <span className="status">{item?.gender}</span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-12">
                            <span className="title">Religion</span>
                            <div className="info">
                              <span className="status">{item?.religion}</span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-13">
                            <span className="title">Hostel Facility</span>
                            <div className="info">
                              <span className="status">
                                {item?.hostel_check === 1
                                  ? "Yes"
                                  : item?.hostel_check === 0
                                  ? "No"
                                  : "--"}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info particular-info-14">
                            <span className="title">Hafiz-e-Quran</span>
                            <div className="info">
                              <span className="status">
                                {item?.is_hafiz === 1
                                  ? "Yes"
                                  : item?.is_hafiz === 0
                                  ? "No"
                                  : "N/A"}
                              </span>
                            </div>
                          </div>

                          <div className="particular-info particular-info-15">
                            <span className="title">Action</span>
                            <div className="info">
                              <div className="table-action-icons">
                                {hasAccess(
                                  sitePermissions.loginAsStudentAdmissionApplication
                                ) && (
                                  <div
                                    className="action-icon"
                                    title="Login as"
                                    onClick={() =>
                                      handleLoginAsUser(item?.user_id)
                                    }
                                  >
                                    <TableBlackRightArrowSvg />
                                  </div>
                                )}

                                {hasAccess(
                                  sitePermissions.editStudentAdmissionApplication
                                ) && (
                                  <div
                                    className="action-icon"
                                    title="Edit application"
                                  >
                                    <EditTableSvg />
                                  </div>
                                )}
                                {hasAccess(
                                  sitePermissions.callStudentAdmissionApplication
                                ) && (
                                  <div
                                    className="action-icon"
                                    title="View phone number"
                                    onClick={() =>
                                      handleShowSingleDetailDialog(
                                        "Phone",
                                        item?.phone
                                      )
                                    }
                                  >
                                    <CallTableSvg />
                                  </div>
                                )}
                                {hasAccess(
                                  sitePermissions.messageStudentAdmissionApplication
                                ) && (
                                  <div
                                    className="action-icon"
                                    title="View email"
                                    onClick={() =>
                                      handleShowSingleDetailDialog(
                                        "Email",
                                        item?.email
                                      )
                                    }
                                  >
                                    <MessageTableSvg />
                                  </div>
                                )}

                                <div className="table-action-button">
                                  <button
                                    onClick={() =>
                                      handleDownloadApplication(item?.app_no)
                                    }
                                  >
                                    Download Application
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data?.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
          {showSingleDetailDialog ? (
            <SingleDetailDialog
              {...singleDetail}
              setOpen={setShowSingleDetailDialog}
            />
          ) : (
            ""
          )}
        </Fragment>
      </AdmissionStudentsListingSection>
    </AdmissionStudentsListingMain>
  );
};

export default AdmissionStudentListing;
