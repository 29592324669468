import { FC } from "react";
import { EditorMain } from "./style";
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
const modules = {
    toolbar: [
        [{ header: [1, 2, false] }], // Header dropdown
        ["bold", "italic", "underline", "strike"], // Formatting buttons
        [{ color: [] }, { background: [] }], // Text color and background color
        [{ list: "ordered" }, { list: "bullet" }], // List options
        ["link"], // Link button
        ["clean"], // Remove formatting
    ],
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "bullet",
    "link",
];
interface EditorProps {
    value?: string;
    onChange: (name: string, value: string) => void;
    name: string;
    register?: Function;
    required?: boolean;
}

const Editor: FC<EditorProps> = ({ value, onChange, name, register, required }) => {
    const quillRef = useRef<any>(null);
    const handleChange = (value: string) => {
        if (value === '<p><br></p>') {
            onChange(name, '');
        } else {
            onChange(name, value);
        }
    };
    return (
        <EditorMain>
            <div className="editor-wrapper">
                <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={value}
                    onChange={handleChange}
                    ref={quillRef}
                    style={{ flex: '1', maxWidth: 'auto', width: '100%' }}
                />
                {register && <input type="text" className="d-none" {...register(name, { required: required ?? true })} />}
            </div>
        </EditorMain>
    )
}
export default Editor;
