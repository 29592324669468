export class ProgramLinkCampaignDTO<MultiselectType extends number[] | string = number[]>{
    id: number;
    program_id: MultiselectType;
    class_start_date: string = '';
    fee_due_date: string = '';
    admission_status: number = 1;
    admission_session_header_id: number;

    constructor(data: Partial<ProgramLinkCampaignDTO> = {}){
        Object.assign(this,data);
    }
}