export class AddAdmissionSessionDTO {
    id: number;
    title: string = '';
    slug: string = '';
    code: string = '';
    year: string = '';
    start_date: string = '';
    end_date: string = '';
    type: string = '';
    is_active: string = '';

    constructor(data = {}){
        for(let key in data){
            if(key in this){
                this[key] = data[key];
            }
        }
    }
}