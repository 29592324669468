import { FC, useEffect, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import MultiselectField from "components/particles/forms/multiselect-field";
import { VirtualCourseDTO } from "utils/helpers/models/academics/virtual-course.dto";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import useAcademics from "containers/private/academics/useHooks";

interface Props {
  setOpen: Function;
  link_course_id: number;
}

const AddVirtualCourse: FC<Props> = ({ setOpen, link_course_id }) => {
  const [formData, setFormData] = useState<VirtualCourseDTO>(new VirtualCourseDTO({template_course_id: [], link_course_id}));
  const [templateCourses, setTemplateCourses] = useState<TemplateCourseDTO[]>([]);
  const { createVirtualCourse, getTemplateCourses } = useAcademics();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<VirtualCourseDTO>();


  const onSubmit = () => {
    createVirtualCourse({ ...formData, template_course_id: formData.template_course_id.join(',') }, setOpen);
  };

  const onSelect = (template_course_id: number[]) => {
    setValue('template_course_id', template_course_id);
    trigger('template_course_id');
    setFormData({ ...formData, template_course_id });
  }

  useEffect(() => {
    getTemplateCourses(setTemplateCourses);
    register('template_course_id', { required: true });
    setValue('link_course_id', link_course_id);
  }, []);

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Add Template Courses</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <MultiselectField
                value={formData.template_course_id}
                searchable
                options={templateCourses}
                helperText="Select Courses"
                label="Template Courses"
                onSelect={onSelect}
                error={errors.template_course_id}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                property="tc_title"
              />
            </div>
            {!isDropdownOpen && <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Submit</button>
              </div>
            </div>}
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default AddVirtualCourse;
