import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import TimeTableListing from "containers/private/time-table/time-table/listing";
import TimeTableCreate from "containers/private/time-table/time-table/create";
import TimeTablePlannerListing from "containers/private/time-table/planner/listing";
import TimeTablePlannerCreate from "containers/private/time-table/planner/create";
import TimeTablePlannerDashboard from "containers/private/time-table/planner/pages/dashboard";
import DefaultCreditHoursListing from "containers/private/time-table/planner/pages/defaut-credit-hours";

const TimeTableRoutes: FC = () => {
  const { getPathToSetRoute } = useUtils();

  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.timeTableListing)}
        Component={TimeTableListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTableCreate)}
        Component={TimeTableCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTablePlannerCreate, true)}
        Component={TimeTablePlannerCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTablePlannerListing, true)}
        Component={TimeTablePlannerListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTablePlannerDashboard, true)}
        Component={TimeTablePlannerDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.defaultCreditHoursListing, true)}
        Component={DefaultCreditHoursListing}
      />
    </Routes>
  );
};

export default TimeTableRoutes;
