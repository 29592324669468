export class EnrolledCourseTeachersDTO {
  regular_part_time_courses: CoursesDTO = new CoursesDTO();
  regular_teachers_enrolled: number = 0;
  regular_workload_courses: CoursesDTO = new CoursesDTO();
  tatal_courses: CoursesDTO = new CoursesDTO();
  total_enrolled_teachers: number = 0;
  visiting_workload_courses: CoursesDTO = new CoursesDTO();
  courses_having_Enrollments_gt_5_lt_10: number = 0;
  courses_having_Enrollments_gt_10_lt_20: number = 0;
  courses_having_Enrollments_gt_20_lt_30: number = 0;
  courses_having_Enrollments_gt_30_lt_40: number = 0;
  courses_having_Enrollments_gt_40: number = 0;
  courses_having_Enrollments_less_then_five: number = 0;
  non_teaching_courses: CoursesDTO = new CoursesDTO();
  regular_teachers_not_enrolled: number = 0;
  regular_teachers_zero_workload_enrolled_in_part_time: number = 0;
  visiting_teachers_enrolled: number = 0;

  constructor(data: Partial<EnrolledCourseTeachersDTO> = {}) {
    const dtoFields: string[] = [
        'regular_part_time_courses',
        'regular_workload_courses',
        'tatal_courses',
        'visiting_workload_courses',
        'non_teaching_courses'
    ];

    for (let key in data) {
      if (!dtoFields.includes(key)) {
        this[key] = data[key];
      } else {
        this[key] = new CoursesDTO(data[key]);
      }
    }
  }
}

class CoursesDTO {
  courses_count: number = 0;
  credit_hours_count: number = 0;

  constructor(data: Partial<CoursesDTO> = {}) {
    Object.assign(this, data);
  }
}
