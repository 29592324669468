import { OrgStructureDTO } from "../organization/org-structure.dto";
import { GradeTemplateDTO } from "./grade-template.dto";

export class TemplateCourseDTO {
  id!: number;
  tc_title: string = "";
  tc_code: string = "";
  tc_description: string = "";
  tc_credit_hours: string = "";
  department_id: string = "";
  tc_is_elective: number = 0;
  tc_is_lab: number = 0;
  tc_short_title: string = "";
  tc_is_active: number = 1;
  tc_is_virtual: number = 0;
  tc_lab_credit_hours: string = "";
  grade_template_id: string = "";
  tc_total_marks: string = "";
  tc_is_research_course: number = 0;
  tc_is_non_credit: number = 0;
  for_all: number = 0;
  grading_scheme_template!: GradeTemplateDTO;
  department!: OrgStructureDTO;

  constructor(data: Partial<TemplateCourseDTO> = {}) {
    for(let key in data){
      if(key === 'department'){
        this[key] = new OrgStructureDTO(data[key]);
      }else if(key === 'grading_scheme_template'){
        this[key] = new GradeTemplateDTO(data[key]);
      }else{
        this[key] = data[key];
      }
    }
  }
}
