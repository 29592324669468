import { FC, Fragment, useEffect, useState } from "react";
import {
  SuperAdminManagementListingMain,
  SuperAdminManagementListingSection,
  SuperAdminManagementListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useOrganization from "../../useHooks"
import useUtils from "hooks/useUtils";
import UpdatePassword from "../components/update-password";

const OrgAdminListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Organization /", path: siteRoutes.organizationListing },
    { title: "Admin List", path: siteRoutes.orgAdminListing },
  ];
  const columns: string[] = ["Name", "Email", "Status", "Role", "Action"];
  const { getOrgAdminById } = useOrganization();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<any[]>([]);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const { handleSearchChange, handleTableSearch } = useUtils();

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllOrgAdminById(page + 1, search, per_page);
  };
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const getAllOrgAdminById = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getOrgAdminById(params?.organization_id, setData, queryParams);
  };
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [adminId, setAdminId] = useState<any>(null);

  const handleOpenUpdatePassword = (adminId: number) => {
    setAdminId(adminId);
    setOpenPassword(true);
  };
  useEffect(() => {
    getAllOrgAdminById(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <SuperAdminManagementListingMain>
      <SuperAdminManagementListingTop>
        <div className="left">
          <span className="page-heading">
            {" "}
            {decodeURIComponent(params?.title)} Admins
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </SuperAdminManagementListingTop>

      <SuperAdminManagementListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getAllOrgAdminById)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllOrgAdminById)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                const roleName =
                  item?.roles?.length > 0 ? item.roles[0].name : "-";

                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">{item?.name}</div>
                    </td>
                    <td>{item?.email}</td>
                    <td>
                      {item?.status === 1 ? (
                        <span className="status-tile green">Active</span>
                      ) : (
                        <span className="status-tile yellow">Inactive</span>
                      )}
                    </td>

                    <td>{item?.role?.name}</td>

                    <td>
                      <div className="table-action-icons">
                        <div
                          className="table-action-button"
                          onClick={() => handleOpenUpdatePassword(item?.id)}
                        >
                          <button className="admin-btn">Update Password</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Fragment>
          {openPassword && (
            <UpdatePassword setOpen={setOpenPassword} admin_Id={adminId} />
          )}
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </SuperAdminManagementListingSection>
    </SuperAdminManagementListingMain>
  );
};

export default OrgAdminListing;
