import Breadcrumb from "components/particles/breadcrumb";
import { FC, Fragment, useEffect, useState } from "react";
import { OrgListingMain, OrgListingSection, OrgListingTop } from "./style";
import {
  BlackTableViewSvg,
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import useOrganization from "../../useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { OrganizationDTO } from "utils/helpers/models/organization/organization.dto";
import { CityDTO } from "utils/helpers/models/organization/city.dto";

interface OrganizationListingProps { }

const OrganizationListing: FC<OrganizationListingProps> = () => {
  const breadcrumbLinks = [
    { title: "Organization /", path: "" },
    { title: "Organizations", path: siteRoutes.organizationListing },
  ];
  const columns: string[] = [
    "Logo",
    "Name",
    "Domain",
    "Address",
    "City",
    "District",
    "Action",
  ];
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [data, setData] = useState<OrganizationDTO<CityDTO>[]>([]);
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch } = useUtils();
  const {
    getOrganizations,
    deleteOrganization,
    downloadOrganizationsExcelFile,
    downloadOrganizationsPdfFile,
  } = useOrganization();

  const goToCreateOrg = () => {
    navigate(siteRoutes.createOrganization);
  };

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search
      }
      deleteOrganization(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllOrganizations(page + 1, search, per_page);
  };

  const goToUpdateOrg = (id: number) => {
    navigate(`${siteRoutes.createOrganization}?id=${id}`);
  };

  const getAllOrganizations = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getOrganizations(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAllOrganizations(pagination.page, search, pagination.per_page);
  }, []);

  const goToViewOrg = (id: number) => {
    navigate(`${siteRoutes.viewOrganization}?id=${id}`);
  };
  const goToOrgAdmin = (organization: any) => {
    const { id, name } = organization;
    navigate(
      `${siteRoutes.orgAdminListing
      }?organization_id=${id}&title=${decodeURIComponent(name)}`
    );
  };

  return (
    <OrgListingMain>
      <OrgListingTop>
        <div className="left">
          <span className="page-heading">Organization Listing</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createOrganization) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={goToCreateOrg}>
                + Add Organization
              </button>
            </div>
          )}
        </div>
      </OrgListingTop>
      <OrgListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadOrgListPdf) && (
              <div
                className="export-btn"
                onClick={downloadOrganizationsPdfFile}
              >
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadOrgListExcel) && (
              <div
                className="export-btn"
                onClick={downloadOrganizationsExcelFile}
              >
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getAllOrganizations)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllOrganizations)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((organization, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {organization?.logo ? (
                        <div className="org-image">
                          <img src={organization?.logo} alt="" />
                        </div>
                      ) : (
                        <div className="org-logo"></div>
                      )}
                    </td>
                    <td>
                      <div className="mw-150">{organization?.name}</div>
                    </td>
                    <td>{organization?.domain ?? "-"}</td>
                    <td>
                      <div className="mw-150">{organization?.address1}</div>
                    </td>
                    <td>{organization?.city?.title ?? "-"}</td>
                    <td>{organization?.district?.title ?? "-"}</td>
                    <td>
                      <div className="table-action-icons">
                        {hasAccess(sitePermissions.viewOrganization) && (
                          <div
                            className="action-icon"
                            onClick={() => goToViewOrg(organization.id)}
                          >
                            <BlackTableViewSvg />
                          </div>
                        )}
                        {hasAccess(sitePermissions.orgAdminListing) && (
                          <div
                            className="table-action-button"
                            onClick={() => goToOrgAdmin(organization)}
                          >
                            <button className="admin-btn">Admins</button>
                          </div>
                        )}
                        {hasAccess(sitePermissions.editOrganization) && (
                          <div
                            className="action-icon"
                            onClick={() => goToUpdateOrg(organization.id)}
                          >
                            <EditTableSvg />
                          </div>
                        )}

                        {hasAccess(sitePermissions.deleteOrganization) && (
                          <div
                            className="action-icon"
                            onClick={() => handleDelete(organization.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </OrgListingSection>
    </OrgListingMain>
  );
};

export default OrganizationListing;
