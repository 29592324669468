import styled from "styled-components";

export const DropdownMain = styled.div`
  width: 100%;
  position: relative;
  margin-top: -2em;

  ul {
    position: absolute;
    max-height: 220px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    background-color: var(--win-bg-color);
    width: 100%;
    padding: 10px;
    top: 2.5em;
    left: 0px;
    z-index: 50;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
    overflow-y: auto;

    .search-section{
      width: 100%;
      height: 40px;
      background: var(--input-field-bg);
      border: 1px solid var(--field-border);
      margin-bottom: 1em;
      padding: 0 1em;
      border-radius: 5px;

      input[type="search"]{
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        height: 100%;
        color: var(--lightgray-medium);
      }
    }

    .no-options-found{
      font-size: 14px;
      font-weight: 500;
      color: var(--primary);
      display: flex;
      justify-content: center;
    }
  }

  li {
    padding: .5em 1em;
    border-radius: 5px;
    color: var(--lightgray-medium);
    list-style: none;
    display: flex;
    gap: 1em;
    align-items: center;

    &.disabled{
      opacity: .8;
    }

    input[type="checkbox"] {
      accent-color: var(--primary);
    }

    .item-text{
        padding-bottom: .3em;
    }
  }
  li:hover {
    background: var(--primary);
    cursor: pointer;
    color: var(--white-constant);
  }

  .text {
    overflow-x: hidden;
  }

  * {
    user-select: none;
  }
`;

export const MultiselectFieldMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .input-field {
      .selected-items {
        user-select: none;
        width: 100%;
        height: 100%;
        display: flex;
        gap: 0.5em;
        align-items: center;
        cursor: pointer;

        .item {
          padding: 0.2em 0.5em;
          background: var(--primary);
          font-size: 13px;
          font-weight: 400;
          color: var(--white-constant);
          border-radius: 2px;
          min-width: 80px;
        }
      }
    }
`;
