export const localOrganizationsData = [
    {
        domains: ['https://eportal.alkawthar.edu.pk', '192.168.100.96', 'https://my.alkawthar.edu.pk', 'http://aku.umsoncloud.com'],
        add_structure_type: 0,
        address1: "Al-Kawthar University, ST-9, Block 7, Gulshan-e-Iqbal, Main University Road, Opposite Safari Park, Karachi",
        address2: "null",
        city: "155",
        cname: "eportal.alkawthar.edu.pk,my.alkawthar.edu.pk",
        contact_heading: "Got a question or issue ?",
        contact_message: "<p><span style=\"color: rgb(39, 42, 58);\">Please contact our Admission Officer at&nbsp;</span><a href=\"http://aku.umsoncloud.com/private/organization/+923126311110\" rel=\"noopener noreferrer\" target=\"_blank\">+923126311110</a><span style=\"color: rgb(39, 42, 58);\">&nbsp;(Call , WhatsApp) or&nbsp;</span><a href=\"mailto:info@alkawthar.edu.pk\" rel=\"noopener noreferrer\" target=\"_blank\">info@alkawthar.edu.pk</a><span style=\"color: rgb(39, 42, 58);\">&nbsp;for&nbsp;assistance.</span></p>",
        country: "null",
        cover_image: "https://api.alkawthar.edu.pk/storage/organizations/7a02ae5a1864a8ecf0bb9bac6f181fba_799261b76e335d173d962d487b93d103_eportal-cover.jpg",
        created_at: "2024-11-21T10:45:49.000000Z",
        dark_logo: "https://api.alkawthar.edu.pk/storage/organizations/7a02ae5a1864a8ecf0bb9bac6f181fba_799261b76e335d173d962d487b93d103_dark.png",
        description: null,
        district: "174",
        domain: "eportal.alkawthar.edu.pk",
        email: "admin@aku.edu.pk",
        fav_icon: "https://api.alkawthar.edu.pk/storage/organizations/7a02ae5a1864a8ecf0bb9bac6f181fba_799261b76e335d173d962d487b93d103_dark.png",
        is_active: 1,
        login_page_image: "https://api.alkawthar.edu.pk/storage/organizations/7a02ae5a1864a8ecf0bb9bac6f181fba_2cd60a442eae32879b89b5138afbd6ed_aku_new_header-changed.jpg",
        logo: "https://api.alkawthar.edu.pk/storage/organizations/7a02ae5a1864a8ecf0bb9bac6f181fba_26848a70e47c7ac0992004eb829c58a3_light.png",
        meta_description: null,
        meta_keywords: null,
        meta_title: null,
        mission: "<h2>To provide a modern education deeply rooted in Islamic values to produce graduates who excel academically and ethically, ready to make positive contributions to society.</h2>",
        more_info: null,
        name: "Al Kawthar University",
        phone: "+923126311110",
        primary_color: "#2d2e83",
        sidebar_dark_logo: "https://api.alkawthar.edu.pk/storage/organizations/f1fed6e8ffe4cc81a65593b94c7472a3_799261b76e335d173d962d487b93d103_dark-logo.png",
        sidebar_light_logo: "https://api.alkawthar.edu.pk/storage/organizations/7a02ae5a1864a8ecf0bb9bac6f181fba_799261b76e335d173d962d487b93d103_light-logo.png",
        state: "3032",
        updated_at: "2024-12-20T19:16:34.000000Z",
        username: "aku",
        vision: "<h1><br></h1><h1><strong>Vision:</strong></h1><h2><br></h2><h2>To design and implement a modern system of higher education inspired by Prophet Muhammad (peace be upon him) and the earliest generation of Muslims.</h2>",
        website: "http://aku.umsoncloud.com",
        zip_code: "0"
    }
]