import { FC, useEffect } from "react";
import { DialogWrapper, SingleDetailDialogMain } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useComponentVisible from "hooks/click-outside";

interface Props{
    label: string;
    value: string;
    setOpen: Function;
}

const SingleDetailDialog: FC<Props> = ({label,value,setOpen}) => {
    const getHref = (): string => {
        if(value.includes('@')){
            return `mailto:${value}`;
        }else{
            return `tel:+92${value}`;
        }
    }

    const handleClickOutside = () => {
       setOpen(false);
    }

    return(
        <SingleDetailDialogMain onClick={() => handleClickOutside()}>
            <DialogWrapper onClick={(e) => e.stopPropagation()}>
                <div className="header">
                    <div className="close-icon" onClick={() => setOpen(false)}>
                        <CloseMediumSvg/>
                    </div>
                </div>
                <div className="content-part">
                    <div className="heading">
                        <span>{label}</span>
                    </div>
                    <a href={getHref()} className="value">
                        <span>{value ?? '-'}</span>
                    </a>
                </div>
            </DialogWrapper>
        </SingleDetailDialogMain>
    )
}

export default SingleDetailDialog;