import { FC, Fragment, useEffect, useState } from "react";
import {
  SuperAdminManagementListingMain,
  SuperAdminManagementListingSection,
  SuperAdminManagementListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TableGreenEyeSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { DropdownMain } from "components/particles/forms/multiselect-dropdown/style";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useOrganization from "../../useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";

const AdminOrganizationListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Organization /", path: siteRoutes.organizationListing },
    { title: "Admin", path: siteRoutes.organizationAdminListing },
  ];
  const columns: string[] = ["Name", "Email", "Status", "Role", "Action"];
  const { getAdmins, deleteAdmin } = useOrganization();
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any[]>([]);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const navigate = useNavigate();
  const { handleSearchChange, handleTableSearch } = useUtils();

  const goToCreateAdmin = () => {
    navigate(siteRoutes.createOrganizationAdmin);
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllAdmins(page + 1, search, per_page);
  };
  const getAllAdmins = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getAdmins(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAllAdmins(pagination.page, search, pagination.per_page);
  }, []);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search
      };
      deleteAdmin(id, setData, queryParams, setPagination);
    }
  };

  const goToEditAdmin = (id: number) => {
    navigate(`${siteRoutes.createOrganizationAdmin}?id=${id}`);
  };

  return (
    <SuperAdminManagementListingMain>
      <SuperAdminManagementListingTop>
        <div className="left">
          <span className="page-heading"> Admins</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="add-new-button">
            <button className="lg-rounded-btn" onClick={goToCreateAdmin}>
              + Create New Admins
            </button>
          </div>
        </div>
      </SuperAdminManagementListingTop>

      <SuperAdminManagementListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => handleSearchChange(e, setSearch, pagination.per_page, getAllAdmins)}
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllAdmins)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                const roleName =
                  item?.roles?.length > 0 ? item.roles[0].name : "-";
                // // console.log(item?.roles?.name);

                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">{item?.name}</div>
                    </td>
                    <td>{item?.email}</td>
                    <td>
                      {item?.status === 1 ? (
                        <span className="status-tile green">Active</span>
                      ) : (
                        <span className="status-tile yellow">Inactive</span>
                      )}
                    </td>

                    <td>{item?.role?.name}</td>

                    <td>
                      <div className="table-action-icons">
                        <div
                          className="action-icon"
                          onClick={() => goToEditAdmin(item?.id)}
                        >
                          <EditTableSvg />
                        </div>

                        <div className="action-icon">
                          <TableGreenEyeSvg />
                        </div>

                        <div
                          className="action-icon"
                          onClick={() => handleDelete(item?.id)}
                        >
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div>
          <DropdownMain />
        </div>
        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </SuperAdminManagementListingSection>
    </SuperAdminManagementListingMain>
  );
};

export default AdminOrganizationListing;
