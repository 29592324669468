import { deleteRequest, getRequest, postRequest, putRequest } from "utils/helpers/common/http-methods";
import { LoginAsUserDTO } from "utils/helpers/models/auth/login-as-user.dto";
import { OrganizationSettingDTO } from "utils/helpers/models/organization/org-setting.dto";
import { UpdateUserCommentsDTO } from "utils/helpers/models/system-administration/update-user-comments.dto";
import { UserManagementDTO } from "utils/helpers/models/system-administration/user-management.dto";

export const SYSTEM_ADMINISTRATION_API = {
    getCurrentOrgSetting: () => getRequest("/admin/get_organization_settings"),
    updateCurrentOrgSetting: (body : OrganizationSettingDTO) => postRequest("/admin/organization_settings/add_update" , body),
    createUser: (body: UserManagementDTO) => postRequest(`admin/user/create`, body),
    updateUser: (id: number, body: any) => postRequest(`admin/user/update/${id}`, body),
    getUsers: (params: any) => getRequest(`admin/users/list`, params),
    getUserById: (id: number) => getRequest(`admin/user/${id}`),
    deleteUserById: (id: number) => deleteRequest(`admin/user/delete/${id}`),
    getRoles: (params: any) => getRequest(`admin/role/list`, params),
    getCitiesByAdmin: () => getRequest(`admin/cities`),
    loginAsUserByAdmin: (body: LoginAsUserDTO) => postRequest(`admin/login-as-user`, body),
    updateUserComments: (body: UpdateUserCommentsDTO, id: number) => putRequest(`admin/user/update-call-status/${id}`, body)
}