export class OrganizationSettingDTO {
  system_email: string = "";
  is_maintenance_on!: number;
  currency: string = "";
  encryption: string = "";
  is_active: string = "";
  maintenance_mode_text?: string = "";
  smtp_host: string = "";
  smtp_port: string = "";
  smtp_user: string = "";
  smtp_password: string = "";
}
