import { FC, Fragment, useState, useEffect } from "react";
import {
  AddTablSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  DownloadTableActionSvg,
  DownloadTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  SurveyTypeListingMain,
  SurveyTypeListingSection,
  SurveyTypeListingTop,
} from "./style";

import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useQEC from "../../useHooks";

const SurveyTypeListing: FC = () => {
  const [search, setSearch] = useState("");

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "Serial No",
    "Name",
    "Conducted",
    "Filled By",
    "RelationShip",
    "Performa",
    "Status",
    "Action",
  ];

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };
  const navigate = useNavigate();
  const goToCreateSurveyType = () => {
    navigate(siteRoutes.createSurveyType);
  };

  const breadcrumbLinks = [
    { title: "QEC /", path: "" },
    { title: "Survey Types", path: siteRoutes.surveyTypeListing },
  ];
  const { getSurveyTypes } = useQEC();
  const [types, setTypes] = useState<any[]>([]);

  useEffect(() => {
    getSurveyTypes(setTypes);
  }, []);
  // console.log(types);
  const baseUrl = "http://api.umsoncloud.com";

  return (
    <SurveyTypeListingMain>
      <SurveyTypeListingTop>
        <div className="left">
          <span className="page-heading">Survey Listing</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn" onClick={goToCreateSurveyType}>
              + Add New Type
            </button>
          </div>
        </div>
      </SurveyTypeListingTop>

      <SurveyTypeListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <Fragment>
                {types?.map((item: any, index: number) => {
                  const fullPath = `${baseUrl}${item.file_path}`;
                  return (
                    <tr>
                      <td>
                        <div className="mw-150" key={index}>
                          {index + 1}
                        </div>
                      </td>
                      <td>
                        <div className="mw-100">{item?.title}</div>
                      </td>
                      <td>
                        <div className="mw-100">Department of Islamic</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.filled_by}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.relation}</div>
                      </td>
                      <td>{item?.performa}</td>

                      <td>
                        {item?.is_active === 1 ? (
                          <span className="status-tile green">Active</span>
                        ) : (
                          <span className="status-tile red">InActive</span>
                        )}
                      </td>
                      <td>
                        <div className="table-action-icons">
                          <div className="action-icon">
                            <a
                              href={fullPath}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <AddTablSvg />
                            </a>
                          </div>
                          <div className="action-icon">
                            <DownloadTableSvg />
                          </div>

                          <div className="action-icon">
                            <EditTableSvg />
                          </div>

                          <div className="action-icon">
                            <DeleteTableSvg />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <Pagination
              onPageChange={(page: any) => console.log(page)}
              {...pagination}
            />
          </Fragment>
        </div>
      </SurveyTypeListingSection>
    </SurveyTypeListingMain>
  );
};

export default SurveyTypeListing;
