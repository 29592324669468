import { MeritFormulaDTO } from "./merit-formula.dto";

export class ProgramLinkToMeritDTO {
  id!: number;
  merit_id: string = "";
  where_class: string = "1";
  seq_no: string = "1";
  template_id: string = "";
  program_id: string = "";
  admission_session_id: string = "";
  merit: MeritFormulaDTO;

  constructor(data: Partial<ProgramLinkToMeritDTO> = {}){
    Object.assign(this,data);
    for(let key in data){
      if(key === 'merit'){
        this.merit = new MeritFormulaDTO(data[key]);
      }else{
        this[key] = data[key];
      }
    }
  }
}
