export class DefaultCreditHoursDTO {
  id!: number;
  title: string = "";
  no_of_courses: number;
  is_class: number;
  no_of_hours: string = "";
  split: string = "";
  planner_id: number;
  is_active: number;

  constructor(data: Partial<DefaultCreditHoursDTO> = {}) {
    Object.assign(this, data);
  }
}
