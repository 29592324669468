import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  DownloadPrimaryTableSvg,
  EditGreenTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  StudenSvg,
  STudyPlanSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  ResultSettingTemplateListingMain,
  ResultSettingTemplateListingSection,
  ResultSettingTemplateListingTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "../../useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

interface PlanofStudiesListingProps { }

const ResultSettingTemplateListing: FC<PlanofStudiesListingProps> = ({ }) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },

    {
      title: "Result Setting Template",
      path: siteRoutes.resultSettingTemplateListing,
    },
  ];
  const columns: string[] = [
    "Sr No.",
    "Title",
    "Total Marks Distribution Criteria",
    "Theory %",
    "Practical %",
    "Theory Passing %",
    "Practical Passing %",
    "Is Generic",
    "Theory Credit Hours",
    "Practical Credit Hours",
    "Action",
  ];
  const navigate = useNavigate();
  const { createQuery } = useUtils();
  const { getResultTemplate, deleteResultTemplate } = useAcademics();
  const [data, setData] = useState<PlanOfStudyDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch } = useUtils();
  const [search, setSearch] = useState<string>("");

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search
      };
      deleteResultTemplate(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllResultTemplate(page + 1, search);
  };
  const getAllResultTemplate = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    getResultTemplate(setData, queryParams, setPagination);
  };

  const goToCreate = () => {
    navigate(siteRoutes.createResultSettingTemplateListing);
  };

  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.createResultSettingTemplateListing}?id=${id}`);
  };

  useEffect(() => {
    getAllResultTemplate(pagination.page, search);
  }, []);
  const [isTableOpened, setIsTableOpened] = useState(false);

  return (
    <ResultSettingTemplateListingMain>
      <ResultSettingTemplateListingTop>
        <div className="left">
          <span className="page-heading">Result Setting Template</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createStudyPlans) && (
            <div className="create-org-btn">
              <button onClick={goToCreate} className="lg-rounded-btn">
                + Add Result Setting Template
              </button>
            </div>
          )}
        </div>
      </ResultSettingTemplateListingTop>

      <ResultSettingTemplateListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getAllResultTemplate)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllResultTemplate)}
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">{index + 1}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.rst_header?.title}</div>
                    </td>
                    <td>{item?.total_marks_distribution}</td>
                    <td>{item?.theory_percentage}</td>
                    <td>{item.practical_percentage}</td>
                    <td>{item?.theory_passing_percentage}</td>
                    <td>{item?.practical_passing_percentage}</td>
                    <td>{item?.is_generic == 1 ? "Yes" : "No"}</td>
                    <td>{item?.credit_hours_theory}</td>
                    <td>{item?.credit_hours_practical}</td>

                    <td>
                      <div className="table-action-icons">
                        <div
                          className="action-icons"
                          onClick={() => goToEdit(item?.id)}
                        >
                          <EditTableSvg />
                        </div>
                        <div
                          className="action-icons"
                          onClick={() => handleDelete(item?.id)}
                        >
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </ResultSettingTemplateListingSection>
    </ResultSettingTemplateListingMain>
  );
};

export default ResultSettingTemplateListing;
