import { FC, Fragment, useEffect, useState } from "react";
import {
  CertificateLevelListingMain,
  CertificateLevelListingSection,
  CertificateLevelListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { DropdownMain } from "components/particles/forms/multiselect-dropdown/style";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useOrganization from "../../useHooks";
import { CertificateLevelDTO } from "utils/helpers/models/organization/certificate-levels.dto";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

interface AdmissionDocumentsMasterListingProps { }

const CertificateLevelListing: FC<
  AdmissionDocumentsMasterListingProps
> = () => {
  const breadcrumbLinks = [
    { title: "Organization /", path: "" },

    {
      title: "Certificate Levels",
      path: siteRoutes.certificateLevelList,
    },
  ];
  const columns: string[] = [
    "Title",
    "Display Order",
    "Short Code",
    "Offered Program",
    "Qualification List",
    "Status",
    "Action",
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { getCertificateLevelsSuper } = useOrganization();
  const [search, setSearch] = useState<string>('');
  const [data, setData] = useState<CertificateLevelDTO[]>([]);
  const { handleSearchChange, handleTableSearch} = useUtils();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllCertificateLevels(page,search,per_page);
  };
  const navigate = useNavigate();

  const goToCreateCertificateLevels = () => {
    navigate(siteRoutes.createCertificateLevels);
  };

  useEffect(() => {
    getAllCertificateLevels(pagination.page, search, pagination.per_page);
  }, []);

  const getAllCertificateLevels = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search
    }

    getCertificateLevelsSuper(setData, queryParams, setPagination);
  }


  return (
    <CertificateLevelListingMain>
      <CertificateLevelListingTop>
        <div className="left">
          <span className="page-heading">Certificate Levels </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="add-new-button">
            <button
              className="lg-rounded-btn"
              onClick={goToCreateCertificateLevels}
            >
              + Add Certificate Level
            </button>
          </div>
        </div>
      </CertificateLevelListingTop>

      <CertificateLevelListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={e => handleSearchChange(e,setSearch,pagination.per_page, getAllCertificateLevels)}
              onKeyUp={e => handleTableSearch(e,pagination.per_page,getAllCertificateLevels)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((level,index) => {
                return (
                  <tr key={index}>
                    <td>{level?.title}</td>
                    <td>{level?.display_order}</td>
                    <td>{level?.short_code ?? "-"}</td>

                    <td>
                      {level?.offer_program === 1 ? (
                        <span className="status-tile green">Yes</span>
                      ) : (
                        <span className="status-tile red">No</span>
                      )}
                    </td>
                    <td>
                      {level?.qualification_list === 1 ? (
                        <span className="status-tile green">Yes</span>
                      ) : (
                        <span className="status-tile red">No</span>
                      )}
                    </td>
                    <td>
                      {level?.is_active === 1 ? (
                        <span className="status-tile green">Active</span>
                      ) : (
                        <span className="status-tile red">In-Active</span>
                      )}
                    </td>

                    <td>
                      <div className="table-action-icons">
                        <div className="action-icon">
                          <EditTableSvg />
                        </div>

                        <div className="action-icon">
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length}/>
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </CertificateLevelListingSection>
    </CertificateLevelListingMain>
  );
};

export default CertificateLevelListing;
