import styled from "styled-components";

export const StudentRegListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const StudentRegListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  table {
    tr {
      td {
        @media screen and (min-width: 2200px) {
          border-bottom: 1px solid var(--lightgray-extralight);
        }
      }
    }
    td {
      &.extra-col {
        display: none;

        &.extra-col-1{
          @media screen and (min-width: 1580px){
            display: table-cell;
          }
        }
        ${() => {
    let str = "";
    for (let item of [1, 2, 3, 4, 5]) {
      str += `&.extra-col-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
    }
    return str;
  }}
        &-6 {
          @media screen and (min-width: 2200px) {
            display: table-cell;
          }
        }
      }

      .rounded-expand-button {
        @media screen and (min-width: 2200px) {
          display: none;
        }
      }
    }

    tr.expanded-row {
      @media screen and (min-width: 2200px) {
        display: none;
      }
      .expanded-content {
        .particular-info {
          ${() => {
    let str = "";
    for (let item of [1, 2, 3, 4, 5]) {
      str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: none;
          }
        }`;
    }
    return str;
  }}
        }
      }
    }
    thead th {
      &.extra-head {
        display: none;
        ${() => {
    let str = "";
    for (let item of [1, 2, 3, 4, 5]) {
      str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
    }
    return str;
  }}

        &-6 {
          @media screen and (min-width: 2200px) {
            display: table-cell;
          }
        }
      }
    }
  }
`;

export const StudentRegListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 690px){
        flex-direction: column;
      }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 690px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 690px){
        width: 100%;
      }
    .buttons{
      display: flex;
      gap: 1em;

      @media screen and (max-width: 690px){
        flex-direction: column;
        width: 100%;

        & *{
          width: 100%;
        }
      }
    }
  }
`;

export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div<{ $showFilterDropdown: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--lightgray-extralight);
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .dropdown-arrow {
    .icon {
      width: 20px;
      height: 28px;
      rotate: ${({ $showFilterDropdown }) =>
    $showFilterDropdown ? "0" : "180deg"};
      transition: all 0.1s ease-in-out;

      path {
        fill: var(--lightgray-medium);
      }

      @media screen and (max-width: 480px) {
        width: 18px;
        height: 24px;
      }
    }
  }
`;
