import { FINANCE_APIS } from "libs/apis/finance.api";
import { HR_MANAGEMENT_APIS } from "libs/apis/hr-management.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { AccSlotInstallmentParticular } from "utils/helpers/models/finance/acc-slot-installment-particular.dto";
import { BankDiscountAdjustmentDTO } from "utils/helpers/models/finance/bank-transaction.dto";
import { TeacherDTO } from "utils/helpers/models/hr-management/teacher.dto";

const useHRManagement = () => {
  const navigate = useNavigate();

  const createTeachers = async (body: TeacherDTO) => {
    const response = await HR_MANAGEMENT_APIS.createTeachers(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };
  const updateBankInfo = async (id: number, body: any) => {
    const response = await FINANCE_APIS.updateBankInfo(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(-1);
    }
  };

  const getBankInfos = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await FINANCE_APIS.getBankInfos(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getBankInfoById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await FINANCE_APIS.getBankInfoById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };
  const getTeachersTitles = async (
    setTeachersTitles: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await HR_MANAGEMENT_APIS.getTeachersTitles(queryParams);
    const data = response?.response;
    setTeachersTitles(data);
  };

  const deleteBankInfo = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await FINANCE_APIS.deleteBankInfoById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getBankInfos(setData, queryParams, setPagination);
    }
  };

  const updateAccSlotInstallmentParticular = async (
    id: number,
    body: any,
    setOpen: Function,
    particularSlots: AccSlotInstallmentParticular[],
    setParticularSlots: Function
  ) => {
    const response = await FINANCE_APIS.updateAccSlotInstallmentParticular(
      id,
      body
    );
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      const index = particularSlots.findIndex((ps: any) => ps.id === body.id);
      particularSlots[index] = body;
      setParticularSlots([...particularSlots]);
      setOpen(false);
    }
  };

  return {
    createTeachers,
    getTeachersTitles,
    updateBankInfo,
    getBankInfos,
    getBankInfoById,
    deleteBankInfo,
  };
};

export default useHRManagement;
