import { FC, Fragment, useState, useEffect } from "react";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";

import {
  AcademicStudentListingMain,
  AcademicStudentListingSection,
  AcademicStudentListingTop,
  ActionButtons,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useOrganization from "containers/private/organization/useHooks";
import { AddProgramDTO as ProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { CertificateLevelDTO as CertificateLevelDTO } from "utils/helpers/models/organization/certificate-levels.dto";
import { OrgStructureDTO as OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useAcademics from "../../useHooks";
import { PlanOfStudyStudentDTO } from "utils/helpers/models/academics/plan-of-study-student";
import useUtils from "hooks/useUtils";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { warningToaster } from "utils/helpers/common/alert-service";

const AddAcademicStudentsToPlanOfStudy: FC = () => {
  const breadcrumbLinks = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },

    {
      title: "Add Students To Plan Of Studies",
      path: window.location.pathname + window.location.search,
    },
  ];
  const columns: string[] = [
    "Student Name",
    "Registration No.",
    "Program",
    "CNIC",
    // "Plan Of Study",
    "Student Status",
    "Campus",
  ];

  const { handleTableSearch, handleSearchChange, getQueryParams } = useUtils();
  const [search, setSearch] = useState<string>("");
  const { getSpecificOrgStructures, getCertificateLevelsAdmin, getPrograms } =
    useOrganization();
  const { createPlanOfStudyStudent } = useAcademics();
  const { getAcademicStudents } = useAcademics();
  const [certificateLevels, setCertificateLevels] = useState<
    CertificateLevelDTO[]
  >([]);
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [faculties, setFaculties] = useState<OrgStructureDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const { pos_id = "" } = getQueryParams();
  const [formData, setFormData] = useState<PlanOfStudyStudentDTO>(
    new PlanOfStudyStudentDTO({ pos_id })
  );
  const [programs, setPrograms] = useState<ProgramDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [filters, setFilters] = useState<any>({
    campus_id: "",
    department_id: "",
    faculty_id: "",
    program_level_id: "",
    program_id: "",
  });
  const [data, setData] = useState<any[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const toggleRowExpand = (index: number) => {
    data[index].isExpanded = !data[index].isExpanded;
    setData([...data]);
  };
  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    filters[name] = value;
    setFilters({ ...filters });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllAcademicStudents(1, search, pagination.per_page, filters);
  };

  const onSelectStudent = (studentId: number) => {
    if (formData.students.includes(studentId)) {
      const index = formData.students.indexOf(studentId);
      formData.students.splice(index, 1);
    } else {
      formData.students.push(studentId);
    }

    setFormData({ ...formData });
  };

  const onSelectAllStudents = () => {
    if (data.every((student: any) => formData.students.includes(student.id))) {
      data.forEach((student: { id: number }) => {
        const index = data.indexOf(student.id);
        formData.students.splice(index, 1);
      });
    } else {
      data.forEach((student: { id: number }) => {
        if (!formData.students.includes(student.id)) {
          formData.students.push(student.id);
        }
      });
    }
    setFormData({ ...formData });
  };

  const areAllStudentsSelected = (): boolean => {
    return (
      (data.length ? true : false) &&
      data.every((s: { id: number }) => formData.students.includes(s.id))
    );
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllAcademicStudents(page + 1, search, per_page, filters);
  };
  const getAllAcademicStudents = (
    page: number,
    search: string,
    per_page: number,
    filters: any
  ) => {
    const filtersToSend: any = {};
    for (let key in filters) {
      if (filters[key]) filtersToSend[key] = filters[key];
    }

    const queryParams = {
      per_page,
      page,
      search,
      pos_assigned: 0,
      ...filtersToSend
    };
    getAcademicStudents(setData, queryParams, setPagination);
  };

  const onReset = () => {
    formData.students = [];
    setFormData({ ...formData });
  };

  const onSubmit = () => {
    if (!formData.students.length) {
      warningToaster("Please select atleast one student");
    } else {
      createPlanOfStudyStudent({
        ...formData,
        student_ids: formData.students.join(),
      });
    }
  };

  useEffect(() => {
    getAllAcademicStudents(
      pagination.page,
      search,
      pagination.per_page,
      filters
    );
    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
    getSpecificOrgStructures(setFaculties, {
      type: OrgStructureTypes.faculty,
      per_page: "All",
    });
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
    getCertificateLevelsAdmin(setCertificateLevels);
    getPrograms(setPrograms);
  }, []);

  return (
    <AcademicStudentListingMain>
      <AcademicStudentListingTop>
        <div className="left">
          <span className="page-heading">Add Students</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AcademicStudentListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Select campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.campus_id}
                      onChange={handleFilterChange}
                      name="campus_id"
                    >
                      <option value="">Select campus</option>
                      {campuses.map((campus, index) => {
                        return (
                          <option value={campus.id} key={index}>
                            {campus.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Faculty</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.faculty_id}
                      onChange={handleFilterChange}
                      name="faculty_id"
                    >
                      <option value="">Select Faculty</option>
                      {faculties.map((faculty, index) => {
                        return (
                          <option value={faculty.id} key={index}>
                            {faculty.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.department_id}
                      onChange={handleFilterChange}
                      name="department_id"
                    >
                      <option value="">Select Department</option>
                      {departments.map((department, index) => {
                        return (
                          <option value={department.id} key={index}>
                            {department.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program Level</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.program_level_id}
                      onChange={handleFilterChange}
                      name="program_level_id"
                    >
                      <option value="">Select Program Level</option>
                      {certificateLevels.map((level, index) => {
                        return (
                          <option value={level.id} key={index}>
                            {level.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program </label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.program_id}
                      onChange={handleFilterChange}
                      name="program_id"
                    >
                      <option value="">Select Program </option>
                      {programs.map((program, index) => {
                        return (
                          <option value={program.id} key={index}>
                            {program.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={() =>
                    getAllAcademicStudents(
                      1,
                      search,
                      pagination.per_page,
                      filters
                    )
                  }
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AcademicStudentListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllAcademicStudents
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllAcademicStudents
                )
              }
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                <th>
                  <div className="thead-field checkbox">
                    <input
                      type="checkbox"
                      checked={areAllStudentsSelected()}
                      onChange={onSelectAllStudents}
                    />
                  </div>
                </th>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((student, index) => (
                <Fragment key={index}>
                  <tr
                    className={`expandable ${student.isExpanded && "opened"}`}
                  >
                    <td>
                      <div className="table-field checkbox">
                        <input
                          type="checkbox"
                          checked={formData.students.includes(student.id)}
                          onChange={() => onSelectStudent(student.id)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="mw-100">
                        {student?.user?.first_name
                          ? `${student?.user?.first_name} ${student?.user?.last_name}`
                          : "-"}
                      </div>
                    </td>
                    <td>
                      <div className="mw-100">{student?.reg_number}</div>
                    </td>
                    <td>
                      <div className="mw-150">
                        {student?.program?.title || "-"}
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{student?.user?.cnic ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">
                        {student?.std_status?.title ?? "-"}
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">
                        {student?.campus?.title ?? "-"}
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>

        <ActionButtons>
          <div className="buttons-wrapper">
            <button className="lg-rounded-btn gray" onClick={onReset}>
              Reset
            </button>
            <button className="lg-rounded-btn" onClick={onSubmit}>
              Add Selected Students
            </button>
          </div>
        </ActionButtons>
      </AcademicStudentListingSection>
    </AcademicStudentListingMain>
  );
};

export default AddAcademicStudentsToPlanOfStudy;
