import { FC, useEffect, useState } from "react";
import {
  CreateAdmissionCampaignTop,
  CreateACFormSection,
  CreateAdmissionCampaignMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { AdmissionCampaignDTO } from "utils/helpers/models/admissions/admission-campaign.dto";
import useOrganization from "containers/private/organization/useHooks";
import useAdmissions from "../../useHooks";
import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import { warningToaster } from "utils/helpers/common/alert-service";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";

interface CreateAdmissionCampaignProps {}

const CreateAdmissionCampaign: FC<CreateAdmissionCampaignProps> = () => {
  const { getQueryParams, compareDateStrings } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks = [
    { title: "Admission /", path: "" },
    {
      title: "Admission Campaigns /",
      path: siteRoutes?.admissionCampaignListing,
    },
    { title: `${params?.id ? 'Update' : ''} Admission Announce`, path: window.location.pathname + window.location.search },
  ];
  
  const [certificateLevels, setCertificateLevels] = useState<any[]>([]);
  const [sessions, setSessions] = useState<any[]>([]);
  const { getCertificateLevelsOfferProgram, getSpecificOrgStructures } = useOrganization();
  const {
    getAdmissionSessions,
    createAdmissionCampaign,
    updateAdmissionCampaign,
    getAdmissionCampaignById,
  } = useAdmissions();

  const [formData, setFormData] = useState<AdmissionCampaignDTO>(new AdmissionCampaignDTO());
  const [campus, setCampus] = useState<OrgStructureDTO[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<AdmissionCampaignDTO>();

  const onSubmit = () => {
    if(compareDateStrings(formData.start_date, formData.end_date)){
      if (params?.id) {
        updateAdmissionCampaign(formData);
      } else {
        createAdmissionCampaign(formData);
      }
    }else{
      warningToaster('Start date should be before the end date');
    }
  };

  const resetForm = () => {
    for (let key in getValues()) {
      setValue(key as keyof AdmissionCampaignDTO, "");
    }
    setFormData({ ...new AdmissionCampaignDTO() });
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
    trigger(name);
  };

  useEffect(() => {
    getCertificateLevelsOfferProgram(setCertificateLevels);
    getAdmissionSessions(setSessions);
    if (params?.id)
      getAdmissionCampaignById(params?.id, formData, setValue, setFormData);
  }, []);

  useEffect(() => {
    getSpecificOrgStructures(setCampus, {type: OrgStructureTypes.campus});
  }, []);

  return (
    <CreateAdmissionCampaignMain>
      <CreateAdmissionCampaignTop>
        <div className="heading">
          <span className="page-heading">{params?.id ? 'Update' : ''} Admission Announce </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateAdmissionCampaignTop>

      <CreateACFormSection
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Enter Title"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.title} />
            </div>
          </div>
          <div className="input-field">
            <label>Session</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("session_id", { required: true })}
                  value={formData.session_id}
                  onChange={handleChange}
                  disabled={params?.id}
                >
                  <option value="">Select session</option>
                  {sessions?.map((session: any, index: number) => {
                    return (
                      <option value={session.id} key={index}>
                        {session.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors?.session_id} />
            </div>
          </div>

          <div className="input-field">
            <label>Campus</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("cat_id", { required: true })}
                  value={formData.cat_id}
                  onChange={handleChange}
                  disabled={params?.id}
                >
                  <option value="">Select Campus</option>
                  {campus?.map((item: any, index: number) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors?.cat_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Program Level</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("certificate_level_id", { required: true })}
                  value={formData.certificate_level_id}
                  onChange={handleChange}
                  disabled={params?.id}
                >
                  <option value="">Select Program Level</option>
                  {certificateLevels?.map((item: any, index: number) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors?.certificate_level_id} />
            </div>
          </div>

          <div className="input-field">
            <label>Start Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("start_date", { required: false })}
                  value={formData.start_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.start_date} />
            </div>
          </div>
          <div className="input-field">
            <label>End Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("end_date", { required: false })}
                  value={formData.end_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.end_date} />
            </div>
          </div>

          <div className="radio-field">
            <label>Active</label>
            <div className="field-wrap">
              <div className="field">
                <label htmlFor="is-active-yes">Yes</label>
                <input
                  type="radio"
                  id="is-active-yes"
                  value={1}
                  {...register("campaign_status", { required: true })}
                  checked={formData.campaign_status == 1}
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <label htmlFor="is-active-no">No</label>
                <input
                  type="radio"
                  id="is-active-no"
                  value={0}
                  {...register("campaign_status", { required: true })}
                  checked={formData.campaign_status == 0}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              {params?.id ? 'Update' : 'Submit'}
            </button>
          </div>
        </div>
      </CreateACFormSection>
    </CreateAdmissionCampaignMain>
  );
};

export default CreateAdmissionCampaign;
