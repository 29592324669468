import { BankInfoDTO } from "./bank-info.dto";
import { VoucherTypeDTO } from "./voucher-type.dto";

export class VoucherTemplateHeaderDTO {
    id!: number;
    title: string = '';
    bank_ids: number[] = [];
    banks: BankInfoDTO[] = [];
    acc_code: string = '';
    table_name: string = '';
    column_name: string = '';
    tbl_pk_field: string = '';
    db_name: string = ''
    voucher_type_id: string = '';
    voucher_type!: VoucherTypeDTO;

    constructor(data: Partial<VoucherTemplateHeaderDTO> = {}) {
        for(let key in data){
            if(key === 'banks'){
                this[key] = Array.isArray(data[key]) ? data[key].map(i => new BankInfoDTO(i)) : [];
            }else{
                this[key] = data[key];
            }
        }
    }
}