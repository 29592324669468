export class FeePlanDTO {
  excess_amount: number;
  id?: number;
  is_active: number;
  organizations_id?: number;
  slug: string = '';
  title: string = '';

  constructor(data: Partial<FeePlanDTO> = {}){
    Object.assign(this,data);
  }
}
