import { FC, useEffect, useState } from "react";
import {
  CreateAdmissionProgramFormSection,
  CreateAdmissionProgramMain,
  CreateAdmissionProgramTop,
  ProgramsSection,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { FormBuildingSvg } from "assets/images/common/svgs";
import useEportal from "../../useHooks";
import { AddAdmissionApplicationDTO } from "utils/helpers/models/e-portal/add-admission-application.dto";
import { confirmationPopup, warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";

const CreateEportalAdmissionsList: FC = () => {
  const breadcrumbLinks = [
    { title: "Eportal /", path: siteRoutes.ePortalDashboard },
    { title: "Application List", path: siteRoutes.createEportalAdmissionList },
  ];
  const [showEligible, setShowEligible] = useState(true);
  const navigate = useNavigate();
  const {
    getAdmissionPrograms,
    submitAdmissionApplication,
    getCertificateLevelsByUser,
  } = useEportal();

  const [formData, setFormData] = useState<AddAdmissionApplicationDTO>(
    new AddAdmissionApplicationDTO()
  );

  const [programs, setPrograms] = useState<any>();
  const [programLevels, setProgramLevels] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<string>("");
  const [offerProgram, setOfferProgram] = useState<number>(1); // it will be set  to 1 by default under the guidance  of backend team

  const queryParams = {
    offer_program: offerProgram,
    per_page: "All",
  };

  useEffect(() => {
    getAdmissionPrograms(setPrograms, { category_id: categoryId });
  }, [categoryId]);

  useEffect(() => {
    getCertificateLevelsByUser(setProgramLevels, queryParams);
  }, []);

  const onSubmit = async () => {
      if (!formData?.programs?.length) {
        warningToaster(warningMessages.programsRequiredMsg);
      } else {
        const result = await confirmationPopup('Are you sure you want to apply?');
        if(result.isConfirmed){
          submitAdmissionApplication(formData);
        }
      }
  };

  const onSelectProgram = (program: any) => {
    const { id } = program;
    if (formData.programs.includes(id)) {
      const index = formData.programs.indexOf(id);
      formData.programs.splice(index,1);
    } else {
      formData.programs.push(id);
    }

    setFormData({ ...formData });
  };

  return (
    <CreateAdmissionProgramMain>
      <CreateAdmissionProgramTop>
        <div className="left">
          <span className="page-heading">Apply for Admissions</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="update-profile-btn">
            <button className="lg-rounded-btn" onClick={() => navigate(siteRoutes.ePortalEditProfile)}>Update Admission Application</button>
          </div>
        </div>
      </CreateAdmissionProgramTop>
      
      <CreateAdmissionProgramFormSection>
        <div className="form-top">
          <p className="form-heading">Choose Program</p>
          <p className="top-para">
            Based on the information you have provided you are eligible only for
            below program(s).
            <span className="info"> More Info</span>
          </p>
        </div>

        <div className="common-fields">
          <div className="input-field">
            <label>Program Levels</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  value={categoryId}
                  onChange={(e) => {
                    setFormData({...formData, programs: []})
                    setCategoryId(e.target.value)
                  }}
                >
                  <option value="">All levels</option>
                  {Array.isArray(programLevels) &&
                    programLevels?.map((item: any) => {
                      return <option value={item?.id}>{item?.title}</option>;
                    })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <ProgramsSection>
          <ul>
            <li
              className={`eligible-item ${showEligible ? "active" : ""}`}
              onClick={() => setShowEligible(true)}
            >
              <p>
                Eligible Programs{" "}
                <span className={`numbers ${showEligible ? "active" : ""}`}>
                  ({programs?.eligible_programs?.length ?? 0})
                </span>
              </p>
            </li>
            <li
              className={`not-eligible-item ${!showEligible ? "active" : ""}`}
              onClick={() => setShowEligible(false)}
            >
              <p>
                Not Eligible Programs{" "}
                <span className={`numbers-not ${!showEligible ? "active" : ""}`}>
                  ({programs?.eligibility_criteria?.length ?? 0})
                </span>
              </p>
            </li>
          </ul>


          {showEligible ? (
            <div className="eligible-programs">
              {programs?.eligible_programs?.map((program: any, index: number) => (
                <div className="program-box" key={index}>
                  <div className="flex">
                    <input
                      type="checkbox"
                      className="cp"
                      checked={formData.programs?.includes(program?.id)}
                      onChange={() => onSelectProgram(program)}
                    />
                    <div>
                      <p className="program-name">{program.title}</p>
                      <p className="department">{program.department}</p>
                    </div>
                  </div>
                  <hr className="line" />
                  <div className="bottom-flex">
                    <div className="">
                      <FormBuildingSvg />
                      <span>{program?.campus_title}</span>
                    </div>
                      <div className="eligible">
                        <p>Eligible Program</p>
                      </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="not-eligible-programs">
              {programs?.eligibility_criteria?.map(
                (item: any, index: number) => (
                  <div className="program-box" key={index}>
                    <div className="flex">
                      <div>
                        <p className="program-name">{item?.program_title}</p>
                        <p className="department">{item?.department}</p>
                      </div>
                    </div>
                    {/* <div className="green-portion">
                      <div
                        className="green-text"
                        dangerouslySetInnerHTML={{
                          __html: item?.eligibility_criteria,
                        }}
                      ></div>
                    </div> */}
                    <div className="red-portion">
                      <p
                        className="red-text"
                        dangerouslySetInnerHTML={{ __html: item?.criteria }}
                      ></p>
                    </div>
                    <hr className="line" />
                    <div className="bottom-flex">
                      <div className="">
                        <FormBuildingSvg />
                        <span>{item?.campus}</span>
                      </div>
                      <ul>
                        <li className="bottom-text">
                          <p>Not Eligible Programs</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </ProgramsSection>

        {showEligible ? <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button" onClick={() => setFormData({ ...formData, programs: [] })}>Reset</button>
            <button className="lg-rounded-btn" type="button" onClick={onSubmit}>
              Apply
            </button>
          </div>
        </div> : ''}
      </CreateAdmissionProgramFormSection>
    </CreateAdmissionProgramMain>
  );
};

export default CreateEportalAdmissionsList;
