import styled from "styled-components";

export const SingleDetailDialogMain = styled.div`
    width: var(--zoom-view-width);
    height: var(--zoom-view-height);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: var(--black-extralight);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DialogWrapper = styled.div`
    min-width: 300px;
    padding: .5em 0;
    box-shadow: var(--content-box-shadow);
    background: var(--white-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;

    .content-part{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .5em;
        padding: 0 1em;

        .heading{
            font-size: var(--font-18);
            font-weight: 700;
            color: var(--black-text);
        }

        .value{
            font-size: var(--font-16);
            font-weight: 500;
            color: var(--primary);
            text-decoration: underline;
            text-decoration-color: var(--primary);
        }
    }

    .header{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
        padding-right: .5em;

        .close-icon{
            svg{
                width: 15px;
                height: 15px;
                cursor: pointer;
            }
        }
    }
`;