import styled from "styled-components";

export const TestCenterListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
  .main-plan-card {
    display: flex;
    gap: 2em;
  }
`;

export const TestCenterListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  .action-menu {
    position: relative;
  }

  @media screen and (max-width: 630px) {
    padding: 1em;
  }
  .tableHeading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--lightgray-extralight);
    color: var(--black-text);
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  table td {
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: 150px;
    }
    .mw-100 {
      min-width: 100px;
    }

    .expanded-content {
      display: flex;
      flex-direction: column;
      gap: 0.7em;

      .particular-info {
        display: flex;
        gap: 1em;
        .title {
          min-width: 170px;
          color: var(--black-text);
          font-size: 15px;
          font-weight: 400;
        }

        .info {
          font-size: 15px;
          font-weight: 400;
          color: var(--lightgray-medium);

          .table-action-icons {
            @media screen and (max-width: 900px) {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
`;

export const TestCenterListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    .menu-bar {
      position: relative;
      .menu-button {
        svg {
          width: 40px;
          height: 40px;

          @media screen and (max-width: 450px) {
            width: 30px;
            height: 30px;
          }

          rect {
            rx: 5;
          }
        }
      }

      .dropdown-menu {
        position: absolute;
        width: 440px;
        top: 42px;
        right: 0;
        border-radius: 8px;
        background: var(--white-color);
        z-index: 20;
        display: flex;
        flex-direction: column;
        padding: 1em 0.5em;

        @media screen and (max-width: 500px) {
          width: 88vw;
        }

        @media screen and (max-width: 450px) {
          top: 32px;
        }

        .particular-menu {
          font-size: 16px;
          font-weight: 400;
          color: var(--black-text);
          user-select: none;
          cursor: pointer;
          border-radius: 8px;
          padding: 0.5em 1em;
          &:hover {
            color: var(--white-constant);
            background: var(--primary);
          }
        }
      }
    }
  }
  .right {
    display: flex;
    gap: 10px;
  }
`;

export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sats-item {
    display: flex;
    flex-direction: column;
  }
  .stats-title {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-text);
    border-bottom: 1px solid var(--lightgray-medium-light);
    width: 100%;
    padding-bottom: 4px;
  }
  .stats-value {
    padding-top: 10px;
    color: var(--black-text);
    font-weight: 400;
    font-size: 15px;
  }

  .table-radio-field {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 4px;
    .radio label {
      padding-right: 5px;
    }
  }
`;

export const CreateTestCenterSection = styled.form`
  width: 100%;
  /* height: 100%; */
  overflow-y: auto;
  background: var(--white-color);
  padding: 2em 0;
  padding: 0px 20px;
  border-radius: 30px;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em 1em 2em;
    border-bottom: 1px solid var(--gray-light);

    @media screen and (max-width: 520px) {
      padding: 0 1em 1em 1em;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    padding: 30px 20px;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;
    padding: 3em 2em 0 2em;
  }
  @media screen and (max-width: 880px) {
    padding: 1em;
  }
  .page-heading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--lightgray-extralight);
    color: var(--black-text);
  }

  .common-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }

  .action-buttons {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-bottom: 2em;

    .buttons {
      display: flex;
      gap: 2.5em;

      @media screen and (max-width: 600px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }

  .form-quotas-main {
    display: flex;
    gap: 1.5rem;
    @media screen and (max-width: 690px) {
      flex-wrap: wrap;
    }
  }
`;

export const CreateTestCenterListMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;
export const CreateTestCenterListTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 490px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;
export const PlanCards = styled.div`
  width: 32%;
  min-height: 12em;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 1em 2em;

  @media screen and (max-width: 1080px) {
    width: 300px;
  }
  @media screen and (max-width: 650px) {
    width: auto;
  }
  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }

  .seats-main {
    display: flex;
    gap: 0.4em;
    margin-bottom: 5px;
    justify-content: space-between;
  }
  .student-number {
    background-color: #128b9b1a;
    color: #128b9b;
    padding: 3px;
    border-radius: 3px;
    height: 30px;
  }
  .total {
    font-weight: 400;
    font-size: 15px;
    color: var(--lightgray-medium);
  }
  .seats-number {
    background-color: #43ac4324;
    color: #43ac43;
    padding: 3px;
    border-radius: 3px;
    height: 30px;
  }
  .remaining-number {
    background-color: #ffbb3826;
    color: #ffbb38;
    padding: 3px;
    border-radius: 3px;
    height: 30px;
  }
  .table-main {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    height: 30px;
  }
  .campus-name {
    text-align: start;
    color: var(--black-text);
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
  }
`;
