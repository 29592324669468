import styled from "styled-components";

export const AcadDashboardMain = styled.div``;

export const AcadDashboardTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }
`;
export const AcadDashboardSection = styled.div`
  .top-cards-container {
    /* margin-top: 2em; */
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  .top-cards {
    background-color: var(--white-color);
    padding: 0.7em;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    align-items: center;
    width: 32%;

    &:hover {
      cursor: pointer;
      background-color: var(--info-field-bg2);
      transition: 0.1s;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .addCardbg {
    background-color: var(--win-bg-color);

    &:hover {
      background-color: var(--bg-color);
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 51px;
    padding: 18px;
  }
  .title {
    font-size: 15px;
    color: var(--black-text);
    font-weight: 600;
  }
  .title-value {
    color: var(--lightgray-medium);
    font-size: 25px;
    font-weight: 400;
    margin-right: 22px;
  }
  .title-value2 {
    color: var(--lightgray-medium);
    font-size: 13px;
    font-weight: 400;
  }
  .card-title-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .bottom-title {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .line-graph-container {
    margin-top: 1em;
    display: flex;
    gap: 3em;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .sub-heading {
    font-size: 30px;
    color: var(--lightgray-light);
    font-weight: 400;
  }
  .primary {
    color: var(--primary);
  }
`;
export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 1em;
  margin-top: 1em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div<{ $isFilterOpen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--lightgray-extralight);
  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $isFilterOpen }) => ($isFilterOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;

export const EnrolledSection = styled.div<{ $EnStudentOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  .link-heading {
    font-size: var(--font-24);
    font-weight: 700;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-extralight);
    display: flex;
    justify-content: space-between;
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    width: 32%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--white-constant);
  }
  .total-amount {
    color: var(--white-constant);
    font-weight: 500;
  }
  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $EnStudentOpen }) => ($EnStudentOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const NotEnrolledSection = styled.div<{ $notStudentOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  .link-heading {
    font-size: var(--font-24);
    font-weight: 700;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-extralight);
    display: flex;
    justify-content: space-between;
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    width: 32%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--white-constant);
  }
  .total-amount {
    color: var(--white-constant);
    font-weight: 500;
  }
  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $notStudentOpen }) => ($notStudentOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;

export const CourseSection = styled.div <{ $isCAOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  .link-heading {
    font-size: var(--font-24);
    font-weight: 700;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-extralight);
    display: flex;
    justify-content: space-between;
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 1.2em;
    width: 32%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--white-constant);
  }
  .total-amount {
    color: var(--white-constant);
    font-weight: 500;
  }
  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $isCAOpen }) => ($isCAOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const AnomiliesSection = styled.div<{ $isSAOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  .link-heading {
    font-size: var(--font-24);
    font-weight: 700;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-extralight);
    display: flex;
    justify-content: space-between;
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 1.2em;
    width: 19%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--white-constant);
  }
  .total-amount {
    color: var(--white-constant);
    font-weight: 500;
  }
  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $isSAOpen }) => ($isSAOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const AcademicDataSection = styled.div<{ $isAcadDataOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  .link-heading {
    font-size: var(--font-24);
    font-weight: 700;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-extralight);
    display: flex;
    justify-content: space-between;
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 1.2em;
    width: 32%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 32%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--white-constant);
  }
  .total-amount {
    color: var(--white-constant);
    font-weight: 500;
  }
  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $isAcadDataOpen }) => ($isAcadDataOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const EnrolledCourses = styled.div <{ $isCoursesOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  .link-heading {
    font-size: var(--font-24);
    font-weight: 700;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-extralight);
    display: flex;
    justify-content: space-between;
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 1.2em;
    width: 15%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }
  .link-card2 {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 1.2em;
    width: 30%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--white-constant);
  }
  .total-amount {
    color: var(--white-constant);
    font-weight: 500;
  }
  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $isCoursesOpen }) => ($isCoursesOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;

export const SemesterData = styled.div<{$isSRDOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  .link-heading {
    font-size: var(--font-24);
    font-weight: 700;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-extralight);
    display: flex;
    justify-content: space-between;
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    gap: 1.2em;
    width: 24%;
    height: 140px;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--white-constant);
  }
  .total-amount {
    color: var(--white-constant);
    font-weight: 500;
  }
  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $isSRDOpen }) => ($isSRDOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  
`;
