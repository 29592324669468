import { FC, Fragment, useEffect, useState } from "react";
import { Main, ListingSection, CreditHeourTop } from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  // ExcelSvg,
  // PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useTimeTable from "containers/private/time-table/UseHooks";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { DefaultCreditHoursDTO } from "utils/helpers/models/time-table/default-credit-hours.dto";
import { useDebounce } from "hooks/useDebounce";

interface defaultCreditHoursListingProps {}

const DefaultCreditHoursListing: FC<defaultCreditHoursListingProps> = () => {
  const breadcrumbLinks = [
    {
      title: "Time Table Planner /",
      path: siteRoutes.timeTablePlannerDashboard,
    },
    {
      title: "Default Credit Hours Management ",
      path: siteRoutes.defaultCreditHoursListing,
    },
  ];

  const columns = ["Credit Hours", "Class", "Duration", "Split"];

  const { hasAccess } = useStore();
  const { getDefaultCreditHoursList, updateDefaultCreditHoursList } =
    useTimeTable();

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { handleSearchChange, handleTableSearch } = useUtils();
  const [debouncedData, setDebouncedData] =
    useState<DefaultCreditHoursDTO>(null);

  const [data, setData] = useState<DefaultCreditHoursDTO[]>([]);
  const debouncedValue = useDebounce(debouncedData, 500);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<DefaultCreditHoursDTO>();

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getDefaultTimeTableCR();
  };

  const getDefaultTimeTableCR = () => {
    const queryParams = {
      per_page: pagination.per_page,
      page: pagination.page,
      search,
    };
    getDefaultCreditHoursList(setData, queryParams, setPagination);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    crh: DefaultCreditHoursDTO,
    index: number,
    field: keyof DefaultCreditHoursDTO
  ) => {
    const updatedValue = { ...crh, [field]: e.target.value };
    const updatedData = [...data];
    updatedData[index] = updatedValue;
    setData(updatedData);
    setDebouncedData(updatedValue);
  };
  useEffect(() => {
    if (debouncedValue) {
      const { id } = debouncedValue;
      updateDefaultCreditHoursList(id, debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    getDefaultTimeTableCR();
  }, []);

  return (
    <Main>
      <CreditHeourTop>
        <div className="left">
          <span className="page-heading">Default Credit Hours Lisitng</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreditHeourTop>

      <ListingSection className="content-radius-shadow">
        <div className="list-detail">
          <div className="page-sub-heading">
            <span>Default Credit Hours Lisitng</span>
          </div>
        </div>
        <div className="list-header">
          <div className="table-data-export-buttons">
            {/* {hasAccess(sitePermissions.downloadStructureTypesListPdf) && ( */}
            {/* )} */}
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            {/* {hasAccess(sitePermissions.downloadStructureTypesListExcel) && ( */}
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
            {/* )} */}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getDefaultTimeTableCR
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getDefaultTimeTableCR)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((crh, index: number) => {
                const { is_class, no_of_hours, split } = crh;
                return (
                  <tr key={index}>
                    <td>{crh?.no_of_hours ?? "--"}</td>
                    <td>
                      {crh?.is_class === 1 ? (
                        <span className="status-tile">Class</span>
                      ) : (
                        <span className="status-tile red">Lab</span>
                      )}
                    </td>

                    <td>
                      <div className="input-field">
                        <div className="field-wrap">
                          <div className="field">
                            <input
                              type="number"
                              {...register("no_of_hours", { required: true })}
                              value={no_of_hours}
                              onChange={(e) =>
                                handleInputChange(e, crh, index, "no_of_hours")
                              }
                              disabled={
                                !hasAccess(
                                  sitePermissions.defaultCreditHoursUpadate
                                )
                              }
                            />
                          </div>
                          <FormErrorMessage error={errors.title} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="input-field">
                        <div className="field-wrap">
                          <div className="field">
                            <input
                              type="number"
                              {...register("split", { required: true })}
                              value={crh?.split}
                              onChange={(e) =>
                                handleInputChange(e, crh, index, "split")
                              }
                              disabled={
                                !hasAccess(
                                  sitePermissions.defaultCreditHoursUpadate
                                )
                              }
                            />
                          </div>
                          <FormErrorMessage error={errors.split} />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data?.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </ListingSection>
    </Main>
  );
};

export default DefaultCreditHoursListing;
