export class MasterBookDTO{
    additional_remarks: string = '';
    id!: number;
    app_code: string = '';
    app_no: string = '';
    challan_no: string = '';
    cnic: string = '';
    collection_date: string = '';
    created_at: string = '';
    due_date: string = '';
    installment_no: number;
    invalid_file: number = 0;
    is_installment: number = 0;
    remarks: string = '';
    semester: number = 1;
    status: Status = 'Pending';
    total_amount: number = 0;
    trans_id: number;
    upload_file_path: string = '';
    verification_bank: string | null = null;
    voucher_no: string = '';
    year: number;

    constructor(data: Partial<MasterBookDTO> = {}){
        Object.assign(this,data);
    }
}

type Status = 'Pending' | 'Paid';