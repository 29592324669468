import CreateAsset from "containers/private/organization/assets-management/create"
import AssetsListing from "containers/private/organization/assets-management/listing"
import CreateBuilding from "containers/private/organization/building-management/create"
import BuildingsListing from "containers/private/organization/building-management/listing"
import CreateBuildingStructure from "containers/private/organization/building-strucutre/create"
import BuildingStrucutreTypeListing from "containers/private/organization/building-strucutre/listing";

import useUtils from "hooks/useUtils"
import { Route, Routes } from "react-router-dom"
import { siteRoutes } from "utils/helpers/enums/routes.enum"

const InfrastructureAndAssetsRoutes = () => {
    const { getPathToSetRoute } = useUtils();
    return (
        <Routes>
            <Route
                path={getPathToSetRoute(siteRoutes.assetsListing, true)}
                Component={AssetsListing}
            />
            <Route
                path={getPathToSetRoute(siteRoutes.createAsset, true)}
                Component={CreateAsset}
            />
            <Route
                path={getPathToSetRoute(siteRoutes.buildingListing, true)}
                Component={BuildingsListing}
            />
            <Route
                path={getPathToSetRoute(siteRoutes.createBuilding, true)}
                Component={CreateBuilding}
            />
            <Route
                path={getPathToSetRoute(siteRoutes.createBuildingStructure, true)}
                Component={CreateBuildingStructure}
            />
            <Route
                path={getPathToSetRoute(siteRoutes.buildingStructureListing, true)}
                Component={BuildingStrucutreTypeListing}
            />
        </Routes>
    )
}

export default InfrastructureAndAssetsRoutes;