import { FC, Fragment, useEffect, useState } from "react";
import { AddAllocateTeacherMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg, ExcelSvg, PdfSvg, SearchFieldSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import { SessionEnrollmentDTO } from "utils/helpers/models/academics/session-enrollment.dto";
import { successToaster, warningToaster } from "utils/helpers/common/alert-service";
import { StudentDTO } from "utils/helpers/models/user/student.dto";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useAcademics from "containers/private/academics/useHooks";
import { AcademicStatusDTO } from "utils/helpers/models/academics/academic-status.dto";

interface ManageEnrollmentsProps {
  setOpen: Function;
  courses: number[];
  type: string;
}

const ManageEnrollments: FC<ManageEnrollmentsProps> = ({ setOpen, courses: course_ids, type }) => {
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<SessionEnrollmentDTO>();

  const { isFileOfExcelType } = useUtils();
  const [academicStatuses, setAcademicStatuses] = useState<AcademicStatusDTO[]>([]);
  const [students, setStudents] = useState<StudentDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const { getSpecificOrgStructures, getPrograms } = useOrganization();
  const { getStudentsSpecificData, enrollStudentInSession, studentsBulkEnrollmentByFile, getAcademicStatuses } = useAcademics();
  const [formData, setFormData] = useState<SessionEnrollmentDTO>(new SessionEnrollmentDTO({ course_ids, student_ids: [] }));

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };
  const columns: string[] = [
    "Reg No.",
    "First Name",
    "Last Name",
    "CNIC"
  ];

  const handleUploadFile = async (event: any) => {
    const { files } = event.target;
    const file = files![0];
    event.target.value = '';
    if (isFileOfExcelType(file)) {
      formData.student_file = file;
      setFormData({ ...formData });
    } else {
      warningToaster('File must be of excel type');
    }
  }

  useEffect(() => {
    getAcademicStatuses(setAcademicStatuses);
    if (type !== 'upload') {
      getSpecificOrgStructures(setDepartments, { type: OrgStructureTypes.department, per_page: 'All' });
      getPrograms(setPrograms);
    }
  }, []);

  const handleSelectStudent = (id: number) => {
    if (formData.student_ids.includes(id)) {
      const index = formData.student_ids.indexOf(id);
      formData.student_ids.splice(index, 1);
    } else {
      formData.student_ids.push(id);
    }

    setFormData({ ...formData });
  }

  const onSubmit = () => {
    const { course_ids, student_ids } = formData;
    const body: SessionEnrollmentDTO<string> = {
      ...formData,
      course_ids: course_ids.join(','),
      student_ids: student_ids.join(',')
    }
    const form_data = new FormData();
    Object.entries(body).map(([key, value]) => form_data.append(key, value));
    if (type === 'upload') {
      studentsBulkEnrollmentByFile(form_data, setOpen);
    } else {
      if (formData.student_ids.length) {
        enrollStudentInSession(body, setOpen);
      } else {
        warningToaster('Please select atleast one student');
      }
    }
  };

  useEffect(() => {
    const { department_id, program_id } = formData;
    if (department_id && program_id) {
      getStudentsSpecificData(setStudents, { department_id, program_id });
    }
  }, [formData.department_id, formData.program_id]);

  const areAllStudentsSelected = (): boolean => {
    return students.every(s => formData.student_ids.includes(s.id));
  }

  const handleSelectAllStudent = () => {
    const allSelected = areAllStudentsSelected();
    students.forEach((student) => {
      if (allSelected) {
        const index = formData.student_ids.indexOf(student.id);
        formData.student_ids.splice(index, 1);
      } else {
        if (!formData.student_ids.includes(student.id)) {
          formData.student_ids.push(student.id);
        }
      }
    })

    setFormData({ ...formData });
  }

  return (
    <AddAllocateTeacherMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Manage Enrollments</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {type === 'upload' ?
              <div className="top-section">
                <div className="common-fields">
                  <div className="input-field">
                    <label>Upolad File</label>
                    <div className="upload-container">
                      <div className="upload-section">
                        <label htmlFor="file-upload" className="upload-label">
                          Choose a file
                        </label>
                        <input
                          type="file"
                          id="file-upload"
                          className="file-input"
                          onChange={handleUploadFile}
                        />
                      </div>
                      <div className="file-details-section" id="file-details">
                        <p className="file-placeholder">
                          {formData?.student_file?.name ?? 'No file choosen'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label htmlFor="">Enrollment Status</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select {...register('enrollment_status', { required: true })} value={formData.enrollment_status} onChange={handleChange}>
                          <option value="">Select one</option>
                          {academicStatuses.map((status, index) => {
                            return <option key={index} value={status.id}>{status.title}</option>
                          })}
                        </select>
                      </div>
                      <FormErrorMessage error={errors.enrollment_status} />
                    </div>
                  </div>
                </div>
              </div>
              : <Fragment>
                <div className="top-section">
                  <span className="heading">Select Manually</span>
                  <div className="common-fields">
                    <div className="input-field">
                      <label htmlFor="">Department</label>
                      <div className="field-wrap">
                        <div className="field">
                          <select
                            {...register("department_id", { required: true })}
                            value={formData.department_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Department</option>
                            {departments.map((dept, index) => {
                              return <option value={dept.id} key={index}>{dept.title}</option>
                            })}
                          </select>
                        </div>
                        <FormErrorMessage error={errors.department_id} />
                      </div>
                    </div>
                    <div className="input-field">
                      <label htmlFor="">Program</label>
                      <div className="field-wrap">
                        <div className="field">
                          <select
                            {...register("program_id", { required: true })}
                            value={formData.program_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Program</option>
                            {programs.map((program, index) => {
                              return <option value={program.id} key={index}>{program.title}</option>
                            })}
                          </select>
                        </div>
                        <FormErrorMessage error={errors.program_id} />
                      </div>
                    </div>
                    <div className="input-field">
                      <label htmlFor="">Enrollment Status</label>
                      <div className="field-wrap">
                        <div className="field">
                          <select {...register('enrollment_status', { required: true })} value={formData.enrollment_status} onChange={handleChange}>
                            <option value="">Select one</option>
                            {academicStatuses.map((status, index) => {
                              return <option key={index} value={status.id}>{status.title}</option>
                            })}
                          </select>
                        </div>
                        <FormErrorMessage error={errors.enrollment_status} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="list-main">

                  <div className="data-table">
                    <table className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          <th>
                            {students.length && <div className="table-checkbox-field">
                              <input type="checkbox" onChange={handleSelectAllStudent} checked={areAllStudentsSelected()} readOnly />
                            </div>}
                          </th>
                          {columns.map((column: string, index: number) => {
                            return <th key={index}>{column}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {students.map((student, index) => (
                          <tr key={index}>
                            <td>
                              <div className="table-checkbox-field">
                                <input type="checkbox" checked={formData.student_ids.includes(student.id)} onChange={() => handleSelectStudent(student.id)} readOnly />
                              </div>
                            </td>
                            <td>
                              <div className="mw-150">
                                {student.reg_number}
                              </div>
                            </td>
                            <td>{student.first_name}</td>
                            <td>{student.last_name}</td>
                            <td>{student.cnic}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <DataNotFound show={!isLoading && !students.length} />
                </div>
              </Fragment>}
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>

                <button className="lg-rounded-btn" type="submit">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddAllocateTeacherMain>
  );
};

export default ManageEnrollments;
