import styled from "styled-components";

export const ContactContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--black-text);
  border: 0.1px solid var(--field-border);
  border-radius: 5px;

  .contact_heading {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    color: var(--black-text);
    background: var(--info-field-bg2);
    padding: 0.5em 1em;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid var(--modal-grey);
    @media screen and (min-width: 1280px) {
      background: var(--lightgray-medium-light);
    }
    @media screen and (max-width: 610px) {
      font-size: 14px;
    }
  }
  .contact_text {
    width: 100%;
    padding: 1em 1em .5em 1em;
    color: var(--black-text);

    a{
      text-decoration: none;
      color: var(--primary);
    }
    @media screen and (max-width: 610px) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 630px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1279px) {
    .contact_bottom {
      display: none;
    }
    background: var(--white-bg);
    border: 0.1px solid var(--gray-medium);
  }
`;
export const StudentLoginMain = styled.div`
  display: flex;
  align-items: space-between;
  overflow-y: auto;
  padding: 2em 1em;
  gap: 1em;
  height: 100vh;
  @media screen and (max-width: 450px) {
    padding: 2em 0;
  }

  @media screen and (max-width: 1279px) {
    height: 100vh;
  }
`;
export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
  height: inherit;
  .sideImg {
    width: 100%;
    height: 95%;
    float: left;
    object-fit: cover;
    float: right;
  }

  .sideImg img {
    width: 100%;
    height: 100%;
    border-radius: 1em;
  }

  @media screen and (max-width: 1279px) {
    display: none;
    height: 100vh;
  }
`;

export const LoginForm = styled.form`
  width: max(45%, 480px);
  padding: 2em;
  display: grid;
  /* flex-direction: column; */
  gap: 1.5em;
  margin: auto;
  border-radius: 12px !important;

  @media screen and (max-width: 1279px) {
    border-radius: 12px !important;
    margin: auto;
  }

  @media screen and (max-width: 630px) {
    padding: 2em 1em 1em 1em;
    gap: 1em;
  }

  @media screen and (min-width: 1280px) {
    .bottom_contact_container {
      display: none;
    }
    height: inherit;
  }
  @media screen and (max-width: 490px) {
    margin: 0 1em;
    width: 100%;
    height: fit-content;
  }
  .fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .input-field {
    margin-bottom: 10px;
  }

  .radio-field .field-wrap {
    @media screen and (max-width: 490px) {
      flex-direction: column;
      gap: 1em;

      .field {
        justify-content: center;
        height: unset;
      }
    }
  }

  .form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .heading {
      font-size: 18px;
      font-weight: 400;
      color: var(--primary);
      @media screen and (max-width: 610px) {
        font-size: 20px;
      }
    }
    .sub-heading {
      font-size: 14px;
      font-weight: 400;
      color: var(--lightgray-light);

      @media screen and (max-width: 610px) {
        font-size: 12px;
      }
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .field-wrap {
    justify-content: center;

    .field {
      @media screen and (max-width: 490px) {
        height: 45px;
      }
    }
  }

  .bottom-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;

    @media screen and (max-width: 610px) {
      font-size: 12px;
    }

    .remember-me {
      display: flex;
      gap: 0.5em;
      align-items: center;
      padding-left: 0.5em;

      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        accent-color: var(--primary);
      }

      label {
        color: var(--black-text);
      }
    }

    span {
      color: var(--black-text);
    }
  }

  .submit-button {
    width: 100%;
    padding-top: 1em;

    button {
      width: 100%;
      height: 50px;
      color: white;
      font-size: 20px;
      background: var(--primary);
      border-radius: 5px;
      font-weight: 600;

      @media screen and (max-width: 490px) {
        height: 45px;
      }

      @media screen and (max-width: 610px) {
        font-size: 16px;
      }

      .loader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .password-icon {
    width: 14px;
    height: 13px;
  }

  .bottom-text {
    width: 100%;
    display: flex;
    /* margin-bottom: 20px; */
    gap: 0.5em;
    justify-content: center;
    color: var(--black-text);
    @media screen and (max-width: 610px) {
      font-size: 12px;
    }
  }
  .bold {
    font-weight: bold;
  }
`;

export const SiteLogo = styled.div`
  width: 100%;
  max-width: 23rem;
  margin: 0 auto;
  height: auto;
  max-height: 10rem;

  @media screen and (max-width: 430px) {
    max-width: 15rem;
    /* max-height: 0.8em; */
    max-height: 8rem;
    overflow: hidden;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: inherit;
  }
`;
