export class TestTypeDTO{
    id!: number;
    title: string = '';
    fee !: number;
    total_marks !: number;
    obt_marks !: number;
    is_active !: number;
    short_code: string = '';
    display_order: number = 1;
    slug: string;
    organizations_id: number;

    constructor(data: Partial<TestTypeDTO> = {}){
        Object.assign(this,data);
    }
}