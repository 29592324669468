import { FC, useEffect, useState } from "react";
import {
  CreateLinkMeritListTop,
  CreateLinkMeritListSection,
  CreateLinkMeritListMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import { ProgramLinkToMeritDTO } from "utils/helpers/models/admissions/program-link-to-merit.dto";
import useAdmissions from "containers/private/admissions/useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { MeritFormulaDTO } from "utils/helpers/models/admissions/merit-formula.dto";
import { EligibilityTemplateHeaderDTO } from "utils/helpers/models/admissions/eligibility-template-header.dto";

const CreateAddLinkMeritList: FC = () => {
  const { getQueryParams  } = useUtils();
  const { admission_session_id = null, level_id = null, program_id = null, id = null } = getQueryParams() || {};
  const breadcrumbLinks = [
    { title: "Admission /", path: siteRoutes.admissionCampaignListing },
    {
      title: "Admission Campaigns /",
      path: siteRoutes.admissionCampaignListing,
    },
    { title: "Programs /", path: (admission_session_id && level_id)
      ? `${siteRoutes.campaignProgramsListing}?admission_session_id=${admission_session_id}&level_id=${level_id}`
      : siteRoutes.programListing},
    {
      title: "Link Merit /",
      path: (admission_session_id && level_id)
      ? `${siteRoutes.programLinkMeritList}?admission_session_id=${admission_session_id}&level_id=${level_id}`
      : siteRoutes.programLinkMeritList},
    {
      title: `${id ? 'Update' : 'Add'} Link Merit`,
      path: siteRoutes.createProgramLinkMeritList + window.location.search,
    },
  ];

  const [formData, setFormData] = useState<ProgramLinkToMeritDTO>(new ProgramLinkToMeritDTO({program_id,admission_session_id}));
  const [templateHeaders, setTemplateHeaders] = useState<EligibilityTemplateHeaderDTO[]>([]);
  const [meritFormulas, setMeritFormulas] = useState<MeritFormulaDTO[]>([]);
  const {
    getTemplateHeaders,
    getMeritFormulas,
    createProgramLinkMerit,
    updateProgramLinkMerit,
    getProgramLinkMeritById,
  } = useAdmissions();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<ProgramLinkToMeritDTO>();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    getTemplateHeaders(setTemplateHeaders);
    getMeritFormulas(setMeritFormulas);
  }, []);

  const onSubmit = (
    data: ProgramLinkToMeritDTO,
    addMore: boolean = false
  ) => {
    if (id) {
      updateProgramLinkMerit(id, formData);
    } else {
      createProgramLinkMerit(formData, addMore, resetForm);
    }
  };

  const resetForm = () => {
    for (let key in formData) {
      setValue(key as keyof ProgramLinkToMeritDTO, "");
    }

    setFormData({ ...new ProgramLinkToMeritDTO() });
  };

  useEffect(() => {
    if (id)
      getProgramLinkMeritById(id, formData, setValue, setFormData);
  }, []);

  return (
    <CreateLinkMeritListMain>
      <CreateLinkMeritListTop>
        <div className="heading">
          <span className="page-heading">
            {id ? "Update" : "Add"} Link
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateLinkMeritListTop>

      <CreateLinkMeritListSection className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Eligibility Template</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("template_id", { required: true })}
                  value={formData.template_id}
                  onChange={handleChange}
                >
                  <option value="">Select Eligibility Template</option>
                  {templateHeaders.map((item: any, index: number) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.template_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Merit</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("merit_id", { required: true })}
                  value={formData.merit_id}
                  onChange={handleChange}
                >
                  <option value="">Select Merit</option>
                  {meritFormulas.map((item: any, index: number) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.merit_id} />
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {!id ? (
              <button
                className="lg-rounded-btn black"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Save & Add more
              </button>
            ) : (
              ""
            )}
            <button
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {id ? "Update" : "Save"} & Exit
            </button>
          </div>
        </div>
      </CreateLinkMeritListSection>
    </CreateLinkMeritListMain>
  );
};

export default CreateAddLinkMeritList;
