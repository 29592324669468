import { FC, Fragment, useState, useEffect } from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  LibraryPublishersListingMain,
  LibraryPublishersListingSection,
  LibraryPublishersListingTop,
} from "./style";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import CreateCourseRoom from "../components/create-allocate-course-room";
import { confirmationPopup } from "utils/helpers/common/alert-service";

const CourseRoomsListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Academics /", path: "" },
    { title: "Academic Sessions / ", path: siteRoutes.academicSessionListing },
    { title: "Manage Academic Sessions / ", path: siteRoutes.academicSessionListing },
    { title: "Course Rooms ", path: siteRoutes.allocateCourseRoomListing },
  ];
  const [openAddCourseRoomModal, setOpenAddCourseRoomModal] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { getQueryParams } = useUtils();
  const { course_title } = getQueryParams();
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "Room No",
    "Room Name",
    "Action",
  ];

  const goToCreateRoom = () => {
    setOpenAddCourseRoomModal(true);
  };

  useEffect(() => {
    getAllCourseAllocatedRooms(pagination.page, search, pagination.per_page);
  }, []);

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllCourseAllocatedRooms(page + 1, search, per_page);
  };
  const getAllCourseAllocatedRooms = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    // getCourseAllocatedRooms(setData, queryParams, setPagination);
  };

  return (
    <LibraryPublishersListingMain>
      <LibraryPublishersListingTop>
        <div className="left">
          <span className="page-heading">Allocate Course Rooms</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div
            className="download-list-button"
          >
            <button className="lg-rounded-btn" onClick={goToCreateRoom}>+ Add Room</button>
          </div>
        </div>
      </LibraryPublishersListingTop>

      <LibraryPublishersListingSection className="content-radius-shadow">
        <div className="page-heading">{course_title}</div>
        <div className="list-header">

          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">

          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <Fragment>
                <tr>
                  <td>
                    <div className="mw-150">CPEN-1522</div>
                  </td>
                  <td>
                    <div className="mw-150">Class Room</div>
                  </td>
                  <td>
                    <div className="mw-150">
                      <div className="table-action-icons">
                        <div className="action-icon">
                          <EditTableSvg />
                        </div>

                        <div className="action-icon">
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </Fragment>
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <Pagination
              onPageChange={onPageChange}
              {...pagination}
            />

            {openAddCourseRoomModal && (
              <CreateCourseRoom setOpen={setOpenAddCourseRoomModal} />
            )}

          </Fragment>
        </div>
      </LibraryPublishersListingSection>
    </LibraryPublishersListingMain>
  );
};

export default CourseRoomsListing;
