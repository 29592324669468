import { FC, useEffect, useState } from "react";
import { RegisterForm, StudentRegisterMain, RegisterPageStudent } from "./style";
import {
  CyfyLogoDark,
  CyfyLogoSvg,
  EyeHidingSvg,
  EyeSvg,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { UserRegisterDTO } from "utils/helpers/models/auth/user-register.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useEportalAuth from "../useHooks";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import InputMask from "react-input-mask";
import { SiteLogo } from "../login/style";

interface StudentRegisterProps { }
const EportalRegister: FC<StudentRegisterProps> = ({ }) => {

  const AboutUsObj = [
    { item: "LinkedIn" },
    { item: "Facebook" },
    { item: "Instagram" },
    { item: "Google Search" },
    { item: "Friends and Family" },
    { item: "Bill Board " },
    { item: "University Student/Teacher" },

  ]

  const [selectedType, setSelectedType] = useState(true);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const navigate = useNavigate();
  const { userRegister } = useEportalAuth();
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors, touchedFields },
    watch,
  } = useForm<UserRegisterDTO>({
    defaultValues: new UserRegisterDTO(),
    criteriaMode: "all",
  });
  const { addHyphensToCnic } = useUtils();
  const { isDarkTheme, isLoading, organization } = useSelector(
    (state: any) => state.sharedReducer
  );

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      city: "3",
    };
    if (!selectedType) {
      delete payload.cnic;
      userRegister(payload);
    } else {
      delete payload.passport_no;
      const cnicWithHyphens = addHyphensToCnic(data.cnic);
      if (cnicWithHyphens) {
        payload.cnic = cnicWithHyphens;
        userRegister(payload);
      }
    }
  };

  const password = watch("password");
  const password_confirmation = watch("password_confirmation");

  useEffect(() => {
    trigger(["password", "password_confirmation"]);
  }, []);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
  };

  const handleCnicPasport = () => {
    if (selectedType) {
      setValue("cnic", "");
      setSelectedType(false);
    } else {
      setValue("passport_no", "");
      setSelectedType(true);
    }
  };

  return (
    <StudentRegisterMain className="p-custom-scrollbar-8">
      <RegisterForm
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >

        <SiteLogo>
          <img
            src={organization?.sidebar_dark_logo}
            alt="logo"
          />
        </SiteLogo>

        <div className="heading-wrapper">
          <span className="main-heading">Eportal Registeration</span>
          <p className="tag-line">
            (We need some details to create your Account)
          </p>
        </div>
        <div>
          <div className="common-fields">
            <div className="input-field">
              {/* <label>First Name</label> */}
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="text"
                    {...register("first_name", { required: true })}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                </div>
                <FormErrorMessage
                  error={errors.first_name}
                  touched={touchedFields.first_name}
                />
              </div>
            </div>
            <div className="input-field">
              {/* <label>Last Name</label> */}
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="text"
                    {...register("last_name", { required: true })}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                </div>
                <FormErrorMessage error={errors.last_name} />
              </div>
            </div>
            <div className="input-field">
              {/* <label>Last Name</label> */}
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="email"
                    {...register("email", { required: true })}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </div>
                <FormErrorMessage error={errors.email} />
              </div>
            </div>

            <div className="input-field">
              {/* <label>Gender</label> */}
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("gender", { required: true })}
                    onChange={handleChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <FormErrorMessage error={errors.gender} />
              </div>
            </div>

            <div className="input-field">
              {/* <label>Phone</label> */}
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="tel"
                    {...register("phone_no", { required: true })}
                    onChange={handleChange}
                    placeholder="Phone No"
                  />
                </div>
                <FormErrorMessage error={errors.phone_no} />
              </div>
            </div>

            <div className="input-field">
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("how_did_know", { required: true })}
                    onChange={handleChange}
                  >
                    <option value="">How did you hear about us?</option>
                    <option>LinkedIn</option>
                    <option>Facebook</option>
                    <option>Instagram</option>
                    <option>Google Search</option>
                    <option>WhatsApp</option>
                    <option>Friends and Family</option>
                    <option>Bill Board</option>
                    <option>University Student/Teacher</option>
                  </select>
                </div>
                <FormErrorMessage error={errors.how_did_know} />
              </div>
            </div>

            <div className="field-wrap input-field ">
              {/* <label>Select Id Type</label> */}
              <div className="flex_radio">
                <label>Select ID: </label>
                <label>
                  <input
                    type="radio"
                    name="idType"
                    checked={selectedType === true}
                    onChange={handleCnicPasport}
                  />{" "}
                  CNIC
                </label>
                <label>
                  <input
                    type="radio"
                    name="idType"
                    checked={selectedType === false}
                    onChange={handleCnicPasport}
                  />{" "}
                  Passport
                </label>
              </div>
            </div>

            {selectedType == true ? (
              <div className="input-field">
                {/* <label>CNIC / B.Form</label> */}
                <div className="field-wrap">
                  <div className="field">
                    <InputMask
                      mask="99999-9999999-9"
                      {...register("cnic", {
                        required: true,
                      })}
                      placeholder="CNIC / B.Form"
                      onChange={handleChange}
                    >
                      {(inputProps) => <input type="cnic" {...inputProps} />}
                    </InputMask>
                  </div>
                  <FormErrorMessage error={errors.cnic} />
                </div>
              </div>
            ) : (
              <div className="input-field">
                {/* <label>Passport</label> */}
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("passport_no", { required: true })}
                      onChange={handleChange}
                      placeholder="Passport"
                    />
                  </div>
                  <FormErrorMessage error={errors.passport_no} />
                </div>
              </div>
            )}

            <div className="input-field">
              {/* <label>Password</label> */}
              <div className="field-wrap">
                <div className="field">
                  <input
                    type={showPassword?.password ? "text" : "password"}
                    placeholder="Password"
                    autoComplete="new-password"
                    {...register("password", {
                      required: true,
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters",
                      },
                      validate: {
                        hasUpperCase: (value) =>
                          /[A-Z]/.test(value) || "x One Upper Case",
                        hasLowerCase: (value) =>
                          /[a-z]/.test(value) || "x One lower Case",
                        hasNumber: (value) =>
                          /[0-9]/.test(value) || "x One Number",
                        eightCharsLong: (value) =>
                          /^.{8,}/.test(value) || "x 8 Characters Long",
                      },
                    })}
                    onChange={handleChange}
                  />
                  <span
                    className="field-icon"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password,
                      })
                    }
                  >
                    {showPassword?.password ? (
                      <EyeSvg className="password-icon" />
                    ) : (
                      <EyeHidingSvg className="password-icon" />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="input-field">
              <div className="field-wrap">
                <div className="field">
                  <input
                    type={showPassword?.confirmPassword ? "text" : "password"}
                    autoComplete="new-password"
                    {...register("password_confirmation", {
                      required: true,
                    })}
                    placeholder="Confirm Password"
                    onChange={handleChange}
                  />
                  <span
                    className="field-icon"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword.confirmPassword,
                      })
                    }
                  >
                    {showPassword?.confirmPassword ? (
                      <EyeSvg className="password-icon" />
                    ) : (
                      <EyeHidingSvg className="password-icon" />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {!password?.length ||
            password !== password_confirmation ||
            errors?.password?.types ? (
            <div className="error-main">
              <div className="error-box">
                <span className="error-text">
                  {Object.values((errors?.password?.types as any) ?? {}).map(
                    (item: any, index: number) => {
                      return <p key={index}>{item}</p>;
                    }
                  )}
                  {!password?.length || password !== password_confirmation ? (
                    <p>x Password Match</p>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="bottom-container">
            <div className="bottom-text">
              <span>
                Already have an account ?{" "}
                <span
                  className="link-text"
                  onClick={() => navigate(siteRoutes.ePortalLogin)}
                >
                  Sign In
                </span>
              </span>
            </div>

            <div className="submit-button">
              <button type="submit">
                {isLoading ? (
                  <div className="loader">
                    <div className="sm-w-rounded-loader"></div>
                  </div>
                ) : (
                  <span>Register Now</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </RegisterForm>
    </StudentRegisterMain>
  );
};

export default EportalRegister;
