import CreateSurveyTypes from "containers/private/qec/survey-types/create";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/helpers/common/http-methods";
import { TeacherDTO } from "utils/helpers/models/hr-management/teacher.dto";

export const HR_MANAGEMENT_APIS = {
  getTeachersTitles: (params: any) =>
    getRequest(`admin/teachers/titles`, params),
  createTeachers: (body: TeacherDTO) => getRequest(`admin/teachers`, body),
};
