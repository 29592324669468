import { FC, useEffect, useState } from "react";
import {
    AddParticularToChallanMain,
    Container,
    ContentWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { VoucherParticularDTO } from "utils/helpers/models/finance/voucher-particular.dto";
import useFinance from "containers/private/finance/useHooks";
import { VoucherTemplateBodyDTO } from "utils/helpers/models/finance/voucher-template-body.dto";
import useUtils from "hooks/useUtils";
import { warningToaster } from "utils/helpers/common/alert-service";
import MultiselectField from "components/particles/forms/multiselect-field";

interface Props {
    setOpen: Function;
}

const AddParticularToChallan: FC<Props> = ({ setOpen }) => {
    const { getQueryParams } = useUtils();
    const { header_id } = getQueryParams() || {};
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [formData, setFormData] = useState<VoucherTemplateBodyDTO>({ ...new VoucherTemplateBodyDTO(), header_id });
    const [particulars, setParticulars] = useState<VoucherParticularDTO[]>([]);
    const { getVoucherParticulars } = useFinance();
    const { createVoucherTemplateBody } = useFinance();
    const onSelectparticulars = (particular_ids: number[]) => {
        setFormData({ ...formData, particular_ids });
    }

    useEffect(() => {
        getVoucherParticulars(setParticulars);
    }, []);

    const onSubmit = () => {
        if (formData.particular_ids.length) {
            createVoucherTemplateBody({ ...formData, particular_ids: formData.particular_ids.join(',') }, setOpen);
        } else {
            warningToaster('Please select atleast one voucher particular');
        }
    }

    return (
        <AddParticularToChallanMain>
            <Container>
                <ContentWrapper className="p-custom-scrollbar-8" expand={showDropdown}>
                    <div className="header">
                        <div className="empty"></div>
                        <div className="heading">
                            <span>Add Particulars to Challan</span>
                        </div>
                        <div className="close-icon cp" onClick={() => setOpen(false)}>
                            <CloseMediumSvg className="icon" />
                        </div>
                    </div>
                    <form>
                        <MultiselectField
                            options={particulars}
                            onSelect={onSelectparticulars}
                            value={formData.particular_ids}
                            label="Voucher Particulars"
                            helperText="Select Particulars"
                            onOpen={() => setShowDropdown(true)}
                            onClose={() => setShowDropdown(false)}
                        />
                        {!showDropdown && (
                            <div className="action-buttons">
                                <div className="buttons">
                                    <button
                                        className="lg-rounded-btn gray"
                                        type="reset"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>

                                    <button
                                        className="lg-rounded-btn"
                                        type="button"
                                        onClick={onSubmit}
                                    >
                                        Save & Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </form>
                </ContentWrapper>
            </Container>
        </AddParticularToChallanMain>
    );
};

export default AddParticularToChallan;
