import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicVirtualCoursesListingSection,
  AcademicVirtualCoursesListingMain,
  AcademicVirtualCoursesListingTop,
  TemplateCourseStats,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
// import { VirtualCourseDTO } from "utils/helpers/models/finance/bank-info.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "../useHooks";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import { VirtualCourseDTO } from "utils/helpers/models/academics/virtual-course.dto";
import AddVirtualCourse from "./components/add-courses";

interface Props { }

const AcademicVirtualCoursesListing: FC<Props> = ({ }) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Template Courses /",
      path: siteRoutes.academicTemplateCoursesListing,
    },
    {
      title: "Manage Virtual Courses",
      path: siteRoutes.academicVirtualCoursesListing,
    },
  ];
  const navigate = useNavigate();
  const {getVirtualCourses,deleteVirtualCourse} = useAcademics();
  const [openAddCourseModal, setOpenAddCourseModal] = useState<boolean>(false);
  const [templateCourse, setTemplateCourse] =  useState<TemplateCourseDTO>(new TemplateCourseDTO());
  const [data, setData] = useState<VirtualCourseDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const columns: string[] = [
    "Course Title",
    "Course Code",
    "Course Department",
    "Action",
  ];

  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const {link_course_id} = getQueryParams();

  useEffect(() => {
    if(!openAddCourseModal){
      getAllVirtualCourses(pagination.page, search, pagination.per_page);
    }
  }, [openAddCourseModal]);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
        link_course_id
      };
      deleteVirtualCourse(id, setData, setTemplateCourse, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllVirtualCourses(page + 1, search,per_page);
  };
  const getAllVirtualCourses = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
      link_course_id
    };
    getVirtualCourses(setData, setTemplateCourse, queryParams, setPagination);
  };

  return (
    <AcademicVirtualCoursesListingMain>
      <AcademicVirtualCoursesListingTop>
        <div className="left">
          <span className="page-heading">Virtual Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createBank) && (
            <div className="create-fine-slot-btn">
              <button className="lg-rounded-btn" onClick={() => setOpenAddCourseModal(true)}>
                + Add Courses
              </button>
            </div>
          )}
        </div>
      </AcademicVirtualCoursesListingTop>

            <TemplateCourseStats className="content-radius-shadow">
              <div className="stats">
                <div className="sats-item">
                  <div className="stats-title">Course Title</div>
                  <div className="stats-value">{templateCourse.tc_title ?? '-'}</div>
                </div>
                <div className="sats-item">
                  <div className="stats-title">Course Code</div>
                  <div className="stats-value">{templateCourse.tc_code ?? '-'}</div>
                </div>
                <div className="sats-item">
                  <div className="stats-title">Total Credit Hours (Theory-Lab)</div>
                  <div className="stats-value">{+templateCourse?.tc_credit_hours + +templateCourse.tc_lab_credit_hours}({templateCourse?.tc_credit_hours}-{templateCourse?.tc_lab_credit_hours})</div>
                </div>
                <div className="sats-item">
                  <div className="stats-title">Department</div>
                  <div className="stats-value">{templateCourse?.department?.title}</div>
                </div>
                <div className="sats-item">
                  <div className="stats-title">Is Elective</div>
                  <div className="stats-value">{templateCourse?.tc_is_elective ? <span className="status-tile green">Yes</span> : <span className="status-tile red">No</span>}</div>
                </div>
              </div>
            </TemplateCourseStats>

      <AcademicVirtualCoursesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getAllVirtualCourses)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllVirtualCourses)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return <th key={index}>{column}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((course, index) => {
                return (
                  <tr key={index}>
                    <td>{course.template_courses.tc_title}</td>
                    <td>{course.template_courses.tc_code}</td>
                    <td>
                      {course.template_courses.department.title}
                    </td>
                    <td>
                      <div className="table-action-icons">
                        <div className="action-icon" onClick={() => handleDelete(course.id)}>
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination
            {...pagination}
            onPageChange={onPageChange}
          />
          {openAddCourseModal && <AddVirtualCourse  link_course_id={link_course_id} setOpen={setOpenAddCourseModal}/>}
        </Fragment>
      </AcademicVirtualCoursesListingSection>
    </AcademicVirtualCoursesListingMain>
  );
};

export default AcademicVirtualCoursesListing;
