import styled from "styled-components";

export const AdminDashboarMain = styled.div`
  width: 100%;
  @media screen and (max-width: 450px) {
    padding: auto 0.5em;
  }
`;

export const ADashboardTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }
`;
export const ADashboradSection = styled.div`
  .top-cards-container {
    /* margin-top: 2em; */
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  .top-cards {
    background-color: var(--white-color);
    padding: 0.7em;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    align-items: center;
    width: 32%;

    &:hover {
      cursor: pointer;
      background-color: var(--info-field-bg2);
      transition: 0.1s;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .addCardbg {
    background-color: var(--win-bg-color);

    &:hover {
      background-color: var(--bg-color);
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 51px;
    padding: 18px;
  }
  .title {
    font-size: 15px;
    color: var(--black-text);
    font-weight: 600;
  }
  .title-value {
    color: var(--lightgray-medium);
    font-size: 25px;
    font-weight: 400;
    margin-right: 22px;
  }
  .title-value2 {
    color: var(--lightgray-medium);
    font-size: 13px;
    font-weight: 400;
  }
  .card-title-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .bottom-title {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .line-graph-container {
    margin-top: 1em;
    display: flex;
    gap: 3em;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .sub-heading {
    font-size: 30px;
    color: var(--lightgray-light);
    font-weight: 400;
  }
  .primary {
    color: var(--primary);
  }
`;
export const LinkSection = styled.div`
  margin-top: 1em;
  padding: 2em 1em;
  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-medium);
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    /* width: 24%; */
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    width: 32%;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 80%;
    }

    @media (min-width: 1300px) {
      width: 24%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--black-text);
  }
  .total-amount {
    color: var(--black-text);
    font-weight: 500;
  }
`;
export const AllApplicantsSection = styled.div<{ $isApplicantOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  background-color: var(--white-color);
  border-radius: 25px;

  .top-cards-container {
    margin-top: 1em;
  }

  .card-title-main {
    display: flex;
    align-items: start;
    flex-direction: column;
    /* gap: 0; */
  }

  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
    border-bottom: 1px solid var(--lightgray-extralight);
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--lightgray-extralight);
    margin-top: 1em;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }

  .title-value {
    font-size: 1em;
    font-weight: 500;
  }
  .dropdown-arrow {
    display: flex;
    align-items: center;
  }
  .top-cards {
    border-radius: 10px;
    @media (max-width: 850px) {
      width: 48%;
    }
    @media (max-width: 550px) {
      width: 100%;
    }
  }
`;

export const RegisteredSection = styled.div<{ $isRegisterOpen: boolean }>`
  margin-top: 1em;
  padding: 1em;
  background-color: var(--white-color);
  border-radius: 25px;
  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
  }

  .top-cards-container {
    margin-top: 1em;
  }

  .dropdown-arrow {
    display: flex;
    align-items: center;
    width: 20px;
    height: 28px;
    rotate: ${({ $isRegisterOpen }) => ($isRegisterOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .link-main {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    width: 100%;
    border-bottom: 1px solid var(--lightgray-extralight);
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }

  .card-title-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
  }
  .title {
    font-weight: 600;
  }
  .title-value {
    font-size: 1em;
    font-weight: 500;
  }
  .top-cards .icon {
    background-color: var(--win-bg-color);
    color: var(--medium-orange);
  }
  .top-cards {
    width: 33%;
    border-radius: 10px;
    @media screen and (min-width: 854px) {
      width: 32%;
    }
    @media screen and (max-width: 853px) {
      width: 48%;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }
`;
export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 1em;
  margin-top: 1em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div<{ $isFilterOpen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--lightgray-extralight);
  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .dropdown-arrow {
    width: 20px;
    height: 28px;
    rotate: ${({ $isFilterOpen }) => ($isFilterOpen ? "0" : "180deg")};
    transition: all 0.1s ease-in-out;
    color: red;
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const ChartSection = styled.div`
  margin-top: 1em;
  display: flex;
  gap: 1em;

  canvas {
    width: 100% !important;
    display: unset;
    height: inherit;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .pie-container {
    gap: unset;

    @media (max-width: 980px) {
      flex-direction: column;
      gap: unset;

      .fee {
        font-size: 1.5em;
      }
    }

    @media (max-width: 770px) {
      flex-direction: row;
    }
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
`;
export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
export const LegendLabel = styled.span`
  font-size: 14px;
  /* color: #333; */
  width: max-content;
`;

export const LegendBullet = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const AdmApplicationsLineChart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em 1.5em 1em;
  margin-top: 1.5em;

  .chart-wrapper {
    width: 100%;
    height: auto;
  }
  .chart-wrapper canvas {
    width: 100%;
    max-height: inherit;
  }

  .header-part {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 760px) {
      flex-wrap: wrap;
    }

    .heading {
      span {
        font-size: var(--font-32);
        color: var(--black-text);
        font-weight: 600;
      }
    }

    .filter-part {
      display: flex;
      gap: 1em;
      align-items: center;
      @media (max-width: 425px) {
        flex-wrap: wrap;
      }

      .input-field {
        gap: 0;

        .field {
          height: 45px;
        }
      }

      .submit-btn {
        @media (max-width: 425px) {
          width: 100%;
        }
        button {
          height: 45px;
          margin-top: 1.3em;
          @media (max-width: 425px) {
            width: 100%;
          }
        }
      }
    }
  }
`;
