import { AddProgramDTO } from "../organization/add-program.dto";
import { OrgStructureDTO } from "../organization/org-structure.dto";

export class AcademicSectionDTO {
  id!: number;
  title: string = "";
  program_id: string = "";
  is_active!: number;
  is_first_half!: number;
  semester_number!: number;
  sequence_number!: number;
  Shift: string = "";
  enrollment_size!: number;
  organizations_id: string = "";
  department_id: string = "";
  department!: OrgStructureDTO;
  program!: AddProgramDTO;
  tc_credit_hours: string = "";
  tc_lab_credit_hours: string = "";

  constructor(data = {}) {
    if (Object.keys(data).length) {
      this.department = new OrgStructureDTO();
      this.program = new AddProgramDTO();
      Object.assign(this, data);
    }
  }
}
