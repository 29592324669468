import { FC, useEffect, useState } from "react";
import {
  StudentInfo,
  EportalViewProfileMain,
  EportalViewProfileTopSection,
  InfoField,
  InfoSection,
} from "./style";
import useEportal from "../../useHooks";
import dummyImage from "../../../../../assets/images/common/others/download.png";

interface EportalViewProfileProps {}

const ViewEPStudentProfile: FC<EportalViewProfileProps> = () => {
  const [formData, setFormData] = useState<any>({});
  const { getProfile } = useEportal();

  useEffect(() => {
    getProfile(setFormData);
  }, []);

  return (
    <EportalViewProfileMain>
      <EportalViewProfileTopSection>
        <span className="page-heading">View Profile</span>
     
      </EportalViewProfileTopSection>

      <InfoSection>
        <StudentInfo>
        

          <div className="info-container employee-info">
          <div className="profile-pic-section ">
            <div className="content-section">
              <div className="profile-picture">
                <img
                  src={
                    formData.profile_image ? formData.profile_image : dummyImage
                  }
                  alt=""
                />
              </div>
              <div className="user-name">{formData.name}</div>
           
            </div>
          </div>

            <div className="fields-section">
              <InfoField gray={false} studentInfo>
                <span className="title">First Name</span>
                <span className="info">{formData?.first_name}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Last Name</span>
                <span className="info">{formData?.last_name}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Father Name</span>
                <span className="info">{formData?.father_name}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Gender</span>
                <span className="info">{formData?.gender}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Email</span>
                <span className="info">{formData?.email}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Phone No</span>
                <span className="info"> {formData.phone_no ? formData.phone_no : "-"}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
              <span className="title">CNIC/B.Form</span>
              <span className="info">
                {formData.cnic}
              </span>
            </InfoField>
            <InfoField gray={true} studentInfo>
              <span className="title">Address</span>
              <span className="info">
                {formData?.address}
              </span>
            </InfoField>
            <InfoField gray={false} studentInfo>
                <span className="title">Permanent Mailing Address</span>
                <span className="info">{formData?.p_address}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Permanent Address</span>
                <span className="info">{formData?.permanent_city?.title ?? '-'}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Date Of Birth</span>
                <span className="info">{formData?.date_of_birth}</span>
              </InfoField>
              {/* <InfoField gray={true} studentInfo>
                <span className="title">Domicile City</span>
                <span className="info">{formData?.domicile}</span>
              </InfoField> */}
              <InfoField gray={false} studentInfo>
                <span className="title">Current Mailing Address</span>
                <span className="info">{formData?.m_address}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Current Address (City & Tehsil)</span>
                <span className="info"> {formData?.mailing_city?.title ?? '-'}</span>
              </InfoField>
            
             
            </div>
          </div>
        </StudentInfo>
   
        
      </InfoSection>
    </EportalViewProfileMain>
  );
};

export default ViewEPStudentProfile;
