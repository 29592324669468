import { FC, Fragment, useState } from "react";
import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  ManageSpecializationListingMain,
  ManageSpecializationListingSection,
  ManageSpecializationListingTop,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";

const ManageSpecializationListing: FC = ({}) => {
  const breadcrumbLinks = [
    { title: "Academics  /", path: "" },
    {
      title: "Program Specialization /",
      path: siteRoutes.academicProgramSpecializationListing,
    },
    {
      title: "Manage Specialization",
      path: siteRoutes.academicManageSpecializationListing,
    },
  ];
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const { hasAccess } = useStore();

  const columns: string[] = [
    "Specialization Title",
    "Seats",
    "Display Order",
    "Action",
  ];

  const goToCreate = () => {
    navigate(siteRoutes.academicCreateManageSpecialization);
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
  };

  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  ); // State to track the open dropdown by index
  const [isTableOpened, setIsTableOpened] = useState(false);

  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  return (
    <ManageSpecializationListingMain>
      <ManageSpecializationListingTop>
        <div className="left">
          <span className="page-heading">Manage Specialization</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-org-btn">
            <button onClick={goToCreate} className="lg-rounded-btn">
              + Add Specialization
            </button>
          </div>
        </div>
      </ManageSpecializationListingTop>

      <ManageSpecializationListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map((schedule, index) => {
                return (
                  <Fragment key={index}>
                    <tr>
                      <td>Agriculture Extension</td>
                      <td> 20</td>
                      <td>10</td>

                      <td>
                        <div className="table-action-icons">
                          <div className="action-icons">
                            <DeleteTableSvg />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {/* <DataNotFound show={!isLoading && !data.length} /> */}
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </ManageSpecializationListingSection>
    </ManageSpecializationListingMain>
  );
};

export default ManageSpecializationListing;
