import { TestTypeDTO } from "../admissions/test-type.dto";

export class LinkProgramToEntryTestDTO {
  id!: number;
  test_id: string = "";
  admission_session_id: string = "";
  allow_result_awaiting!: number;
  min_required_percentage!: number;
  status!: number;
  from_date: string = "";
  to_date: string = "";
  test?: TestTypeDTO;

  constructor(data: Partial<LinkProgramToEntryTestDTO> = {}){
    for(let key in data){
        if(key === 'test'){
          this.test = new TestTypeDTO(data[key] ?? {});
        }else{
          this[key] = data[key];
        }
    }
  }
}
