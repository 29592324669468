import { useDispatch } from "react-redux";
import { UnknownAction } from "redux";
import { SHARED_REDUCER_ACTIONS } from "store/reducers/shared.reducer";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { domains } from "utils/helpers/enums/shared.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useUtils from "./useUtils";
import { OrganizationDTO } from "utils/helpers/models/organization/organization.dto";
import { localOrganizationsData } from "utils/constants/data";

const useStore = () => {
  const dispatch = useDispatch();
  const { setCSSVariable } = useUtils();
  const dispatchAction = (type: string, payload?: any): void => {
    const dispatcher: UnknownAction = { type, payload };
    dispatch(dispatcher);
  };

  const setSiteZoomLevel = (level?: number) => {
    if(level){
      const remZoom = (100 - level) + 100 + (((100 - level) / 10) * 2.5);
      setCSSVariable('--zoom-level', level + '%');
      setCSSVariable('--zoom-view-height', `${remZoom}vh`);
      setCSSVariable('--zoom-view-width', `${remZoom}vw`);
      localStorage.setItem('zoomLevel', `${level}%`);
    }else{
      const zoomLevel = localStorage.getItem('zoomLevel') ?? '80%';      
      const level = +(zoomLevel as string)?.replace('%', '');
      const remZoom = (100 - level) + 100 + (((100 - level) / 10) * 2.5);
      if(zoomLevel){
        setCSSVariable('--zoom-view-height', `${remZoom}vh`);
        setCSSVariable('--zoom-view-width', `${remZoom}vw`);
        setCSSVariable('--zoom-level', zoomLevel);
      }
    }
  }

  const setPriorLogoutRoute = (route: string) => {
    localStorage.setItem('priorLogoutRoute', route);
  }

  const getPriorLogoutRoute = (): string | null => {
    return localStorage.getItem('priorLogoutRoute');
  }

  const removePriorLogoutRoute = (): void => {
    return localStorage.removeItem('priorLogoutRoute');
  }

  const setLoading = (isLoading: boolean): void => {
    dispatchAction(SHARED_REDUCER_ACTIONS.LOADING, isLoading);
  };

  const setToken = (payload: null | string, isLoginAs: boolean = false): void => {
    if(isLoginAs){
      dispatch({ type: SHARED_REDUCER_ACTIONS.SAVE_LOGIN_AS_TOKEN, payload });
      if (payload) localStorage.setItem("loginAsToken", payload);
  }else{
    dispatch({ type: SHARED_REDUCER_ACTIONS.SAVE_TOKEN, payload });
    if (payload) localStorage.setItem("token", payload);
  }
  };

  const getPermissionByRoute = (route: string): string => {
    let permission: string = '';
    const routes: any = siteRoutes;
    const permissions: any = sitePermissions;
    
    
    for(let key in routes){
      if(routes[key] === route.split('?')[0]){        
        permission = permissions[key];
        break;
      }
    }

    return permission;
  }

  const getRouteByPermission = (permission: string): string => {
    let route: string = '';
    const routes: any = siteRoutes;
    const permissions: any = sitePermissions;
    for(let key in permissions){
      if(permissions[key] === permission.trim()){
        route = routes[key];
        break;
      }
    }

    return route;
  }

  const setUser = (payload: any, isLoginAs: boolean = false): void => {
    if(isLoginAs){
      localStorage.setItem("loginAsUser", JSON.stringify(payload));
      dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_LOGIN_AS_USER, payload);
    }else{
      dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_USER, payload);
      localStorage.setItem("user", JSON.stringify(payload));
    }
  };

  const getFilePathWithBackend = (path: string): string => {
    return `${process.env.REACT_APP_BASE_URL}/${path}`;
  };

  const setPermissions = (permissions: any[], isLoginAs: boolean = false) => {
    const permissionsToSave = permissions.map((permission) => {
      return typeof permission === "string" ? permission : permission.name;
    });
    permissionsToSave.push(sitePermissions.academicVirtualCoursesListing);
    permissionsToSave.push(sitePermissions.shiftProfileListing);
    permissionsToSave.push(sitePermissions.createShiftProfile);
    permissionsToSave.push(sitePermissions.roomToSectionProfileListng);
    permissionsToSave.push(sitePermissions.createRoomToSectionProfile);
    permissionsToSave.push(sitePermissions.roomToCourseProfileListing);
    permissionsToSave.push(sitePermissions.createRoomToCourseProfile);
    permissionsToSave.push(sitePermissions.timeTableYearMapListing);
    permissionsToSave.push(sitePermissions.timeTableFetFileGenerator);
    permissionsToSave.push(sitePermissions.timeTableFetFileGenerator);
    permissionsToSave.push(sitePermissions.academicsDashboard);





    // permissionsToSave.push(sitePermissions.academicVirtualCoursesListing);
    // permissionsToSave.push(sitePermissions.shiftProfileListing);
    // permissionsToSave.push(sitePermissions.createShiftProfile);
    // permissionsToSave.push(sitePermissions.roomToSectionProfileListng);
    // permissionsToSave.push(sitePermissions.createRoomToSectionProfile);
    // permissionsToSave.push(sitePermissions.roomToCourseProfileListing);
    // permissionsToSave.push(sitePermissions.createRoomToCourseProfile);

    if(isLoginAs){
      localStorage.setItem("loginAsPermissions", JSON.stringify(permissionsToSave));
      dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_LOGIN_AS_PERMISSIONS, [
        ...permissionsToSave,
      ]);
    }else{
      localStorage.setItem("permissions", JSON.stringify(permissionsToSave));
      dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_PERMISSIONS, [
        ...permissionsToSave,
      ]);
    }
  };

  const getPermissions = () => {
    let permissions: any = [];
    if(isLoggedInAs()){
      permissions = localStorage.getItem('loginAsPermissions');
    }else{
      permissions = localStorage.getItem("permissions");
    }
    if (permissions) {
      return JSON.parse(permissions);
    } else {
      return [];
    }
  };

  const isLoggedInAs = (): boolean => {
    const loginAsToken = localStorage.getItem("loginAsToken");
    return loginAsToken ? true : false;
  }

  const getToken = (): string | null => {
    if(isLoggedInAs()){
      return localStorage.getItem("loginAsToken");
    }else{
      return localStorage.getItem("token");
    }
  };

  const setRole = (role: any, isLoginAs: boolean = false): void => {
    if(isLoginAs){
    localStorage.setItem("loginAsRole", JSON.stringify(role));
    }else{
      localStorage.setItem("role", JSON.stringify(role));
    }
  };

  const hasAccess = (permission: string): boolean => {
    const permissions = getPermissions();
    return permissions.includes(permission);
  };

  const setUserAlreadyApplied = (applied: boolean) => {
    dispatchAction(SHARED_REDUCER_ACTIONS.HAS_USER_ALREADY_APPLIED, applied);
  }

  const doesFileSizeExceed = (file: File, size: number = 1) => {
    const sizeInKbs = size * 1024;
    return ((file.size / 1024) > sizeInKbs);
  }

  const getRole = (): any => {
    let role = '';
    if(isLoggedInAs()){
      role = localStorage.getItem("role");
    }else{
      role = localStorage.getItem("role");
    }
    if (role) {
      return JSON.parse(role);
    } else {
      return null;
    }
  };

  const setLastLoggedInUserRole = (role: ROLES) => {
    localStorage.setItem('lastUserRole', role);
  }

  const logout = () => {
    if(isLoggedInAs()){
      dispatchAction(SHARED_REDUCER_ACTIONS.LOGIN_AS_LOGOUT);
      localStorage.removeItem('loginAsToken');
      localStorage.removeItem('loginAsUser');
      localStorage.removeItem('loginAsPermissions');
      localStorage.removeItem('loginAsRole');
    }else{
      const lastUserRole = getLastLoggedInUserRole();
      const organization = getOrganization();
      const lastRoute = getPriorLogoutRoute();
      localStorage.clear();
      setLastLoggedInUserRole(lastUserRole as ROLES);
      setOrganization(organization);
      setPriorLogoutRoute(lastRoute);
      dispatchAction(SHARED_REDUCER_ACTIONS.LOGOUT);
      setDarkTheme(false);
    }
  };

  const setDarkTheme = (isDarkTheme: boolean) => {
    addThemeTransitionToEveryElement();
    if (isDarkTheme) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    dispatchAction(SHARED_REDUCER_ACTIONS.THEME_CHANGE, isDarkTheme);

    setTimeout(() => {
      removeThemeTransitionFromEveryElement();
    }, 500);
  };

  const getPriorLoginAsRoute = (): string | null => {
    return localStorage.getItem('priorLoginAsRoute');
  }
  
  const removePriorLoginAsRoute = () => {    
    localStorage.removeItem('priorLoginAsRoute');
  }

  const addThemeTransitionToEveryElement = () => {
    const elements = document.querySelectorAll("*");
    elements.forEach((element) => {
      element.classList.add("theme-transition");
    });
  };

  const removeThemeTransitionFromEveryElement = () => {
    const elements = document.querySelectorAll("*");
    elements.forEach((element) => {
      element.classList.remove("theme-transition");
    });
  };

  const getDomainDefaultUnAuthRoute: any = () => {
    const domainType = getDomainType();
    const lastUserRole = getLastLoggedInUserRole();
    if (domainType == domains.mainDomain) {
      return siteRoutes.superAdminLogin;
    } else if(lastUserRole === ROLES.ADMIN){
      return siteRoutes.adminLogin;
    }else{
      return siteRoutes.ePortalLogin;
    }
  };

  const getUser = (): any => {
    let user = null;
    if(isLoggedInAs()){
      user = localStorage.getItem("loginAsUser");
    }else{
      user = localStorage.getItem("user");
    }
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  };

  const getDomainDefaultAuthRoute: any = () => {
    const domainType = getDomainType();
    if (domainType == domains.mainDomain) {
      return siteRoutes.superAdminDashboard;
    } else {
      const userRole = getRole();
      if (userRole?.guard_name == ROLES.ADMIN) {
        if (hasAccess(sitePermissions.adminDashboard)) {
          return siteRoutes.adminDashboard;
        } else {
          const permissions = getPermissions();
          const firstPermissionRoute = getRouteByPermission(permissions[0]);
          return firstPermissionRoute;
        }
      } else if (userRole?.guard_name == ROLES.USER) {
        return siteRoutes.ePortalDashboard;
      }
    }
  };

  const isSuperAdmin = () => {
    const role = getRole();
    return role?.guard_name === ROLES.SUPER_ADMIN;
  };

  const setPriorLoginAsRoute = (route: string) => {
    localStorage.setItem('priorLoginAsRoute', route);
  }

  const setOrganization = (payload: any) => {
    dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_ORGANIZATION, payload);
    localStorage.setItem("organization", JSON.stringify(payload));
  };

  const setOrganizationGlobals = (organization: any) => {
    const { primary_color = null } = organization || {};
    if (organization?.fav_icon) {
      (document as any).querySelector("link[rel*='icon']").href =
        organization?.fav_icon;
    }
    if(primary_color){
      document.documentElement.style.setProperty("--primary", primary_color);
      document.documentElement.style.setProperty("--primary-extralight", hexToRGBA(primary_color, .2));
    }
  }

  const hexToRGBA = (hex: string, opacity: number): string => {
    hex = hex.replace(/^#/, "");
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

  const getCurrentOrgEportalLink = (): string => {
    const organization = getOrganization();
    let link: string = '';    
    if(organization?.cname && isProductionMode()){
      const orgDomains = organization.cname.split(',');
      const eportalDomain = orgDomains.find(d => d.includes('eportal'));
      link = `https://${eportalDomain}/e-portal/login`;
    }else{
      link = `http://${organization.username}.umsoncloud.com/e-portal/login`;
    }

    return link;
  }

  const isProductionMode = () => {
    return process.env?.REACT_APP_PRODUCTION_MODE && !process.env?.REACT_APP_IS_STAGING;
  }

  const getDomainType = () => {
    const domain = getDomain();
    if (
      domain === "localhost" ||
      domain === "www.umsoncloud.com" ||
      domain === "umsoncloud.com"
    ) {
      return domains.mainDomain;
    } else {
      return domains.subDomain;
    }
  };
  const getDomain = (): string => {
    return window.location.hostname;
  };

  const getOrganization = (): OrganizationDTO | null => {
    const org = localStorage.getItem("organization");
    if (org) {
      return JSON.parse(org);
    } else {
      return null;
    }
  };

  const handleSetOrganizationLocally = () => {
    const domainType = getDomainType();
    if (domainType !== domains.mainDomain) {
      const {hostname} = window.location;
      const organization = localOrganizationsData.find(org => org.domains.includes(hostname));
      if(organization){
        setOrganizationGlobals(organization);
        setOrganization(organization);
      }
    }
  }

  const getRelativePathAfterLogin = (defaultRoute: string) => {
    const priorLogoutPath = getPriorLogoutRoute();
    let routeToReturn = defaultRoute;
    if(priorLogoutPath){
      const permission = getPermissionByRoute(priorLogoutPath);
      if(hasAccess(permission)){
        routeToReturn = priorLogoutPath;
      }
      removePriorLogoutRoute();
    }
    return routeToReturn;
  }

  const getSiteZoomLevel = () => {
    const root = document.documentElement;
    const zoom = getComputedStyle(root).getPropertyValue('--zoom-level').trim();
    return +zoom.replace('%', '');
  }

  const getLastLoggedInUserRole = () => {
    return localStorage.getItem('lastUserRole');
  }

  return {
    setToken,
    setLoading,
    dispatchAction,
    setDarkTheme,
    logout,
    setUser,
    getLastLoggedInUserRole,
    getDomainDefaultUnAuthRoute,
    setPriorLoginAsRoute,
    getRelativePathAfterLogin,
    getDomainDefaultAuthRoute,
    setLastLoggedInUserRole,
    setSiteZoomLevel,
    getFilePathWithBackend,
    setPermissions,
    hasAccess,
    getDomainType,
    getSiteZoomLevel,
    getPermissions,
    removePriorLoginAsRoute,
    setOrganizationGlobals,
    setPriorLogoutRoute,
    removePriorLogoutRoute,
    getPriorLogoutRoute,
    getToken,
    doesFileSizeExceed,
    isProductionMode,
    getPermissionByRoute,
    setOrganization,
    getOrganization,
    getRole,
    setUserAlreadyApplied,
    getRouteByPermission,
    getCurrentOrgEportalLink,
    setRole,
    getUser,
    isLoggedInAs,
    getPriorLoginAsRoute,
    isSuperAdmin,
    handleSetOrganizationLocally
  };
};

export default useStore;
