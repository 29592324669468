export enum domains {
  superAdmin = "localhost",
  mainDomain = "main-domain",
  subDomain = "sub-domain",
  subDeepDomain = "eportal.org.umscloud.com",
}

export enum defaultRoutes {
  unAuthStudent = "/student/student-login",
  authStudent = "/private/student-e-portal",
  unAuthSuperAdmin = "/super-admin-login",
}

export enum OrgStructureTypes {
  campus = "campus",
  faculty = "faculty",
  department = "department",
}

export enum SESSION_TYPES {
  spring = "Spring",
  fall = "Fall",
  Summer = "Summer",
}
export enum AcademicSesionTypes {
  id = "id",
  year = "year",
  type = "type",
  session = "session",
  is_active = "is_active",
  sess_start_date = "sess_start_date",
  sess_end_date = "sess_end_date",
  enrol_start_date = "enrol_start_date",
  enrol_end_date = "enrol_end_date",
  result_declaration_date = "result_declaration_date",
  result_submission_date = "result_submission_date",
  ug_course_repeat_date = "ug_course_repeat_date",
  pg_course_repeat_date = "pg_course_repeat_date",
  semester_sequence_no = "semester_sequence_no",
  show_on_transcript = "show_on_transcript",
  fee_type = "fee_type",
  session_id = "session_id",
}

export enum HEADER_UTILITIES {
  PROFILE = "profile",
  NOTIFICATIONS = "notifications",
  SETTINGS = "settings",
  THEME = "theme",
}
