import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreateTeachersForm,
  CreateTeachersMain,
  CreateTeachersTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { TeacherDTO } from "utils/helpers/models/hr-management/teacher.dto";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import useAcademics from "containers/private/academics/useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useAdmissions from "containers/private/admissions/useHooks";
import useHRManagement from "../../useHook";
import InputMask from "react-input-mask";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";

export const TeachersCreate: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Hr Management / ", path: siteRoutes.academicSessionListing },
    {
      title: `Teachers ${params ? "Update" : "Create"} /`,
      path: siteRoutes.academicSessionListing,
    },
  ];

  const { getTeachersTitles, createTeachers } = useHRManagement();
  const { getSpecificOrgStructures } = useOrganization();

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<TeacherDTO>();

  const [formData, setFormData] = useState<TeacherDTO>(new TeacherDTO());

  const { getAdmissionSessions } = useAdmissions();
  const [teacherTitles, setTeachersTitles] = useState<any[]>([]);
  const [campuses, setCampuses] = useState<OrgStructureTypes[]>([]);
  const [feeTypes, setFeeTypes] = useState<any[]>([]);

  const onSubmit = () => {
    if (params?.id) {
      // updateAcademicSession(params?.id, formData);
    } else {
      createTeachers(formData);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new TeacherDTO()[key as keyof TeacherDTO];
      setValue(key as keyof TeacherDTO, (formData as any)[key]);
    }

    setFormData({ ...formData });
  };

  useEffect(() => {
    getTeachersTitles(setTeachersTitles);
    if (params?.id) {
      // getAcademicSessionById(params?.id, formData, setValue, setFormData);
    }

    //  getSpecificStructure(setCampuses, {
    //    per_page: "All",
    //    type: OrgStructureTypes.campus,
    //  });
    //  getSpecificStructure(setFaculty, {
    //    per_page: "All",
    //    type: OrgStructureTypes.faculty,
    //  });
    //  getSpecificStructure(setDepartment, {
    //    per_page: "All",
    //    type: OrgStructureTypes.department,
    //  });
    console.log(campuses);
  }, []);

  return (
    <CreateTeachersMain>
      <CreateTeachersTop>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Teachers
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateTeachersTop>

      <CreateTeachersForm
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("title_id", { required: true })}
                  value={formData.title_id}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  {teacherTitles.map((titles, index) => {
                    return (
                      <option value={titles.id} key={index}>
                        {titles.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.title_id} />
            </div>
          </div>

          <div className="input-field">
            <label>First Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("teacher_firstname", { required: true })}
                  value={formData.teacher_firstname}
                  onChange={handleChange}
                  placeholder="John"
                />
              </div>
              <FormErrorMessage error={errors.teacher_firstname} />
            </div>
          </div>

          <div className="input-field">
            <label>Middle Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("teacher_middlename", { required: true })}
                  value={formData.teacher_middlename}
                  onChange={handleChange}
                  placeholder="John"
                />
              </div>
              <FormErrorMessage error={errors.teacher_middlename} />
            </div>
          </div>
          <div className="input-field">
            <label>Last Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("teacher_lastname", { required: true })}
                  value={formData.teacher_lastname}
                  onChange={handleChange}
                  placeholder="John"
                />
              </div>
              <FormErrorMessage error={errors.teacher_lastname} />
            </div>
          </div>

          <div className="input-field">
            <label>CNIC</label>
            <div className="field-wrap">
              <div className="field">
                <InputMask
                  mask="99999-9999999-9"
                  {...register("cnic", { required: true })}
                  placeholder="CNIC"
                  value={formData.cnic}
                  onChange={handleChange}
                >
                  {(inputProps) => <input type="text" {...inputProps} />}
                </InputMask>
              </div>
            </div>
          </div>

          <div className="input-field">
            <label>User Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("user_name", { required: true })}
                  value={formData.user_name}
                  onChange={handleChange}
                  placeholder="John169"
                />
              </div>
              <FormErrorMessage error={errors.user_name} />
            </div>
          </div>

          <div className="input-field">
            <label>Phone</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("phone", { required: true })}
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="0000-000000-0"
                />
              </div>
              <FormErrorMessage error={errors.phone} />
            </div>
          </div>
          <div className="input-field">
            <label>Campus</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("campus_id", { required: true })}
                  value={formData.campus_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {feeTypes?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.campus_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Department</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("department_id", { required: true })}
                  value={formData.department_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {feeTypes?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.department_id} />
            </div>
          </div>

          <div className="input-field">
            <label>Personel Email</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="email"
                  {...register("personal_email", { required: true })}
                  value={formData.personal_email}
                  onChange={handleChange}
                  placeholder="john169@gmail.com"
                />
              </div>
              <FormErrorMessage error={errors.personal_email} />
            </div>
          </div>
          <div className="input-field">
            <label>NTN Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("ntn", { required: false })}
                  value={formData.ntn}
                  onChange={handleChange}
                  placeholder="000000000000000"
                />
              </div>
              <FormErrorMessage error={errors.ntn} />
            </div>
          </div>
          <div className="input-field">
            <label>Bank Account Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("bank_account_title", { required: false })}
                  value={formData.bank_account_title}
                  onChange={handleChange}
                  placeholder="elon musk atari"
                />
              </div>
              <FormErrorMessage error={errors.bank_account_title} />
            </div>
          </div>
          <div className="input-field">
            <label>Bank Account</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("bank_account", { required: false })}
                  value={formData.bank_account}
                  onChange={handleChange}
                  placeholder="0000-000000000-0"
                />
              </div>
              <FormErrorMessage error={errors.bank_account} />
            </div>
          </div>
          <div className="input-field">
            <label>Bank Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("bank_name", { required: false })}
                  value={formData.bank_name}
                  onChange={handleChange}
                  placeholder="HBL"
                />
              </div>
              <FormErrorMessage error={errors.bank_name} />
            </div>
          </div>

          <div className="radio-field">
            <label htmlFor="no">Is Visiting</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_visiting_yes"
                  {...register("is_visiting", { required: false })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.is_visiting == 1}
                />
                <label htmlFor="is_active_yes">Yes</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_visiting_no"
                  {...register("is_visiting", { required: false })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.is_visiting == 0}
                />
                <label htmlFor="is_visiting_no">No</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_visiting} />
          </div>

          <div className="radio-field">
            <label htmlFor="no">Is Active</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_active"
                  {...register("is_active", { required: true })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.is_active == 1}
                />
                <label htmlFor="is_active_yes">Yes</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_active_no"
                  {...register("is_active", { required: true })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.is_active == 0}
                />
                <label htmlFor="is_active_no">No</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_active} />
          </div>

          <div className="radio-field">
            <label htmlFor="no">Is On Leave</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_on_leave_yes"
                  {...register("is_on_leave", { required: false })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.is_on_leave == 1}
                />
                <label htmlFor="is_on_leave_yes">Yes</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_on_leave_no"
                  {...register("is_on_leave", { required: false })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.is_on_leave == 0}
                />
                <label htmlFor="is_on_leave_no">No</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_on_leave} />
          </div>

          <div className="radio-field">
            <label htmlFor="no">Is Employee</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_employee_yes"
                  {...register("is_employee", { required: true })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.is_employee == 1}
                />
                <label htmlFor="is_employee_yes">Yes</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_employee_no"
                  {...register("is_employee", { required: true })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.is_employee == 0}
                />
                <label htmlFor="is_employee_no">No</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_employee} />
          </div>
        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              {params.id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </CreateTeachersForm>
    </CreateTeachersMain>
  );
};

export default TeachersCreate;
