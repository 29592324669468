export class PreReqDTO {
  id?: number;
  tc_title?: string;
  course_id!: number;
  pre_req_course_id: number[] = [];

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
