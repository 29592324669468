import styled from "styled-components";

export const MainLayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0;
`;

export const SidebarSection = styled.div<{
  $opened: boolean;
  $showIconedMenu: boolean;
}>`
  height: 100%;
  position: absolute;
  z-index: 5;
  left: ${({ $opened, $showIconedMenu }) =>
    $showIconedMenu ? "0" : $opened ? "0" : "-300px"};
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 1600px) {
    zoom: var(--zoom-level);
  }
`;

export const ContentSection = styled.div<{
  $isSidebarOpened: boolean;
  $showIconedMenu: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  overflow: hidden;

  @media screen and (max-width: 1600px) {
    zoom: var(--zoom-level);
  }

  @media screen and (min-width: 1280px) {
    margin-left: ${({ $isSidebarOpened, $showIconedMenu }) =>
    $showIconedMenu ? "50px" : $isSidebarOpened ? "300px" : "0"};
    transition: all 0.1s ease-in-out;
  }
`;

export const HeaderSection = styled.div`
  width: 100%;
  display: flex;
`;

export const RouterOutlet = styled.div`
  width: 100%;
  height: 100%;
  padding: 1em 2em;
  overflow-y: auto;

  @media screen and (max-width: 800px) {
    padding: 1em;
  }

  @media screen and (max-width: 450px) {
    padding: 0;
  }
  .icon-main{
    background: var(--primary);
    width: 30px;
    height: 30px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    @media screen and (max-width: 768px){
      display: flex;
    }

    .icon{
      width: 20px;
    rotate: 180deg;

    }
  }
`;

export const FooterSection = styled.div`
  width: 100%;
`;


export const BackNavigation = styled.div`
  width: 100%;
  display: flex;
  padding: 0 0 1em 0;

  @media screen and (max-width: 450px){
    padding: 1em 0 1em .5em;
  }
`;