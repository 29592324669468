import { FC, useEffect, useState } from "react";
import {
    QuotaAddListProgramMain,
    QuotaAddListProgramContainer,
    QuotaAddListProgramWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { CoReqDTO } from "utils/helpers/models/academics/co-req-dto";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { useForm } from "react-hook-form";
import useAcademics from "../../useHooks";
import MultiselectField from "components/particles/forms/multiselect-field";

interface CoRequisiteProps {
    setOpen: (open: boolean) => void;
    headerId: any;
    GetCoRequsiteCoursesList: Function;
}

const TemplateCourseCoReqCreate: FC<CoRequisiteProps> = ({
    setOpen,
    headerId,
    GetCoRequsiteCoursesList,
}) => {

    const {
        getTemplateCoursesForAddRequisiteCourses,
        addCoreqCourseToTemplateCourse,
    } = useAcademics();

    const [formData, setFormData] = useState<CoReqDTO>(() => new CoReqDTO());
    const [courses, setCourses] = useState<CoReqDTO[]>([]);
    const [showButton, setShowButton] = useState<boolean>(true);
    const {
        handleSubmit,
        setValue,
        trigger
    } = useForm<CoReqDTO>({
        defaultValues: formData,
        mode: "onChange",
    });

    const handleCloseModal = () => {
        setOpen(false);
    };

    const onSelect = (value: number[], name: keyof CoReqDTO) => {
        (formData as any)[name] = value;
        setValue(name as keyof CoReqDTO, formData[name]);
        trigger([name]);
        setFormData({ ...formData });
    }

    useEffect(() => {
        getTemplateCoursesForAddRequisiteCourses(setCourses);
    }, []);

    const onSubmit = () => {
        if (formData?.co_req_course_id?.length > 0) {
            const body = {
                course_id: headerId,
                co_req_course_id: formData?.co_req_course_id,
            };
            addCoreqCourseToTemplateCourse(body, setOpen, GetCoRequsiteCoursesList);
        } else {
            warningToaster(warningMessages.academicsTemplateCourseNotseletedWarning);
        }
    };

    return (
        <QuotaAddListProgramMain>
            <QuotaAddListProgramContainer>
                <QuotaAddListProgramWrapper className="p-custom-scrollbar-8" showSubmitButton={showButton}>
                    <div className="header">
                        <div className="empty"></div>
                        <div className="heading">
                            <span>Add Co Requisite Courses</span>
                        </div>
                        <div className="close-icon cp" onClick={handleCloseModal}>
                            <CloseMediumSvg className="icon" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <MultiselectField
                            options={courses}
                            onSelect={(item) => onSelect(item, "co_req_course_id")}
                            value={formData.co_req_course_id}
                            searchable
                            property="tc_title"
                            label="Departments"
                            helperText="-- Select --"
                            onClose={() => setShowButton(true)}
                            onOpen={() => setShowButton(false)}
                        />

                        <div className="action-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                                    type="button"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                                <button className="lg-rounded-btn" type="submit">
                                    Save & Close
                                </button>
                            </div>
                        </div>
                    </form>
                </QuotaAddListProgramWrapper>
            </QuotaAddListProgramContainer>
        </QuotaAddListProgramMain >
    );
};

export default TemplateCourseCoReqCreate;
