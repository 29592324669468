export class CityDTO {
    country: string = 'PK';
    district_id: number;
    division_id: number;
    id: number;
    is_district: number;
    is_division: number;
    is_tehsil: number;
    latitude: number;
    longitude: number;
    region: string;
    title: string;

    constructor(data = {}) {
        for (let key in data) {
            this[key] = data[key];
        }
    }
}