import { createGlobalStyle } from 'styled-components';

const w1 = Math.ceil((1 / window.devicePixelRatio) * 10) / 10;
const CommonStyles = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Nunito', sans-serif;
    }

    body {
        width: var(--width);
        height: var(--height);
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        --w1: ${w1}px;
        --_w1: ${-w1}px;
        background-color: var(--win-bg-color);
        overflow: hidden;
    }

    .theme-transition{
        transition: all 150ms ease-in;
    }

    input, button, select, textarea{
        border: none;
        outline: none;
        font-size: inherit;
        color: inherit;
        background: transparent;
    }

    input::placeholder{
        color: inherit;
    }

    .cp{
        cursor: pointer;
    }

    .cd{
        cursor: default;
    }

    button{
        cursor: pointer;
    }

    button:disabled{
        opacity: .5;
    }  

    .tooltip {
        position: relative;
        display: inline-block;
      }
      
      /* Tooltip text */
    .tooltip .tooltiptext {
       visibility: hidden;
       background-color: var(--black-text);
       color: var(--white-color);
       text-align: center;
       padding: 2px 5px;
       border-radius: 6px;
       width: auto;
       bottom: 100%;
    //    left: 50%;
    //    margin-left: -40px;
       position: absolute;
       z-index: 1;
       margin-bottom: 2px;
       -webkit-text-wrap: nowrap;
    }
      /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
          }

`;

export default CommonStyles;