export class AddBuildingDTO {
  id!: number;
  title: string;
  short_name: string;
  building_length!: number;
  building_width!: number;
  img_url: any = null;
  map_img_url: any = null;
  latitude!: number;
  longitude!: number;
  location_type_id: string;
  building_type_id: string;
  location_id: any;
  campus: string;
  faculty: string;
  department: string;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
