
import { FC, Fragment, useEffect, useState } from "react";

import {
  BuildingsListingMain,
  BuildingsListingSection,
  BuildingsListingTop,
} from "./style";

import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  RoomActionIconSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import useOrganization from "../../useHooks";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import Breadcrumb from "components/particles/breadcrumb";
import useStore from "hooks/useStore";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import { getAllByAltText } from "@testing-library/react";
import { AddBuildingDTO } from "utils/helpers/models/organization/add-building.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";

interface Props { }

const columns: string[] = [
  "Image",
  "Campus",
  "Building title",
  "Building Length",
  "Building Width",
  "Latitude",
  "Longitude",
  "Short Name",
  "Action",
];

const BuildingStructureTypeListing: FC<Props> = ({ }) => {
  const breadcrumbLinks = [
    {
      title: "Infrastructure & Asset Management /",
      path: "",
    },
    {
      title: "Building Structure Listing",
      path: siteRoutes.buildingStructureListing,
    },
  ];

  const navigate = useNavigate();
  const { hasAccess } = useStore();
  const { handleTableSearch, handleSearchChange } = useUtils();
  const { getBuildingStucutureTypesList, deleteBuildingStructureTypeById } = useOrganization();
  const [data, setData] = useState<AddBuildingDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const goToCreateBuilding = () => {
    navigate(siteRoutes.createBuilding);
  };

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search
      }
      deleteBuildingStructureTypeById(id, setData, queryParams, setPagination);
    };
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getBuildingStructureTypeList(page + 1, search, per_page);
  };

  const goToUpdateBuilding = (id: number) => {
    navigate(`${siteRoutes.createBuilding}?id=${id}`);
  };

  const goToRoomsListing = (id: number) => {
    navigate(`${siteRoutes.roomsListing}?id=${id}`);
  };

  const getBuildingStructureTypeList = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getBuildingStucutureTypesList(setData, queryParams, setPagination);
  }

  useEffect(() => {
    getBuildingStructureTypeList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <BuildingsListingMain>
      <BuildingsListingTop>
        <div className="left">
          <span className="page-heading">Building</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createBuilding) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={goToCreateBuilding}>
                + Add Building
              </button>
            </div>
          )}
        </div>
      </BuildingsListingTop>
      <BuildingsListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadBuildingListPdf) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadBuildingListExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, pagination.per_page, getBuildingStructureTypeList)
              }
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getBuildingStructureTypeList)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((structure, index) => (
                <tr key={index}>
                  <td>
                    <img className="org-logo" src={structure?.img_url} alt={`${structure?.title + " " + 1} img`} />
                  </td>
                  <td>{structure?.campus ?? "-"}</td>
                  <td>
                    <div className="mw-150">{structure?.title}</div>
                  </td>
                  <td>{structure?.building_length ?? "-"}</td>
                  <td>{structure?.building_width ?? "-"}</td>
                  <td>{structure?.latitude ?? "-"}</td>
                  <td>{structure?.longitude ?? "-"}</td>
                  <td>
                    <div className="mw-150">{structure?.short_name}</div>
                  </td>
                  <td>
                    <div className="table-action-icons">
                      {hasAccess(sitePermissions.editeBuildingStructure) && (
                        <div
                          className="action-icon"
                          onClick={() => goToUpdateBuilding(structure.id)}
                        >
                          <EditTableSvg />
                        </div>
                      )}
                      <div className="action-icon" title="Rooms" onClick={() => goToRoomsListing(structure?.id)}>
                        <RoomActionIconSvg />
                      </div>

                      {hasAccess(sitePermissions.deleteBuildingStructure) && (
                        <div
                          className="action-icon"
                          onClick={() => handleDelete(structure?.id)}
                        >
                          <DeleteTableSvg />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
              }

            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </BuildingsListingSection>
    </BuildingsListingMain >
  );
};

export default BuildingStructureTypeListing;
