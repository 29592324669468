import { CityDTO } from "../organization/city.dto";

export class TestingCenterDTO {
    id!: number;
    name: string = '';
    description: string = '';
    address: string = '';
    capacity: string = '';
    phone_no: string = '';
    lat: string = '';
    long: string = '';
    is_active!: number;
    city: string = '';
    email: string = '';
    center_cities!: CityDTO;
    organizations_id: number;

    constructor(data: Partial<TestingCenterDTO> = {}){
        for(let key in data){
            if(key === 'center_cities'){
                this.center_cities = new CityDTO(data[key]);
            }else{
                this[key] = data[key];
            }
        }
    }
}