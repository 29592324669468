import { FC, Fragment, useState, useEffect } from "react";
import {
    DeleteTableSvg,
    DownArrowLightgrayMediumSvg,
    EditTableSvg,
    ExcelSvg,
    PdfSvg,
    SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
    AdmissionApplicantsListingMain,
    AdmissionApplicantsListingSection,
    AdmissionApplicantsListingTop,
    FilterHeader,
    Filters,
    FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useTimeTable from "../../UseHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import useOrganization from "containers/private/organization/useHooks";
import { TimeTablePlanerDTO } from "utils/helpers/models/time-table/time-table-planer.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import useAcademics from "containers/private/academics/useHooks";
interface AdmissionApplicantsListingProps { }

const AdmissionApplicantsListing: FC<AdmissionApplicantsListingProps> = ({ }) => {

    const breadcrumbLinks = [
        { title: "Time Table /", path: siteRoutes.timeTableListing },
        { title: "Time Table Planner Listing", path: siteRoutes.timeTablePlannerListing },
    ];

    const navigate = useNavigate();
    const { getQueryParams } = useUtils();
    const params = getQueryParams();

    const {
        getTimeTablePlanner,
        deleteTimeTablePlannerById,
        updateTimeTablePlannerById,
    } = useTimeTable();
    const {
        getSpecificOrgStructures,
    } = useOrganization();
    const {
        getAcademicSessions,
    } = useAcademics();

    const [academicSession, setAcademicSession] = useState<AcademicSessionDTO[]>([]);
    const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
    const [search, setSearch] = useState("");
    const { hasAccess } = useStore();
    const { isLoading } = useSelector((state: any) => state.sharedReducer);
    const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
    const [data, setData] = useState<TimeTablePlanerDTO[]>([]);
    const [filters, setFilters] = useState<any>({
        campus: "",
        academic_session: "",
        is_active: "",
    });
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });
    const columns: string[] = [
        "",
        "Planer Title",
        "Academic Session",
        "Campuses",
        "Status",
        "Linked Time Table",
    ];

    const toggleRowExpand = (index: number) => {
        (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
        setData([...data]);
    };

    const updateTimeTableByID = (id: number) => {
        navigate(`${siteRoutes.timeTablePlannerCreate}?id=${id}`);
    }

    const goToAddTimeTablePlanner = () => {
        navigate(siteRoutes.timeTablePlannerCreate);
    };

    const handleDelete = async (id: number) => {
        const result = await confirmationPopup();
        if (result.isConfirmed) {
            const queryParams = {
                page: 1,
                per_page: pagination.per_page,
                search,
            };
            deleteTimeTablePlannerById(id, setData, queryParams, setPagination);
        }
    };

    const toggleFilterDropdown = () => {
        setOpenFilterDropdown(!openFilterDropdown);
    };

    const handleFilterChange = (event: any) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));

    }
    const onSubmitFilters = () => {
        getTimeTablePlannerListing();
        console.log(filters)
    };

    const handleChangeTimeTablePlannerStatus = async (planner: TimeTablePlanerDTO, index: number) => {
        const response = await confirmationPopup();
        if (response.isConfirmed) {
            const { id } = planner;
            data[index] = planner;
            setData([...data]);
            updateTimeTablePlannerById(id, planner);
        }
    }

    const onClearFilters = () => {
        const clearedFilters = Object.keys(filters).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setFilters(clearedFilters);
        getTimeTablePlannerListing();
    };


    const handleUpdateClass = async (event: any, courseId: number, index: number) => {
        const { checked } = event.target;
        const result = await confirmationPopup();
        if (result.isConfirmed) {
            const is_class = checked ? 1 : 0;
        }
    }

    const getTimeTablePlannerListing = () => {
        const filterOptions = { ...filters };

        for (let key in filterOptions) {
            if (!filters[key]) {
                delete filterOptions[key];
            }
        }
        const queryParams = {
            per_page: pagination.per_page,
            page: pagination.page,
            search,
            ...filterOptions,
        };
        getTimeTablePlanner(setData, queryParams, setPagination);
    }

    useEffect(() => {
        getAcademicSessions(setAcademicSession);
        getSpecificOrgStructures(setCampuses, { type: OrgStructureTypes.campus, per_page: 'All' });
        getTimeTablePlannerListing();
    }, []);

    return (
        <AdmissionApplicantsListingMain>
            <AdmissionApplicantsListingTop>
                <div className="left">
                    <span className="page-heading">Time Table Planner Listing</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">
                    {hasAccess(sitePermissions.timeTablePlannerCreate) && (
                        <div className="download-list-button">
                            <button className="lg-rounded-btn" onClick={goToAddTimeTablePlanner}>+ Add Time Table Planner</button>
                        </div>
                    )}
                </div>
            </AdmissionApplicantsListingTop>

            <FilterSection className="content-radius-shadow">
                <FilterHeader $showFilterDropdown={openFilterDropdown}>
                    <span className="filter-heading">Filter</span>
                    <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
                        <DownArrowLightgrayMediumSvg className="icon" />
                    </span>
                </FilterHeader>
                {openFilterDropdown && (
                    <Filters>
                        <div className="filter-fields">

                            <div className="input-field">
                                <label htmlFor="campus-select">Campus</label>
                                <div className="field-wrap">
                                    <div className="field">
                                        <select
                                            id="campus"
                                            name="campus"
                                            value={filters.campus}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {
                                                campuses?.map((item: any, i: number) => {
                                                    return (
                                                        <option key={i} value={item?.id}>{item?.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="input-field">
                                <label htmlFor="campus-select">Academic Session</label>
                                <div className="field-wrap">
                                    <div className="field">
                                        <select
                                            id="campus-select"
                                            name="academic_session"
                                            value={filters.academic_session}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="">Select Academic Session</option>
                                            {
                                                academicSession?.map((item: any, i: number) => {
                                                    return (
                                                        <option key={i} value={item?.session?.id}>{item?.session?.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="radio-field">
                            <label htmlFor="no">Is Active</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="radio"
                                        id="is_active_yes"
                                        value={1}
                                        name="is_active"
                                        checked={filters.is_active === "1"}
                                        onChange={handleFilterChange}
                                    />
                                    <label htmlFor="is_active_yes">Activate</label>
                                </div>
                                <div className="field">
                                    <input
                                        type="radio"
                                        id="is_active_no"
                                        value={0}
                                        name="is_active"
                                        checked={filters.is_active === "0"}
                                        onChange={handleFilterChange}
                                    />
                                    <label htmlFor="is_active_no">Deactivate</label>
                                </div>
                            </div>
                        </div>

                        <div className="submit-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                                    onClick={onClearFilters}
                                >
                                    Reset
                                </button>
                                <button className="lg-rounded-btn" onClick={onSubmitFilters}>
                                    Apply Filters
                                </button>
                            </div>
                        </div>

                    </Filters>
                )}
            </FilterSection>

            <AdmissionApplicantsListingSection className="content-radius-shadow">
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        {hasAccess(sitePermissions.admissionStudentDownloadPDF) && (
                            <div className="export-btn">
                                <span>
                                    <PdfSvg className="icon" />
                                </span>
                                <span className="text">PDF</span>
                            </div>
                        )}
                        {hasAccess(sitePermissions.admissionStudentDownloadExcel) && (
                            <div className="export-btn">
                                <span>
                                    <ExcelSvg className="icon" />
                                </span>
                                <span className="text">Excel</span>
                            </div>
                        )}
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>

                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return (
                                        <th
                                            key={index}
                                            className={
                                                index > 6 ? `extra-head extra-head-${index - 6}` : ""
                                            }
                                        >
                                            {column}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map(
                                    (planner, index) => {
                                        const { is_active } = planner;
                                        return (

                                            <Fragment key={index}>
                                                <tr className="expandable opened">
                                                    <td>
                                                        <div
                                                            className="rounded-expand-button"
                                                            onClick={() => toggleRowExpand(index)}
                                                        >
                                                            <span>{(planner as any).isExpanded ? "-" : "+"}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="mw-150">{planner.title}</div>
                                                    </td>
                                                    <td>
                                                        <div className="mw-150">{planner?.academic_session?.type ?? "--"}</div>
                                                    </td>
                                                    <td>
                                                        <div className="mw-150">{planner?.campus?.title ?? "--"}</div>
                                                    </td>
                                                    <td>
                                                        <label className="switch-field">
                                                            <input type="checkbox" checked={is_active == 1} onChange={({ target: { checked } }) => handleChangeTimeTablePlannerStatus({ ...planner, is_active: checked ? 1 : 0 }, index)} />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div className="mw-150">--</div>
                                                    </td>

                                                </tr>

                                                {(planner as any).isExpanded && (
                                                    <tr className="expanded-row">
                                                        <td></td>
                                                        <td colSpan={10}>
                                                            <div className="expanded-content">
                                                                {/* Expanded Row Details */}

                                                                <div className="particular-info particular-info-1">
                                                                    <span className="title">Buildings: </span>
                                                                    <span className="info">{planner.building_id.length}</span>
                                                                </div>
                                                                <div className="particular-info particular-info-2">
                                                                    <span className="title">Facilties: </span>
                                                                    <span className="info">{planner.faculty_id.length}</span>
                                                                </div>
                                                                <div className="particular-info particular-info-2">
                                                                    <span className="title">Department: </span>
                                                                    <span className="info">{planner.department_id.length}</span>
                                                                </div>
                                                                <div className="particular-info particular-info-2">
                                                                    <span className="title">Programs: </span>
                                                                    <span className="info">{planner.program_id.length}</span>
                                                                </div>
                                                                <div className="particular-info particular-info-2">
                                                                    <span className="title">Sections: </span>
                                                                    <span className="info">{planner.section_id.length}</span>
                                                                </div>
                                                                <div className="particular-info particular-info-2">
                                                                    <span className="title">Action</span>
                                                                    <div className="table-action-icons">
                                                                        <Fragment>
                                                                            {hasAccess(sitePermissions.timeTableUpdate) && (
                                                                                <div
                                                                                    className="action-icon"
                                                                                    onClick={() => updateTimeTableByID(planner?.id)}
                                                                                >
                                                                                    <EditTableSvg />
                                                                                </div>
                                                                            )}
                                                                            {hasAccess(sitePermissions.timeTableDelete) && (
                                                                                <div
                                                                                    className="action-icon"
                                                                                    onClick={() => handleDelete(planner?.id)}
                                                                                >
                                                                                    <DeleteTableSvg />
                                                                                </div>
                                                                            )}

                                                                            <div className="action-button">
                                                                                <button>Manage Planner</button>
                                                                            </div>
                                                                        </Fragment>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Fragment>
                                        )
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    <Fragment>
                        <DataNotFound show={!isLoading && !Object.keys(data)?.length} />
                        {/* <Pagination
              onPageChange={(page: any) =>  console.log(page)}
              {...pagination}
            /> */}
                    </Fragment>
                </div>
            </AdmissionApplicantsListingSection>
        </AdmissionApplicantsListingMain>
    );
};

export default AdmissionApplicantsListing;
