import { FC, useEffect, useRef, useState } from "react";
import { DropdownMain, MultiselectFieldMain } from "./style";
import { useSelector } from "react-redux";
import useComponentVisible from "hooks/click-outside";
import FormErrorMessage from "../form-error-message";
interface MultiselectProps {
    options: any[];
    onSelect: (value: number[]) => void;
    value: number[];
    property?: string;
    searchable?: boolean;
    disabled?: boolean;
    label: string;
    onOpen?: Function;
    onClose?: (hasChanged?: boolean) => void;
    helperText?: string;
    error?: any;
}

const MultiselectField: FC<MultiselectProps> = ({
    options,
    onSelect,
    value = [],
    property = 'title',
    searchable,
    label = '',
    disabled = false,
    helperText = '',
    onOpen,
    onClose,
    error
}) => {
    const { isLoading } = useSelector((state: any) => state.sharedReducer);
    const [searchedOptions, setSearchedOptions] = useState<any[]>([]);
    const { ref, setIsComponentVisible, isComponentVisible } = useComponentVisible();
    const [search, setSearch] = useState<string>('');
    const searchRef = useRef(null);

    const toggleShowDropdown = () => {
        setIsComponentVisible(!isComponentVisible);
    }

    useEffect(() => {
        if (isComponentVisible) {
            if (searchable && searchRef && options.length) {
                (searchRef as any).current.focus();
            }
            if (onOpen) {
                onOpen();
            }
        } else {
            if (onClose) {
                onClose();
            }
        }
    }, [isComponentVisible])

    const handleSelect = (item: any) => {
        if (!disabled) {
            if (!item?.disabled) {
                const { id } = item;
                if (value.includes(id)) {
                    const index = value.indexOf(id);
                    value.splice(index, 1);
                } else {
                    value.push(id);
                }
                onSelect(value);
            }
        }
    };

    const handleSearchChange = (event: any) => {
        const { value } = event.target;
        setSearch(value);
        const helper = options.filter((item) =>
            item[property].toLowerCase().includes(search.toLowerCase())
        );
        setSearchedOptions([...helper]);
    };

    const getOptions = (): any[] => {
        return search === '' ? options : searchedOptions;
    }

    const areAllSelected = (): boolean => {
        return options.filter(o => !o?.disabled).every(o => value.includes(o.id));
    }

    const onSelectAll = () => {
        options.forEach(o => {
            const { id } = o;
            if (!value.includes(id) && !o?.disabled) {
                value.push(id);
            }
        })

        onSelect(value);
    }

    const onDeSelectAll = () => {
        value = [];
        onSelect(value);
    }

    return (
        <MultiselectFieldMain ref={ref}>
            <div className="input-field" onClick={toggleShowDropdown}>
                <label>{label}</label>
                <div className="field-wrap">
                    <div className="field">
                        <div className="selected-items">
                            <input
                                type="text"
                                placeholder={helperText}
                                readOnly
                                className="cp"
                            />
                            {(value ?? []).length ? (
                                <div className="item">
                                    {value.length} selected
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isComponentVisible && <DropdownMain>
                <ul className="p-custom-scrollbar-8">
                    {(searchable && options.length) ?
                        <div className="search-section">
                            <input type="search" placeholder="Search" onChange={handleSearchChange} ref={searchRef} />
                        </div> : ''
                    }
                    {(options.length && !disabled) ? areAllSelected() ? <li onClick={onDeSelectAll}>
                        <div className="checkbox">
                            <input type="checkbox" checked={areAllSelected()} readOnly className="hidden" />
                        </div>
                        <div className="item-text">
                            <span className="text">
                                Deselect All
                            </span>
                        </div>
                    </li> : <li onClick={onSelectAll}>
                        <div className="checkbox">
                            <input type="checkbox" checked={areAllSelected()} readOnly className="hidden" />
                        </div>
                        <div className="item-text">
                            <span className="text">
                                Select All
                            </span>
                        </div>
                    </li> : ''}
                    {getOptions().map((item: any, index: number) => {
                        return (
                            <li key={index} onClick={() => handleSelect(item)} className={`${(item?.disabled || disabled) ? 'disabled' : ''}`}>
                                <div className="checkbox">
                                    <input type="checkbox" checked={value.includes(item.id)} readOnly disabled={disabled} />
                                </div>
                                <div className="item-text">
                                    <span className="text">
                                        {item?.[property]}
                                    </span>
                                </div>
                            </li>
                        );
                    })}
                    {!getOptions().length && !isLoading && (
                        <span className="no-options-found">No options found</span>
                    )}
                </ul>
            </DropdownMain>}
            {(error && !isComponentVisible) ? <FormErrorMessage error={error} /> : ''}
        </MultiselectFieldMain>
    );
};

export default MultiselectField;
