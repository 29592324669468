import { BankInfoDTO } from "./bank-info.dto";

export class FinanceApplicationDTO {
        id!: number;
        title: string = '';
        code: string = '';
        token: string = '';
        challan_title: string = '';
        bank_ids: number[] = [];
        banks!: BankInfoDTO[];

        constructor(data: Partial<FinanceApplicationDTO> = {}) {
                for (let key in data) {
                        if (key === 'banks') {
                                this[key] = Array.isArray(data[key]) ? data[key].map(i => new BankInfoDTO(i)) : []
                        } else {
                                this[key] = data[key];
                        }
                }
        }
}