import { FC, Fragment, useEffect, useState } from "react";
import { Main, STListingSection, EmailTempTop } from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import {
    DeleteTableSvg,
    EditTableSvg,
    ExcelSvg,
    PdfSvg,
    SearchFieldSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import useOrganization from "containers/private/organization/useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useEmailTemplate from "../UseHooks";

interface StructureTypeListingProps { }

const EmailTemplateListing: FC<StructureTypeListingProps> = () => {
    const breadcrumbLinks = [
        {
            title: "Email Template /",
            path: "",
        },
        {
            title: "Email Template Listing ",
            path: siteRoutes.emailTemplateListing,
        },
    ];

    const columns = ["Subject", "Name", "Key", "Actions"];

    const {
        getEmailTemplateList,
        deleteEmailTemplatesById,
    } = useEmailTemplate();

    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const { getQueryParams, handleSearchChange, handleTableSearch } = useUtils();

    const params = getQueryParams();
    const [data, setData] = useState<any[]>([]);

    const { hasAccess, isSuperAdmin } = useStore();
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });

    const { isLoading } = useSelector((state: any) => state.sharedReducer);

    const GoToAddEmailTemplate = () => {
        navigate(siteRoutes.emailTemplateCreate);
    };

    const getEmailTemplates = () => {
        const queryParams = {
            per_page: "All",
            page: 1,
        }
        getEmailTemplateList(setData, queryParams);
    }

    const handleDelete = async (id: number) => {
        const result = await confirmationPopup();
        if (result.isConfirmed) {
            deleteEmailTemplatesById(id);
            getEmailTemplates();
        }
    };

    const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
        const { selected: page, per_page } = pageInfo;
        setPagination({ ...pagination, page: page + 1, per_page });
        getAllStructureTypes(page + 1, search);
    };

    const getAllStructureTypes = (page: number, search: string) => {
        const queryParams = {
            per_page: pagination.per_page,
            page,
            search,
        };
        if (params?.organizationId || isSuperAdmin()) {
            // getStructureTypesSuperAdmin(
            //     params?.organizationId,
            //     setData,
            //     queryParams,
            //     setPagination,
            //     search
            // );
        } else {
            // getStructureTypesAdmin(setData, queryParams, setPagination);
        }
    };

    const goToUpdateEmailTemplate = (id: number) => {
        navigate(
            `${siteRoutes.emailTemplateCreate}?id=${id}`
        );
    };

    // const handleDownloadExcelList = () => {
    //     if (params?.organizationId) {
    //         downloadStructureTypesExcelFileBySuperAdmin();
    //     } else {
    //         downloadStructureTypesExcelFileByAdmin();
    //     }
    // const handleDownloadPdfList = () => {
    //     if (params?.organizationId) {
    //         downloadStructureTypesPdfFileBySuperAdmin();
    //     } else {
    //         downloadStructureTypesPdfFileByAdmin();
    //     }
    // };
    // };
    useEffect(() => {
        getEmailTemplates();
    }, [])

    // console.log(data);
    return (
        <Main>
            <EmailTempTop>
                <div className="left">
                    <span className="page-heading">Email Templates</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">
                    {hasAccess(sitePermissions.emailTemplateCreate) && (
                        <div className="create-org-btn">
                            <button className="lg-rounded-btn" onClick={GoToAddEmailTemplate}>
                                + Add Email Template
                            </button>
                        </div>
                    )}
                </div>
            </EmailTempTop>

            <STListingSection className="content-radius-shadow">
                {/* <div className="list-detail">
                    <div className="page-sub-heading">
                        <span>Email Templates</span>
                    </div>
                </div> */}
                <div className="list-header">
                    {/* <div className="table-data-export-buttons">
                        {hasAccess(sitePermissions.downloadStructureTypesListPdf) && (
                            <div className="export-btn" onClick={handleDownloadPdfList}>
                                <span>
                                    <PdfSvg className="icon" />
                                </span>
                                <span className="text">PDF</span>
                            </div>
                        )}
                        {hasAccess(sitePermissions.downloadStructureTypesListExcel) && (
                            <div className="export-btn" onClick={handleDownloadExcelList}>
                                <span>
                                    <ExcelSvg className="icon" />
                                </span>
                                <span className="text">Excel</span>
                            </div>
                        )}
                    </div> */}
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => handleSearchChange(e, setSearch, pagination.per_page, getAllStructureTypes)}
                            onKeyUp={e => handleTableSearch(e, pagination.per_page, getAllStructureTypes)}
                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.subject}</td>
                                        <td>{item?.template_name}</td>
                                        <td>{item?.key}</td>
                                        <td>
                                            <div className="table-action-icons">
                                                <Fragment>
                                                    {hasAccess(sitePermissions.emailTemplateEdit) && (
                                                        <div
                                                            className="action-icon"
                                                            onClick={() => goToUpdateEmailTemplate(item?.id)}
                                                        >
                                                            <EditTableSvg />
                                                        </div>
                                                    )}

                                                    {hasAccess(sitePermissions.emailTemplateDelete) && (
                                                        <div
                                                            className="action-icon"
                                                            onClick={() => handleDelete(item?.id)}
                                                        >
                                                            <DeleteTableSvg />
                                                        </div>
                                                    )}
                                                </Fragment>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    <DataNotFound show={!isLoading && !data.length} />
                    <Pagination onPageChange={onPageChange} {...pagination} />
                </Fragment>
            </STListingSection>
        </Main>
    );
};

export default EmailTemplateListing;
