import { createGlobalStyle } from "styled-components";

const VariablesStyles = createGlobalStyle`
    :root{
        --primary: #128B9B;
        --blue-color: #65C8FF;
        --blue-light-color:rgb(132, 210, 252);
        --light-gray:rgb(209, 207, 207);
        --black-text: #272A3A;
        --white-text: #ffff;
        --white-bg: #ffff;
        --lightgray-light: #878A99;
        --blue-light: #878A99;
        --lightgray-medium: #5C5E69;
        --metalic-gray: #8BA3CB;
        --spring-green: #0FCB02;
        --medium-red: #FF4B4A;
        --field-border: #E9EBEC;   
        --white-color: #FFFFFF;
        --win-bg-color: #f2f6f9; 
        --gray-medium: #CED4DA;
        --extra-dark-gray: #31374A;
        --gray-light: #CBD0DD;
        --3d-gray: #F2F4F7;
        --lightgray-extralight: #E6EFF5;
        --lightgray-medium-light: #E3E6ED;
        --lightgreen-medium: #43AC43;
        --green-dark:rgb(41, 121, 41);
        --lightgreen-shadow: rgba(15, 203, 2, 0.15);
        --warning-yellow: #FFBB38;
        --lightyellow-shadow: rgba(255, 187, 56, 0.15);
        --error-red: rgba(255, 75, 74, 1);
        --lightred-shadow: rgba(255, 187, 56, 0.15);
        --toastify-toast-width: 450px;
        --input-field-bg: transparent;
        --reset-button-bg: #CED4DA;
        --header-utility-icons-bg: #F5F7FA;
        --white-constant: #ffffff;
        --alert-popup-bg: #ffffff;
        --medium-orange: #FFA34F;
        --light-orange: #FFBB38;
        --orange-shadow: rgba(255, 187, 56, 0.15);
        --primary-extralight: rgba(18, 139, 171, 0.2);
        --modal-backshadow: rgba(217, 217, 217, 0.4);
        --table-row-hover: rgba(217, 217, 217, 0.2);
        --medium-crimson: #FF4B4A;
        --light-medium-crimson: #FF5959;
        --info-field-bg2: #F2F6F9;
        --info-field-bg1: #ffffff;
        --crimson-shadow: rgba(219, 75, 74, 0.1);
        --modal-grey : #6C757D;
        --info-bg:#F7FBFC;
        --light-medium-purple: #718EBF;
        --bright-yellow: #ffeaa7;
        --green-darner: #74b9ff;
        --green-extralight: rgba(15, 203, 2, 0.15);
        --yellow-extralight: rgba(255, 187, 56, 0.15);
        --metalic-purple-extralight: rgba(234, 238, 245, 1);
        --orange-extralight: rgba(251, 237, 237, 1);
        --mature-green-extralight: rgba(130, 189, 193, 0.1);
        --purple-extralight: rgba(74, 58, 255, 0.1);
        --glowing-yellow-extralight: rgba(254, 252, 233, 1);
        --sea-green-extralight: rgba(18, 139, 171, 0.15);
        --dark-black-constant: #000000;
        --black-extralight: rgba(0,0,0,0.3);
        --zoom-view-height: 100vh;
        --zoom-view-width: 100vw;
        --silver-color:silver;


        //font-variables

        --font-24: 24px;
        --font-20: 20px;
        --font-32: 32px;
        --font-18: 18px;
        --font-14: 14px;
        --font-16: 16px;

        /* //zoom variables */

        --zoom-level: 80%;
        /* --zoom-level: 100%; */

        //box shadow
        --content-box-shadow: 0px 4px 70px 0px #0000001A;
    }

    body{
        &.dark{
        --black-text: #fff;
        --lightgray-light: #fff;
        --lightgray-medium: #fff;
        --metalic-gray: #fff;
        --spring-green: #0FCB02;
        --medium-red: #FF4B4A;
        --white-color: #1A1A1A;
        --win-bg-color: #121212;
        --gray-medium: #fff;
        --table-row-hover: rgba(217, 217, 217, 0.1);
        --gray-light: #fff;
        --3d-gray: #fff;
        --lightgray-extralight: #fff;
        --lightgray-medium-light: #fff;
        --error-red: rgba(255, 75, 74, 1);
        --input-field-bg: rgba(233, 235, 236, 0.05);
        --field-border: #E9EBEC0D;
        --reset-button-bg: rgba(233, 235, 236, 0.05);
        --header-utility-icons-bg: rgba(245, 247, 250, 0.05);
        --alert-popup-bg: rgba(57, 57, 57, 1);
        --info-field-bg2: rgba(242, 246, 249, 0.05);
        --info-field-bg1: rgba(233, 235, 236, 0.05);
        --modal-grey :  #fff;
        --info-bg: rgba(233, 235, 236, 0.05);
        --extra-dark-gray: white;
        }
    }
`;

export default VariablesStyles;
