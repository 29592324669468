import { TemplateCourseDTO } from "./template-course.dto";

export class VirtualCourseDTO<MultiSelectType extends number[] | string = number[]>{
    id: number;
    link_course_id: number
    template_course_id: MultiSelectType;
    template_courses: TemplateCourseDTO;

    constructor(data: Partial<VirtualCourseDTO> = {}){
        for(let key in data){
            if(key === 'template_courses'){
                this[key] = new TemplateCourseDTO(data[key]);
            }else{
                this[key] = data[key];
            }
        }
    }
}