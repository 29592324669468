import { FC, useEffect, useState } from "react";
import { AddAllocateTeacherMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import MultiselectField from "components/particles/forms/multiselect-field";
import { useForm } from "react-hook-form";
import useAdmissions from "containers/private/admissions/useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { SyncRegisteredStudentsDTO } from "utils/helpers/models/admissions/sync-registered-students.dto";
import useAcademics from "containers/private/academics/useHooks";

interface SyncStudentProps {
  setOpen: Function;
  std_ids: number[];
}

const SyncStudent: FC<SyncStudentProps> = ({ setOpen, std_ids }) => {
  const { syncRegisteredStudents } = useAdmissions();
  const [feePlans, setFeePlans] = useState<any[]>([]);
  const {getAcademicFeePlans} = useAcademics();
  const [formData, setFormData] = useState<SyncRegisteredStudentsDTO>(new SyncRegisteredStudentsDTO({ std_ids }));

  const {
    handleSubmit,
    setValue,
    register,
    trigger,
    formState: { errors },
  } = useForm<SyncRegisteredStudentsDTO>();

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFormData({...formData, [name]: value});
    setValue(name,value);
    trigger(name);
  }

  const onSubmit = () => {
    const { std_ids } = formData;
    syncRegisteredStudents({...formData, std_ids: std_ids.join(",")}, setOpen);
  };

  useEffect(() => {
    getAcademicFeePlans(setFeePlans);
  }, []);

  return (
    <AddAllocateTeacherMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Sync Students</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label>Fee Plan</label>
                <div className="field-wrap">
                  <div className="field">
                    <select {...register('fee_plan_id', {required: true})} value={formData.fee_plan_id} onChange={handleChange}>
                      <option value="">Select one</option>
                      {feePlans.map((plan,index) => {
                        return <option value={plan.id} key={index}>{plan.title}</option>
                      })}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.fee_plan_id}/>
                </div>
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="lg-rounded-btn">Save & Close</button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddAllocateTeacherMain>
  );
};

export default SyncStudent;
