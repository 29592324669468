import useStore from "hooks/useStore";
import { FC, ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface NonProtectedRoutesProps {
    children: ReactElement;
}

const NonProtectedRoutes: FC<NonProtectedRoutesProps> = ({ children }) => {
    const {
        getToken,
        getDomainDefaultAuthRoute,
        getUser,
        logout
    } = useStore();

    const token = getToken();
    const { is_admin } = getUser() || {};
    
    if (token) {
        if(is_admin){
            logout();
        }else{
            const authDefaultRoute = getDomainDefaultAuthRoute();
            return <Navigate to={authDefaultRoute} replace />
        }
    }

    return children;
}

export default NonProtectedRoutes;