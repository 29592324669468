export class SessionEnrollmentDTO<T extends number[] | string = number[]>{
    department_id!: number;
    program_id!: number;
    student_file!: File;
    course_ids: T;
    student_ids: T;
    enrollment_status: string; 


    constructor(data: Partial<SessionEnrollmentDTO<number[]>> = {}){
        Object.assign(this,data);
    }
}