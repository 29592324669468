export class AddToMeritDTO{
    exp_date: string = '';
    offer_date: string = '';
    adm_session_id: number;
    quota_id: number;
    app_no: string = '';
    merit: number;

    constructor(data: Partial<AddToMeritDTO> = {}){
        Object.assign(this,data);
    }
}