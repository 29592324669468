import { FC, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import { AddToMeritDTO } from "utils/helpers/models/academics/add-to-merit.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useAdmissions from "containers/private/admissions/useHooks";

class Props implements Omit<AddToMeritDTO, 'offer_date' | 'exp_date'>{
  setOpen: Function;
  adm_session_id: number;
  quota_id: number;
  merit: number;
  app_no: string;
}

const AddToMerit: FC<Props> = ({ setOpen, adm_session_id, quota_id, merit, app_no }) => {
  const [formData, setFormData] = useState<AddToMeritDTO>(new AddToMeritDTO({adm_session_id,quota_id,merit,app_no}));
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<AddToMeritDTO>();
  
  const { addToMerit } = useAdmissions();


  const onSubmit = () => {
    addToMerit(formData, setOpen);
  };

  const handleChange = (event: any) => {
    const {target: {name,value}} = event;
    setValue(name,value);
    trigger([name]);
    setFormData({...formData, [name]: value});
  }

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Add to Merit</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
                <div className="input-field">
                    <label htmlFor="">Offer Date</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input type="date" {...register('offer_date', {required: true})} value={formData.offer_date} onChange={handleChange} />
                        </div>
                        <FormErrorMessage error={errors.offer_date}/>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor="">Exp Date</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input type="date" {...register('exp_date', {required: true})} value={formData.exp_date} onChange={handleChange} />
                        </div>
                        <FormErrorMessage error={errors.exp_date}/>
                    </div>
                </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Submit</button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default AddToMerit;
