import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicSessionManagementListingSection,
  AcademicSessionManagementListingTop,
  AcademicSessionManagementListingMain,
} from "./style";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  RedCloseSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import AllocateSection from "../components/allocate-section";
import AllocateTeacher from "../components/allocate-teacher";
import GradingSchema from "../components/grading-schema";
import useAcademics from "../../useHooks";
import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import AllocateCourseRooms from "../components/allocate-course-rooms";
import ManageEnrollments from "../components/manage-enrollments";
import {
  confirmationPopup,
  customConfirmationPopup,
} from "utils/helpers/common/alert-service";
import { spacing } from "react-select/dist/declarations/src/theme";
import { AcademicSectionDTO } from "utils/helpers/models/academics/academic-section.dto";

interface AcademicSessionManagementListingProps {}

const AcademicSessionManagementListing: FC<
  AcademicSessionManagementListingProps
> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Academic Sessions / ", path: siteRoutes.academicSessionListing },
    {
      title: "Manage Academic Sessions",
      path: siteRoutes.academicSessionManagementListing,
    },
  ];

  const {
    getSessionAllocatedCourses,
    deleteSessionAllocatedCourses,
    deleteAcademicSessionSection,
    deleteAcademicSessionTeacher,
    updateSessionCourseClass,
  } = useAcademics();

  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch, getQueryParams, createQuery } =
    useUtils();
  const { session_id } = getQueryParams();

  const columns: string[] = [
    "",
    "Course Code",
    "Course Title",
    "Credit Hour's",
    "Sections",
    "Teachers",
    // "Result Status",
    // "Course ID",
    // "Associated Section",
    // "Teacher Name",
    // "WorkLoad Type",
    // "Action",
  ];
  const [openAllocateSectionModal, setOpenAllocateSectionModal] =
    useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [openAllocateTeacherModal, setOpenAllocateTeacherModal] =
    useState<boolean>(false);
  const [openEnrollmentsModal, setOpenEnrollmentsModal] =
    useState<boolean>(false);
  const [openGradeSchemaModal, setOpenGradeSchemaModal] =
    useState<boolean>(false);
  const [courseId, setCourseId] = useState<number>();
  const [enrollmentType, setEnrollmentType] = useState<string>("");
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [allocateCourseRoom, setAlocateCourseRoom] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");

  const goToGradeSchema = () => {
    setOpenGradeSchemaModal(true);
  };

  const goToGradeBooks = (course_id: number) => {
    navigate(`${siteRoutes.academicGradeBookListing}?course_id=${course_id}`);
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllSessionAllocatedCourses(page + 1, search, per_page);
  };
  const getAllSessionAllocatedCourses = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      per_page,
      page,
      search,
      session_id,
    };
    getSessionAllocatedCourses(setData, queryParams, setPagination);
  };

  const handleUpdateClass = async (
    event: any,
    courseId: number,
    index: number
  ) => {
    const { checked } = event.target;
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const is_class = checked ? 1 : 0;
      data[index].is_class = is_class;
      setData([...data]);
      updateSessionCourseClass({ is_class }, courseId);
    }
  };

  const goToAddCourses = () => {
    navigate(`${siteRoutes.academicCourseListing}?session_id=${session_id}`);
  };
  const handleOpenAllocateRoomModal = (courseId: number) => {
    setCourseId(courseId);
    setAlocateCourseRoom(true);
  };

  const toggleDropdown = (index: number) => {
    data[index].isDropdownOpen = !data[index].isDropdownOpen;
    setData([...data]);
  };
  const toggleRowExpand = (index: number) => {
    data[index].isExpanded = !data[index].isExpanded;
    setData([...data]);
  };

  const isAnyMenuDropdownOpen = (): boolean => {
    return data.some((i) => i.isDropdownOpen);
  };

  const handleCourseSelection = (courseId: number) => {
    if (selectedCourses.includes(courseId)) {
      const index = selectedCourses.indexOf(courseId);
      selectedCourses.splice(index, 1);
    } else {
      selectedCourses.push(courseId);
    }

    setSelectedCourses([...selectedCourses]);
  };

  const handleEnrollment = async () => {
    const result = await customConfirmationPopup(
      "Which would you like to enroll students",
      "Upload File",
      "Add Manually"
    );
    if (result.isConfirmed) {
      setEnrollmentType("upload");
    } else {
      setEnrollmentType("manual");
    }

    setOpenEnrollmentsModal(true);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        session_id,
        search,
      };
      deleteSessionAllocatedCourses(id, setData, queryParams, setPagination);
    }
  };

  const handleDeleteSection = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const queryParams = {
        per_page: pagination.per_page,
        page: 1,
        session_id,
      };
      deleteAcademicSessionSection(id, setData, queryParams, setPagination);
    }
  };
  const handleDeleteTeacher = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const queryParams = {
        per_page: pagination.per_page,
        page: 1,
        session_id,
      };
      deleteAcademicSessionTeacher(id, setData, queryParams, setPagination);
    }
  };

  const showCreditHours = (course: {
    tc_credit_hours: string;
    tc_lab_credit_hours: string;
  }) => {
    const { tc_credit_hours, tc_lab_credit_hours } = (course as any)
      .template_courses;

    if (!tc_credit_hours && tc_lab_credit_hours) {
      return {
        value: tc_lab_credit_hours,
        title: "Lab Credit Hours",
      };
    } else if (tc_credit_hours && !tc_lab_credit_hours) {
      return {
        value: tc_credit_hours,
        title: "Theory Credit Hours",
      };
    } else if (tc_credit_hours && tc_lab_credit_hours) {
      return {
        value: `${tc_credit_hours}  ${tc_lab_credit_hours}`,
        titles: [
          { value: tc_credit_hours, title: "Theory Credit Hours" },
          { value: tc_lab_credit_hours, title: "Lab Credit Hours" },
        ],
      };
    } else {
      return {
        value: "NCrH",
        title: "Non Credit Hour Course",
      };
    }
  };

  useEffect(() => {
    if (
      !openAllocateSectionModal ||
      !openAllocateTeacherModal ||
      !openEnrollmentsModal
    ) {
      if (
        !openAllocateSectionModal &&
        !openAllocateTeacherModal &&
        !openEnrollmentsModal
      ) {
        setSelectedCourses([]);
        getAllSessionAllocatedCourses(1, search, pagination.per_page);
      }
    }
  }, [
    openAllocateSectionModal,
    openAllocateTeacherModal,
    openEnrollmentsModal,
  ]);

  return (
    <AcademicSessionManagementListingMain>
      <AcademicSessionManagementListingTop>
        <div className="left">
          <span className="page-heading">Manage Academic Sessions</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {selectedCourses.length ? (
            <Fragment>
              <div className="allocation-btn">
                <button
                  className="lg-rounded-btn black"
                  onClick={() => setOpenAllocateSectionModal(true)}
                >
                  Allocate Sections
                </button>
              </div>
              <div className="allocation-btn">
                <button
                  className="lg-rounded-btn black"
                  onClick={() => setOpenAllocateTeacherModal(true)}
                >
                  Allocate Teachers
                </button>
              </div>
              <div className="allocation-btn">
                <button
                  className="lg-rounded-btn spring"
                  onClick={handleEnrollment}
                >
                  Manage Enrollments
                </button>
              </div>
            </Fragment>
          ) : (
            ""
          )}
          {hasAccess(sitePermissions.academicCourseListing) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={goToAddCourses}>
                + Add New Courses
              </button>
            </div>
          )}
        </div>
      </AcademicSessionManagementListingTop>
      <AcademicSessionManagementListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllSessionAllocatedCourses
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllSessionAllocatedCourses
                )
              }
            />
          </div>
        </div>
        <div
          className={`data-table ${
            isAnyMenuDropdownOpen() ? "menu-opened" : ""
          }`}
        >
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return (
                    <th
                      className={
                        index > 5 ? `extra-head extra-head-${index - 6}` : ""
                      }
                      key={index}
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                const { sections = [], teachers = [] } = item;
                const creditHoursInfo = showCreditHours(item);
                console.log(item);
                return (
                  <Fragment key={index}>
                    <tr
                      className={`expandable ${
                        item.isExpanded ? "opened" : ""
                      }`}
                    >
                      <td>
                        <div className="row-action-cell">
                          <div className="table-checkbox-field">
                            <input
                              type="checkbox"
                              checked={selectedCourses.includes(item.id)}
                              onChange={() => handleCourseSelection(item?.id)}
                              readOnly
                            />
                          </div>
                          <div
                            className="rounded-expand-button"
                            onClick={() => toggleRowExpand(index)}
                          >
                            <span>{item.isExpanded ? "-" : "+"}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.c_code}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.c_title}</div>
                      </td>

                      <td className="curser-pointer">
                        {creditHoursInfo.titles ? (
                          <Fragment>
                            <span
                              title={creditHoursInfo.titles[0].title}
                              className="curser-pointer"
                            >
                              {creditHoursInfo.titles[0].value}
                            </span>
                            <span> - </span>
                            <span
                              title={creditHoursInfo.titles[1].title}
                              className="cursor-pointer"
                            >
                              {creditHoursInfo.titles[1].value}
                            </span>
                          </Fragment>
                        ) : (
                          <span
                            title={creditHoursInfo.title}
                            className="cursor-pointer"
                          >
                            {creditHoursInfo.value}
                          </span>
                        )}
                      </td>

                      <td>
                        <div className="mw-150">
                          {sections.length
                            ? sections.map((section: any) => {
                                return (
                                  <div className="sections">
                                    <div className="data-wrap">
                                      {section?.section_details?.title ?? "-"}
                                      <RedCloseSvg
                                        className="cp"
                                        onClick={() =>
                                          handleDeleteSection(section?.id)
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {teachers.length
                            ? teachers.map((teacher: any) => {
                                const {
                                  teacher_details: {
                                    teacher_firstname,
                                    teacher_lastname,
                                    teacher_title,
                                  },
                                } = teacher || {};
                                const name = `${teacher_title?.title} ${teacher_firstname} ${teacher_lastname}`;
                                return (
                                  <div className="sections">
                                    <div className="data-wrap">
                                      {name}
                                      <RedCloseSvg
                                        className="cp"
                                        onClick={() =>
                                          handleDeleteTeacher(teacher?.id)
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            : "-"}
                        </div>
                      </td>
                    </tr>

                    {item.isExpanded && (
                      <tr className="expanded-row">
                        <td colSpan={7}>
                          <div className="expanded-content bordered">
                            <div className="particular-info particular-info-1">
                              <span className="title">Campus</span>
                              <span className="info">
                                {item?.campus?.title ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">Students Enrolled</span>
                              <span className="info">
                                {item?.enrollments_count ?? "0"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Result Status</span>
                              <span className="info">Not Submitted</span>
                            </div>
                            <div className="particular-info particular-info-4">
                              <span className="title">Is Lab</span>
                              <span className="info">
                                {item?.template_courses?.tc_is_lab ? (
                                  <span className="status-tile green">Yes</span>
                                ) : (
                                  <span className="status-tile yellow">No</span>
                                )}
                              </span>
                            </div>
                            <div className="particular-info particular-info-5">
                              <span className="title">Is Class</span>
                              <span className="info">
                                <div className="switch-field">
                                  <label className="switch-field">
                                    <input
                                      type="checkbox"
                                      checked={item?.is_class == 1}
                                      onChange={(e) =>
                                        handleUpdateClass(e, item.id, index)
                                      }
                                    />
                                    <span className="slider"></span>
                                  </label>
                                </div>
                              </span>
                            </div>
                            <div className="particular-info particular-info-6">
                              <span className="title">Is Thesis</span>
                              <span className="info">
                                {item?.template_courses
                                  ?.tc_is_research_course ? (
                                  <span className="status-tile green">Yes</span>
                                ) : (
                                  <span className="status-tile yellow">No</span>
                                )}
                              </span>
                            </div>
                            <div className="particular-info particular-info-7">
                              <span className="title">Workload Type</span>
                              <span className="info">
                                {" "}
                                {item?.teachers
                                  ?.map(
                                    (teacher: any) =>
                                      `${teacher?.course_type?.title}`
                                  )
                                  .join(", ")}
                              </span>
                            </div>
                            <div className="particular-info particular-info-8 last">
                              <span className="title">Action</span>
                              <div className="table-action-buttons">
                                <div className="action-icon">
                                  <EditTableSvg />
                                </div>
                                <div
                                  className="action-icon"
                                  onClick={() => handleDelete(item?.id)}
                                >
                                  <DeleteTableSvg />
                                </div>
                                <div className="table-action-button black">
                                  <button className="yellow">
                                    Enrollments Data (CSV)
                                  </button>
                                </div>
                                <div
                                  className="table-action-button purple"
                                  onClick={() => goToGradeBooks(item?.id)}
                                >
                                  <button className="purple">
                                    Manage Gradebooks
                                  </button>
                                </div>
                                <div
                                  className="table-action-button purple"
                                  onClick={goToGradeSchema}
                                >
                                  <button className="purple">
                                    View Grading Scheme
                                  </button>
                                </div>
                                <div
                                  className="table-action-button purple"
                                  onClick={() =>
                                    handleOpenAllocateRoomModal(item?.id)
                                  }
                                >
                                  <button className="red">
                                    Course Room Allocation
                                  </button>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
          {openAllocateSectionModal && (
            <AllocateSection
              setOpen={setOpenAllocateSectionModal}
              courses={selectedCourses}
            />
          )}

          {openEnrollmentsModal && (
            <ManageEnrollments
              setOpen={setOpenEnrollmentsModal}
              courses={selectedCourses}
              type={enrollmentType}
            />
          )}
          {openGradeSchemaModal && (
            <GradingSchema setOpen={setOpenGradeSchemaModal} />
          )}
          {openAllocateTeacherModal && (
            <AllocateTeacher
              setOpen={setOpenAllocateTeacherModal}
              course_id={selectedCourses}
            />
          )}
          {allocateCourseRoom && (
            <AllocateCourseRooms
              setOpen={setAlocateCourseRoom}
              course_id={courseId}
            />
          )}
        </Fragment>
      </AcademicSessionManagementListingSection>
    </AcademicSessionManagementListingMain>
  );
};

export default AcademicSessionManagementListing;
