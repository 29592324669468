import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicFeePlanListingMain,
  AcademicFeePlanListingSection,
  AcademicFeePlanListingTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAcademics from "../../useHooks";
import { FeePlanDTO } from "utils/helpers/models/academics/fee-plan.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";

const AcademicFeePlanListing: FC = () => {
  const [search, setSearch] = useState<string>("");
  const { hasAccess } = useStore();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "Name",
    "Is Active",
    "Excess Amount",
    "Action",
  ];
  const { handleSearchChange, handleTableSearch} = useUtils();
  const [data, setData] = useState<FeePlanDTO[]>([]);

  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },

    { title: "Fee Plans", path: siteRoutes.academicFeePlansListing },
  ];
  const { getAcademicFeePlans, deleteAcademicFeePlan, updateAcademicFeePlan } = useAcademics();
  const navigate = useNavigate();

  const goToCreateFeePlan = () => {
    navigate(siteRoutes.createAcademicFeePlan);
  };
  const goToManageFeePlan = () => {
    navigate(siteRoutes.academicManageFeePlanListing);
  };

  useEffect(() => {
    getAllAcademicFeePlans(pagination.page, search, pagination.per_page);
  }, []);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search
      };
      deleteAcademicFeePlan(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllAcademicFeePlans(page + 1, search, per_page);
  };
  const getAllAcademicFeePlans = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getAcademicFeePlans(setData, queryParams, setPagination);
  };

  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.createAcademicFeePlan}?id=${id}`)
  }

  const handleUpdateStatus = async (plan: FeePlanDTO, index: number) => {
    const result = await confirmationPopup();
    if(result.isConfirmed){
      plan.is_active = plan.is_active == 1 ? 0 : 1;
      data[index] = plan;
      setData([...data]);
      updateAcademicFeePlan(plan.id, plan);
    }
  }

  return (
    <AcademicFeePlanListingMain>
      <AcademicFeePlanListingTop>
        <div className="left">
          <span className="page-heading">Fee Plans </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createAcademicFeePlan) && <div className="add-new-button">
            <button className="lg-rounded-btn" onClick={goToCreateFeePlan}>
              + Add Fee Plan
            </button>
          </div>}
        </div>
      </AcademicFeePlanListingTop>

      <AcademicFeePlanListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => handleSearchChange(e, setSearch, pagination.per_page, getAllAcademicFeePlans)}
              onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllAcademicFeePlans)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((plan, index) => (
                <tr key={index}>
                  <td>
                    <div className="mw-150">
                      {plan.title}
                    </div>
                  </td>
                  <td>
                    <div className="switch-field">
                      <label className="switch-field">
                        <input
                          type="checkbox"
                          checked={plan.is_active == 1}
                          onChange={() => handleUpdateStatus(plan, index)}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </td>
                  <td>{plan.excess_amount}</td>
                  <td>
                    <div className="table-action-icons">
                      <div className="action-icon" onClick={() => goToEdit(plan.id)}>
                        <EditTableSvg />
                      </div>
                      <div
                        className="table-action-button"
                        onClick={goToManageFeePlan}
                      >
                        <button className="black">Manage Fee</button>
                      </div>
                      <div className="action-icon" onClick={() => handleDelete(plan.id)}>
                        <DeleteTableSvg />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length}/>
          <Pagination
            onPageChange={onPageChange}
            {...pagination}
          />
        </Fragment>
      </AcademicFeePlanListingSection>
    </AcademicFeePlanListingMain>
  );
};

export default AcademicFeePlanListing;
