import { FC, useEffect, useState } from "react";
import {
  AllocateSectionMain,
  Container,
  ContentWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { AddCourseSectionDTO } from "utils/helpers/models/academics/academic-session.dto";
import useAcademics from "containers/private/academics/useHooks";
import { AcademicSectionDTO } from "utils/helpers/models/academics/academic-section.dto";
import useComponentVisible from "hooks/click-outside";
import MultiselectDropdown from "components/particles/forms/multiselect-dropdown";
import { warningToaster } from "utils/helpers/common/alert-service";
import MultiselectField from "components/particles/forms/multiselect-field";

interface AddAllocateSectionProps {
  setOpen: Function;
  courses: number[];
}

const AllocateSection: FC<AddAllocateSectionProps> = ({ setOpen, courses: course_id }) => {
  const { addCourseSection, getAcademicSections } = useAcademics();
  const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState<boolean>(false);
  const [sections, setSections] = useState<AcademicSectionDTO[]>([]);
  const [formData, setFormData] = useState<AddCourseSectionDTO>(new AddCourseSectionDTO({ course_id, section_id: [] }));

  useEffect(() => {
    getAcademicSections(setSections);
  }, []);

  const onSelectSections = (value: number[]) => {
    formData.section_id = value;
    setFormData({ ...formData });
  }

  const flatSections = (): any[] => {
    const placeholderOption = { indexedTitle: 'Department | Program | Section', disabled: true };
    const flatSections = sections.map((section) => ({ ...section, indexedTitle: `${section.department.title} | ${section.program.title} | ${section.title}` }));
    return [placeholderOption, ...flatSections];
  }

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (!formData.section_id.length) {
      warningToaster('Please select atleast one section');
      return;
    }
    const { course_id, section_id } = formData;
    const body: AddCourseSectionDTO<string> = {
      ...formData,
      course_id: course_id.join(','),
      section_id: section_id.join(',')
    }
    addCourseSection(body, setOpen);
  }

  return (
    <AllocateSectionMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Allocate Section</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="common-fields">
              <div className="sections-field">
                <MultiselectField
                  options={flatSections()}
                  onSelect={onSelectSections}
                  value={formData.section_id}
                  property="indexedTitle"
                  searchable
                  label="Sections"
                  helperText="Sections"
                  onOpen={() => setIsSectionDropdownOpen(true)}
                  onClose={() => setIsSectionDropdownOpen(false)}
                />
              </div>
            </div>
            {!isSectionDropdownOpen ? <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="button" onClick={() => setOpen(false)}>
                  Close
                </button>
                <button className="lg-rounded-btn">
                  Save & Close
                </button>
              </div>
            </div> : ''}
          </form>
        </ContentWrapper>
      </Container>
    </AllocateSectionMain>
  );
};

export default AllocateSection;
