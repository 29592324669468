export class MeritFormulaDTO {
  title: string = "";
  merit: string = "";
  is_active!: number;
  merit_weighted_data: WeightedData[] = [];

  constructor(data: Partial<MeritFormulaDTO> = {}){
    Object.assign(this,data);
  }
}
export class WeightedData {
  certificate_level_id: string = "";
  result_type_id: any = "";
  is_hafiz: number = 0;
  is_hafiz_value: number = 0;
  is_test: number = 0;
  certificate_level_weighted: number = 1;
  test_weighted: number = 1;
  options: string[] = [];
}
