import { TemplateCourseDTO } from "./template-course.dto";

export class PlanOfStudyCourseDTO {
    id!: number;
    pos_id: string = '';
    tc_id: string = '';
    semester_seq_number!: number;
    sequence !: number
    show_on_transcript!: number;
    template_course!: TemplateCourseDTO;
    department_id: string = ''
}