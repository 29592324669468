import { FC, Fragment, useState, useEffect } from "react";
import {
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditGreenTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
  MajorSelectionMain,
  MajorSelectionSection,
  MajorSelectionTop,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const MajorSelection: FC = ({ }) => {
  const breadcrumbLinks = [
    { title: "Academics /", path: "" },
    {
      title: "Program Specialization  /",
      path: siteRoutes.academicProgramSpecializationListing,
    },
    {
      title: "Manage Students  /",
      path: siteRoutes.academicManageStudentListing,
    },
    {
      title: "Applied Program Specialization",
      path: siteRoutes.academicAppliedSpecialization,
    },
  ];

  const columns: string[] = ["Specialization Title", "Action"];

  return (
    <MajorSelectionMain>
      <MajorSelectionTop>
        <div className="left">
          <span className="page-heading">Applied Program Specialization</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </MajorSelectionTop>

      <MajorSelectionSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map((item: any, index: number) => (
                <Fragment key={index}>
                  <tr>
                    <td>
                      <div className="mw-150">Specialization Title Here</div>
                    </td>

                    <td>
                      <div className="table-action-icons">
                        <div className="action-icons">
                          <EditTableSvg />
                        </div>
                        <div className="action-icons">
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            {/* <Pagination
              onPageChange={(page: any) =>  console.log(page)}
              {...pagination}
            /> */}
          </Fragment>
        </div>
      </MajorSelectionSection>
    </MajorSelectionMain>
  );
};

export default MajorSelection;
