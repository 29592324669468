import useStore from "hooks/useStore";
import { USER_AUTH_APIS } from "libs/apis/user-auth.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { UserRegisterDTO } from "utils/helpers/models/auth/user-register.dto";
import { VerityTempTokenDTO } from "utils/helpers/models/auth/verify-temp-token.dto";
import { EportalLoginDTO } from "utils/helpers/models/e-portal/eportal-login-dto";


const useEportalAuth = () => {
    const { setUser, setToken, setPermissions, setRole, setLastLoggedInUserRole, getRelativePathAfterLogin } = useStore();
    const navigate = useNavigate();

    const userRegister = async (body: UserRegisterDTO) => {
        const response = await USER_AUTH_APIS.userRegister(body);
        if (response?.status) {
            successToaster(response?.message);
            userLogin({...body, login_type: body.cnic ? 'cnic' : 'passport'});
        }
    }

    const userLogin = async (body: EportalLoginDTO | UserRegisterDTO) => {
        const response = await USER_AUTH_APIS.userLogin(body);
        if (response?.status) {
            setRole(response?.data?.roles[0]);
            delete response?.data?.role_id;
            delete response?.data?.roles;
            setUser({...response?.data, is_eligible: response?.is_eligible, is_already_apply: response?.is_already_apply});
            setToken(response?.token);
            setPermissions(response?.permissions);
            setLastLoggedInUserRole(ROLES.USER);
            successToaster(response?.message);
            const pathToNavigate = getRelativePathAfterLogin(siteRoutes.ePortalDashboard);
            navigate(pathToNavigate, { replace: true });
            // navigate(siteRoutes.ePortalDashboard, { replace: true });
        }else{
            navigate(siteRoutes.ePortalLogin);
        }
    }

    const userGetOtp = async (body: any, setPortion: Function) => {
        const response = await USER_AUTH_APIS.userGetOtp(body);
        if (response?.original?.status) {
            successToaster(response?.original?.message);
            setPortion(2);
        }
    }

    const userVerifyOtp = async (body: any, setPortion: Function) => {
        const response = await USER_AUTH_APIS.userVerifyOtp(body);
        if (response?.status) {
            successToaster(response?.message);
            setPortion(3);
        }
    }

    const userResetPassword = async (body: any) => {
        const response = await USER_AUTH_APIS.userResetPassword(body);
        if (response?.status) {
            successToaster(response?.message);
            if (response?.status) {
                navigate(siteRoutes.ePortalLogin);
            }
        }
    }

    return {
        userRegister,
        userLogin,
        userGetOtp,
        userVerifyOtp,
        userResetPassword,
    }
}

export default useEportalAuth;