import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicCoursesListingSection,
  AcademicCoursesListingMain,
  AcademicCoursesListingTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { ExcelSvg, PdfSvg, SearchFieldSvg } from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "../../useHooks";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import AllocateSection from "./components/add-clearance";
import SelectCampus from "./components/add-clearance";
import AddClearance from "./components/add-clearance";

const AcademicClearanceListing: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    {
      title: " Student Dashboard /",
      path: "",
    },
    { title: "My Clarance ", path: siteRoutes.academicStudentClearance },
  ];
  const navigate = useNavigate();
  const [data, setData] = useState<TemplateCourseDTO[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = [
    "Clearance Department",
    "Clearance Type",
    "Reason",
    "Status",
    "Remarks By Depaetment",
  ];

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
  };

  const [openClearanceModal, setOpenClearanceModal] = useState<boolean>(false);

  const goToSelectCampus = () => {
    setOpenClearanceModal(true);
  };

  return (
    <AcademicCoursesListingMain>
      <AcademicCoursesListingTop>
        <div className="left">
          <span className="page-heading">My Clarance</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-fine-slot-btn">
            <button className="lg-rounded-btn" onClick={goToSelectCampus}>
              Apply for Clearance
            </button>
          </div>
        </div>
      </AcademicCoursesListingTop>

      <AcademicCoursesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Finance Department</td>

                <td>
                  <div className="mw-150">Fee clearance</div>
                </td>

                <td>Here</td>
                <td>
                  <span className="status-tile green">Yes</span>
                </td>
                <td>Here.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
          {openClearanceModal && (
            <AddClearance setOpen={setOpenClearanceModal} />
          )}
        </Fragment>
      </AcademicCoursesListingSection>
    </AcademicCoursesListingMain>
  );
};

export default AcademicClearanceListing;
