export class TimeTableDTO {
  id!: number;
  title: string = "";
  campus_id: string = "";
  start_date: string = "";
  end_date: string = "";
  is_admin!: number;
  status?: number;
  is_active!: number;

  constructor(data: Partial<TimeTableDTO> = {}) {
    Object.assign(this, data);
  }
}
