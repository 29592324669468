import { CityDTO } from "./city.dto";

export class OrganizationDTO <CityType extends string | CityDTO = string> {
  id!: number;
  name: string = "";
  logo: any = null;
  cover_image: any = null;
  fav_icon: any = null;
  sidebar_dark_logo: any = null;
  sidebar_light_logo: any = null;
  dark_logo: any = null;
  login_page_image : any = null;
  email: string = "";
  password: string = "";
  phone: string = "";
  state: string = "";
  city: CityType;
  add_structure_type: number | string = 0;
  zip_code: number = 0;
  address1: string = "";
  website: string = "";
  username: string = "";
  district: CityType;
  vision: string = "";
  contact_message: string = '';
  contact_heading: string = ''
  mission: string = "";
  primary_color: string = "#128B9B";
  domain: string = "";
  cname: string = "";

  constructor(data: Partial<OrganizationDTO> = {}){
    Object.assign(this,data);
    console.log(this,'this')
  }
}
