import { FC, Fragment, useEffect, useState } from "react";
import {
    DeleteTableSvg,
    DownArrowLightgrayMediumSvg,
    EditTableSvg,
    ExcelSvg,
    PdfSvg,
    SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
    LibraryBooksListingMain,
    LibraryBooksListingSection,
    LibraryBooksListingTop,
    FilterSection,
    FilterHeader,
    Filters,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useTestingServices from "containers/private/testing-services/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useSelector } from "react-redux";
import { TestingCenterRoomDTO } from "utils/helpers/models/testing-service/testing-center-room.dto";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";

const LibraryBooksListing: FC = () => {
    const breadcrumbLinks: BreadcrumbLink[] = [
        { title: 'Student Dashboard / ', path: siteRoutes.academicSessionListing },
        { title: 'Library Books', path: siteRoutes.studentPortalLibraryBooks },
    ]
    const columns: string[] = [
        "Book Name",
        "Author",
        "Home Library",
        "Call No.",
        "Status",
        "Due Date",
        "Barcode",
    ];
    const navigate = useNavigate();
    const { getTestingCenterRooms, deleteTestingCenterRoom, getTestingCenterById, updateTestingCenter } = useTestingServices();
    const [data, setData] = useState<TestingCenterRoomDTO[]>([]);
    const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
    const [center, setCenter] = useState<TestingCenterDTO>(new TestingCenterDTO());
    const { isLoading } = useSelector((state: any) => state.sharedReducer);
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });
    const [search, setSearch] = useState<string>("");
    const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
    const params = getQueryParams();


    useEffect(() => {
        // getAllTestingCenterRooms(pagination.page, search);
        // getTestingCenterById(params?.id, center, setCenter);
    }, []);

    const handleDelete = async (id: number) => {
        const response = await confirmationPopup();
        if (response.isConfirmed) {
            const queryParams = {
                page: 1,
                per_page: pagination.per_page,
            };
            // deleteTestingCenterRoom(id, setData, queryParams, setPagination);
        }
    };

    const toggleFilterDropdown = () => {
        setShowFilterDropdown(!showFilterDropdown);
    }

    const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
        const { selected: page, per_page } = pageInfo;
        setPagination({ ...pagination, page: page + 1, per_page });
        getAllTestingCenterRooms(page + 1, search);
    };
    const getAllTestingCenterRooms = (page: number, search: string) => {
        const queryParams = {
            per_page: pagination.per_page,
            page,
            search,
        };
        // getTestingCenterRooms(setData, queryParams, setPagination);
    };

    return (
        <LibraryBooksListingMain>
            <LibraryBooksListingTop>
                <div className="left">
                    <span className="page-heading">Library Books</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
            </LibraryBooksListingTop>

            <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {showFilterDropdown && (
          <Filters>
            <div className="filter-fields">
                <div className="input-field">
                    <label htmlFor="">Search By Author Name</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input type="text"  placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor="">Search By Book Name</label>
                    <div className="field-wrap">
                        <div className="field">
                            <input type="text"  placeholder="Search" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">
                  Reset
                </button>
                <button
                  className="lg-rounded-btn"
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

            <LibraryBooksListingSection
                isTableOverflowing={false}
                className="content-radius-shadow"
            >
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        <div className="export-btn">
                            <span>
                                <PdfSvg className="icon" />
                            </span>
                            <span className="text">PDF</span>
                        </div>

                        <div className="export-btn">
                            <span>
                                <ExcelSvg className="icon" />
                            </span>
                            <span className="text">Excel</span>
                        </div>
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                            value={search}
                            onChange={(e) =>
                                handleSearchChange(e, setSearch, pagination.per_page, getAllTestingCenterRooms)
                            }
                            onKeyUp={(e) => handleTableSearch(e, pagination.per_page, getAllTestingCenterRooms)}
                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 1, 1, 1, 1].map((room, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="mw-150">
                                            Transaction & Proceedings Of  The American Philogical Association
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mw-150">Khayr al-Din al-Zirikll</div>
                                        </td>
                                        <td>Serat Chair</td>
                                        <td>324.213 K234T</td>
                                        <td>Available</td>
                                        <td>23-04-2022</td>
                                        <td>ML2354</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    {/* <DataNotFound show={!isLoading && !data.length} /> */}
                    <Pagination
                        onPageChange={onPageChange}
                        {...pagination}
                    />
                </Fragment>
            </LibraryBooksListingSection>
        </LibraryBooksListingMain>
    );
};

export default LibraryBooksListing;
