import { AdmissionCampaignDTO } from "../admissions/admission-campaign.dto";
import { TestTypeDTO } from "../admissions/test-type.dto";
import { FinanceApplicationDTO } from "../finance/application.dto";
import { VoucherTemplateHeaderDTO } from "../finance/voucher-template-header.dto";

export class TestScheduleDTO<
  MultiSelectType extends number[] | string = number[]
> {
  id!: number;
  description: string = "";
  test_id: string = "";
  session_id: string = "";
  start_date: string = "";
  end_date: string = "";
  fee: string = "";
  test_date: string = ""; 
  result_date: string = "";
  result_valid_till: string = "";
  challan_upload_date: string = "";
  in_house_close_date: string = "";
  in_house_users: MultiSelectType;
  expected_date: string = "";
  center_ids: MultiSelectType;
  batch: string = "";
  is_phd!: number;
  valid_years: string = "";
  passing_marks: string = "";
  is_active!: number;
  acc_application_id: string = "";
  voucher_template_id: string = "";
  voucher_type!: FinanceApplicationDTO;
  voucher_type_id!: string;
  title?: string;
  test: TestTypeDTO;
  test_applications_count: number;
  voucher_template_header: VoucherTemplateHeaderDTO;
  session: AdmissionCampaignDTO;

  constructor(data: Partial<TestScheduleDTO> = {}) {
    for(let key in data){
      if(key === 'voucher_type'){
        this.voucher_type = new FinanceApplicationDTO(data[key]);
      }else if(key === 'test'){
        this.test = new TestTypeDTO(data[key]);
      }else if(key === 'voucher_template_header') {
        this[key] = new VoucherTemplateHeaderDTO(data[key]);
      }else if(key === 'session'){
        this[key] = new AdmissionCampaignDTO(data[key]);
      }else{
        this[key] = data[key];
      }
    }
  }
}
