import { AcademicSessionDTO } from "../academics/academic-session.dto";
import { OrgStructureDTO } from "../organization/org-structure.dto";

export class TimeTablePlanerDTO<
  MultiSelectType extends number[] | string = number[]
> {
  id!: number;
  title: string = "";
  campus_id: string = "";
  program_id: MultiSelectType;
  faculty_id: MultiSelectType;
  department_id: MultiSelectType;
  section_id: MultiSelectType;
  building_id: MultiSelectType;
  status?: number;
  linked_time_table: MultiSelectType;
  academic_session_id: string = "";
  academic_session: AcademicSessionDTO;
  campus: OrgStructureDTO;
  is_active!: number;

  constructor(data: Partial<TimeTablePlanerDTO> = {}) {
    Object.assign(this, data);
  }
}
