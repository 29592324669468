import { FC, useEffect, useState } from "react";
import {
  LinkProgramToAdmCampaignMain,
  Container,
  ContentWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useOrganization from "containers/private/organization/useHooks";
import useAdmissions from "containers/private/admissions/useHooks";
import useUtils from "hooks/useUtils";
import { ProgramLinkCampaignDTO } from "utils/helpers/models/admissions/program-link-campaign.dto";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import MultiselectField from "components/particles/forms/multiselect-field";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { warningToaster } from "utils/helpers/common/alert-service";

interface Props {
  setOpen: Function;
  admission_session_header_id: number;
  setData: Function;
}

const LinkProgramToAdmissionCampaign: FC<Props> = ({ setOpen, admission_session_header_id, setData }) => {
  const { handleSubmit, register, formState: {errors}, setValue, trigger} = useForm();
  const [formData, setFormData] = useState<ProgramLinkCampaignDTO>(new ProgramLinkCampaignDTO({program_id: [],admission_session_header_id}));
  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const { getPrograms } = useOrganization();
  const { updateLinkedProgramsBySessionId } = useAdmissions();
  const { getQueryParams, compareDateStrings } = useUtils();
  const params = getQueryParams();

  const onSubmit = () => {
    if(compareDateStrings(formData.fee_due_date,formData.class_start_date)){
      updateLinkedProgramsBySessionId(admission_session_header_id, formData, setOpen, setData);
    }else{
      warningToaster('Fee due date must be before the class start date');
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSelectPrograms = (program_id: number[]) => {
    setFormData({ ...formData, program_id });
    setValue('program_id', program_id);
  };

  useEffect(() => {
    const queryParams = { level_id: params?.level_id, per_page: 'All', admission_session_header_id };
    getPrograms(setPrograms, queryParams);
    register('program_id', {required: true});
  }, []);

  const handleChange = (event: any) => {
    const { target: {name, value}} = event;
    setValue(name,value)
    trigger([name]);
    setFormData({...formData, [name] : value});
  }

  return (
    <LinkProgramToAdmCampaignMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Link Program</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
            <MultiselectField
              options={programs}
              onSelect={onSelectPrograms}
              value={formData.program_id}
              label="Programs"
              error={errors.program_id}
              searchable
            />
            <div className="input-field">
                <label>Class Start Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      {...register("class_start_date", { required: true })}
                      value={formData.class_start_date}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.class_start_date} />
                </div>
              </div>
              <div className="input-field">
                <label>Processing Fee Due Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      {...register("fee_due_date", { required: true })}
                      value={formData.fee_due_date}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.fee_due_date} />
                </div>
              </div>
              <div className="radio-field">
                <label>Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <label htmlFor="is-active-yes">Active</label>
                    <input
                      type="radio"
                      id="is-active-yes"
                      value={1}
                      checked={formData.admission_status == 1}
                      {...register("admission_status", { required: true })}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="is-active-no">Deactivate</label>
                    <input
                      type="radio"
                      id="is-active-no"
                      value={0}
                      checked={formData.admission_status == 0}
                      {...register("admission_status", { required: true })}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <FormErrorMessage error={errors.admission_status} />
              </div>
            </div>
              <div className="action-buttons">
                <div className="buttons">
                  <button
                    className="lg-rounded-btn gray"
                    type="reset"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>

                  <button
                    className="lg-rounded-btn"
                    type="submit"
                  >
                    Save & Close
                  </button>
                </div>
              </div>
          </form>
        </ContentWrapper>
      </Container>
    </LinkProgramToAdmCampaignMain>
  );
};

export default LinkProgramToAdmissionCampaign;
