import { getRequest, postRequest } from "utils/helpers/common/http-methods";

export const DSSYSTEM_APIS = {
  getOrgDataForAdminDashboard: () =>
    getRequest("/admin/dashboards/organization"),

  getAdmissionDashboard: (params: any) =>
    getRequest("/admin/dashboards/admissions", params),
  getFilterDataForAdmDashboard: () =>
    getRequest(`/admin/admission_session/years`),
  getCourseAnomalies: (params: any) => getRequest(`admin/dashboard/academic/course/anomalies`, params),
  getEnrolledStudents: (params: any) => getRequest(`admin/dashboard/academic/enrolled/students`, params),
  getNotEnrolledStudents: (params: any) => getRequest(`admin/dashboard/academic/not/enrolled/students`, params),
  getStudentAnomalies: (params: any) => getRequest(`admin/dashboard/academic/student/anomalies`, params),
  getAcademicsData: (params: any) => getRequest(`admin/dashboard/academic/academic-data`, params),
  getEnrolledCoursesAndTeachers: (params: any) => getRequest(`admin/dashboard/academic/enrolled/courses-teachers`, params),
};
