import { FC, Fragment, useEffect, useState } from "react";
import { Main, STListingSection, EmailTempTop } from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import {
    DeleteTableSvg,
    EditTableSvg,
    ExcelSvg,
    PdfSvg,
    // ExcelSvg,
    // PdfSvg,
    SearchFieldSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useTimeTable from "../../UseHooks";
import { TimeTableDTO } from "utils/helpers/models/time-table/time-table.dto";


interface StructureTypeListingProps { }

const TimeTableListing: FC<StructureTypeListingProps> = () => {
    const breadcrumbLinks = [
        {
            title: "Time Table /",
            path: "",
        },
        {
            title: "Time Table Listing ",
            path: siteRoutes.timeTableListing,
        },
    ];

    const columns = [
        "Title",
        "Start Date",
        "End Date",
        "Campus",
        "Status",
        "Action",

    ];

    const {
        deleteTimeTableById,
        getTimeTableList,
        updateTimeTableById,
    } = useTimeTable();

    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const { handleSearchChange, handleTableSearch } = useUtils();

    const [data, setData] = useState<TimeTableDTO[]>([]);

    const { hasAccess } = useStore();
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });

    const { isLoading } = useSelector((state: any) => state.sharedReducer);

    const handleDelete = async (id: number) => {
        const result = await confirmationPopup();
        if (result.isConfirmed) {
            const queryParams = {
                per_page: pagination.per_page,
                page: pagination.page,
                search,
            };
            deleteTimeTableById(id, setData, queryParams, setPagination);
        }
    };

    const handleChangeTimeTablePlannerStatus = async (timetable: TimeTableDTO, index: number) => {
        const response = await confirmationPopup();
        if (response.isConfirmed) {
            const { id } = timetable;
            data[index] = timetable;
            setData([...data]);
            updateTimeTableById(id, timetable);
        }
    }


    const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
        const { selected: page, per_page } = pageInfo;
        setPagination({ ...pagination, page: page + 1, per_page });
        getAllTimeTableList();
    };

    const getAllTimeTableList = () => {
        const queryParams = {
            per_page: pagination.per_page,
            page: pagination.page,
            search,
        };
        getTimeTableList(setData, queryParams, setPagination);
    };

    const updateTimeTableByID = (id: number) => {
        navigate(`${siteRoutes.timeTableCreate}?id=${id}`
        );
    };

    const GoToAddTimeTable = () => {
        navigate(siteRoutes.timeTableCreate);
    };

    useEffect(() => {
        getAllTimeTableList();
    }, [])

    return (
        <Main>
            <EmailTempTop>
                <div className="left">
                    <span className="page-heading">Time Table Listing</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">
                    {hasAccess(sitePermissions.timeTableCreate) && (
                        <div className="create-org-btn">
                            <button className="lg-rounded-btn" onClick={GoToAddTimeTable}>
                                + Add Time Table
                            </button>
                        </div>
                    )}
                </div>
            </EmailTempTop>

            <STListingSection className="content-radius-shadow">
                <div className="list-detail">
                    <div className="page-sub-heading">
                        <span>Time Table Listing</span>
                    </div>
                </div>
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        {/* {hasAccess(sitePermissions.downloadStructureTypesListPdf) && ( */}
                        <div className="export-btn">
                            <span>
                                <PdfSvg className="icon" />
                            </span>
                            <span className="text">PDF</span>
                        </div>
                        {/* )} */}
                        {/* {hasAccess(sitePermissions.downloadStructureTypesListExcel) && ( */}
                        <div className="export-btn">
                            <span>
                                <ExcelSvg className="icon" />
                            </span>
                            <span className="text">Excel</span>
                        </div>
                        {/* )} */}
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                            value={search}
                        // onChange={(e) => handleSearchChange(e, setSearch, pagination.per_page, getAllStructureTypes)}
                        // onKeyUp={e => handleTableSearch(e, pagination.per_page, getAllStructureTypes)}
                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>

                            {data?.map((timetable, index: number) => {
                                const { is_active } = timetable;
                                return (
                                    <tr key={index}>
                                        <td>{timetable?.title ?? "--"}</td>
                                        <td>{timetable?.start_date ?? "--"}</td>
                                        <td>{timetable?.end_date ?? "--"}</td>
                                        <td>{timetable?.title ?? "--"}</td>
                                        <td>
                                            <label className="switch-field">
                                                <input type="checkbox" checked={is_active == 1} onChange={({ target: { checked } }) => handleChangeTimeTablePlannerStatus({ ...timetable, is_active: checked ? 1 : 0 }, index)} />
                                                <span className="slider"></span>
                                            </label>
                                        </td>

                                        <td>
                                            <div className="table-action-icons">
                                                <Fragment>
                                                    {hasAccess(sitePermissions.timeTableUpdate) && (
                                                        <div
                                                            className="action-icon"
                                                            onClick={() => updateTimeTableByID(timetable?.id)}
                                                        >
                                                            <EditTableSvg />
                                                        </div>
                                                    )}
                                                    {hasAccess(sitePermissions.timeTableDelete) && (
                                                        <div
                                                            className="action-icon"
                                                            onClick={() => handleDelete(timetable?.id)}
                                                        >
                                                            <DeleteTableSvg />
                                                        </div>
                                                    )}

                                                    <div className="action-button">
                                                        <button>Activities</button>
                                                    </div>
                                                </Fragment>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    <DataNotFound show={!isLoading && !data?.length} />
                    <Pagination onPageChange={onPageChange} {...pagination} />
                </Fragment>
            </STListingSection>
        </Main >
    );
};

export default TimeTableListing;
