import { FC } from "react";
import { BreadCrumbLink, BreadCrumbMain } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";

interface BreadcrumbProps {
    links: BreadcrumbLink[]
}

export interface BreadcrumbLink {
    title: string;
    path: string;
    state?: any;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ links }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { hasAccess, getPermissionByRoute } = useStore();

    const handleNavigation = (link: BreadcrumbLink) => {
        const { path: route = '', state = null} = link;
        if(route.split('?')[0] === pathname) return;
        const permission = getPermissionByRoute(route.split('?')[0]);
        if (hasAccess(permission)) navigate(route, {state});
    };

    return (
        <BreadCrumbMain>
            {links.map((link, index) => {
                return (
                    <BreadCrumbLink
                        $active={pathname === link.path.split('?')[0]}
                        key={index}
                        onClick={() => handleNavigation(link)}
                    >
                        {link.title}
                    </BreadCrumbLink>
                );
            })}
        </BreadCrumbMain>
    );
};

export default Breadcrumb;
